import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { DDAuthorisationAccount } from "@app/products/direct-debit/authorisations/[id]/components/accordion/accounts/_index";
import { DDAuthorisationAddAccountButton } from "@app/products/direct-debit/authorisations/[id]/components/buttons/add-account/_index";
import { SaveButtonDirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/[id]/components/buttons/save/_index";
import { DirectDebitAuthorisationForm } from "@app/products/direct-debit/authorisations/[id]/components/general/_index";
import { AuthorisationsHistoryTab } from "@app/products/direct-debit/authorisations/[id]/components/slidebar/history/_index";
import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import { DDContactsTab } from "@app/products/direct-debit/components/sidebar/contact";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export const ExistManageDirectDebitAuthorisation = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const { currentUserInfo } = useGlobalStore();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const location = useLocation();

  const directDebitAuthorisation = useMemo(() => {
    return dataForms?.GeneralForm as DirectDebitAuthorisation;
  }, [dataForms?.GeneralForm]);
  const authorisationNumber =
    directDebitAuthorisation?.Authorisation_Number ?? "";

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Direct Debit - Authorisation - ${directDebitAuthorisation?.Authorisation_Id}`,
      Text: `Direct Debit - Authorisation`,
      LinkUrl: location.pathname,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.DirectDebit,
      RecordType_ENUM: RECORDTYPE.Receipting_DirectDebitAuthorisation,
      Record_ID: directDebitAuthorisation?.Authorisation_Id,
    },
  ];

  const listPanelBar: ITabProps[] = [
    {
      title: "Accounts",
      tabType: TabTableType.DDAccount,
      component: <DDAuthorisationAccount />,
    },
  ];

  const { checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.DirectDebit_Dialog_Authorisation,
    productType: PRODUCT_TYPE_NUMBER.DirectDebit,
  });

  const { checkPermissions: checkPermissionsAccount } = useCheckPermissions({
    formIdentifier: FormIdentifier.DirectDebit_Dialog_Account,
    productType: PRODUCT_TYPE_NUMBER.DirectDebit,
  });

  const getFormTitle = () => {
    if (!directDebitAuthorisation) return "Authorisation";
    var title = `${directDebitAuthorisation?.Authorisation_Number}`;
    if (directDebitAuthorisation?.Authorised_By_Name)
      title += ` - ${directDebitAuthorisation?.Authorised_By_Name}`;
    return title;
  };

  const getFormBadge = useMemo(() => {
    if (!directDebitAuthorisation) return undefined;
    if (directDebitAuthorisation?.Core_Contact_VIP) return ["VIP"];
    return undefined;
  }, [directDebitAuthorisation]);

  const isDisableAccountButton = !checkPermissionsAccount([
    FormAction.CORE_ALLOW_NEW,
    FormAction.CORE_ALLOW_SAVE,
  ]);

  const MemoizedDDAuthorisationAddAccountButton = useMemo(() => {
    return (
      <DDAuthorisationAddAccountButton
        Authorisation_Id={directDebitAuthorisation?.Authorisation_Id}
        Authorisation_Number={directDebitAuthorisation?.Authorisation_Number}
        isDisabled={isDisableAccountButton}
      />
    );
  }, [
    directDebitAuthorisation?.Authorisation_Id,
    directDebitAuthorisation?.Authorisation_Number,
    isDisableAccountButton,
  ]);

  return (
    <>
      <FormTitle title={getFormTitle()} badge={getFormBadge} />
      <CCManagePageActionBar
        leftActions={[
          <SaveButtonDirectDebitAuthorisation
            disabled={!checkPermissions(FormAction.CORE_ALLOW_EDIT)}
          />,
          <CCNavButton title={"Add"} type="sub">
            {MemoizedDDAuthorisationAddAccountButton}
          </CCNavButton>,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,
          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {directDebitAuthorisation && <DirectDebitAuthorisationForm />}
            <TabTable
              id={+authorisationNumber}
              recordType={RECORDTYPE.Receipting_DirectDebitAuthorisation}
              initialPanels={listPanelBar}
            />
          </div>
        </div>

        {/* Handle later after Confirmation */}
        {showSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                {
                  title: "Contacts",
                  component: (
                    <DDContactsTab
                      existedContact={
                        dataForms?.GeneralForm?.CCFormOptions?.initialContact
                      }
                    />
                  ),
                },
                {
                  title: "History",
                  component: <AuthorisationsHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});
