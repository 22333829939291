import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ChangeInstalmentButton } from "@app/products/property/assessments/components/action-bar/buttons/change-instalment/_index";
import { HousekeepingButton } from "@app/products/property/assessments/components/action-bar/buttons/housekeeping/_index";
import { ReAllocationOfCreditsButton } from "@app/products/property/assessments/components/action-bar/buttons/re-allocation-of-credits/_index";
import { RebateClaimDetailTab } from "@app/products/property/assessments/rebates/rebate-claims/[id]/components/reference-sidebar/detail/_index";
import { ASSESSMENTS_PENSIONER_OR_REBATES_REBATE_CLAIMS_ROUTE } from "@app/products/property/assessments/rebates/rebate-claims/[id]/constant";
import { DeleteClaimButton } from "@app/products/property/assessments/rebates/rebate-claims/components/action-bar/buttons/delete-claim/_index";
import { PROPERTY_ASSESSMENT_REBATES_CLAIMS_VIEW_URL } from "@app/products/property/assessments/rebates/rebate-claims/constant";
import { VO_Rebate_Claim } from "@app/products/property/assessments/rebates/rebate-claims/model";
import { useRebatesClaimsStore } from "@app/products/property/assessments/rebates/rebate-claims/store";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/property/assessments/rebates/rebate-claims/util";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { propertyRoute } from "@app/products/property/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";
import { NewRebatesClaimsButton } from "./components/action-bar/buttons/new-rebate-claim/_index";

const nameOf = nameOfFactory<VO_Rebate_Claim>();
export default observer(() => {
  //store
  const {
    isLoading,
    responseLoadError,
    reLoadApiConfig,
    columnConfigRebatesClaims,
    loadViewConfigRebateClaims,
  } = useRebatesClaimsStore();

  //using hook
  useEffectOnce(() => {
    loadViewConfigRebateClaims();
  });

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={assessmentsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <NewRebatesClaimsButton />
        <DeleteClaimButton />
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Assessment}
        recordType={RECORDTYPE.CommunityProperty_Rebate_Claim}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Rebate_Claim}
        keyField="Id"
      />,
      <CCNavButton title={"Tools"} type="more">
        <HousekeepingButton />
        <ReAllocationOfCreditsButton />
        <ChangeInstalmentButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={ASSESSMENTS_PENSIONER_OR_REBATES_REBATE_CLAIMS_ROUTE}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        productType={PRODUCT_TYPE.CommunityProperty}
        displayName={getBookmarkDisplayName}
        detail={getBookmarkDetail}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <RebateClaimDetailTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Rebate_Claim}
          />
        ),
      },
    ],
  });

  //show Loading
  if (isLoading) return <Loading isLoading isFullScreen />;

  //Show load failed and reload
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          reLoadApiConfig();
        }}
      />
    );

  return (
    <CCProductListView
      dataUrl={PROPERTY_ASSESSMENT_REBATES_CLAIMS_VIEW_URL}
      columnFields={columnConfigRebatesClaims}
      primaryField={nameOf("Rebate_Claim_Id")}
    />
  );
});
