import "@app/products/animals/components/forms/components/form-element/_index.scss";
import {
  DirectDebitBankAccount,
  DirectDebitStatusCode,
} from "@app/products/direct-debit/system-admin/model";
import { directDebitBankAccountPickerOptions } from "@app/products/direct-debit/system-admin/status-codes/[id]/components/general/components/form-element/util";
import { stringLengthValidator } from "@app/products/direct-debit/system-admin/util";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { default as React } from "react";

export interface IDirectDebitStatusCodeFormElementProps {
  isInactive?: boolean;
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<DirectDebitStatusCode>();

export const DirectDebitStatusCodeFormElement = ({
  isInactive = false,
  formRenderProps,
}: IDirectDebitStatusCodeFormElementProps) => {
  const { valueGetter, onChange } = formRenderProps;

  const getValue = (nameOfField: keyof DirectDebitStatusCode) =>
    valueGetter(nameOf(nameOfField));

  const onChangeField = (nameOfField: string, value: any) => {
    onChange(nameOf(nameOfField as keyof DirectDebitStatusCode), {
      value: value,
    });
  };

  const ddBankAccountsPickerHandler = async (value: DirectDebitBankAccount) => {
    onChangeField(
      "Direct_Debit_Bank_Account_Id",
      value?.Direct_Debit_Bank_Account_Id
    );
    onChangeField(
      "Direct_Debit_Bank_Acc_Name",
      value?.Direct_Debit_Bank_Acc_Name
    );
    onChangeField("Direct_Debit_Bank_Description", value?.Description);
  };

  return (
    <FormElement id="cc-form-manage-page-general">
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <div className="cc-field">
            <CCLabel title="Bank account" isMandatory />
            <Field
              component={InputPickerSearch}
              name={nameOf("Direct_Debit_Bank_Description")}
              value={getValue("Direct_Debit_Bank_Description")}
              options={directDebitBankAccountPickerOptions}
              onChange={ddBankAccountsPickerHandler}
              disabled={isInactive}
              validator={requiredValidator}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Status code" isMandatory />
            <Field
              component={CCInput}
              name={nameOf("Status_Code")}
              disabled={isInactive}
              validator={(value) => stringLengthValidator(value, 1, 20)}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Status description" />
            <Field
              component={CCInput}
              name={nameOf("Status_Description")}
              disabled={isInactive}
              validator={(value) => stringLengthValidator(value, 0, 50)}
            />
          </div>
          <div className="cc-field">
            <CCLabel title="Is rejection" />
            <Field
              component={CCSwitch}
              name={nameOf("Is_Rejected")}
              disabled={isInactive}
              checked={getValue("Is_Rejected")}
            />
          </div>
        </div>
      </section>
    </FormElement>
  );
};
