import { DirectDebitBankAccount } from "@app/products/direct-debit/system-admin/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const getDirectDebitBankAccountById = async (
  directDebitBankAccountId: number,
  cancelToken: CancelToken
): Promise<APIResponse<DirectDebitBankAccount> | undefined> => {
  try {
    return await CoreAPIService.getClient().get<DirectDebitBankAccount>(
      `api/receipting/internal/directdebitbankaccount/${directDebitBankAccountId}`,
      {
        cancelToken,
      }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postSaveDirectDebitBankAccount = async (
  directDebitBankAccount: DirectDebitBankAccount
): Promise<APIResponse<IdentityPacket> | undefined> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/receipting/internal/directdebitbankaccount",
      directDebitBankAccount
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
