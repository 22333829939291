import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { DDSessionLineByAccountNumber } from "@app/products/direct-debit/accounts/[id]/components/accordion/transfers/_index";
import { SaveButtonDirectDebitAccount } from "@app/products/direct-debit/accounts/[id]/components/button/save";
import { SetStatusButtonDirectDebit } from "@app/products/direct-debit/accounts/[id]/components/button/set-status";
import { DirectDebitAccountForm } from "@app/products/direct-debit/accounts/[id]/components/general/_index";
import { AccountHistoryTab } from "@app/products/direct-debit/accounts/[id]/components/slidebar/history/_index";
import {
  checkDisabledStatus,
  checkDisabledStatusWorkflow,
} from "@app/products/direct-debit/accounts/[id]/util";
import {
  DirectDebitAccountStatus,
  ManageDirectDebitAccountProps,
  UsedDirectDebitAccountStatus,
} from "@app/products/direct-debit/accounts/model";
import { DDContactsTab } from "@app/products/direct-debit/components/sidebar/contact";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { EWatchStatusLoading } from "@common/stores/route/model";
import { routeDataStoreInstance } from "@common/stores/route/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistManageDirectDebitAccount = observer(() => {
  const { id } = useParams<{ id: string }>();

  // #region STORE
  const { currentUserInfo } = useGlobalStore();
  const { dataForms } = useFlexibleFormStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.DirectDebit_Dialog_Account,
    productType: PRODUCT_TYPE_NUMBER.DirectDebit,
  });
  // #endregion

  const [showSlideBar, setShowSlideBar] = useState(true);

  const directDebitAccount = useMemo(() => {
    return dataForms?.GeneralForm as ManageDirectDebitAccountProps;
  }, [dataForms?.GeneralForm]);

  const listPanelBar: ITabProps[] = [
    {
      title: "Transfers",
      tabType: TabTableType.DDTransfers,
      component: (
        <DDSessionLineByAccountNumber
          accountNumber={directDebitAccount?.Account_Number}
        />
      ),
    },
  ];

  const getFormTitle = useMemo(() => {
    if (!directDebitAccount) return "Direct Debit Account";
    var title = `${directDebitAccount?.Account_Number}`;
    if (directDebitAccount?.CCFormOptions?.initialName)
      title += ` - ${directDebitAccount?.CCFormOptions?.initialName}`;
    if (directDebitAccount?.Integration_Module_Type_Name)
      title += ` - ${directDebitAccount?.Integration_Module_Type_Name}`;
    return title;
  }, [directDebitAccount]);

  const getFormBadge = useMemo(() => {
    if (!directDebitAccount) return undefined;
    const badges = [];
    if (directDebitAccount?.AccountStatus_Name)
      badges.push(directDebitAccount.AccountStatus_Name);
    if (directDebitAccount?.Core_Contact_VIP) badges.push("VIP");
    return badges.length === 0 ? undefined : badges;
  }, [directDebitAccount]);

  const bookmarkList: IBookmarkItem[] = [
    {
      LinkText: `Direct Debit - Account - ${id ?? 0}`,
      Text: "Direct Debit - Account",
      LinkUrl: window.location.pathname,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.DirectDebit,
      RecordType_ENUM: RECORDTYPE.Receipting_DirectDebit_Account,
      Record_ID: +id ?? 0,
    },
  ];

  useEffect(() => {
    routeDataStoreInstance.setLoadingList(
      EWatchStatusLoading.IsLoadingPermission,
      isLoadingPermission
    );
  }, [isLoadingPermission]);

  const isDisabledButton = useCallback(
    (buttonType: UsedDirectDebitAccountStatus) => {
      const disabledStatus = checkDisabledStatusWorkflow(
        directDebitAccount?.AccountStatus_ENUM as UsedDirectDebitAccountStatus
      );
      return disabledStatus[buttonType];
    },
    [directDebitAccount?.AccountStatus_ENUM]
  );

  const isDisableEdit =
    !checkPermissions(FormAction.CORE_ALLOW_EDIT) ||
    checkDisabledStatus(directDebitAccount?.AccountStatus_ENUM);

  const MemoizedSaveButton = useMemo(() => {
    return <SaveButtonDirectDebitAccount isDisabled={isDisableEdit} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisableEdit]);

  return (
    <>
      <FormTitle title={getFormTitle} badge={getFormBadge} />
      <CCManagePageActionBar
        leftActions={[
          <>{MemoizedSaveButton}</>,
          <SetStatusButtonDirectDebit
            status={DirectDebitAccountStatus.Active}
            title="Set active"
            disabled={
              !checkPermissions(FormAction.CORE_ALLOW_ACTIVATE) ||
              isDisabledButton(DirectDebitAccountStatus.Active)
            }
          />,
          <SetStatusButtonDirectDebit
            status={DirectDebitAccountStatus.Suspended}
            title="Suspended"
            disabled={
              !checkPermissions(FormAction.DIRECTDEBIT_FORM_SETSUSPEND) ||
              isDisabledButton(DirectDebitAccountStatus.Suspended)
            }
          />,
          <SetStatusButtonDirectDebit
            status={DirectDebitAccountStatus.Cancelled}
            title="Cancelled"
            disabled={
              !checkPermissions(FormAction.DIRECTDEBIT_FORM_SETCANCEL) ||
              isDisabledButton(DirectDebitAccountStatus.Cancelled)
            }
          />,
          <SetStatusButtonDirectDebit
            status={DirectDebitAccountStatus.Terminated}
            title="Terminated"
            disabled={
              !checkPermissions(FormAction.DIRECTDEBIT_FORM_SETTERMINATE) ||
              isDisabledButton(DirectDebitAccountStatus.Terminated)
            }
          />,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,
          <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-manage-left">
          <CCAppNotification />
          <YieldNotificationPortal placeId={TypePlaceId.Main} />
          <div className="cc-manage-form-body">
            {directDebitAccount && <DirectDebitAccountForm />}
            <TabTable
              id={parseInt(dataForms?.GeneralForm?.Account_ID)}
              recordType={RECORDTYPE.Receipting_DirectDebit_Account}
              initialPanels={listPanelBar}
            />
          </div>
        </div>

        {/* Handle later after Confirmation */}
        {showSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                {
                  title: "Contact",
                  component: (
                    <DDContactsTab
                      existedContact={
                        dataForms?.GeneralForm?.CCFormOptions?.initialContact
                      }
                    />
                  ),
                },
                {
                  title: "History",
                  component: <AccountHistoryTab />,
                },
              ]}
            />
          </div>
        )}
      </div>
    </>
  );
});
