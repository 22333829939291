import {
  ButtonStates,
  DirectDebitAccount,
  DirectDebitAccountStatus,
  IntegrationModuleItemType,
} from "@app/products/direct-debit/accounts/model";
import { DBRowState } from "@common/models/baseClassStandard";
import { flexibleFormStoreInstance } from "@common/stores/flexible-form/store";

export const checkIsInactiveDirectDebitAccount = (): boolean => {
  const data = flexibleFormStoreInstance?.dataForms
    ?.GeneralForm as DirectDebitAccount;
  return (data?.Sys_DBRowState as DBRowState) === DBRowState.Inactive;
};
export const changeStatusNotification = (
  status: DirectDebitAccountStatus
): string => {
  switch (status) {
    case DirectDebitAccountStatus.Active:
      return `Set active direct debit`;
    case DirectDebitAccountStatus.Cancelled:
      return `Cancel direct debit`;
    case DirectDebitAccountStatus.Suspended:
      return `Suspend direct debit`;
    case DirectDebitAccountStatus.Terminated:
      return `Terminate direct debit`;
    default:
      return "";
  }
};

export const getKeyValueFromLOVs = (
  itemTypeData: IntegrationModuleItemType[]
) => {
  return itemTypeData.map((item) => {
    return {
      Key: item.Item_Type_ID,
      Value: item.Name,
      Register_ID: item.Register_ID,
    };
  });
};

export const checkDisabledStatus = (
  AccountStatus_ENUM: DirectDebitAccountStatus
): boolean => {
  return (
    AccountStatus_ENUM === DirectDebitAccountStatus.Cancelled ||
    AccountStatus_ENUM === DirectDebitAccountStatus.Terminated ||
    AccountStatus_ENUM === DirectDebitAccountStatus.Suspended
  );
};
export const checkDisabledStatusWorkflow = (
  status: DirectDebitAccountStatus | undefined
): ButtonStates => {
  const defaultState: ButtonStates = {
    [DirectDebitAccountStatus.Active]: false,
    [DirectDebitAccountStatus.Suspended]: false,
    [DirectDebitAccountStatus.Cancelled]: false,
    [DirectDebitAccountStatus.Terminated]: false,
  };

  if (status === undefined) return defaultState;

  switch (status) {
    case DirectDebitAccountStatus.Active:
      return { ...defaultState, [DirectDebitAccountStatus.Active]: true };
    case DirectDebitAccountStatus.Suspended:
      return {
        ...defaultState,
        [DirectDebitAccountStatus.Suspended]: true,
        [DirectDebitAccountStatus.Terminated]: true,
      };
    case DirectDebitAccountStatus.Cancelled:
      return {
        ...defaultState,
        [DirectDebitAccountStatus.Suspended]: true,
        [DirectDebitAccountStatus.Active]: true,
        [DirectDebitAccountStatus.Cancelled]: true,
      };
    case DirectDebitAccountStatus.Terminated:
      return {
        ...defaultState,
        [DirectDebitAccountStatus.Active]: true,
        [DirectDebitAccountStatus.Suspended]: true,
        [DirectDebitAccountStatus.Cancelled]: true,
        [DirectDebitAccountStatus.Terminated]: true,
      };
    default:
      return defaultState;
  }
};
