import { useCommonCoreStore } from "@common/stores/core/store";
import { Button } from "@progress/kendo-react-buttons";
import { isArray, map } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import "./_index.scss";

interface IFormNavigationProps {
  title?: string;
  id?: string;
  badge?: number | string | (string | undefined)[];
}

// Common Title template
export const FormNavigation = observer(
  ({ title, id, badge }: IFormNavigationProps) => {
    const history = useHistory();
    const { historyStack } = useCommonCoreStore();

    const renderBadge = () => {
      if (!badge) return "";
      if (isArray(badge) && badge.length) {
        return (
          <div className="cc-list-badge">
            {map(
              badge,
              (item: string | undefined) =>
                item && (
                  <span key={item} className="cc-standard-badge">
                    {item}
                  </span>
                )
            )}
          </div>
        );
      }
      return <span className="cc-standard-badge">{badge}</span>;
    };

    const handleNavigation = useCallback(() => {
      /* When the user opens a page on a new tab(historyStack.length always equals 0 and history.length always equals 2), click the back button.
      At this time, the browser doesn't have any history so we need to push the user to the home page */
      return historyStack?.length === 0 && history?.length === 2
        ? history.push("/")
        : history.goBack();
    }, [historyStack, history]);

    return (
      <div className="cc-common-form-navigation">
        <Button
          className="cc-rounded-back-button"
          onClick={handleNavigation}
          iconClass="fal fa-arrow-left"
        />

        <h5 className="cc-form-title" id={id ?? ""}>
          {title?.toLocaleUpperCase()}
        </h5>
        {renderBadge()}
      </div>
    );
  }
);
