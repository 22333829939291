import {
  CompositeFilterDescriptor,
  GroupResult,
} from "@progress/kendo-data-query";

export const totalColumnField = (
  field: string,
  data: any[] = []
): number | null => {
  let countEmptyRow = 0;
  const total = data?.reduce((acc: number, current: any) => {
    let currentValue = current[field];
    if (currentValue === undefined || currentValue === null) {
      currentValue = 0;
      countEmptyRow++;
    }
    return acc + currentValue;
  }, 0);
  return countEmptyRow === data.length ? null : total;
};

export const calculateSelectedTotalColumn = (
  field: string,
  data: any
): number | null => {
  const selectedList = data.filter((item: any) => item.selected === true);
  return totalColumnField(field, selectedList);
};

const getItemsFromGroupResult = (
  groupResult: GroupResult | Object,
  items: Object[]
): Object[] => {
  if (!groupResult) {
    return [];
  }

  if (!groupResult.hasOwnProperty("field")) {
    items.push(groupResult);
    return items;
  }
  for (const group of (groupResult as GroupResult).items) {
    getItemsFromGroupResult(group, items);
  }
  return items;
};

export const getItemsFromGroupResults = (
  groupResults: GroupResult[]
): Object[] => {
  if (!groupResults || groupResults.length === 0) {
    return [];
  }

  const items: Object[] = [];
  for (const group of groupResults) {
    getItemsFromGroupResult(group, items);
  }
  return items;
};

const isCompositeFilterDescriptor = (
  object: any
): object is CompositeFilterDescriptor => {
  return object && "filters" in object;
};

export const isErrorFilter = (
  compositeFilter: CompositeFilterDescriptor
): boolean => {
  if (!isCompositeFilterDescriptor(compositeFilter)) return false;
  if (compositeFilter.filters.length <= 0) return true;
  return compositeFilter.filters.some((item: any) => {
    return isErrorFilter(item);
  });
};
