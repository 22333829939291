import { DTO_Journal } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/new-transaction/model";
import { DTO_AssessmentTransaction } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_Supplementary_Create } from "@app/products/property/supplementary-rates/components/form-elements/new-supplementary/model";

export enum EKeysOfStepsAdjustChargeBalances {
  CreateJournal = "CreateJournal",
  CreateSupplementary = "CreateSupplementary",
  AssessmentCharges = "AssessmentCharges",
  ChargeBalanceAdjustments = "ChargeBalanceAdjustments",
  TransactionDetails = "TransactionDetails",
  Comments = "Comments",
  Documents = "Documents",
}

export const keysOfSendStepsAdjustChargeBalances = [
  EKeysOfStepsAdjustChargeBalances.CreateJournal,
  EKeysOfStepsAdjustChargeBalances.CreateSupplementary,
  EKeysOfStepsAdjustChargeBalances.AssessmentCharges,
  EKeysOfStepsAdjustChargeBalances.TransactionDetails,
];

export interface RequestJournalAssessmentAdjustChargeBalancesObj {
  AssessmentId: number;
  Charge_Ids: number[];
}

export interface DTO_Workflow_JournalAssessmentAdjustChargeBalances {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_JournalAssessmentAdjustChargeBalances;
}

export interface DTO_WorkflowDetail_JournalAssessmentAdjustChargeBalances {
  Journal_Number: number | null;
  Assessment_Id: number;
  JournalMode: number;
  SupplementaryMode: number;
  CreateSupplementary: DTO_Supplementary_Create;
  CreateJournal: DTO_Journal;
  AssessmentCharges: DTO_AssessmentCharge[];
  TransactionDetails: DTO_AssessmentTransaction;
}

export interface DTO_AssessmentChargeBalances {
  Charge_Balances_Id: number;
  Levy_Name: string;
  CI_Instalment_Number: number | null;
  CB_Original_Amount: number | null;
  CA_Charge_Date: Date | null;
  CB_Balance: number | null;
  CB_AmendBalanceBy: number;
  CB_AmendedBalance: number;
  CB_Interest: number | null;
  CB_AmendInterestBy: number;
  CB_AmendedInterest: number;
  Charge_Instalment_Id: number;
}

export interface DTO_AssessmentCharge {
  Adjustment_Amount: number | null;
  ChargeDate: Date | null;
  GrossAmount: number | null;
  RebatesGiven: number | null;
  Balance: number | null;
  Charge_Interest_Balance: number | null;
  Name: string;
  Code: string;
  Description: string;
  RatingPeriod: string;
  Charge_Id: number;
  AssessmentChargeBalances: DTO_AssessmentChargeBalances[];
  Charge_Installments: DTO_ChargeInstallments[];
}

export interface DTO_ChargeInstallments {
  Installment_No: number;
  Due_Date: Date;
  Original_Amount: number | null;
  Current_Balance: number | null;
  Current_Interest: number | null;
  New_Balance: number | null;
  New_Interest: number | null;
  Charge_Instalment_Id: number;
}
