import { Svc_LLPermit } from "@app/products/local-laws/permits/model";

export const localLawsPermitsURL = (viewEnum: number) => {
  return `odata/locallaw/internal/llpermits/GetLLPermitsView(viewEnum=${viewEnum})?$count=true&`;
};

export const llPermitsBookmark = (viewName: string) => {
  return {
    listViewDisplayURL() {
      return "Local Laws - Permits";
    },

    listViewDisplayTitle() {
      return `Local Laws - Permits - ${viewName}`;
    },

    recordDisplayURL(selectedRow: Svc_LLPermit) {
      const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
      return `Local Laws - ${viewName} ${dynamicDisplayName}`;
    },
  };
};
