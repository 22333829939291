import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ReferenceButton } from "@app/products/property/fire-prevention/components/action-bar/buttons/reference/_index";
import { AddMemoButton } from "@app/products/property/fire-prevention/hazld-case/[id]/components/buttons/add-memo/_index";
import FirePreventionActivities from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/activities/_index";
import { FirePreventionManageGeneral } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/general/_index";
import Inspection from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/inspection/_index";
import { FirePreventionMemos } from "@app/products/property/fire-prevention/hazld-case/[id]/components/child-screens/memos/_index";
import { FirePreventionContactsTab } from "@app/products/property/fire-prevention/hazld-case/[id]/components/reference-sidebar/contacts/_index";
import { FirePreventionDetailTab } from "@app/products/property/fire-prevention/hazld-case/[id]/components/reference-sidebar/detail/_index";
import { useFirePreventionStore } from "@app/products/property/fire-prevention/hazld-case/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedFirePrevention = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const [noticeGridSelectData, setNoticeGridSelectData] = useState();
  const { id } = useParams<{ id: string }>();
  const { firePrevention, isLoading, responseLoadError, loadFirePrevention } =
    useFirePreventionStore();

  const listPanelBar: IPropertyPanelBar[] = [
    {
      title: "Activities",
      component: <FirePreventionActivities />,
    },
    {
      title: "Inspections",
      component: (
        <Inspection setNoticeGridSelectData={setNoticeGridSelectData} />
      ),
    },
    {
      title: "Memos",
      component: <FirePreventionMemos />,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="HazlD Case" />

      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadFirePrevention(id)}
        />
      ) : (
        <>
          <FormTitle title={firePrevention?.FirePreventionNumber + ""} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <ReferenceButton noticeGridSelectData={noticeGridSelectData} />
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

              <CCNavButton title={"Add"} type="sub">
                <CCNavButton title={"Add item"} />
                <AddMemoButton />
              </CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <CCNavButton title={"Bookmark"} iconClass="fal fa-bookmark" />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {firePrevention && (
                  <CCGeneralPanel component={<FirePreventionManageGeneral />} />
                )}
                <PropertyPanelBar initialPanels={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <FirePreventionDetailTab />,
                    },
                    { title: "Map", component: <MapSTripTab /> },
                    {
                      title: "Contacts",
                      component: <FirePreventionContactsTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={
                            RECORDTYPE.CommunityProperty_HazardId_Fire_Prevention
                          }
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
