import { getJournal } from "@app/core/journal/api";
import {
  DEFAULT_JOURNAL_SKIP,
  DEFAULT_JOURNAL_TAKE,
} from "@app/core/journal/configs";
import { IJournal } from "@app/core/journal/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { APIResponseError } from "@common/apis/model";
import {
  isNonAuthoritativeResponse,
  isSuccessResponse,
} from "@common/apis/util";
import {
  BELL_CHARACTER,
  SEPARATOR_CHARACTER,
} from "@common/constants/characters";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useAddUniqueEventEmitter } from "@common/hooks/event-emitter/useAddUniqueEventEmitter";
import { ResponsePacket } from "@common/models/identityPacket";
import { formatDateByKendo } from "@common/utils/formatting";
import { customLogger } from "@common/utils/logger";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import { isString } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./_index.scss";

export interface IJournalProps {
  id?: number | string;
  recordType: RECORDTYPE;
  triggersReload?: any;
}

export const Journal = ({ id, recordType, triggersReload }: IJournalProps) => {
  const [data, setData] = useState<IJournal[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [state, setState] = useState({
    skip: DEFAULT_JOURNAL_SKIP,
    take: DEFAULT_JOURNAL_TAKE,
  });

  const loadData = useCallback(
    (skip: number, take: number) => {
      if (!id) return;
      if (skip === 0) setIsLoading(true);
      getJournal(id, recordType, skip, take).then((response) => {
        let newData: IJournal[] = [];
        let errorResponse = undefined;
        customLogger("Core Journal, getJournal").info(response?.data);
        if (isSuccessResponse(response) && response.data) {
          newData = response.data as IJournal[];
        } else if (isNonAuthoritativeResponse(response) && response.data) {
          const data = response.data as ResponsePacket;
          errorResponse = {
            status: response.status,
            error: data.Errors,
          };
        } else {
          errorResponse = {
            status: response.status,
            error: response.error ?? "Load journal failed",
          };
        }
        setIsLoading(false);
        if (skip === DEFAULT_JOURNAL_SKIP && take === DEFAULT_JOURNAL_TAKE) {
          setData(newData);
        } else {
          setData((data) => [...data, ...newData]);
        }
        setResponseLoadError(errorResponse);
        setState({ skip, take });
      });
    },
    [id, recordType]
  );

  const loadMoreData = useCallback(() => {
    loadData(state.skip + state.take, state.take);
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (!id) return;
    loadData(DEFAULT_JOURNAL_SKIP, DEFAULT_JOURNAL_TAKE);
    // eslint-disable-next-line
  }, [id, recordType, triggersReload]);

  useAddUniqueEventEmitter([
    {
      eventType: CCJournalEventType.RefreshData,
      listener: () => {
        loadData(DEFAULT_JOURNAL_SKIP, DEFAULT_JOURNAL_TAKE);
      },
    },
  ]);

  const isHasMore = useMemo(
    () => data.length === state.skip + state.take,
    [data, state]
  );

  if (isLoading) return <Loading isLoading={isLoading} />;

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData(state.skip, state.take);
        }}
      />
    );

  return (
    <div className="cc-journal">
      <InfiniteScroll
        height={"100%"}
        className={"cc-infinite-scroll"}
        dataLength={data.length}
        next={loadMoreData}
        hasMore={isHasMore}
        loader={<Loading isLoading />}
        endMessage={
          !data.length ? (
            <NoData />
          ) : (
            <div className="cc-journal-end">End of Journal</div>
          )
        }
      >
        <ul className="cc-timeline">
          {data.map((journal: IJournal, index: number) => (
            <li key={index} className="cc-timeline-item">
              <div className="cc-timeline-header">
                <div className="cc-timeline-date">
                  {formatDateByKendo(
                    new Date(journal.RecordedDate),
                    DATETIME_FORMAT.LONG_DATETIME
                  )}
                </div>
              </div>
              <div className="cc-timeline-user">{journal.RecordedBy}</div>
              {journal?.Title && (
                <p className="cc-timeline-title">
                  {sanitizeHtml(journal.Title)}
                </p>
              )}
              {isString(journal.Description) && (
                <p className="cc-timeline-content">
                  {sanitizeHtml(
                    journal.Description.replaceAll(
                      BELL_CHARACTER,
                      SEPARATOR_CHARACTER
                    )
                  )}
                </p>
              )}
            </li>
          ))}
        </ul>
      </InfiniteScroll>
    </div>
  );
};
