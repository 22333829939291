import { ICCNotificationPortalDefault } from "@components/cc-notification-portal/components/notification-portal-content/model";

export const AttributeYieldNotificationPortal = "cc-yield-notification-id";
export const YieldNotificationPortalFormStepDialog =
  "cc-local-notification-form-step";
export const YieldNotificationPortalDialog = "cc-notification-portal-dialog";

export enum TypePlaceId {
  Main = "Main",
  Sidebar = "Sidebar",
  // DialogAbcForXyzManagePage: "DialogAbcForXyzManagePage", // Example
}

export const notificationPortalDefaultValue: ICCNotificationPortalDefault = {
  delay: 3000,
  type: "success",
  closable: true,
  showIcon: true,
  autoClose: true,
  placeId: TypePlaceId.Main,
  stateId: "",
};

export const NOTIFICATION_TIMEOUT = 500;
