import { DTO_Documents } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/model";
import { eComponent } from "@app/products/property/components/associations/model";
import { DTO_WorkflowHeader } from "@app/products/property/model";
import { DTO_LOV, DTO_LOV_Number } from "@common/models/odataResponse";

export enum EKeysOfSteps {
  Type = "Type",
  Details = "COODetails",
  Property = "PropertyDetails",
  Owners = "OwnerDetails",
  RatePayers = "RatePayerDetails",
  DuplicateRatePayers = "DuplicateRatePayerDetails",
  AssociatedNames = "AssociatedNamesDetails",
  Rebates = "RebateEntitlements",
  Voting = "Voting",
  Documents = "Documents",
  Comments = "Comments",
  PICDetails = "PICDetails",
  SecondaryWorkflow = "SecondaryWorkflow",
  NamesGridData = "NamesGridData",
}

export const keysOfSendSteps = [
  EKeysOfSteps.Details,
  EKeysOfSteps.Property,
  EKeysOfSteps.Owners,
  EKeysOfSteps.RatePayers,
  EKeysOfSteps.DuplicateRatePayers,
  EKeysOfSteps.Rebates,
  EKeysOfSteps.PICDetails,
  EKeysOfSteps.Documents,
  EKeysOfSteps.AssociatedNames,
  EKeysOfSteps.SecondaryWorkflow,
];

export interface DTO_Workflow_ChangeOfOwnership {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_ChangeOfOwnership;
}

export interface DTO_WorkflowDetail_ChangeOfOwnership {
  COODetails: DTO_ChangeOfOwnerShip_Detail;
  PropertyDetails: DTO_PropertyDetails;
  OwnerDetails: DTO_Contact;
  RatePayerDetails: DTO_Contact;
  DuplicateRatePayerDetails: DTO_Contact;
  AssociatedNamesDetails: DTO_Entity_Details[];
  RebateEntitlements: DTO_RebateEntitlements;
  PICDetails: DTO_PICDetails;
  Documents: DTO_Documents;
  // TODO: Recheck when have API
  SelectedSecondaryWorkflow: number[];
}

export interface DTO_PICDetails {
  PICs: DTO_PIC[];
}

export interface DTO_PIC {
  PIC_Id: number;
  Property_Address: string;
  PIC_Number: string;
  Pic_Manager: string;
  Trading_Name: string;
}
export interface DTO_ChangeOfOwnerShip_Detail {
  Change_Of_Ownership_Id: number;
  Type: number;
  Reference: string;
  EffectiveDate: Date;
  SalePrice: number | null;
  ContractDate: Date | null;
  Chattels: number | null;
  Comment: string;
  RetainAssociatedNames: boolean;
  RetainRatePayerDetails: boolean;
  RetainLeaseDetails: boolean;
  ComponentInvoked: eComponent;
  ComponentInvoked_Id: number;
}

export interface DTO_PropertyDetails {
  Assessments: DTO_Assessment[];
  Titles: DTO_Title[];
}

export interface DTO_Assessment {
  Assessment_Id: number;
  Assessment_Number: string;
  Property_Address: string;
}

export interface DTO_Title {
  Legal_Description: string;
  Owner: string;
  Title_Id: number;
  Assessment_Id: number;
  //Primary field created by UX
  Id?: string;
}

export interface DTO_Contact {
  Contacts: DTO_Entity_Details[];
  Name: string;
  Attention_Of: string;
  Care_Of: string;
  Address: string;
  Locality: string;
  State: string;
  Country: string;
  Postcode: string;
  DPID: number | null;
  Notice_Id: number | null;
  Formatted_Name_Address: string;
}

export interface DTO_Entity_Details {
  Entity_Id: number;
  Entity_Name_Address_Id: number;
  Role_Name?: string;
  Percentage: number | null;
  Name: string;
  Surname: string;
  GivenName: string;
  Title: string;
  Address: string;
  isnew: boolean;
  isFromRetainNames: boolean;
  //Primary field created by UX
  Id?: string;
}

export interface DTO_RebateEntitlements {
  Rebates: DTO_Rebates[];
  Owners: DTO_RebateEntitlementOwners[];
  Calculate_Rebate_Adjustment: boolean;
}

export interface DTO_Rebates {
  Rebate_Entitlement_Id: number;
  is_InActive: boolean;
  Loss_Of_Eligibility_Date: Date | null;
  Rebate_Type_Id: number;
  Rebate_Type: string;
  Percentage: number;
  Application_Date: Date | null;
  Effective_Date: Date | null;
  Assessment_Id: number;
}

export interface DTO_RebateEntitlementOwners {
  Rebate_Entitlement_Id: number;
  Entity_Id: number;
  Entitled_To_Rebate: boolean;
  Owner: string;
  ConcessionCard_Held: string;
  Assessment_Id: number;
}
export interface RequestChangeOfOwnershipObj {
  AssessmentId: number | null;
  Entity_Id: number | null;
  Title_Id: number | null;
  PIC_Id: number | null;
}

export interface DTO_ChangeOfOwnership_LOVs {
  ChangeOfOwnershipTypes: DTO_COO_Type[];
  NoticeGroups: DTO_LOV_Number[];
  Roles: DTO_Role[];
  NameFormat: DTO_LOV;
  OwnerNameFormat: DTO_LOV;
}

export interface DTO_Role {
  Role_Id: number;
  Role_Name: string;
  Role_Type_Id: number;
  Role_Type_Name: string;
  IsOwner: boolean | null;
  IsRatepayer: boolean | null;
  IsAssociatedName: boolean | null;
  IsPICRole: boolean | null;
  IsRegisterAccount: boolean | null;
}

export interface DTO_COO_Type {
  Change_of_Ownership_Type: number;
  COOT_Name: string;
  COOT_Clear_Rebates: boolean;
  COOT_Display_Rebates: boolean;
  COOT_Display_Percentage: boolean;
  COOT_Display_Owner_Roles: boolean;
  COOT_Display_Associated_Names: boolean;
  COOT_Display_RatePayer_Roles: boolean;
  COOT_Display_PIC_Roles: boolean;
  COOT_Display_Register_Roles: boolean;
  COOT_Display_Retain_AssociatedNames: boolean;
  COOT_Display_Retain_Occupier: boolean;
  COOT_Retain_Occupier: boolean;
  COOT_Retain_AssociatedNames: boolean;
}

export enum RoleTypes {
  Owner_RatePayer = 1,
  Associated_Name,
  Lessee,
  Register_Account_Name,
  Utility_Agent_Name,
  HazId_Postal_Name,
  Tribe_User,
  Ratepayer,
  Owner,
  Property_Agent,
  Duplicate_Owner,
}

export enum RoleId {
  Owner_RatePayer = 1,
  Owner = 13,
}

export const PIC_Manager_Type_ID = 14;
export const PIC_Manager_Type_Name = "PIC Manager";
