import { ICommonContactItemRender } from "@common/pages/contacts/contacts-sidebar/model";

export const premiseContactData: ICommonContactItemRender[] = [
  {
    ID: "3173054",
    Type: "Proprietor",
    DisplayName: "Lena Stojanovski",
    WorkPhone: "(640) 254-957",
    Mobile: "1-540-062-1600",
    Email: "tan.tran@openoffice.com.au",
    PreferredMethod_ENUM: "0",
  },
  {
    ID: "3173058",
    Type: "Primary Contact",
    DisplayName: "Lena Stojanovski",
    WorkPhone: "(640) 389-657",
    Mobile: "1-640-062-1500",
    Email: "tan.tran@openoffice.com.au",
    PreferredMethod_ENUM: "0",
  },
];
