import { getPermitFormById } from "@app/products/local-laws/[id]/api";
import { Permit } from "@app/products/local-laws/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { usePageType } from "@common/hooks/usePageType";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const PermitDetailsTab = observer(() => {
  //#region STORE ========/
  const { dataForms } = useFlexibleFormStore();
  const permitData = dataForms?.GeneralForm?.Permit as Permit;
  //#endregion STORE =====/

  //#region STATE ========/
  const [permit, setPermit] = useState<Permit>(permitData);

  const { isProductListView } = usePageType();
  const { lastSelectedId } = useCCProductListViewStore();
  const { cancelToken } = useCancelRequest();
  //#endregion STATE =====/

  //#region VARIABLE ========/
  const { id } = useParams<{ id: string }>();
  const permitId: string | number = id ? id : lastSelectedId;
  //#endregion VARIABLE =====/

  //#region API ========/
  const slider = useFlexibleFetchData({
    isActive: isProductListView,
    slides: [
      {
        fetch: () => getPermitFormById(+permitId, cancelToken()),
        handleSuccess: ({ dataFromApi }) => {
          const _permitResponse = dataFromApi;
          const _permitFormData = _permitResponse.data?.ReturnObj;
          setPermit(_permitFormData?.Permit as Permit);
        },
      },
    ],
  });
  //#endregion API =====/

  //#region WATCH ========/
  useEffect(() => {
    if (permitData) setPermit(permitData);
    if (isProductListView) slider.fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permitData, permitId, isProductListView]);
  //#endregion WATCH =====/

  if (slider?.isFetching) return <Loading isLoading />;
  if (slider?.errorComponent) return slider?.errorComponent;
  if (!permit) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Permit id:"}
        value={permit.Permit_ID.toString()}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Lodged date:"}
          value={
            permit.Date_Lodged
              ? format(permit.Date_Lodged, DATE_FORMAT.DATE)
              : ""
          }
        />
        <ProductReferenceRow
          title={"Permit type:"}
          value={permit.PermitType?.Name}
        />
        <ProductReferenceRow
          title={"Permit holder:"}
          value={permit.Contact?.Contact?.DisplayName}
        />
        <ProductReferenceRow
          title={"Officer:"}
          value={permit.Officer?.DisplayName}
        />
        <ProductReferenceRow
          title={"Effect from:"}
          value={
            permit.Date_EffectiveFrom
              ? format(permit.Date_EffectiveFrom, DATE_FORMAT.DATE)
              : ""
          }
        />
        <ProductReferenceRow
          title={"Effect to:"}
          value={
            permit.Date_EffectiveTo
              ? format(permit.Date_EffectiveTo, DATE_FORMAT.DATE)
              : ""
          }
        />
        <ProductReferenceRow
          title={"Debtor number:"}
          value={permit.DebtorNumber}
        />
        <ProductReferenceRow
          title={"Description:"}
          value={permit?.Description}
        />
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
