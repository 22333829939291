import "@app/products/animals/components/forms/components/form-element/_index.scss";
import { setupPoundRegisterPickSiteAddress } from "@app/products/animals/pound-register/[id]/api";
import { PoundRegisterAnimalSection } from "@app/products/animals/pound-register/[id]/components/child-screens/general/form-element/animals-section/_index";
import { crmsEventPickerOptions } from "@app/products/animals/pound-register/[id]/components/child-screens/general/form-element/config";
import { getPoundRegisterValueFromSetting } from "@app/products/animals/pound-register/[id]/components/child-screens/general/form-element/util";
import { PoundRegisterWorkflowElements } from "@app/products/animals/pound-register/[id]/components/child-screens/general/form-element/workflow-elements/_index";
import {
  AnimalPoundRegisterSettingValue,
  CRMSEventPicker,
  PoundRegister,
  PoundRegisterHandlerRequest,
  PoundRegisterUpdateTriggers,
  PoundRegister_ImpoundedBy,
  Svc_FormAction_PoundRegister,
  Svc_PoundRegister,
} from "@app/products/animals/pound-register/[id]/model";
import { useAnimalPoundRegisterStore } from "@app/products/animals/pound-register/[id]/store";
import { optionInputPickerOfficer } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { isSuccessResponse } from "@common/apis/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { Address_BuildAddress } from "@common/input-pickers/address/model";
import { SiteUser } from "@common/pages/settings/security/site-users/_id/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";

export interface IPoundRegisterFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<PoundRegister>();
const nameOfMapObj = nameOfFactory<Svc_PoundRegister>();
const getNameOf = (name: keyof PoundRegister) =>
  `${nameOfMapObj("PoundRegister")}.${nameOf(name)}`;

const PoundRegisterFormElement = observer(
  ({ formRenderProps }: IPoundRegisterFormElementProps) => {
    const { onChange, valueGetter } = formRenderProps;
    const { settings } = useCommonCoreStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { poundRegisterLOVs, loadPoundRegisterHandle } =
      useAnimalPoundRegisterStore();

    const [getSettingValue, loadSettingValue] =
      useState<AnimalPoundRegisterSettingValue>();
    const [isLoadingPickAddressDialog, setIsLoadingPickAddressDialog] =
      useState(false);
    const [initialBuildAddress, setInitialBuildAddress] =
      useState<Address_BuildAddress>();

    //#region Field Display
    const externalContactDisplay = `${getNameOf(
      "ImpoundedByExternal"
    )}.Contact.DisplayName`;

    const councilOfficerDisplay = `${getNameOf(
      "ImpoundedByCouncilOfficer"
    )}.DisplayName`;

    const pickupLocationDisplay = `${getNameOf(
      "PickUpLocation"
    )}.Formatted_SingleLine`;

    const poundRegisterObj = valueGetter(nameOfMapObj("PoundRegister"));
    //#endregion

    //Check Visible
    var divImpoundedByExternalVisible =
      valueGetter(getNameOf("ImpoundedBy_ENUM")) ===
        PoundRegister_ImpoundedBy.Other ||
      valueGetter(getNameOf("ImpoundedBy_ENUM")) ===
        PoundRegister_ImpoundedBy.Police;

    var divImpoundedByCouncilVisible =
      valueGetter(getNameOf("ImpoundedBy_ENUM")) ===
      PoundRegister_ImpoundedBy.Council;

    //#region Handle Function
    const eventPickerHandler = async (eventValue: CRMSEventPicker) => {
      onChange(getNameOf("CRMSEventNo"), {
        value: eventValue?.RefNo,
      });
      onChange(getNameOf("CRMSEvent_ID"), {
        value: eventValue?.ID,
      });
    };

    const handleChangeExternalContact = (event: ComboBoxChangeEvent) => {
      const { value } = event;
      onChange(`${getNameOf("ImpoundedByExternal")}.Contact`, { value: value });

      if (!isNil(value)) {
        let params: PoundRegisterHandlerRequest = {
          FormAction: Svc_FormAction_PoundRegister.Form_PickExternalContact,
          PoundRegister: poundRegisterObj,
          PoundRegisterArgs: {
            ImpoundedByExternal: {
              Contact: value,
            },
          },
        };

        loadPoundRegisterHandle(params, "Change contact failed.");
      } else {
        onChange(`${getNameOf("ImpoundedByExternal_RID")}`, { value: null });
      }
    };

    const onPickOfficer = async (value: SiteUser | null) => {
      if (isNil(value)) {
        onChange(getNameOf("ImpoundedByCouncilOfficer"), {
          value: null,
        });
        onChange(getNameOf("ImpoundedByCouncilOfficer_ID"), {
          value: null,
        });
        return;
      }
      if (!isNil(value) && value?.Contact_ID) {
        onChange(getNameOf("ImpoundedByCouncilOfficer"), {
          value: value,
        });
        onChange(getNameOf("ImpoundedByCouncilOfficer_ID"), {
          value: value.Contact_ID,
        });
      } else {
        pushNotification({
          title: "Officer is not found",
          type: "error",
          autoClose: false,
        });
      }
    };

    const updateSaveTriggers = (triggers: PoundRegisterUpdateTriggers) => {
      let saveTriggers: PoundRegisterUpdateTriggers[] =
        valueGetter(getNameOf("SaveTriggers")) ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(getNameOf("SaveTriggers"), {
          value: saveTriggers,
        });
      }
    };

    const handleOpenPickAddressDialog = async () => {
      if (!poundRegisterObj) return;
      setIsLoadingPickAddressDialog(true);
      const response = await setupPoundRegisterPickSiteAddress(
        valueGetter(getNameOf("PickUpLocation"))
      );
      setIsLoadingPickAddressDialog(false);

      if (isSuccessResponse(response) && response.data) {
        setInitialBuildAddress(response.data.ReturnObj);
      } else {
        pushNotification({
          autoClose: false,
          title: "Setup address fail.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };

    const handleSubmitSiteAddress = (
      buildAddress: Address_BuildAddress | null
    ) => {
      const address = buildAddress?.Address;
      let params: PoundRegisterHandlerRequest = {
        FormAction: Svc_FormAction_PoundRegister.Form_PickAddress,
        PoundRegister: poundRegisterObj,
        PoundRegisterArgs: { PickUpLocation: address },
      };

      loadPoundRegisterHandle(params, "Change address failed.");
    };
    //#endregion

    //#region Validate
    const externalContactValidator = useCallback(() => {
      return requiredValidator(valueGetter(externalContactDisplay));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalContactDisplay]);

    const councilOfficerValidator = useCallback(() => {
      return requiredValidator(
        valueGetter(getNameOf("ImpoundedByCouncilOfficer_ID"))
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [`${getNameOf("ImpoundedByCouncilOfficer_ID")}`]);

    const pickupLocationValidator = useCallback(() => {
      return requiredValidator(valueGetter(pickupLocationDisplay));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickupLocationDisplay]);
    //#endregion

    useEffectOnce(() => {
      loadSettingValue(getPoundRegisterValueFromSetting(settings));
    });

    return (
      <FormElement className="cc-animal-pound-register-form-element">
        <PoundRegisterWorkflowElements formRenderProps={formRenderProps} />
        {/* SECTION 1 */}
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Date entered" />
              <Field
                name={`${getNameOf("DateEntered")}`}
                component={CCDateTimePicker}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
              />
            </div>

            <div className="cc-field">
              <CCLabel title="CRMS event" />
              <Field
                component={InputPickerSearch}
                name={getNameOf("CRMSEventNo")}
                nameDisplay="DisplayName"
                value={valueGetter(getNameOf("CRMSEventNo"))}
                options={crmsEventPickerOptions}
                onChange={eventPickerHandler}
                isCRMS
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />

        {/* SECTION 2 */}
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Impounded by" />
              <Field
                name={getNameOf("ImpoundedBy_ENUM")}
                textField="Value"
                dataItemKey="Key"
                data={poundRegisterLOVs?.ImpoundedBy}
                isUseDefaultOnchange
                component={CCSearchComboBox}
              />
            </div>

            {divImpoundedByExternalVisible && (
              <div className="cc-field">
                <CCLabel title="External Contact" isMandatory />
                <Field
                  uniqueKey="PoundRegisterExternalContactPicker"
                  name={`${getNameOf("ImpoundedByExternal")}.Contact`}
                  component={ContactPicker}
                  onError={(error: any) => {
                    pushNotification({
                      type: "error",
                      title: "Pick contact errors",
                      description: error,
                      autoClose: false,
                    });
                  }}
                  placeholder="Select contact"
                  onChange={handleChangeExternalContact}
                  validator={externalContactValidator}
                  displayValue={valueGetter(externalContactDisplay)}
                />
              </div>
            )}

            {divImpoundedByCouncilVisible && (
              <div className="cc-field">
                <CCLabel title="Council officer" isMandatory />
                <Field
                  name={getNameOf("ImpoundedByCouncilOfficer")}
                  nameDisplay="DisplayName"
                  placeholder="Council officer"
                  component={InputPickerSearch}
                  onChange={onPickOfficer}
                  options={optionInputPickerOfficer}
                  validator={councilOfficerValidator}
                  displayValue={valueGetter(councilOfficerDisplay)}
                  isCRMS
                />
              </div>
            )}
          </div>

          <div className="cc-form-cols-1">
            <div className="cc-field">
              <CCLabel title="Impound details" />
              <Field
                name={getNameOf("ImpoundDetails")}
                component={CCTextArea}
                rows={3}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Pick up location" isMandatory />
              <Field
                uniqueKey="PickUpLocation"
                name={getNameOf("PickUpLocation")}
                component={PropertyDetail}
                initialData={initialBuildAddress}
                placeholder={"Select site address"}
                formRenderProps={formRenderProps}
                validator={pickupLocationValidator}
                isSearchPropertyAddresses={true}
                onError={(error: any) => {
                  pushNotification({
                    type: "error",
                    title: "Select site address failed.",
                    description: error,
                    autoClose: false,
                  });
                }}
                value={valueGetter(pickupLocationDisplay)}
                onChangeEventHandler={handleSubmitSiteAddress}
                updateSaveTriggers={() => {
                  updateSaveTriggers(
                    PoundRegisterUpdateTriggers.UpdatePickUpLocation
                  );
                }}
                onSubmit={handleSubmitSiteAddress}
                onButtonClick={handleOpenPickAddressDialog}
                isLoadingDialog={isLoadingPickAddressDialog}
              />
            </div>
            <CCValueField
              label="Pick up location details"
              value={valueGetter(pickupLocationDisplay)}
            />
          </div>
        </section>
        <hr className="cc-divider" />

        {/* SECTION 3 */}
        <PoundRegisterAnimalSection
          formRenderProps={formRenderProps}
          getSettingValue={getSettingValue}
        />
      </FormElement>
    );
  }
);

export default PoundRegisterFormElement;
