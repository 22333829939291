import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { useAssessmentTransactionStore } from "@app/products/property/assessments/[id]/components/child-screens/transactions/store";
import {
  getInitialReverseTransaction,
  postProcessReverseTransaction,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/reverse-transaction/api";
import { DTO_Workflow_JournalAssessmentReverseTransaction } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/reverse-transaction/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WhichJournalFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/_index";
import { JournalMode } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-journal/model";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { NewJournalFormStep } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/_index";
import { useNewJournalStepStore } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/store";
import { DTO_Journal_LOVs } from "@app/products/property/journals/list/components/dialogs/create-journal/model";
import { JournalStatus } from "@app/products/property/journals/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { isEmpty, isNil, pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { TransactionFormStep } from "./components/form-elements/transaction/_index";
import {
  ReverseTransactionKeysOfSteps,
  reverseTransactionKeysOfSendSteps,
} from "./config";

interface IReverseTransactionDialog {
  onClose: () => void;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
}
export const ReverseTransactionDialog = observer(
  ({
    onClose,
    isSaveOnNextStep = false,
    suffixTitle,
    prefixTitle,
    dataFromActionList,
  }: IReverseTransactionDialog) => {
    //#region <Store>
    const { currentFormTitle } = useCommonProductStore();
    const { gridSelectedTransactionIds } = useAssessmentTransactionStore();
    const { setNewJournalStepLOVs } = useNewJournalStepStore();
    const { pushNotification } = useCCAppNotificationStore();
    //set data for modes
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const {
      isIncompleteMode,
      isFromActionList,
      isReadOnly,
      statusBadge,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
    } = usePropertyWorkflow(dataFromActionList);
    //#endregion

    //#region <Local state>
    const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
    const [workflowInitData, setWorkflowInitData] =
      useState<DTO_Workflow_JournalAssessmentReverseTransaction>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const history = useHistory();
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [isLoadingProcess, setIsLoadingProcess] = useState<
      WorkflowProcessMode | undefined
    >();
    //#endregion

    //Get labels
    const [assessmentLabel, assessmentLowercaseLabel] =
      Label.CommunityProperty.getLabel([
        ECustomColNameProperty.Assessment,
        ECustomColNameProperty.AssessmentLowercase,
      ]);

    //#region <Title header>
    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title =
        currentFormTitle(formId ?? 0) ||
        `Reverse ${assessmentLabel} Transaction`;
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);
    //#endregion

    //region <Initial value>
    const initialValue = useMemo((): any => {
      const journalNumber = workflowInitData?.WorkflowDetail?.Journal_Number;
      let initWhichJournal: any = {
        JournalMode: workflowInitData?.WorkflowDetail?.JournalMode,
        JournalSelected: journalNumber
          ? [{ Journal_Number: journalNumber }]
          : [],
      };
      let initNewJournal: any = {
        ...workflowInitData?.WorkflowDetail?.CreateJournal,
      };
      let initTransactions: any = {
        Transactions: workflowInitData?.WorkflowDetail?.Transactions,
      };

      formStepRef?.current?.setStepsVisible([
        {
          key: ReverseTransactionKeysOfSteps.NewJournal,
          visible:
            workflowInitData?.WorkflowDetail.JournalMode ===
            JournalMode.CreateNew,
          isClearData: true,
        },
      ]);

      return {
        [ReverseTransactionKeysOfSteps.WhichJournal]: initWhichJournal,
        [ReverseTransactionKeysOfSteps.NewJournal]: initNewJournal,
        [ReverseTransactionKeysOfSteps.Transactions]: initTransactions,
        [ReverseTransactionKeysOfSteps.Documents]: {},
        [ReverseTransactionKeysOfSteps.Comments]: {},
      };
    }, [workflowInitData]);
    //#endregion

    const steps: IStep[] = [
      {
        label: "Journal",
        component: WhichJournalFormStep,
        visible: true,
        key: ReverseTransactionKeysOfSteps.WhichJournal,
        options: {
          //hidden showBringUpField for now
          showBringUpField: false,
          isReadOnly,
          newJournalKey: ReverseTransactionKeysOfSteps.NewJournal,
          journalTypeNumber:
            workflowInitData?.WorkflowDetail?.CreateJournal?.Journal_Type ?? 2,
          journalStatus: JournalStatus.Incomplete,
        },
        initialValues: initialValue[ReverseTransactionKeysOfSteps.WhichJournal],
      },
      {
        label: "New journal",
        initialValues: initialValue[ReverseTransactionKeysOfSteps.NewJournal],
        component: NewJournalFormStep,
        visible: true,
        key: ReverseTransactionKeysOfSteps.NewJournal,
        options: {
          isReadOnly,
          isDisableJournalType: true,
          showStatusField: true,
          showWhichSupplementary: false,
          dataJournalStatus: false,
        },
      },
      {
        label: "Transaction",
        initialValues: initialValue[ReverseTransactionKeysOfSteps.Transactions],
        component: TransactionFormStep,
        visible: true,
        key: ReverseTransactionKeysOfSteps.Transactions,
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        visible: true,
        key: ReverseTransactionKeysOfSteps.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Journal,
        },
        initialValues: initialValue[ReverseTransactionKeysOfSteps.Comments],
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        visible: true,
        key: ReverseTransactionKeysOfSteps.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          workflowType: WorkflowTypes.Journal_Assessment_Reverse_Transaction,
        },
        initialValues: initialValue[ReverseTransactionKeysOfSteps.Documents],
      },
    ];

    //#region handle Cancel Button>
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessReverseTransaction,
          dataCancel: data,
          defaultSuccessMessage: `Reverse ${assessmentLowercaseLabel} transactions was cancelled successfully.`,
          defaultErrorMessage: `Reverse ${assessmentLowercaseLabel} transactions could not be cancelled.`,
        });
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <Handle close dialog>
    /**
     * @param renderProps
     */
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <Handle save and next>
    const handleSaveAndNext = async (
      payload: DTO_Workflow_JournalAssessmentReverseTransaction
    ): Promise<boolean> => {
      //check condition use for Save button
      setIsLoadingProcess(WorkflowProcessMode.Save);
      //Calling process Save at next button
      const response = await postProcessReverseTransaction(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingProcess(undefined);
      //set default notification
      const defaultSuccessMessage = `Reverse ${assessmentLowercaseLabel} transactions was saved successfully.`;
      const defaultFailedMessage = `Reverse ${assessmentLowercaseLabel} transactions could not be saved.`;
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (!isSaveOnNextStep) {
          onClose();
          pushNotification({
            title:
              response?.data?.SuccessMessage ??
              response?.data?.Notification ??
              defaultSuccessMessage,
            type: "success",
          });
        } else {
          // check is the first saving
          if (isFirstSave) {
            setIsFirstSave(false);
            //set current workflowDraft Id
            setWorkflowDraftId(response?.data?.ID ?? 0);
            // set payload to send
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID,
              },
            });
          }
        }
        return true;
      } else {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      return false;
    };
    //#endregion

    //#region <Handle next button>
    /**
     * @param data
     * @param step
     * @param keyStep
     * @returns
     */
    const handleNextButton = async (
      data: DTO_Workflow_JournalAssessmentReverseTransaction
    ) => {
      const newData = { ...data };
      const processPayload = processData(newData);
      //send data to call api save
      return handleSaveAndNext(processPayload);
    };
    //#endregion

    //#region <Handle park process>
    /**
     * @param payload
     * @param mode
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_JournalAssessmentReverseTransaction,
      mode?: EListSubmitButton
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Park);
      mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
      const actionCloseRetainDialog = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog();
      };
      //props send to process workflow
      const parkProps: INewProcessWorkflow<DTO_Workflow_JournalAssessmentReverseTransaction> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.SuccessMessage ??
                e?.Notification ??
                `Reverse ${assessmentLowercaseLabel} transactions was parked successfully.`,
              type: "success",
            });
            isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
          },
          setLoading: () => {
            setIsLoadingProcess(undefined);
          },
          actionClose: () => {
            mode === EListSubmitButton.ConfirmCloseYes &&
              actionCloseRetainDialog();
          },
          defaultFailedMessage: `Reverse ${assessmentLowercaseLabel} transactions could not be parked.`,
          modeProcess: WorkflowProcessMode.Park,
        };

      //calling api process workflow
      await handleProcessWorkflow(parkProps);
    };
    //#endregion

    //#region
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          handleConfirmFinish(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleParkProcess(
            processData(data),
            EListSubmitButton.ConfirmCloseYes
          );
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };
    //#endregion

    const processData = (data: any) => {
      let workflowDetail: any = {};
      const sendSteps = pickBy(data, (value, key) => {
        if (
          reverseTransactionKeysOfSendSteps.includes(
            key as ReverseTransactionKeysOfSteps
          )
        ) {
          return { [key]: value };
        }
      });
      for (const [key, value] of Object.entries(sendSteps)) {
        const dataStep = { ...value };
        if (dataStep?._option) {
          delete dataStep._option;
        }
        workflowDetail[key] = dataStep;
      }
      workflowDetail.JournalMode =
        data?.[ReverseTransactionKeysOfSteps.WhichJournal]?.JournalMode;
      workflowDetail.Journal_Number =
        data?.[
          ReverseTransactionKeysOfSteps.WhichJournal
        ]?.JournalSelected?.[0]?.Journal_Number;
      if (
        !isNil(
          data?.[ReverseTransactionKeysOfSteps.NewJournal]?.SupplementaryMode
        )
      ) {
        workflowDetail.SupplementaryMode =
          data[ReverseTransactionKeysOfSteps.NewJournal].SupplementaryMode;
        delete workflowDetail[ReverseTransactionKeysOfSteps.NewJournal]
          .SupplementaryMode;
      }

      if (
        !isEmpty(
          data?.[ReverseTransactionKeysOfSteps.Transactions]?.Transactions
        )
      ) {
        workflowDetail.Transactions =
          data[ReverseTransactionKeysOfSteps.Transactions].Transactions;
      }

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    //#region <Handle process workflow>
    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: INewProcessWorkflow<DTO_Workflow_JournalAssessmentReverseTransaction>
    ) => {
      const {
        actionSuccess,
        defaultFailedMessage,
        setLoading,
        modeProcess,
        payload,
      } = props;
      const response = await postProcessReverseTransaction(
        modeProcess,
        payload
      );
      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          if (props?.actionFail) props?.actionFail(response);
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        if (props?.actionFail) props?.actionFail(response);
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      if (props?.actionClose) props.actionClose();
    };
    //#endregion

    //#region <Handle finish process>
    /**
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_JournalAssessmentReverseTransaction
    ) => {
      //set loading button
      setIsLoadingProcess(WorkflowProcessMode.Finish);
      //props send to process workflow
      const finishProps: INewProcessWorkflow<DTO_Workflow_JournalAssessmentReverseTransaction> =
        {
          payload: payload,
          actionSuccess: (e) => {
            const notificationContent: IAppNotificationItemAddProps = {
              title:
                e?.SuccessMessage ??
                e?.Notification ??
                `${assessmentLabel} transactions was reversed successfully.`,
              type: "success",
            };
            onClose();
            if (!isNil(e?.Component_ID)) {
              history.push(`${PROPERTY_JOURNALS_ROUTE}/${e?.Component_ID}`, {
                notification: notificationContent,
              });
            } else {
              pushNotification(notificationContent);
            }
          },
          setLoading: () => {
            setIsLoadingProcess(undefined);
          },
          defaultFailedMessage: `${assessmentLabel} transactions could not be reversed.`,
          modeProcess: WorkflowProcessMode.Finish,
        };
      //calling api process workflow
      await handleProcessWorkflow(finishProps);
    };
    //#endregion

    //#region <Handle confirm finish>
    const handleConfirmFinish = (
      payload: DTO_Workflow_JournalAssessmentReverseTransaction
    ) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage: `The ${assessmentLowercaseLabel} transactions will be created based on the information provided. Are you sure you want to submit?`,
      });
    };
    //#endregion

    //#region <Get workflow data>
    /**
     * load initValue for FormStep
     * call once time
     */
    const getWorkflowData = async () => {
      const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
      formStepRef?.current?.setLoadingFormStep(true);
      const response = await getInitialReverseTransaction(
        workflowDraftId,
        isFromActionList,
        isFromActionList ? undefined : [...gridSelectedTransactionIds]
      );
      formStepRef?.current?.setLoadingFormStep(false);
      if (Array.isArray(response)) {
        const [lovsJournal, workflowData] = response;
        if (
          isSuccessResponse(lovsJournal) &&
          isSuccessResponse(workflowData) &&
          lovsJournal?.data &&
          workflowData?.data
        ) {
          //set Lovs Data
          setNewJournalStepLOVs({
            JournalTypes: convertValueLOVToNumber(
              lovsJournal?.data?.JournalTypes ?? [],
              "Code"
            ),
            Officer: convertValueLOVToNumber(
              lovsJournal?.data?.Officer ?? [],
              "Code"
            ),
          });
          setWorkflowInitData(workflowData?.data);
          if (workflowData.data?.WorkflowHeader) {
            setWorkflowHeader(workflowData.data?.WorkflowHeader);
            setWorkflowDraftId(
              workflowData.data?.WorkflowHeader?.WorkflowDraft
                ?.Workflow_Draft_Id ?? 0
            );
          }
        } else {
          let responseError: APIResponse<
            | DTO_Journal_LOVs
            | DTO_Workflow_JournalAssessmentReverseTransaction
            | ResponsePacket
          > = response[0];
          if (!isSuccessResponse(response[1])) {
            responseError = response[1];
          }
          formStepRef?.current?.setLoadingFormStep(false);
          formStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        formStepRef?.current?.setLoadingFormStep(false);
        formStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };
    //#endregion

    //#region <Use Effect calling initial data>
    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion

    return (
      <CCFormStep
        ref={formStepRef}
        onSubmit={handleSubmit}
        initialSteps={steps}
        initialValues={initialValue}
        listButtonId={listSubmitButton}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="60%"
            titleHeader={titleHeader}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            badge={statusBadge}
            disabled={isLoadingProcess !== undefined}
            footerElement={
              <>
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Park
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled ||
                        isLoadingProcess === WorkflowProcessMode.Park
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={!isNil(isLoadingProcess)}
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {(isToBeApprovalMode || isReadOnly) &&
                  renderProps.isLastStep ? null : (
                    <Button
                      themeColor="primary"
                      id={
                        !renderProps.isLastStep &&
                        (isToBeApprovalMode || isReadOnly)
                          ? "cc-next-step-button"
                          : renderProps.nextButton.idButton
                      }
                      disabled={
                        isLoadingProcess === WorkflowProcessMode.Save ||
                        renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Save
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingProcess === WorkflowProcessMode.Save
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              </>
            }
          />
        )}
      />
    );
  }
);
