// @TODO: Need to integate later
const MOCK_URL = "";
export const HMUrl = {
  // HM CRUD API
  CREATE_HM: "",
  GET_HM: "",
  UPDATE_HM: "",
  DELETE_HM: "",

  // HM Form OData
  GET_FORM_HM: "",

  // HM View OData
  GET_VIEW_HM_PREMISES_REGISTER: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_BYTYPE: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_BYSUBURB: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_BYAREA: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_BYRISK: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_BYOFFICER: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_BYFSP: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_BYPROPERTY: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_BYSTARRATING:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_BYCATEGORY: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_BYPROPRIETOR:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_PCBA: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_PERSCRIBEDACCOM:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_POOLSSPAS: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_COOLINGTOWERS:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_DRINKINGWATER:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_FASTCHOICES:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_FOODSTALLS: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_FOODVEHICLES:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_OSFEES: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_NONRENEWABLE:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_STREATRADER:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_PRELODGEMENTS:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_CLOSURES: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_PENDINGTRANSFERS:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_DUECONDITIONS:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_COMPCONDITIONS:
    "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_PREMISES_ALERTS: "/odata/hm/internal/hmregister?$count=true&",
  GET_VIEW_HM_TOBACCO_REGISTER:
    "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_BYTYPE: "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_BYSUBURB:
    "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_BYAREA: "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_BYOFFICER:
    "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_PRELODGEMENTS:
    "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_CLOSURES:
    "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_TOBACCO_PENDINGTRANSFERS:
    "/odata/hm/internal/tobaccoregister?$count=true&",
  GET_VIEW_HM_INSPECTIONS_BYDATE:
    "/odata/core/internal/inspections?$count=true&",
  GET_VIEW_HM_INSPECTIONS_BYTYPE:
    "/odata/core/internal/inspections?$count=true&",
  GET_VIEW_HM_INSPECTIONS_BYOFFICER:
    "/odata/core/internal/inspections?$count=true&",
  GET_VIEW_HM_INSPECTIONS_BYMONTH:
    "/odata/core/internal/inspections?$count=true&",
  GET_VIEW_HM_INSPECTIONS_BYAREA:
    "/odata/core/internal/inspections?$count=true&",
  GET_VIEW_HM_INSPECTIONS_ASSESSMENTS:
    "/odata/core/internal/inspections?$count=true&",
  GET_VIEW_HM_INSPECTIONS_AUDITS:
    "/odata/core/internal/inspections?$count=true&",
  GET_VIEW_HM_INSPECTIONS_OSNCITEMS:
    "/odata/core/internal/inspections?$count=true&",
  GET_VIEW_HM_FOOD_PICK_LIST:
    "/odata/hm/internal/healthmanager/GetFoodPickList()",
  GET_VIEW_HM_TOBACCO_PICK_LIST:
    "/odata/hm/internal/healthmanager/GetTobaccoPickList()",

  // Mock HM View OData
  GET_VIEW_HM_RENEWALS_ALLBYDUESTATUS: MOCK_URL,
  GET_VIEW_HM_RENEWALS_ALLBYTYPE: MOCK_URL,
  GET_VIEW_HM_RENEWALS_REQUIRESRENEWALFEE: MOCK_URL,
  GET_VIEW_HM_RENEWALS_REQUIRESNOTICE: MOCK_URL,
  GET_VIEW_HM_RENEWALS_NOTICESENT: MOCK_URL,
  GET_VIEW_HM_RENEWALS_NOTICERECEIVED: MOCK_URL,
  GET_VIEW_HM_RENEWALS_FEESOUTSTANDING: MOCK_URL,
  GET_VIEW_HM_RENEWALS_REQUIRESCERTIFICATEPAID: MOCK_URL,
  GET_VIEW_HM_RENEWALS_REQUIRESCERTIFICATE: MOCK_URL,
  GET_VIEW_HM_INSPSCHEDULE_MYSCHEDULE: MOCK_URL,
  GET_VIEW_HM_INSPSCHEDULE_CALENDAR: MOCK_URL,
  GET_VIEW_HM_INSPSCHEDULE_ALLBYOFFICER: MOCK_URL,
  GET_VIEW_HM_INSPSCHEDULE_NOTINSPECTED: MOCK_URL,
  GET_VIEW_HM_INSPSCHEDULE_INSPECTIONSDUE: MOCK_URL,
  GET_VIEW_HM_INSPSCHEDULE_AUDITSCHEDULE: MOCK_URL,
  GET_VIEW_HM_COMPLAINTS_BYREFNO: MOCK_URL,
  GET_VIEW_HM_COMPLAINTS_BYCATEGORY: MOCK_URL,
  GET_VIEW_HM_COMPLAINTS_BYSTATUS: MOCK_URL,
  GET_VIEW_HM_NOTICES_BYDATE: MOCK_URL,
  GET_VIEW_HM_NOTICES_BYDUESTATUS: MOCK_URL,
  GET_VIEW_HM_NOTICES_BYOFFICER: MOCK_URL,
  GET_VIEW_HM_SAMPLES_BYDATE: MOCK_URL,
  GET_VIEW_HM_SAMPLES_BYTYPE: MOCK_URL,
  GET_VIEW_HM_SAMPLES_BYOFFICER: MOCK_URL,
  GET_VIEW_HM_SAMPLES_BYMONTH: MOCK_URL,
  GET_VIEW_HM_SAMPLES_BYSTATUS: MOCK_URL,
  GET_VIEW_HM_SAMPLES_BYSURVEY: MOCK_URL,
  GET_VIEW_HM_SAMPLES_SURVEYS: MOCK_URL,
  GET_VIEW_HM_SAMPLES_LASTSAMPLED: MOCK_URL,
  GET_VIEW_HM_RECALLS_BYTRADINGNAME: MOCK_URL,
  GET_VIEW_HM_RECALLS_BYMONTH: MOCK_URL,
  GET_VIEW_HM_RECALLS_BYNUMBER: MOCK_URL,
  GET_VIEW_HM_FINANCE_OSFEES: MOCK_URL,
  GET_VIEW_HM_FINANCE_PAIDFEES: MOCK_URL,
  GET_VIEW_HM_FINANCE_FEESALL: MOCK_URL,
  GET_VIEW_HM_FINANCE_NOTSENTTOFINANCE: MOCK_URL,
  GET_VIEW_HM_FINANCE_SENTTOFINANCE: MOCK_URL,
  GET_VIEW_HM_FSR_LOG: MOCK_URL,
  GET_VIEW_HM_FSR_PREMISESREPORT: MOCK_URL,
  GET_VIEW_HM_FSR_PREMISESTYPES: MOCK_URL,
  GET_VIEW_HM_FSR_INSPECTIONSTYPES: MOCK_URL,
  GET_VIEW_HM_FSR_NOTICETYPES: MOCK_URL,
  GET_VIEW_HM_FSR_COMPLAINTTYPES: MOCK_URL,
  GET_VIEW_HM_FSR_KEYWORDS: MOCK_URL,
  GET_VIEW_HM_GOVTREPORTS_TOBACCORETAILERS: MOCK_URL,
  GET_VIEW_HM_GOVTREPORTS_TOBACCOINSPECTIONS: MOCK_URL,
  GET_VIEW_HM_GOVTREPORTS_VENDINGMACHINES: MOCK_URL,
  GET_VIEW_HM_GOVTREPORTS_EATINFACILITIES: MOCK_URL,
  GET_VIEW_HM_GOVTREPORTS_OUTDOORDINING: MOCK_URL,
  GET_VIEW_HM_GOVTREPORTS_LICENCED: MOCK_URL,
  GET_VIEW_HM_SYSTEMADMIN_PREMISESTYPES: MOCK_URL,
  GET_VIEW_HM_SYSTEMADMIN_CLASSIFICATIONS: MOCK_URL,
  GET_VIEW_HM_SYSTEMADMIN_RISKS: MOCK_URL,
  GET_VIEW_HM_SYSTEMADMIN_NCITEMS: MOCK_URL,
  GET_VIEW_HM_SYSTEMADMIN_INSPCOMMENTS: MOCK_URL,
  GET_VIEW_HM_SYSTEMADMIN_SYSTEMSETTINGS: MOCK_URL,
  GET_VIEW_HM_RECYCLEBIN_PREMISES: MOCK_URL,
  GET_VIEW_HM_RECYCLEBIN_PREMISESTYPES: MOCK_URL,
  GET_VIEW_HM_RECYCLEBIN_FEES: MOCK_URL,
  GET_VIEW_HM_RECYCLEBIN_INSPECTIONS: MOCK_URL,
  GET_VIEW_HM_RECYCLEBIN_SAMPLES: MOCK_URL,
};
