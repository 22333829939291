import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { useAssessmentChargesStore } from "@app/products/property/assessments/[id]/components/child-screens/charges/store";
import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import {
  getInitialDataModifyChargeDetail,
  postNewModifyChargeDetail,
} from "@app/products/property/assessments/components/dialogs/modify-charge-details/api";
import { ChargeDetailsFormStep } from "@app/products/property/assessments/components/dialogs/modify-charge-details/form-elements/details/_index";
import {
  DTO_Charge_LOVs,
  DTO_Workflow_AssessmentModifyChargeDetail,
  EKeysOfStepsAssessmentModifyChargeDetail,
  ModifChargeDetailsRequestObjFromSpatial,
} from "@app/products/property/assessments/components/dialogs/modify-charge-details/model";
import { useAssessmentModifyChargeDetailDialogStore } from "@app/products/property/assessments/components/dialogs/modify-charge-details/store";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

interface IModifyChargeDetailsDialogProps {
  onClose: () => void;
  isSaveOnNextStep?: boolean;
  dataFromActionList?: VO_Workflow_Draft;
  isRedirectManagePage?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
}

export const ModifyChargeDetailsDialog = observer(
  ({
    onClose,
    // use when integrate api
    isSaveOnNextStep = false,
    dataFromActionList,
    isRedirectManagePage = true,
    prefixTitle,
    suffixTitle,
  }: IModifyChargeDetailsDialogProps) => {
    // State store
    const history = useHistory();
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const { chargesSelected, reloadChargesExistedFilter } =
      useAssessmentChargesStore();
    const { setAssessmentModifyChargeDetailLOVs } =
      useAssessmentModifyChargeDetailDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForFinishDialog } = useConfirmFinishDialogStore();
    const { currentFormTitle } = useCommonProductStore();
    const {
      isIncompleteMode,
      isReadOnly,
      statusBadge,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
      isFromActionList,
    } = usePropertyWorkflow(dataFromActionList);

    // State local
    const [initData, setInitData] =
      useState<DTO_Workflow_AssessmentModifyChargeDetail>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const { pushNotification } = useCCAppNotificationStore();
    const [isLoadingProcess, setIsLoadingProcess] = useState<
      WorkflowProcessMode | undefined
    >();
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Modify Charge Detail";
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    //#region <Set up initial value>
    const initialValue = useMemo((): any => {
      let initDetails: any = {
        ...initData?.WorkflowDetail?.ChargesDetails,
      };

      return {
        [EKeysOfStepsAssessmentModifyChargeDetail.Details]: initDetails,
        [EKeysOfStepsAssessmentModifyChargeDetail.Comments]: {},
        [EKeysOfStepsAssessmentModifyChargeDetail.Documents]: {},
      };
    }, [initData]);
    //#endregion

    //#region <STEPS>
    const steps: IStep[] = [
      {
        label: "Details",
        component: ChargeDetailsFormStep,
        visible: true,
        key: EKeysOfStepsAssessmentModifyChargeDetail.Details,
        options: {
          isReadOnly,
        },
        initialValues:
          initialValue[EKeysOfStepsAssessmentModifyChargeDetail.Details],
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        visible: true,
        key: EKeysOfStepsAssessmentModifyChargeDetail.Comments,
        customClassName: "cc-comment-step-fixed-height-grid",
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Assessment,
        },
        initialValues:
          initialValue[EKeysOfStepsAssessmentModifyChargeDetail.Comments],
      },
      {
        label: "Documents",
        component: DocumentsFormStep,
        visible: true,
        key: EKeysOfStepsAssessmentModifyChargeDetail.Documents,
        options: {
          isReadOnly,
          workflowDraftId,
          // workflowType: WorkflowTypes.Create_Assessment, need check
        },
        initialValues:
          initialValue[EKeysOfStepsAssessmentModifyChargeDetail.Documents],
      },
    ];
    //#endregion

    //#region <HANDLE SUBMIT>
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Finish:
          handleConfirmFinish(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleParkProcess(
            processData(data),
            EListSubmitButton.ConfirmCloseYes
          );
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };
    //#endregion

    //#region <FINISH>
    const handleConfirmFinish = (
      payload: DTO_Workflow_AssessmentModifyChargeDetail
    ) => {
      const dataProcessed = processData(payload);
      const finishCallback = function async() {
        return handleFinishProcess(dataProcessed);
      };
      setDataForFinishDialog({
        finishCallback,
        confirmMessage:
          "The charge detail will be modified based on the information provided. Are you sure you want to submit?",
      });
    };

    const handleFinishProcess = async (
      payload: DTO_Workflow_AssessmentModifyChargeDetail
    ) => {
      //set loading button
      setIsLoadingProcess(WorkflowProcessMode.Finish);
      //props send to process workflow
      const finishProps: INewProcessWorkflow<DTO_Workflow_AssessmentModifyChargeDetail> =
        {
          payload: payload,
          actionSuccess: async (e) => {
            const notificationContent: IAppNotificationItemAddProps = {
              title:
                e?.Notification ??
                "Modify charge detail was completed successfully",
              type: "success",
            };
            onClose();
            if (isRedirectManagePage) {
              history.push(`${PROPERTY_ASSESSMENT_ROUTE}/${e?.Component_ID}`, {
                notification: notificationContent,
              });
            } else {
              pushNotification(notificationContent);
              reloadChargesExistedFilter(e?.Component_ID);
            }
          },
          setLoading: () => {
            setIsLoadingProcess(undefined);
          },
          defaultFailedMessage: "Charge detail could not be modified.",
          modeProcess: WorkflowProcessMode.Finish,
        };
      //calling api process workflow
      await handleProcessWorkflow(finishProps);
    };
    //#endregion

    //#region <PARK>
    const handleParkProcess = async (
      payload: DTO_Workflow_AssessmentModifyChargeDetail,
      mode?: EListSubmitButton
    ) => {
      //set loading button and dialog
      setIsLoadingProcess(WorkflowProcessMode.Park);
      mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
      const actionCloseRetainDialog = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog();
      };
      //props send to process workflow
      const parkProps: INewProcessWorkflow<DTO_Workflow_AssessmentModifyChargeDetail> =
        {
          payload: payload,
          actionSuccess: (e) => {
            onClose();
            pushNotification({
              title:
                e?.Notification ?? "Charge detail was parked successfully.",
              type: "success",
            });
            isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
          },
          setLoading: () => {
            setIsLoadingProcess(undefined);
          },
          actionClose: () => {
            mode === EListSubmitButton.ConfirmCloseYes &&
              actionCloseRetainDialog();
          },
          defaultFailedMessage: "Charge detail could not be parked.",
          modeProcess: WorkflowProcessMode.Park,
        };

      //calling api process workflow
      await handleProcessWorkflow(parkProps);
    };
    //#endregion

    //#region <CANCEL>
    const handleCancelButton = (data: any) => {
      if (isFromActionList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postNewModifyChargeDetail,
          dataCancel: data,
          defaultSuccessMessage: "Charge detail was cancelled successfully",
          defaultErrorMessage: "Charge detail could not be cancelled",
        });
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <CLOSE>
    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };
    //#endregion

    //#region <NEXT and SAVE>
    const handleNextButton = async (
      data: DTO_Workflow_AssessmentModifyChargeDetail
    ) => {
      const newData = { ...data };
      const processPayload = processData(newData);
      //send data to call api save
      return handleSaveAndNext(processPayload);
    };

    const handleSaveAndNext = async (
      payload: DTO_Workflow_AssessmentModifyChargeDetail
    ): Promise<boolean> => {
      //check condition use for Save button
      setIsLoadingProcess(WorkflowProcessMode.Save);
      //Calling process Save at next button
      const response = await postNewModifyChargeDetail(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingProcess(undefined);
      //set default notification
      const defaultSuccessMessage = "Charge detail was saved successfully.";
      const defaultFailedMessage = "Charge detail could not be saved.";
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (!isSaveOnNextStep) {
          onClose();
          pushNotification({
            title: response?.data?.Notification ?? defaultSuccessMessage,
            type: "success",
          });
        } else {
          // check is the first saving
          if (isFirstSave) {
            setIsFirstSave(false);
            //set current workflowDraft Id
            setWorkflowDraftId(response?.data?.ID ?? 0);
            // set payload to send
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID,
              },
            });
          }
        }
        return true;
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      return false;
    };
    //#endregion

    //#region <PROCESS DATA, PROCESS WORKFLOW>
    const processData = (data: any) => {
      let workflowDetail = {
        ChargesDetails: {
          ...initData?.WorkflowDetail?.ChargesDetails,
          ...data?.Details,
        },
      };

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const handleProcessWorkflow = async (
      props: INewProcessWorkflow<DTO_Workflow_AssessmentModifyChargeDetail>
    ) => {
      const {
        actionSuccess,
        defaultFailedMessage,
        setLoading,
        modeProcess,
        payload,
      } = props;
      const response = await postNewModifyChargeDetail(modeProcess, payload);
      setLoading();
      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        if (props?.actionFail) props?.actionFail(response);
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
      if (props?.actionClose) props.actionClose();
    };
    //#endregion

    //#region <GET DATA>
    const getWorkflowData = async () => {
      const draftId = dataFromActionList?.Workflow_Draft_Id;
      notificationFormStepRef?.current?.setLoadingFormStep(true);

      const requestObjFromSpatial = {
        ChargeId: chargesSelected?.[0]?.Id,
      } as ModifChargeDetailsRequestObjFromSpatial;

      const response = await getInitialDataModifyChargeDetail(
        draftId,
        requestObjFromSpatial
      );
      if (Array.isArray(response)) {
        const [lovsChargeDetails, workflowData] = response;
        if (
          isSuccessResponse(response[0]) &&
          isSuccessResponse(response[1]) &&
          lovsChargeDetails?.data &&
          workflowData?.data
        ) {
          //set Lovs Data Assessment
          setAssessmentModifyChargeDetailLOVs(
            lovsChargeDetails?.data?.dtoCreate_charge_LOVs
          );
          if (workflowData?.data) {
            //set New InitData
            setInitData({
              WorkflowDetail: workflowData?.data?.WorkflowDetail,
              WorkflowHeader: workflowData?.data?.WorkflowHeader,
            });
            //set workflow header and workflow Draft Id
            if (workflowData?.data?.WorkflowHeader) {
              setWorkflowHeader(workflowData?.data.WorkflowHeader);
              setWorkflowDraftId(
                workflowData?.data?.WorkflowHeader?.WorkflowDraft
                  ?.Workflow_Draft_Id ?? 0
              );
            }
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
        } else {
          let responseError: APIResponse<
            | DTO_Charge_LOVs
            | DTO_Workflow_AssessmentModifyChargeDetail
            | ResponsePacket
          > = response[0];
          if (!isSuccessResponse(response[1])) {
            responseError = response[1];
          }
          notificationFormStepRef?.current?.setLoadingFormStep(false);
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error:
                (responseError.data as ResponsePacket)?.Errors ??
                "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = response as APIResponse;
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };
    //#endregion

    useEffectOnce(() => {
      getWorkflowData();
    });

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        onSubmit={handleSubmit}
        listButtonId={listSubmitButton}
        initialValues={initialValue}
        initialSteps={steps}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => {
          return (
            <CCDialog
              maxWidth="65%"
              titleHeader={titleHeader}
              badge={statusBadge}
              disabled={isLoadingProcess !== undefined}
              onClose={() => handleCloseDialog(renderProps)}
              bodyElement={renderProps.children}
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  {isShowParkButton(isFromActionList, isIncompleteMode) && (
                    <Button
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Park
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      id={EListSubmitButton.Park}
                      onClick={renderProps.submitButton.onClick}
                      disabled={
                        renderProps.nextButton.disabled ||
                        isLoadingProcess === WorkflowProcessMode.Park
                      }
                    >
                      Park
                    </Button>
                  )}
                  {isShowCancelWorkflowButton && (
                    <Button
                      className={"cc-dialog-button"}
                      disabled={!isNil(isLoadingProcess)}
                      id={EListSubmitButton.Cancel}
                      onClick={renderProps.submitButton.onClick}
                    >
                      Cancel
                    </Button>
                  )}
                  {!renderProps.prevButton.disabled && (
                    <Button
                      className={"cc-dialog-button"}
                      themeColor="primary"
                      onClick={renderProps.prevButton.onClick}
                    >
                      Previous
                    </Button>
                  )}
                  {(isToBeApprovalMode || isReadOnly) &&
                  renderProps.isLastStep ? null : (
                    <Button
                      themeColor="primary"
                      id={
                        !renderProps.isLastStep &&
                        (isToBeApprovalMode || isReadOnly)
                          ? "cc-next-step-button"
                          : renderProps.nextButton.idButton
                      }
                      disabled={
                        isLoadingProcess === WorkflowProcessMode.Save ||
                        renderProps.nextButton.disabled
                      }
                      iconClass={
                        isLoadingProcess === WorkflowProcessMode.Save
                          ? "fas fa-spinner fa-spin"
                          : ""
                      }
                      className={"cc-dialog-button"}
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingProcess === WorkflowProcessMode.Save
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )}
                </div>
              }
            />
          );
        }}
      />
    );
  }
);
