import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { ExistedSupplementaryRates } from "./components/forms/existed/_index";
import { NewSupplementaryRates } from "./components/forms/new/_index";

const ManageSupplementaryRates = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const supplementaryRatesId = params.id;

  const { loadSupplementaryRates, resetSupplementaryRatesStore } =
    useSupplementaryRatesStore();
  const { resetMenu } = useCCSubActionBarStore();

  useEffect(() => {
    if (isNew || !supplementaryRatesId) return;
    loadSupplementaryRates(+supplementaryRatesId);
  }, [supplementaryRatesId, isNew, loadSupplementaryRates]);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetSupplementaryRatesStore();
    };
  });

  if (isNew) {
    return <NewSupplementaryRates />;
  }

  return <ExistedSupplementaryRates />;
};
export default observer(ManageSupplementaryRates);
