import { loadViewConfiguresColumns } from "@app/products/property/api";
import { useDetailAssessmentListViewStore } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/store";
import { GridDetailAssessment } from "@app/products/property/assessments/collections/components/detail-assessment/components/grid-detail-assessment/_index";
import { colCollectionAssess } from "@app/products/property/assessments/collections/components/detail-assessment/config";
import { VO_Collection_Assessment } from "@app/products/property/assessments/collections/components/detail-assessment/model";
import { ViewConfiguration } from "@app/products/property/model";
import { APIResponseError } from "@common/apis/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<VO_Collection_Assessment>();
export const DetailAssessment = (props: GridDetailRowProps) => {
  const {resetStore} = useDetailAssessmentListViewStore()
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedColList, setProcessedColList] =
    useState<IColumnFields[]>(colCollectionAssess);

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.OData_Collection_Assessments,
      colCollectionAssess
    );
    if (Array.isArray(response)) {
      setProcessedColList(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
    return () => resetStore()
  });
  
  if (isLoading) return <Loading isLoading={isLoading}/>;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );
    
  return (
    <GridDetailAssessment
      className="cc-detail-assessments-collections"
      dataUrl={`odata/property/internal/collectionassessmentsregister?collectionId=${props.dataItem.Collection_Id}&$count=true&`}
      columnFields={processedColList}
      primaryField={nameOf("Assessment_Id")}
    />
  );
};
