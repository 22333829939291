import { searchNameItemRender } from "@app/products/property/certificates/[id]/components/form-steps/components/config";
import { SearchNameCombobox } from "@app/products/property/components/fields/search-name/_index";
import { loadNameDetail } from "@app/products/property/components/fields/search-name/api";
import { DTO_Entity_Name_Address } from "@app/products/property/components/fields/search-name/model";
import {
  handleSearchNameProcess,
  ISearchNameProcessParams,
} from "@app/products/property/components/fields/search-name/util";
import { SearchOptions } from "@app/products/property/components/fields/search-options/_index";
import { DTO_Workflow_CreateContact } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { ResponseMessage } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import "./_index.scss";
import { processDefaultValue } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/details/util";

export const ApplicantDetailFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    localNotificationRef,
    nameOf,
    options = {
      isReadOnly: false,
      isLoadingInStep: false,
      setIsLoadingInStep: () => {},
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const handleLoadNameDetail = async (value: any) => {
      const response = await loadNameDetail(value?.Entity_Id);
      if (isSuccessResponse(response)) {
        const contactDetail = response?.data?.Entity_Name_Address;
        onChange(nameOf("ApplicantId"), {
          value: contactDetail?.Entity_Name_Address_Id,
        });
        onChange(nameOf("Address"), { value: contactDetail?.Full_Address });
        onChange(nameOf("Locality"), { value: contactDetail?.Locality });
        onChange(nameOf("State"), { value: contactDetail?.State });
        onChange(nameOf("Postcode"), { value: contactDetail?.Postcode });
        onChange(nameOf("Email"), { value: value?.Email });
        onChange(nameOf("Country"), { value: contactDetail?.Country });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: response?.error ?? "Name could not be added",
          type: "error",
          autoClose: false,
        });
      }
    };
    const handleAddName = async (value: any) => {
      onChange(nameOf("Name"), {
        value: { Name: value?.DisplayName },
      });
      const dataProcess: ISearchNameProcessParams = {
        data: value,
        handleLoadNameDetail: async (data: DTO_Entity_Name_Address) => {
          await handleLoadNameDetail({
            ...value,
            Entity_Id: data?.Entity_Id,
          });
        },
        notificationAction: {
          canNotAddName: (response) => {
            localNotificationRef?.current?.pushNotification({
              title: response?.error ?? "Name could not be added",
              type: "error",
              autoClose: false,
            });
          },
        },
        setLoading: (isLoading: boolean) => {
          options.setIsLoadingInStep(isLoading);
        },
      };

      await handleSearchNameProcess(dataProcess);
    };

    const handleSubmitNewContactDialog = (
      _payload: DTO_Workflow_CreateContact,
      responseMessage: ResponseMessage
    ) => {
      if (responseMessage?.IsSuccess && responseMessage?.Component_ID) {
        handleAddName({
          Entity_Id: responseMessage.Component_ID,
        });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: responseMessage?.ErrorMessage ?? "Name could not be added",
          type: "error",
          autoClose: false,
        });
      }
    };

    return (
      <>
        <section className="cc-field-group cc-applicant-detail-step">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Reference</label>
              <Field
                name={nameOf("Reference")}
                component={CCInput}
                placeholder="Reference"
                readOnly={options?.isReadOnly}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <SearchNameCombobox
                name={nameOf("Name")}
                title="Applicant name"
                placeholder="Applicant name"
                onSelectionChange={(values) => handleAddName(values)}
                disabled={options?.isReadOnly}
                selectedSearchOptions={getFieldValue("SearchOptions")}
                onError={(error: any) => {
                  localNotificationRef?.current?.pushNotification({
                    type: "error",
                    title: error ?? "Search name error",
                    autoClose: false,
                  });
                }}
                isClearSearchValue={false}
                defaultValue={processDefaultValue(getFieldValue("Name"))}
                onSubmitNewContactDialog={handleSubmitNewContactDialog}
                itemRender={searchNameItemRender}
              />
            </div>
            <SearchOptions
              formRenderProps={formRenderProps}
              nameOf={nameOf}
              isDisable={options?.isReadOnly}
            />
            <div className="cc-field">
              <CCLabel title={"Address"} isLoading={options.isLoadingInStep} />
              <Field
                name={nameOf("Address")}
                readOnly
                component={CCInput}
                placeholder="Address"
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Locality"} isLoading={options.isLoadingInStep} />
              <Field
                name={nameOf("Locality")}
                readOnly
                component={CCInput}
                placeholder="Locality"
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"State"} isLoading={options.isLoadingInStep} />
              <Field
                name={nameOf("State")}
                readOnly
                component={CCInput}
                placeholder="State"
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Postcode"} isLoading={options.isLoadingInStep} />
              <Field
                name={nameOf("Postcode")}
                readOnly
                component={CCInput}
                placeholder="Postcode"
              />
            </div>
            <div className="cc-field">
              <CCLabel
                title={"Email address"}
                isLoading={options.isLoadingInStep}
              />
              <Field
                name={nameOf("Email")}
                readOnly
                placeholder="Email address"
                component={CCInput}
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Country"} isLoading={options.isLoadingInStep} />
              <Field
                name={nameOf("Country")}
                readOnly
                component={CCInput}
                placeholder="Country"
              />
            </div>
          </div>
        </section>
      </>
    );
  }
);
