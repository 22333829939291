import { PoundRegisterSubmitActions } from "@app/products/animals/pound-register/[id]/model";
import { useAnimalPoundRegisterStore } from "@app/products/animals/pound-register/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const PoundAddRegistrationButton = observer(() => {
  const { poundRegisterInfo, isLoading, onSubmit } =
    useAnimalPoundRegisterStore();

  const isVisible = useMemo(() => {
    return (
      isEmpty(poundRegisterInfo?.AnimalsRegistration_ID) &&
      !isEmpty(poundRegisterInfo?.RegisterNo)
    );
  }, [poundRegisterInfo]);

  return isVisible ? (
    <CCNavButton
      title={"Add registration"}
      name={PoundRegisterSubmitActions.AddRegister}
      onClick={onSubmit}
      isLoading={isLoading}
    />
  ) : null;
});
