import { eventEmitter } from "@/App";
import {
  getImportValuationLOVs,
  importValuations,
  updateImportValuationHeader,
} from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/dialogs/import-valuations-tas/api";
import { ImportValuationFileRestrictions } from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/dialogs/import-valuations-tas/config";
import {
  DTO_ImportValuationHeader,
  DTO_Supplementary_Import,
} from "@app/products/property/supplementary-rates/import-valuations/components/action-bar/dialogs/import-valuations-tas/model";
import { convertValueLOVToNumber } from "@app/products/property/util";

import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IImportValuationsDialogProps {
  onClose: () => void;
}

const nameOf = nameOfFactory<DTO_ImportValuationHeader>();
export const ImportValuationsTASDialog = observer(
  ({ onClose }: IImportValuationsDialogProps) => {
    const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);

    const { pushNotification, clearNotifications } =
      useCCAppNotificationStore();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [importValuationLOVs, setImportValuationLOVs] =
      useState<DTO_Supplementary_Import>();
    const [initialValues, setInitialValues] =
      useState<DTO_ImportValuationHeader>();
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [finishConfirmDialogData, setFinishConfirmDialogData] =
      useState<DTO_ImportValuationHeader>();

    const loadLOVs = async () => {
      setIsLoading(true);
      const response = await getImportValuationLOVs();
      let errorResponse = undefined;
      if (isSuccessResponse(response)) {
        setImportValuationLOVs(response?.data);
        setInitialValues(response?.data?.ImportValuationHeader);
      } else {
        errorResponse = {
          status: response?.status,
          error: response?.error ?? "Load failed",
        };
      }
      setResponseLoadError(errorResponse);
      setIsLoading(false);
    };

    const valuationTypesLOvs =
      convertValueLOVToNumber(
        importValuationLOVs?.ValuationTypes ?? [],
        "Code"
      ) ?? [];

    useEffectOnce(() => {
      loadLOVs();
    });

    const handleFinish = async (data: DTO_ImportValuationHeader) => {
      setIsSubmitting(true);
      //Save options
      const saveOptionsResponse = await updateImportValuationHeader(data);
      clearNotifications();
      if (
        isSuccessResponse(saveOptionsResponse) &&
        saveOptionsResponse?.data?.IsSuccess
      ) {
        //Upload file
        if (!isNil(data?.File)) {
          const file = data?.File[0].getRawFile();
          const formData = new FormData();
          formData.append("file", file);

          const importValResponse = await importValuations(formData);
          setIsSubmitting(false);
          if (
            isSuccessResponse(importValResponse) &&
            importValResponse?.data?.IsSuccess
          ) {
            eventEmitter.emit(CCGridEventType.RefreshOData);
            onClose();
            if (data?.Match_On_Assess_Number) {
              pushNotification({
                title: "Valuations have been imported.",
                type: "success",
              });
            } else {
              pushNotification({
                title: "Valuations have been imported.",
                description: `Valuations currently do not match any assessment. 
                Please ensure the assessments exist and that the assessments have the corresponding valuation number as unmatched valuations will not be imported into the supplementary.`,
                type: "warning",
                autoClose: false,
              });
            }
          } else {
            setFinishConfirmDialogData(undefined);
            notificationRef.current?.pushNotification({
              title:
                importValResponse?.data?.ErrorMessage ??
                "Import valuations failed.",
              type: "error",
              autoClose: false,
            });
          }
        }
      } else {
        setIsSubmitting(false);
        setFinishConfirmDialogData(undefined);
        notificationRef.current?.pushNotification({
          title:
            saveOptionsResponse?.data?.ErrorMessage ??
            "Import valuations failed.",
          type: "error",
          autoClose: false,
        });
      }
    };

    return (
      <Form
        onSubmit={(data) => {
          setFinishConfirmDialogData(data as DTO_ImportValuationHeader);
        }}
        initialValues={initialValues}
        key={JSON.stringify(initialValues)}
        render={(formRenderProps: FormRenderProps) => {
          const { valueGetter, onChange, onSubmit, modified, valid } =
            formRenderProps;
          return (
            <>
              <CCDialog
                titleHeader="Tasmania Valuation File Import"
                onClose={onClose}
                maxWidth="55%"
                maxHeight="70%"
                bodyElement={
                  //Loading
                  isLoading ? (
                    <Loading isLoading />
                  ) : //Load other data failed
                  responseLoadError ? (
                    <CCLoadFailed
                      responseError={responseLoadError}
                      onReload={() => {
                        loadLOVs();
                      }}
                    />
                  ) : (
                    <div className="cc-import-valuation cc-form">
                      <CCLocalNotification ref={notificationRef} />
                      <FormElement>
                        <section className="cc-field-group">
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <CCLabel title="Default valuation dates" />
                              <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
                                <div className="cc-form-cols-3">
                                  <div className="cc-field">
                                    <CCLabel
                                      title="Date of valuation"
                                      isMandatory
                                    />
                                    <Field
                                      name={nameOf("Issue_Date")}
                                      component={CCDatePicker}
                                      format={DATE_FORMAT.DATE_CONTROL}
                                      validator={requiredValidator}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <CCLabel
                                      title="Effective date"
                                      isMandatory
                                    />
                                    <Field
                                      name={nameOf("Supp_Date")}
                                      component={CCDatePicker}
                                      format={DATE_FORMAT.DATE_CONTROL}
                                      validator={requiredValidator}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <CCLabel title="Base date" isMandatory />
                                    <Field
                                      name={nameOf("Base_Date")}
                                      component={CCDatePicker}
                                      format={DATE_FORMAT.DATE_CONTROL}
                                      validator={requiredValidator}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <CCLabel title="Valuation types" />
                              <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
                                <div className="cc-form-cols-3">
                                  <div className="cc-field">
                                    <CCLabel title="Land value" />
                                    <Field
                                      name={nameOf("Valuation_Type_1")}
                                      component={CCSearchComboBox}
                                      data={valuationTypesLOvs}
                                      textField="Name"
                                      dataItemKey="Code"
                                      onChange={(
                                        event: ComboBoxChangeEvent
                                      ) => {
                                        onChange(nameOf("Valuation_Type_1"), {
                                          value: event.value?.Code,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <CCLabel title="Capital value" />
                                    <Field
                                      name={nameOf("Valuation_Type_2")}
                                      component={CCSearchComboBox}
                                      data={valuationTypesLOvs}
                                      textField="Name"
                                      dataItemKey="Code"
                                      onChange={(
                                        event: ComboBoxChangeEvent
                                      ) => {
                                        onChange(nameOf("Valuation_Type_2"), {
                                          value: event.value?.Code,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <CCLabel title="AAV" />
                                    <Field
                                      name={nameOf("Valuation_Type_3")}
                                      component={CCSearchComboBox}
                                      data={valuationTypesLOvs}
                                      textField="Name"
                                      dataItemKey="Code"
                                      onChange={(
                                        event: ComboBoxChangeEvent
                                      ) => {
                                        onChange(nameOf("Valuation_Type_3"), {
                                          value: event.value?.Code,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <CCLabel title="Adjusted capital value" />
                                    <Field
                                      name={nameOf("Valuation_Type_4")}
                                      component={CCSearchComboBox}
                                      data={valuationTypesLOvs}
                                      textField="Name"
                                      dataItemKey="Code"
                                      onChange={(
                                        event: ComboBoxChangeEvent
                                      ) => {
                                        onChange(nameOf("Valuation_Type_4"), {
                                          value: event.value?.Code,
                                        });
                                      }}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <CCLabel title="Adjusted AAV" />
                                    <Field
                                      name={nameOf("Valuation_Type_5")}
                                      component={CCSearchComboBox}
                                      data={valuationTypesLOvs}
                                      textField="Name"
                                      dataItemKey="Code"
                                      onChange={(
                                        event: ComboBoxChangeEvent
                                      ) => {
                                        onChange(nameOf("Valuation_Type_5"), {
                                          value: event.value?.Code,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">Options</label>
                              <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
                                <div className="cc-form-cols-3">
                                  <div className="cc-field">
                                    <CCLabel title="Revaluations" />
                                    <Field
                                      name={nameOf("Is_Revaluation")}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        nameOf("Is_Revaluation")
                                      )}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <CCLabel title="Only import last valuation" />
                                    <Field
                                      name={nameOf(
                                        "Only_Import_Last_Valuation"
                                      )}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        nameOf("Only_Import_Last_Valuation")
                                      )}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <CCLabel title="Match on assessment number" />
                                    <Field
                                      name={nameOf("Match_On_Assess_Number")}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        nameOf("Match_On_Assess_Number")
                                      )}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <CCLabel title="Update land use" />
                                    <Field
                                      name={nameOf("Update_Land_Use")}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        nameOf("Update_Land_Use")
                                      )}
                                    />
                                  </div>
                                  <div className="cc-field">
                                    <CCLabel title="Adjustment year" />
                                    <Field
                                      name={nameOf("Adjustment_Year")}
                                      component={CCSwitch}
                                      checked={valueGetter(
                                        nameOf("Adjustment_Year")
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cc-form-cols-1">
                            <div className="cc-field">
                              <label className="cc-label">
                                Upload file
                                <CCTooltip type="validator" position="right" />
                                <CCTooltip
                                  type="custom"
                                  position="auto"
                                  content=" "
                                  customTemplate={
                                    <div>
                                      &#8226; Allowed file type(s): .txt, .csv,
                                      .dat
                                      <br />
                                      &#8226; Maximum upload file size: 4 MB
                                    </div>
                                  }
                                >
                                  <i className="fa fa-info-circle ml-1 text-accent" />
                                </CCTooltip>
                              </label>
                              <Field
                                name={"File"}
                                component={CCUploadFile}
                                accept={
                                  ImportValuationFileRestrictions.ACCEPT_FORMAT
                                }
                                autoUpload={false}
                                batch={false}
                                multiple={false}
                                withCredentials={false}
                                files={valueGetter("File")}
                                restrictions={{
                                  allowedExtensions:
                                    ImportValuationFileRestrictions.ACCEPT_FORMAT,
                                  maxFileSize:
                                    ImportValuationFileRestrictions.MAX_FILE_SIZE,
                                }}
                                onAdd={(event: UploadOnAddEvent) => {
                                  onChange("File", {
                                    value: event.newState,
                                  });
                                }}
                                onRemove={(event: UploadOnRemoveEvent) => {
                                  onChange("File", {
                                    value: event.newState,
                                  });
                                }}
                                showActionButtons={false}
                                validator={requiredValidator}
                              />
                            </div>
                          </div>
                        </section>
                      </FormElement>
                    </div>
                  )
                }
                footerElement={
                  <div className="cc-dialog-footer-actions-right">
                    <Button
                      className="cc-dialog-button"
                      themeColor="primary"
                      disabled={!modified || !valid || isLoading}
                      onClick={onSubmit}
                    >
                      Finish
                    </Button>
                  </div>
                }
              />
              {finishConfirmDialogData && (
                <ConfirmDialog
                  title="Confirmation"
                  subMessage={"Are you sure you want to submit?"}
                  onClosePopup={() => setFinishConfirmDialogData(undefined)}
                  isLoadingYes={isSubmitting}
                  onAsyncConfirm={() => {
                    return handleFinish(finishConfirmDialogData);
                  }}
                />
              )}
            </>
          );
        }}
      />
    );
  }
);
