import { VO_Title } from "@app/products/property/titles/list/model";
import { PROPERTY_TITLES_ROUTE } from "@app/products/property/titles/[id]/constant";
import {
  BOOLEAN_FORMAT,
  DATETIME_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_Title>();

export const colList: IColumnFields[] = [
  {
    field: nameOf("Legal_Description"),
    title: "Legal Description",
    locked: true,
    linkTo: (dataItem) => `${PROPERTY_TITLES_ROUTE}/${dataItem.Title_Id}`,
  },
  {
    field: nameOf("Reference"),
    title: "Title Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Tenure_Id"),
    title: "Tenure",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Type"),
    title: "Type",
  },
  {
    field: nameOf("Plan_Type_Code"),
    title: "Plan",
  },
  {
    field: nameOf("Lot"),
    title: "Lot",
  },
  {
    field: nameOf("Crown_Allotment"),
    title: "Crown Allotment",
  },
  {
    field: nameOf("PlanType"),
    title: "Plan Type",
  },
  {
    field: nameOf("Plan_Number"),
    title: "Plan Number",
  },
  {
    field: nameOf("Dealing_Number"),
    title: "Dealing Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Book_Number"),
    title: "Book Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Section"),
    title: "Section",
  },
  {
    field: nameOf("Volume"),
    title: "Volume",
  },
  {
    field: nameOf("Folio"),
    title: "Folio",
  },
  {
    field: nameOf("LGA"),
    title: "LGA",
  },
  {
    field: nameOf("Assessment_Group"),
    title: "Region",
  },
  {
    field: nameOf("Differential_Category"),
    title: "RLP Board",
  },
  {
    field: nameOf("Title_System"),
    title: "Lot System",
  },
  {
    field: nameOf("Township_Id"),
    title: "Township",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("County"),
    title: "County",
  },
  {
    field: nameOf("State"),
    title: "State",
  },
  {
    field: nameOf("Country"),
    title: "Country",
  },
  {
    field: nameOf("ParishName"),
    title: "Parish",
  },
  {
    field: nameOf("Status"),
    title: "Status",
  },
  {
    field: nameOf("Division_Date"),
    title: "Division Date",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("Frontage"),
    title: "Frontage",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Land_Area"),
    title: "Land Area",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOf("Is_Area_Surveyed"),
    title: "Is Area Surveyed",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: nameOf("CoverageName"), title: "Coverage" },
  { field: nameOf("Owners"), title: "Owners" },
  { field: nameOf("Address"), title: "Address" },
  { field: nameOf("Locality"), title: "Locality" },
  {
    field: nameOf("Title_Id"),
    title: "Title ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
