import { InspectionType } from "@app/core/inspections/[id]/model";
import { InspectionTypeFormElement } from "@common/pages/settings/lookups/inspection-types/_id/components/general/components/_index";
import { TOnSubmitFlexible } from "@common/stores/flexible-form/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import {
  Form,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const InspectionTypeForm = observer(() => {
  //#region STORE
  const { dataForms, middlewareSubmitForm, setOnSubmitFlexible } =
    useFlexibleFormStore();
  //#endregion STORE

  //#region HOOK
  const { pushNotificationPortal } = useNotificationPortalStore();
  // #endregion

  //#region INITIAL_VALUES
  const inspectionType = dataForms?.GeneralForm as InspectionType;
  //#endregion INITIAL_VALUES

  const handleOnSubmit = (event: FormSubmitClickEvent) => {
    const { whenNotModified, whenNotValid } = middlewareSubmitForm(event);
    whenNotModified(() => {
      pushNotificationPortal({
        title: "The form is not modified.",
        type: "warning",
      });
    });

    whenNotValid(() => {
      pushNotificationPortal({
        title: "Please enter all required information.",
        type: "warning",
      });
    });
  };

  //#region setOnSubmitFlexible ========/
  const submitRef = useRef<TOnSubmitFlexible>();
  useEffect(() => {
    if (submitRef.current) setOnSubmitFlexible(submitRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitRef.current]);
  //#endregion setOnSubmitFlexible =====/

  return (
    <div className="cc-form">
      <Form
        onSubmitClick={handleOnSubmit}
        initialValues={inspectionType}
        key={JSON.stringify(inspectionType)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = {
            formName: "GeneralForm",
            eventSubmit: formRenderProps.onSubmit,
          };
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={
                <InspectionTypeFormElement formRenderProps={formRenderProps} />
              }
            />
          );
        }}
      />
    </div>
  );
});
