import { DirectDebitSessionFormElement } from "@app/products/direct-debit/sessions/[id]/components/general/components/form-element/_index";
import { DirectDebitSessionHeader } from "@app/products/direct-debit/sessions/model";
import { TOnSubmitFlexible } from "@common/stores/flexible-form/model";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { Form, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";

export const DirectDebitSessionForm = observer(() => {
  // #region STORE
  const { dataForms, setOnSubmitFlexible } = useFlexibleFormStore();
  // #endregion STORE

  // #region INITIAL_VALUES
  const directDebitSession = dataForms?.GeneralForm as DirectDebitSessionHeader;
  // #endregion INITIAL_VALUES

  //#region setOnSubmitFlexible ========/
  const submitRef = useRef<TOnSubmitFlexible>();
  useEffect(() => {
    if (submitRef.current) setOnSubmitFlexible(submitRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitRef.current]);
  //#endregion setOnSubmitFlexible =====/

  return (
    <div className="cc-form">
      <Form
        initialValues={directDebitSession}
        key={JSON.stringify(directDebitSession)}
        render={(formRenderProps: FormRenderProps) => {
          submitRef.current = {
            formName: "GeneralForm",
            eventSubmit: formRenderProps.onSubmit,
          };
          return (
            <CCGeneralPanel
              isInvalid={!formRenderProps.valid}
              component={<DirectDebitSessionFormElement />}
            />
          );
        }}
      />
    </div>
  );
});
