import { DTO_Entity } from "@app/products/property/components/fields/search-name/model";
import { DuplicateContactDialog } from "@app/products/property/contacts-central-names/list/components/dialogs/components/dialog/duplicate-contact/_index";
import { UpdateContactDialog } from "@app/products/property/contacts-central-names/list/components/dialogs/update-contact/_index";
import { Button } from "@progress/kendo-react-buttons";
import pluralize from "pluralize";
import React, { useState } from "react";
import "./_index.scss";

interface IDuplicateContactButtonProps {
  onClose: () => void;
  isSearching: boolean;
  duplicateContact: DTO_Entity[];
}

export const DuplicateContactButton = ({
  onClose,
  isSearching,
  duplicateContact,
}: IDuplicateContactButtonProps) => {
  const [showDuplicateContactDialog, setShowDuplicateContactDialog] =
    useState<boolean>(false);
  const [updateContactDialog, setUpdateContactDialog] = useState<
    DTO_Entity | undefined
  >(undefined);

  return (
    <>
      {isSearching || duplicateContact.length > 0 ? (
        <Button
          fillMode="outline"
          className="cc-duplicate-contact-button"
          disabled={isSearching}
          iconClass={isSearching ? "fas fa-spinner fa-spin" : ""}
          onClick={() => setShowDuplicateContactDialog(true)}
        >
          {isSearching
            ? "Checking"
            : `${duplicateContact.length} possible ${pluralize(
                "duplicate",
                duplicateContact.length
              )}`}
        </Button>
      ) : null}

      {showDuplicateContactDialog && (
        <DuplicateContactDialog
          contacts={duplicateContact}
          onClose={() => setShowDuplicateContactDialog(false)}
          onUpdateContact={(entity) => {
            setUpdateContactDialog(entity);
            setShowDuplicateContactDialog(false);
          }}
        />
      )}
      {updateContactDialog && (
        <UpdateContactDialog
          entityId={updateContactDialog?.Entity_Id}
          onClose={() => {
            setUpdateContactDialog(undefined);
            onClose();
          }}
          handleSubmitDialog={() => {
            setUpdateContactDialog(undefined);
            onClose();
          }}
          onCloseSpecial={() => {
            setUpdateContactDialog(undefined);
          }}
          isSaveOnNextStep
        />
      )}
    </>
  );
};
