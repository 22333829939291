import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import { IProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { ECustomColNameProperty } from "@app/products/property/config";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { ASSESSMENT_ACCORDION_GRID_ID } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/_index";
import {
  getAssessmentAdjustmentLOVs,
  getWorkflowAssessmentAdjustment,
  postProcessAssessmentAdjustment,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/api";
import {
  DTO_Workflow_AssessmentAdjustment,
  EKeysOfAssessmentAdjustmentsSteps,
  IInitialData,
  assessmentAdjustmentsKeysOfSendSteps,
} from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { ResponsePacket } from "@common/models/identityPacket";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { pickBy } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import { AssessmentLeviesFormStep } from "./components/form-elements/assessment-levies/_index";
import { BalanceTransferFormStep } from "./components/form-elements/balance-transfer/_index";
import { GeneralFormStep } from "./components/form-elements/general/_index";
import { LevyCalculationFormStep } from "./components/form-elements/levy-calculation/_index";
import { RatingPeriodsFormStep } from "./components/form-elements/rating-periods/_index";
import { ValuationsFormStep } from "./components/form-elements/valuations/_index";

interface IAssessmentAdjustmentsDialogProps {
  onClose: () => void;
  approvalStatus?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  supplementaryId: number;
  prefixTitle?: string;
  suffixTitle?: string;
}

export const AssessmentAdjustmentsDialog = observer(
  ({
    onClose,
    dataFromActionList,
    isSaveOnNextStep = false,
    supplementaryId = 0,
    prefixTitle,
    suffixTitle,
  }: IAssessmentAdjustmentsDialogProps) => {
    //#region HOOKS -------------------------------------------->
    const {
      isReadOnly: isReadOnlyWorkflow,
      statusBadge,
      isFromActionList,
    } = usePropertyWorkflow(dataFromActionList);
    //Use ref
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);

    //Use store
    const {
      assessmentId,
      setAssessmentId,
      setSupplementaryRatesId,
      setSupplementaryAssessmentId,
      setAssessmentAdjustmentLOVs,
      supplementaryAssessments,
    } = useSupplementaryRatesStore();
    //Fields are readonly if the workflow is finished or the supplementary status is 'Complete'
    const isReadOnly =
      isReadOnlyWorkflow ||
      (supplementaryAssessments?.SupplementaryDetail?.IsComplete ?? false);
    const { isLLS, isActro } = CommunityProperty.getFlagOfStates();
    const { pushNotification } = useCCAppNotificationStore();
    const { currentFormTitle } = useCommonProductStore();

    //Use state
    const [initialData, setInitialData] = useState<IInitialData>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const [isLoadingPark, setIsLoadingPark] = useState<boolean>(false);

    //Get label
    const assessmentLabel = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.Assessment
    );

    //Use memo
    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title =
        currentFormTitle(formId ?? 0) ?? `${assessmentLabel} Adjustment`;
      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line
    }, [workflowHeader, prefixTitle, suffixTitle]);

    const initialValues = useMemo(() => {
      let initGeneral = {};
      let initLevies = {};
      let initRatingPeriod = {};

      if (initialData) {
        const workflowDetail = initialData?.workflowData?.WorkflowDetail;
        //General
        if (workflowDetail?.General) {
          initGeneral = {
            ...initGeneral,
            ...workflowDetail?.General,
            InstalmentPlanVisibility: workflowDetail?.InstalmentPlanVisibility,
          };
        }

        //Levies
        if (workflowDetail?.Levies) {
          initLevies = {
            ...initLevies,
            ...workflowDetail?.Levies,
          };
        }

        //Rating period
        if (workflowDetail?.RatingPeriod) {
          initRatingPeriod = {
            ...initRatingPeriod,
            ...workflowDetail?.RatingPeriod,
          };
        }
      }

      notificationFormStepRef?.current?.setStepsVisible([
        {
          key: EKeysOfAssessmentAdjustmentsSteps.AssessmentLevies,
          visible: !!initialData?.showAssessmentLeviesStepper,
          isClearData: true,
        },
        {
          key: EKeysOfAssessmentAdjustmentsSteps.Valuations,
          visible: !!initialData?.showValuationsStepper,
          isClearData: true,
        },
        {
          key: EKeysOfAssessmentAdjustmentsSteps.RatingPeriods,
          visible: !!initialData?.showRatingPeriodsStepper,
          isClearData: true,
        },
        {
          key: EKeysOfAssessmentAdjustmentsSteps.LevyCalculation,
          visible: !!initialData?.showLevyCalculationStepper,
          isClearData: true,
        },
      ]);

      return {
        [EKeysOfAssessmentAdjustmentsSteps.General]: initGeneral,
        [EKeysOfAssessmentAdjustmentsSteps.AssessmentLevies]: initLevies,
        [EKeysOfAssessmentAdjustmentsSteps.Valuations]: {},
        [EKeysOfAssessmentAdjustmentsSteps.RatingPeriods]: initRatingPeriod,
        [EKeysOfAssessmentAdjustmentsSteps.LevyCalculation]: {},
        [EKeysOfAssessmentAdjustmentsSteps.BalanceTransfer]: {},
      };
    }, [initialData]);

    //Use effect
    /**
     * get workflow data
     */
    const getWorkflowData = async () => {
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
      //Get workflow data
      const workflowDataResponse = await getWorkflowAssessmentAdjustment(
        {
          AssessmentId: assessmentId.toString(),
          SupplementaryId: supplementaryId.toString(),
        },
        workflowDraftId
      );
      const workflowData = workflowDataResponse?.data;
      if (isSuccessResponse(workflowDataResponse) && workflowData) {
        //Get LOVs
        const lovsResponse = await getAssessmentAdjustmentLOVs(
          workflowData.WorkflowDetail?.SupplementaryId,
          workflowData.WorkflowDetail?.AssessmentId
        );
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        const lovsData = lovsResponse?.data;
        if (isSuccessResponse(lovsResponse) && lovsData) {
          //Set current assessment ID, supp ID and supp assessment ID to the store for using in other steppers
          setAssessmentId(workflowData.WorkflowDetail?.AssessmentId);
          setSupplementaryRatesId(workflowData.WorkflowDetail?.SupplementaryId);
          setSupplementaryAssessmentId(
            workflowData.WorkflowDetail?.SupplementaryAssessmentId
          );
          //Set LOVs data
          setAssessmentAdjustmentLOVs(lovsData);
          //Set initial workflow data
          setInitialData({
            showAssessmentLeviesStepper: lovsData.AllowLevyChanges,
            showValuationsStepper: lovsData.AllowValuationChanges,
            showRatingPeriodsStepper: lovsData.CanRaiseCharges,
            showLevyCalculationStepper: lovsData.CanRaiseCharges,
            workflowData: {
              WorkflowDetail: workflowData.WorkflowDetail,
              WorkflowHeader: workflowData.WorkflowHeader,
            },
          });
          //Set workflow header and workflow draft ID
          if (workflowData?.WorkflowHeader) {
            setWorkflowHeader(workflowData.WorkflowHeader);
            setWorkflowDraftId(
              workflowData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id ??
                0
            );
          }
        } else {
          const responseError = lovsResponse as APIResponse;
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: {
              status: responseError.status,
              error: "Load workflow failed",
            },
          });
        }
      } else {
        const responseError = workflowDataResponse as APIResponse<
          DTO_Workflow_AssessmentAdjustment | ResponsePacket
        >;
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Load workflow failed",
          },
        });
      }
    };

    useEffectOnce(() => {
      getWorkflowData();
    });
    //#endregion <--------------------------------------------

    //#region COMMON FUNCTIONS -------------------------------------------->
    /**
     * Handle submit to control all buttons in dialog
     * @param events
     * @param buttonId
     */
    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Save:
          await sendSaveAssessmentAdjustments(processData(data), true);
          break;
        case EListSubmitButton.Finish:
          await handleFinishProcess(processData(data));
          break;
        case EListSubmitButton.Park:
          await handleParkProcess(processData(data));
          break;
        default:
          break;
      }
    };

    /**
     * Process data before sending to API
     * @param data
     * @param currentStep
     * @param modeSubmitting
     */
    const processData = (
      data: any,
      currentStep?: EKeysOfAssessmentAdjustmentsSteps,
      modeSubmitting?: WorkflowProcessMode
    ) => {
      let workflowDetail: any = {};
      const sendSteps = pickBy(data, function (value, key) {
        if (
          assessmentAdjustmentsKeysOfSendSteps.includes(
            key as EKeysOfAssessmentAdjustmentsSteps
          )
        ) {
          return { [key]: value };
        }
      });

      const {
        AssessmentId,
        SupplementaryId,
        SupplementaryAssessmentId,
        AllowLevyRecalculations,
      } = initialData?.workflowData?.WorkflowDetail ?? {};

      workflowDetail = {
        ...initialData?.workflowData?.WorkflowDetail,
        AssessmentId,
        SupplementaryId,
        SupplementaryAssessmentId,
        AllowLevyRecalculations,
      };

      if (sendSteps) {
        for (const [key, value] of Object.entries(sendSteps)) {
          workflowDetail[key as keyof DTO_Workflow_AssessmentAdjustment] = {
            ...value,
          };
        }
      }

      if (
        currentStep === EKeysOfAssessmentAdjustmentsSteps.RatingPeriods &&
        modeSubmitting === WorkflowProcessMode.Save
      ) {
        workflowDetail = {
          ...workflowDetail,
          AllowLevyRecalculations: true,
        };
      }

      delete workflowDetail.General.InstalmentPlanVisibility;

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    /**
     * common function
     * handle calling API with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_AssessmentAdjustment>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;
      const response = await postProcessAssessmentAdjustment(
        modeProcess,
        payload
      );

      setLoading();
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };
    //#endregion <--------------------------------------------

    //#region SAVE and NEXT -------------------------------------------->
    /**
     * Handle Next button
     * @param data
     * @param filterSteps
     * @param keyStep
     */
    const handleNextButton = async (
      data: any,
      _filterSteps: any,
      keyStep: string
    ) => {
      setIsLoadingOnNext(true);
      return await sendSaveAssessmentAdjustments(
        processData(
          data,
          keyStep as EKeysOfAssessmentAdjustmentsSteps,
          WorkflowProcessMode.Save
        )
      );
    };

    /**
     * Send save assessment adjustments process (call API with 'Save' mode)
     * @param payload
     * @param isCloseDialog
     */
    const sendSaveAssessmentAdjustments = async (
      payload: DTO_Workflow_AssessmentAdjustment,
      isCloseDialog: boolean = false
    ) => {
      const response = await postProcessAssessmentAdjustment(
        WorkflowProcessMode.Save,
        payload
      );
      setIsLoadingOnNext(false);
      const defaultSuccessMessage = `${assessmentLabel} adjustment was saved successfully.`;
      const defaultFailedMessage = `${assessmentLabel} adjustment could not be saved.`;
      if (isSuccessResponse(response)) {
        if (response?.data?.IsSuccess) {
          if (isCloseDialog) {
            onClose();
            pushNotification({
              title: response?.data?.SuccessMessage ?? defaultSuccessMessage,
              type: "success",
            });
          }
          if (isFirstSave) {
            setIsFirstSave(false);
            setWorkflowDraftId(response?.data?.ID ?? 0);
            setWorkflowHeader({
              ...workflowHeader,
              WorkflowDraft: {
                ...workflowHeader.WorkflowDraft,
                Workflow_Draft_Id: response?.data?.ID ?? 0,
              },
            });
          }
          return true;
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
          return false;
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
        return false;
      }
    };
    //#endregion <--------------------------------------------

    //#region PARK -------------------------------------------->
    /**
     * Handle park process
     * @param payload
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_AssessmentAdjustment
    ) => {
      //set loading button and dialog
      setIsLoadingPark(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_AssessmentAdjustment> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          if (!isFromActionList) {
            //Reload assessment status in Assessment accordion
            eventEmitter.emit(CCGridEventType.RefreshOData, {
              gridIds: [ASSESSMENT_ACCORDION_GRID_ID],
            });
          }
          pushNotification({
            title:
              e?.Notification ??
              `${assessmentLabel} adjustment was parked successfully.`,
            type: "success",
          });
        },
        defaultFailedMessage: `${assessmentLabel} adjustment could not be parked.`,
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingPark(false);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };
    //#endregion <--------------------------------------------

    //#region FINISH -------------------------------------------->
    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_AssessmentAdjustment
    ) => {
      setIsLoadingOnNext(true);

      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_AssessmentAdjustment> = {
        payload: payload,
        actionSuccess: (e) => {
          // Reload data
          eventEmitter.emit(CCGridEventType.RefreshOData);
          if (!isFromActionList) {
            eventEmitter.emit(CCGridEventType.RefreshOData, {
              gridIds: [ASSESSMENT_ACCORDION_GRID_ID],
            });
          }

          onClose();
          pushNotification({
            title:
              e?.Notification ??
              `${assessmentLabel} adjustment was completed successfully.`,
            type: "success",
          });
        },
        defaultFailedMessage: `${assessmentLabel} adjustment could not be completed.`,
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {
        setIsLoadingOnNext(false);
      };
      //calling API process workflow
      await handleProcessWorkflow(finishProps, setLoading);
    };
    //#endregion <--------------------------------------------

    const steps: IStep[] = [
      {
        label: "General",
        component: GeneralFormStep,
        initialValues: initialValues.General,
        visible: true,
        key: EKeysOfAssessmentAdjustmentsSteps.General,
        options: {
          isReadOnly,
          workflowDraftId,
        },
      },
      {
        label: assessmentLabel + " levies",
        component: AssessmentLeviesFormStep,
        initialValues: initialValues.Levies,
        visible: false,
        key: EKeysOfAssessmentAdjustmentsSteps.AssessmentLevies,
        options: {
          isReadOnly,
          workflowDraftId,
        },
      },
      {
        label: "Valuations",
        component: ValuationsFormStep,
        initialValues: initialValues.Valuations,
        visible: false,
        key: EKeysOfAssessmentAdjustmentsSteps.Valuations,
        options: {
          isReadOnly,
          workflowDraftId,
          isLLS,
        },
      },
      {
        label: "Rating periods",
        component: RatingPeriodsFormStep,
        initialValues: initialValues.RatingPeriod,
        visible: false,
        key: EKeysOfAssessmentAdjustmentsSteps.RatingPeriods,
        options: {
          isReadOnly,
          workflowDraftId,
        },
      },
      {
        label: "Levy calculation",
        component: LevyCalculationFormStep,
        initialValues: initialValues.LevyCalculation,
        visible: false,
        key: EKeysOfAssessmentAdjustmentsSteps.LevyCalculation,
      },
      {
        label: "Balance transfer",
        component: BalanceTransferFormStep,
        initialValues: initialValues.BalanceTransfer,
        visible: !(isActro || isLLS),
        key: EKeysOfAssessmentAdjustmentsSteps.BalanceTransfer,
        options: {
          isReadOnly,
          workflowDraftId,
        },
      },
    ];

    return (
      <>
        <CCFormStep
          ref={notificationFormStepRef}
          onSubmit={handleSubmit}
          listButtonId={listSubmitButton}
          initialValues={initialValues}
          initialSteps={steps}
          saveOnNextStep={
            isSaveOnNextStep && !isReadOnly ? handleNextButton : undefined
          }
          renderForm={(renderProps: ICCFormStepRender) => {
            return (
              <CCDialog
                maxWidth="60%"
                titleHeader={titleHeader}
                onClose={onClose}
                badge={statusBadge}
                bodyElement={renderProps.children}
                footerElement={
                  <div className={"cc-dialog-footer-actions-right"}>
                    {!isReadOnly && (
                      <Button
                        iconClass={
                          isLoadingPark ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        id={EListSubmitButton.Park}
                        onClick={renderProps.submitButton.onClick}
                        disabled={
                          renderProps.nextButton.disabled || isLoadingPark
                        }
                      >
                        Park
                      </Button>
                    )}
                    {!renderProps.prevButton.disabled && (
                      <Button
                        className={"cc-dialog-button"}
                        themeColor="primary"
                        onClick={renderProps.prevButton.onClick}
                      >
                        Previous
                      </Button>
                    )}
                    {isReadOnly ? (
                      !renderProps.isLastStep && (
                        <Button
                          themeColor="primary"
                          id="cc-next-step-button"
                          disabled={
                            isLoadingOnNext || renderProps.nextButton.disabled
                          }
                          className={"cc-dialog-button"}
                          iconClass={
                            isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                          }
                          onClick={renderProps.nextButton.onClick}
                        >
                          {isLoadingOnNext
                            ? "Saving"
                            : renderProps.nextButton.label}
                        </Button>
                      )
                    ) : (
                      <Button
                        themeColor="primary"
                        id={renderProps.nextButton.idButton}
                        disabled={
                          isLoadingOnNext || renderProps.nextButton.disabled
                        }
                        iconClass={
                          isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.nextButton.onClick}
                      >
                        {isLoadingOnNext && !renderProps.isLastStep
                          ? "Saving"
                          : renderProps.nextButton.label}
                      </Button>
                    )}
                  </div>
                }
              />
            );
          }}
        />
      </>
    );
  }
);
