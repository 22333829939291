import { apiCoreAsseticVersion } from "@app/core/components/common/utils";
import {
  CloseEventRequest,
  EventCloseSetup,
} from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/close-event/model";
import {
  EventReschedule,
  RescheduleMapObj,
} from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/details-reschedule/model";
import { EventBatchCloseRequest } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/event-bactch-close/model";
import {
  EventLOVs,
  IdentityPacket,
} from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import {
  Asset,
  Contact_PickContactFilters,
  Event,
  EventFormNavigate,
  EventHandlerRequest,
  EventHandlerResponse,
  EventIdentityPacket,
  EventWithAccessRight,
  PickCategoryFilter,
} from "@app/products/crms/[id]/model";
import { NewEventCloneParams } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { FormIdentifier } from "@app/products/crms/model";
import { ServiceStandardPickListPacket } from "@app/products/crms/service-standards/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { Address_BuildAddress } from "@common/input-pickers/address/model";
import { IIdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const getCRMSEventSummaryById = async (
  id: number,
  eventCloneParams?: NewEventCloneParams
): Promise<APIResponse<IIdentityPacket<EventWithAccessRight> | undefined>> => {
  if (eventCloneParams) {
    delete eventCloneParams.id;
  }
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<EventWithAccessRight>
    >(`/api/crms/internal/event/${id}`, {
      params: eventCloneParams ?? undefined,
    });
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postCRMSEvent = async (
  crmsEvent: Event
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "/api/crms/internal/event",
      crmsEvent
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postCRMSSimpleEvent = async (
  crmsEvent: Event
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "/api/crms/internal/event?isStandardEvent=false",
      crmsEvent
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCRMSEventLovs = async (
  cancelToken?: CancelToken
): Promise<APIResponse<EventLOVs | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<EventLOVs>(
      "/api/crms/internal/event-lovs",
      { cancelToken }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const sendForAction = async (
  crmsEvent: Event
): Promise<APIResponse<EventIdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<EventIdentityPacket>(
      "/api/crms/internal/event/send-for-action",
      crmsEvent
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const setupCloseEvent = async (
  crmsEvent: Event
): Promise<APIResponse<IIdentityPacket<EventCloseSetup> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<EventCloseSetup>
    >("/api/crms/internal/event/setup-close-event", crmsEvent);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const closeEvent = async (
  closeEventInfo: CloseEventRequest
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "/api/crms/internal/event/close-event",
      closeEventInfo
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const batchCloseEvent = async (
  closeEventInfo: EventBatchCloseRequest,
  formIdentifier: FormIdentifier
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `/api/crms/internal/event/batch-close?formIdentifier=${formIdentifier}`,
      closeEventInfo
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const eventHandler = async (
  params: EventHandlerRequest
): Promise<APIResponse<IIdentityPacket<EventHandlerResponse> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<EventHandlerResponse>
    >(`/api/crms/internal/event/form-event-handler`, params);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const setupPickEventAddress = async (
  crmsEvent: Event
): Promise<APIResponse<IIdentityPacket<Address_BuildAddress> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<Address_BuildAddress>
    >("api/crms/internal/event/setup-pick-event-address", crmsEvent);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const setupReschedule = async (
  crmsEvent: Event
): Promise<APIResponse<IIdentityPacket<EventReschedule> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<EventReschedule>
    >("/api/crms/internal/event/setup-reschedule", crmsEvent);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const rescheduleEvent = async (
  rescheduleEvent: RescheduleMapObj
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "/api/crms/internal/event/reschedule",
      rescheduleEvent
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getEventFormNavigation = async (): Promise<
  APIResponse<IIdentityPacket<EventFormNavigate> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<EventFormNavigate>
    >("/api/crms/internal/event/form-navigation");
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCRMSNewSimpleEvent = async (
  cancelToken: CancelToken
): Promise<APIResponse<IIdentityPacket<Event> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IIdentityPacket<Event>>(
      "/api/crms/internal/event/new-simple-event",
      { cancelToken }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const simpleEventHandler = async (
  params: EventHandlerRequest,
  cancelToken?: CancelToken
): Promise<APIResponse<IIdentityPacket<EventHandlerResponse> | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<EventHandlerResponse>
    >(`/api/crms/internal/event/simple-event-handler`, params, { cancelToken });
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postContactManagerFilter = async (
  Event: Event
): Promise<APIResponse<Contact_PickContactFilters | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<Contact_PickContactFilters>(
      "/api/crms/internal/event/action-officer-contact-filter",
      Event
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const saveAsPending = async (
  event: Event
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "/api/crms/internal/event/save-as-pending",
      event
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const reopenEvent = async (
  event: Event
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/crms/internal/event/reopen-event",
      event
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postSetupRecategoriseFilter = async (
  pickCategoryFilter: PickCategoryFilter
): Promise<
  APIResponse<IIdentityPacket<ServiceStandardPickListPacket> | undefined>
> => {
  try {
    return await CoreAPIService.getClient().post<
      IIdentityPacket<ServiceStandardPickListPacket>
    >("api/crms/internal/event/setup-recategorise-filter", pickCategoryFilter);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCountLinkedSvcStandard = async (
  articleId: number | null
): Promise<APIResponse<IIdentityPacket<number>>> => {
  try {
    return await CoreAPIService.getClient().get<IIdentityPacket<number>>(
      `api/crms/internal/event/count-linked-svc-standard/${articleId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getServiceStandardKB = async (
  articleId: number | null
): Promise<APIResponse<any>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      `odata/crms/internal/servicestandard/GetPickServiceStandardList(articleID=${articleId})?$count=true&`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getAsseticVersion = async (
  siteCode: string
): Promise<APIResponse<Asset | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      apiCoreAsseticVersion(siteCode)
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSetupAddDocument = async (
  servicestandardID: number
): Promise<APIResponse<IIdentityPacket<number[]> | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<IIdentityPacket<number[]>>(
      `api/crms/internal/event/setup-add-document/${servicestandardID}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
