import { getAsseticVersion } from "@app/products/crms/[id]/api";
import { AssetPickerSearchInput } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/asset-picker-search/components/asset-picker-search-input/_index";
import { AssetDetailsDialog } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/dialogs/asset-details-dialog/_index";
import { Asset } from "@app/products/crms/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxProps } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { isArray, isNil } from "lodash";
import React, { MouseEventHandler, useMemo, useState } from "react";

export interface IAddressPickerSearchProps extends ComboBoxProps {
  visited?: boolean;
  initialData: any;
  textField?: string;
  disabledButton?: boolean;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  formRenderProps: FormRenderProps;
  name: string;
  onError?: (value: any) => void;
  onChangeEventHandler?: (asset: Asset | null) => void;
  initialDataSubmit?: any;
  isInitiallyLoading?: boolean;
}

export const AssetPickerSearch = (props: IAddressPickerSearchProps) => {
  const {
    name,
    initialData,
    value,
    className,
    textField,
    onButtonClick,
    formRenderProps,
    onError,
    onChangeEventHandler,
    validationMessage,
    initialDataSubmit,
    visited,
    isInitiallyLoading,
    ...others
  } = props;

  const { onChange: onChangeForm } = formRenderProps;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDialog, setIsShowDialog] = useState(false);

  const inputDisplayName = useMemo(() => {
    if (isNil(value)) return "";
    if (textField && !isArray(value) && textField in value)
      return value[textField];
    return value ?? "";
  }, [value, textField]);

  const handleOnChange = async (data: any) => {
    let newData = undefined;
    if (data && data.SiteCode) {
      setIsLoading(true);
      const response = await getAsseticVersion(data.SiteCode);
      setIsLoading(false);
      if (isSuccessResponse(response) && response?.data) {
        newData = { ...data, ...response.data };
      }
    }
    onChangeForm(name, {
      value: newData ?? data,
    });
    if (onChangeEventHandler) onChangeEventHandler(newData ?? data);
  };

  const handleSubmit = (values: any) => {
    onChangeForm(name, { value: values });

    setIsShowDialog(false);
    if (onChangeEventHandler) onChangeEventHandler(values ?? null);
  };

  return (
    <>
      <div
        className={`${className ?? ""} cc-input-picker-new ${
          !others.valid ? "cc-input-picker-invalid" : ""
        }`}
      >
        <Field
          {...props}
          name="Search"
          component={AssetPickerSearchInput}
          placeholder="Search"
          formRenderProps={formRenderProps}
          onChange={handleOnChange}
          inputValueDisplayName={inputDisplayName}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
        <Button
          disabled={
            !isNil(props.disabledButton) ? props.disabledButton : isLoading
          }
          className="cc-input-picker-button"
          iconClass="fa fa-ellipsis-h"
          title="Redirect to asset details dialog"
          onClick={(event) => {
            event.preventDefault();
            setIsShowDialog(true);
            if (onButtonClick) return onButtonClick(event);
          }}
        />
      </div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}
      {isShowDialog && (
        <AssetDetailsDialog
          onClose={() => setIsShowDialog(false)}
          onSubmit={handleSubmit}
          initialData={initialData}
          initialDataSubmit={initialDataSubmit}
          formRenderProps={formRenderProps}
          isInitiallyLoading={isInitiallyLoading}
        />
      )}
    </>
  );
};
