import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { MailMergeButtonManagePage } from "@app/core/new-mail-merge/buttons/mail-merge-manage-page/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { DebtRecoveryAssessments } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/assessments/_index";
import { DebtRecoveryDeferredDuty } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/deferred-duty/_index";
import { CreateActionButton } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/button/create-action/_index";
import { EditActionButton } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/button/edit-action/_index";
import { IssueActionButton } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/button/issue-action/_index";
import { DebtRecoveryFunctionalityRestrictionsButton } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/button/modify-functionality-restrictions/_index";
import { ViewActionButton } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/components/button/view-action/_index";
import { DebtRecoveryForm } from "@app/products/property/assessments/debt-recovery/[id]/components/child-screens/general/_index";
import { AssessmentDebtRecoveryAssociationTab } from "@app/products/property/assessments/debt-recovery/[id]/components/reference-sidebar/associations/_index";
import { AssessmentDebtRecoveryDetailTab } from "@app/products/property/assessments/debt-recovery/[id]/components/reference-sidebar/detail/_index";
import { useDebtRecoveryStore } from "@app/products/property/assessments/debt-recovery/[id]/store";
import { eDocumentTypeCategory } from "@app/products/property/components/action-bar/button/add-attachment/model";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { ModifyDebtRecoveryButton } from "@app/products/property/components/action-bar/button/modify-debt-recovery/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Label } from "@common/stores/products/config";
import { ECustomColNameProperty } from "@app/products/property/config";

const ExistedDebtRecovery = observer(() => {
  const { debtRecovery, isLoading, responseLoadError, loadDebtRecovery } =
    useDebtRecoveryStore();

  const [showSlideBar, setShowSlideBar] = useState(true);

  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const getBookmarkDetail = useMemo(() => {
    return getDisplayTextWithDashes([debtRecovery?.RatePayer ?? ""]);
  }, [debtRecovery]);
  const { currentUserInfo } = useGlobalStore();

  //Get label
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: getBookmarkDetail,
      LinkUrl: managePageUrl,
      LinkText: `Property - ${assessmentLabel} - ${id}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: +id,
    },
  ];

  const getFormTitle = useMemo(() => {
    return `ID: ${debtRecovery?.DebtRecoveryId} ;Ratepayers: ${
      debtRecovery?.RatePayer
        ? debtRecovery?.RatePayer.replace(/\r\n/g, ", ")
        : ""
    }`;
  }, [debtRecovery]);
  const listPanelBar: IPropertyPanelBar[] = [
    {
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Debt_Recovery}
          componentNumber={eComponent.Debt_Recovery}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    {
      title: "Assessments",
      component: <DebtRecoveryAssessments />,
    },
    {
      title: "Related",
      component: (
        <PropertyAssociations
          id={parseInt(id)}
          componentNumber={eComponent.Debt_Recovery}
        />
      ),
    },
    // Requirement: https://dev.azure.com/openofficeau/Radium/_workitems/edit/12802/
    // {
    //   title: "Audit",
    //   component: (
    //     <PropertyAudit
    //       id={parseInt(id)}
    //       componentNumber={eComponent.Debt_Recovery}
    //     />
    //   ),
    // },
    {
      title: "Deferred Duty",
      component: <DebtRecoveryDeferredDuty />,
    },
    {
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Debt_Recovery}
        />
      ),
    },
    {
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.Debt_Recovery}
        />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} />
      <FormNavigation title="DEBT RECOVERY" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadDebtRecovery(+id)}
        />
      ) : (
        <>
          <FormTitle title={getFormTitle} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <ModifyDebtRecoveryButton />
                <DebtRecoveryFunctionalityRestrictionsButton
                  debtRecoveryId={parseInt(id)}
                />
                <CCNavButton title="Documents" type="dropdown">
                  <CreateActionButton />
                  <EditActionButton />
                  <IssueActionButton />
                  <ViewActionButton />
                </CCNavButton>
              </CCNavButton>,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Debt_Recovery}
                />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Debt_Recovery}
                  documentCategoryTypeId={
                    eDocumentTypeCategory.Debt_Recovery_Document
                  }
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Debt_Recovery}
                />
                {/* TODO: will be shown when it has a function */}
                {/* <CCNavButton title={"Contact"} /> */}
                <MailMergeButtonManagePage
                  productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
                  dataSetFilter={
                    MAILMERGEDATASET.COMMUNITYPROPERTY_Debt_Recovery
                  }
                  recordType={RECORDTYPE.CommunityProperty_Debt_Recovery}
                  isEmptyGridSelectedRow={!parseInt(id)}
                  selectedRecordIds={[parseInt(id)]}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {debtRecovery && (
                  <CCGeneralPanel component={<DebtRecoveryForm />} />
                )}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.Debt_Recovery}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <AssessmentDebtRecoveryDetailTab />,
                    },
                    {
                      title: "Contacts",
                      component: (
                        <PropertyContactTab
                          id={parseInt(id)}
                          componentNumber={eComponent.Debt_Recovery}
                        />
                      ),
                    },
                    {
                      title: "Related",
                      component: <AssessmentDebtRecoveryAssociationTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={
                            RECORDTYPE.CommunityProperty_Debt_Recovery
                          }
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});

export default ExistedDebtRecovery;
