import {
  PoundRegisterSubmitActions,
  PoundRegister_Status,
} from "@app/products/animals/pound-register/[id]/model";
import { useAnimalPoundRegisterStore } from "@app/products/animals/pound-register/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const PoundLodgeRegistrationButton = observer(() => {
  const { poundRegisterInfo, isLoadingWorkflow, onSubmit } =
    useAnimalPoundRegisterStore();

  const isVisible = useMemo(() => {
    return (
      poundRegisterInfo?.Status_ENUM === PoundRegister_Status.NewRegistration ||
      poundRegisterInfo?.Status_ENUM === PoundRegister_Status.WithRanger
    );
  }, [poundRegisterInfo]);

  return isVisible ? (
    <CCNavButton
      title={"Lodge into pound"}
      name={PoundRegisterSubmitActions.LodgeRegistration}
      onClick={onSubmit}
      isLoading={isLoadingWorkflow}
      disabled={isLoadingWorkflow}
    />
  ) : null;
});
