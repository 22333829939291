import { optionInputPickerAddress } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/components/form-element/config";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { WastewaterSearchLocality } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/locality/_index";
import { LocalityResult } from "@app/products/waste-water/[id]/components/inputs/address-picker/components/property-detail-dialog/componets/search/locality/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { getAddressLovs } from "@common/input-pickers/address/api";
import {
  Address,
  AddressClassification,
  AddressLovs,
  AddressValidationType,
  Address_BuildAddress,
} from "@common/input-pickers/address/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { getFullAddressHTML } from "@common/utils/formatting";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { ICCInputPickerProps } from "@components/cc-input-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<Address>();
interface IAddressPickerProps {
  buildAddress?: Address_BuildAddress;
  nearestCrossStreet?: boolean;
  onChangeDataSearch?: (data: any) => void;
}

export const AddressPicker = ({
  buildAddress,
  nearestCrossStreet,
  value,
  onChangeDataSearch,
  ...other
}: IAddressPickerProps & ICCInputPickerProps) => {
  const addressElementProps = { nearestCrossStreet, buildAddress };

  return (
    <InputPickerSearch
      {...other}
      value={value}
      nameDisplay={getFullAddressHTML}
      onChange={onChangeDataSearch}
      options={optionInputPickerAddress}
      customDialog={(value, onClose, handleSubmitCustomDialog) => {
        const handleSubmit = (values: any) => {
          handleSubmitCustomDialog(values);
        };
        return (
          <Form
            initialValues={value}
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <AddressFormElement
                formRenderProps={formRenderProps}
                onClose={onClose}
                {...addressElementProps}
              />
            )}
          />
        );
      }}
    />
  );
};

const AddressFormElement = observer(
  ({
    formRenderProps,
    onClose,
    nearestCrossStreet,
    buildAddress,
  }: {
    formRenderProps: FormRenderProps;
    onClose: () => void;
  } & IAddressPickerProps) => {
    const { onSubmit, modified, valid, valueGetter, onChange } =
      formRenderProps;
    const { settings } = useCommonCoreStore();

    const [isLoading, setIsLoading] = useState(false);
    const [responseLoadError, setResponseLoadError] = useState<
      APIResponseError | undefined
    >();
    const [addressLovs, setAddressLovs] = useState<AddressLovs | undefined>();
    //Settings
    const isShowDescription = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CorporateSettings_PropertyDialog_DescriptionInAddressDialog
      ]
    );
    const addressValidationType: AddressValidationType =
      (getNumberValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_AddressValidation]
      ) as AddressValidationType) ?? AddressValidationType.None;

    const showNearestCrossStreet = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CorporateSettings_AddressDialog_NearestCrossStreet
      ]
    );

    const isStreetTypeEnabled = getBoolValueSetting(
      settings[ECorporateSettingsField.Global_Contact_SplitAddressName]
    );

    //Value getter
    const type: AddressClassification =
      valueGetter(nameOf("AddressClassification_ENUM")) ??
      AddressClassification.Generic;

    const handleOnChangeStreetName = (event: InputChangeEvent) => {
      if (
        type === AddressClassification.Premises ||
        type === AddressClassification.Generic ||
        type === AddressClassification.Property
      ) {
        if (isStreetTypeEnabled) {
          onChange("StreetNamePart_Name", {
            value: event.value?.trim(),
          });
          onChange("StreetName", {
            value:
              event.value?.trim() +
              " " +
              valueGetter(nameOf("StreetNamePart_Type"))?.toString().trim(),
          });
        } else {
          onChange("StreetName", {
            value: event.value?.trim(),
          });
        }
      }
      onChange("_Options.StreetName", {
        value: event.value,
      });
    };

    const handleLoadLOVs = () => {
      setIsLoading(true);
      getAddressLovs().then((response) => {
        setIsLoading(false);
        let newAddressLovs = undefined;
        let errorResponse = undefined;
        if (isSuccessResponse(response)) {
          newAddressLovs = response.data;
        } else {
          errorResponse = {
            status: response.status,
            error: response.error,
          };
        }
        if (newAddressLovs && buildAddress?.Filters?.ClassificationFilters) {
          //Filter classification
          newAddressLovs.Classifications =
            newAddressLovs?.Classifications.filter((type) =>
              buildAddress.Filters.ClassificationFilters.includes(type.Key)
            );
        }

        setAddressLovs(newAddressLovs);
        setResponseLoadError(errorResponse);
      });
    };

    useEffectOnce(() => {
      handleLoadLOVs();
    });

    const isShowAddressLookahead = useMemo(
      () =>
        addressValidationType === AddressValidationType.DataTools &&
        (type === AddressClassification.Generic ||
          type === AddressClassification.Property),
      [addressValidationType, type]
    );

    const propertyNameLabel = useMemo(
      () =>
        type === AddressClassification.Consultant_Address
          ? "Company Name"
          : "Property Name",
      [type]
    );

    const isShowInternational = useMemo(
      () =>
        type === AddressClassification.International ||
        type === AddressClassification.Consultant_Address,
      [type]
    );
    const isShowPoBox = useMemo(
      () => type === AddressClassification.POBox,
      [type]
    );
    const isShowPremises = useMemo(
      () => type === AddressClassification.Premises,
      [type]
    );
    const isShowDescriptive = useMemo(
      () => type === AddressClassification.DescriptiveAddress,
      [type]
    );
    const isShowGeneric = useMemo(
      () =>
        !(
          isShowInternational ||
          isShowPoBox ||
          isShowPremises ||
          isShowDescriptive
        ),
      [isShowInternational, isShowPoBox, isShowPremises, isShowDescriptive]
    );
    const isShowGenericNearestCrossStreet = useMemo(
      () => showNearestCrossStreet && nearestCrossStreet,
      [nearestCrossStreet, showNearestCrossStreet]
    );

    useEffectOnce(() => {
      if (
        !isStreetTypeEnabled &&
        !isNil(valueGetter("Flag_ForceUse_StreetType"))
      ) {
        onChange("Flag_StreetName_HasParts", {
          value: valueGetter("Address.Flag_ForceUse_StreetType"),
        });
      } else {
        onChange("Flag_StreetName_HasParts", {
          value: isStreetTypeEnabled,
        });
      }
    });

    const handleOnLocalityChange = (locality: LocalityResult | null) => {
      onChange(nameOf("Suburb"), {
        value: locality?.Locality,
      });
      onChange(nameOf("State"), {
        value: locality?.State,
      });
      onChange(nameOf("Postcode"), {
        value: locality?.PostCode,
      });
    };

    return (
      <FormElement>
        <CCDialog
          maxWidth="40%"
          maxHeight={"90%"}
          titleHeader={"Address"}
          onClose={onClose}
          bodyElement={
            isLoading ? (
              <Loading isLoading={isLoading} />
            ) : responseLoadError ? (
              <CCLoadFailed
                responseError={responseLoadError}
                onReload={() => {
                  handleLoadLOVs();
                }}
              />
            ) : (
              <div className="cc-form">
                <section className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label">
                        Address type <CCTooltip type="validator" />
                      </label>
                      <Field
                        name={nameOf("AddressClassification_ENUM")}
                        component={CCSearchComboBox}
                        data={addressLovs?.Classifications ?? []}
                        textField="Value"
                        dataItemKey="Key"
                        isUseDefaultOnchange
                        validator={requiredValidator}
                      />
                    </div>
                    {isShowDescription && (
                      <div className="cc-field">
                        <label className="cc-label">Description</label>
                        <Field
                          name={nameOf("ContactAddressType_KWD")}
                          component={CCSearchComboBox}
                          data={addressLovs?.ContactAddressTypes ?? []}
                          textField="Value"
                          dataItemKey="Key"
                          isUseDefaultOnchange
                        />
                      </div>
                    )}
                    {isShowAddressLookahead && (
                      <div className="cc-field">
                        <label className="cc-label">Enter an address</label>
                        <Field
                          name={"nullName"}
                          component={CCComboBox}
                          placeholder="Search address"
                        />
                      </div>
                    )}
                  </div>
                </section>
                <hr className="cc-divider" />
                <section className="cc-field-group">
                  {isShowInternational && (
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Care of</label>
                        <Field
                          name={nameOf("Location_Description")}
                          placeholder={"Care Of"}
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">{propertyNameLabel}</label>
                        <Field
                          name={nameOf("PropertyName")}
                          placeholder={propertyNameLabel}
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Address line 1</label>
                        <Field
                          name={nameOf("AddressLine1")}
                          placeholder={"Address Line 1"}
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Address line 2</label>
                        <Field
                          name={nameOf("AddressLine2")}
                          placeholder={"Address Line 2"}
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Town / City</label>
                        <Field
                          name={nameOf("Suburb")}
                          placeholder={"Town / City"}
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">State</label>
                          <Field
                            name={nameOf("State")}
                            component={CCDropDownList} // TODO: Need to confirm before change to CCSearchComboBox
                            data={addressLovs?.States}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Postcode / Zip</label>
                          <Field
                            name={nameOf("Postcode")}
                            placeholder={"Postcode / Zip"}
                            component={CCInput}
                          />
                        </div>
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Country</label>
                        <Field
                          name={nameOf("Country_KWD")}
                          placeholder={"Country"}
                          component={CCSearchComboBox}
                          data={addressLovs?.Countries ?? []}
                          textField="Value"
                          dataItemKey="Key"
                          isUseDefaultOnchange
                        />
                      </div>
                    </div>
                  )}
                  {isShowGeneric && (
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Location description</label>
                        <Field
                          name={nameOf("Location_Description")}
                          component={CCComboBox}
                          data={addressLovs?.LocationDescriptions?.map(
                            (obj) => obj.Value
                          )}
                          suggest
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Property name</label>
                        <Field
                          name={nameOf("PropertyName")}
                          placeholder={"Property Name"}
                          component={CCInput}
                        />
                      </div>
                      {isShowGenericNearestCrossStreet && (
                        <div className="cc-field">
                          <label className="cc-label">
                            Nearest cross street
                          </label>
                          <Field
                            name={nameOf("NearestCrossStreet")}
                            placeholder={"Nearest Cross Street"}
                            component={CCInput}
                          />
                        </div>
                      )}
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">Unit</label>
                          <Field
                            name={nameOf("UnitNo")}
                            placeholder={"Unit"}
                            component={CCInput}
                          />
                        </div>

                        <div className="cc-field">
                          <label className="cc-label">Street number</label>
                          <Field
                            name={nameOf("StreetNo")}
                            placeholder={"Street Number"}
                            component={CCInput}
                          />
                        </div>
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Street name</label>
                        <Field
                          name={nameOf("StreetName")}
                          placeholder={"Street Name"}
                          component={CCInput}
                          onChange={handleOnChangeStreetName}
                        />
                      </div>
                      {isStreetTypeEnabled && (
                        <div className="cc-field">
                          <label className="cc-label">Street type</label>
                          <Field
                            name={nameOf("StreetNamePart_Type")}
                            component={CCSearchComboBox}
                            data={addressLovs?.StreetTypes ?? []}
                            textField="Value"
                            dataItemKey="Key"
                            value={getDropdownValue(
                              valueGetter(nameOf("StreetNamePart_Type")),
                              addressLovs?.StreetTypes ?? [],
                              "Key"
                            )}
                            onChange={(event: ComboBoxChangeEvent) => {
                              onChange(nameOf("StreetNamePart_Type"), {
                                value: event.target.value?.Key ?? null,
                              });
                            }}
                          />
                        </div>
                      )}

                      <div className="cc-field">
                        <label className="cc-label">Locality</label>
                        <Field
                          name={nameOf("Suburb")}
                          component={WastewaterSearchLocality}
                          placeholder={"Locality"}
                          onChangeLocality={handleOnLocalityChange}
                        />
                      </div>
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">State</label>
                          <Field
                            name={nameOf("State")}
                            component={CCDropDownList}
                            data={addressLovs?.States}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Postcode</label>
                          <Field
                            name={nameOf("Postcode")}
                            placeholder={"Postcode"}
                            component={CCInput}
                          />
                        </div>
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Assessment number</label>
                        <Field
                          name={nameOf("AssessmentNo")}
                          placeholder={"Assessment Number"}
                          component={CCInput}
                        />
                      </div>
                    </div>
                  )}
                  {isShowPoBox && (
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Attention</label>
                        <Field
                          name={nameOf("Location_Description")}
                          placeholder={"Attention"}
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Delivery type</label>
                        <Field
                          name={nameOf("AddressBoxType_KWD")}
                          component={CCSearchComboBox}
                          data={addressLovs?.AddressBoxTypes ?? []}
                          textField="Value"
                          dataItemKey="Key"
                          isUseDefaultOnchange
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Number</label>
                        <Field
                          name={nameOf("PoBoxNo")}
                          placeholder={"Number"}
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">Locality</label>
                        <Field
                          name={nameOf("Suburb")}
                          component={WastewaterSearchLocality}
                          placeholder={"Locality"}
                          onChangeLocality={handleOnLocalityChange}
                        />
                      </div>
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">State</label>
                          <Field
                            name={nameOf("State")}
                            component={CCDropDownList}
                            data={addressLovs?.States}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Postcode</label>
                          <Field
                            name={nameOf("Postcode")}
                            placeholder={"Postcode"}
                            component={CCInput}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {isShowPremises && (
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Property name</label>
                        <Field
                          name={nameOf("PropertyName")}
                          placeholder={"Property Name"}
                          component={CCInput}
                        />
                      </div>
                      <div className="cc-form-cols-2">
                        <div className="cc-field">
                          <label className="cc-label">Unit</label>
                          <Field
                            name={nameOf("UnitNo")}
                            placeholder={"Unit"}
                            component={CCInput}
                          />
                        </div>

                        <div className="cc-field">
                          <label className="cc-label">Street number</label>
                          <Field
                            name={nameOf("StreetNo")}
                            placeholder={"Street Number"}
                            component={CCInput}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Street name</label>
                          <Field
                            name={nameOf("StreetName")}
                            placeholder={"Street Name"}
                            component={CCInput}
                            onChange={handleOnChangeStreetName}
                          />
                        </div>
                        {isStreetTypeEnabled && (
                          <div className="cc-field">
                            <label className="cc-label">Street type</label>
                            <Field
                              name={nameOf("StreetNamePart_Type")}
                              component={CCSearchComboBox}
                              data={addressLovs?.StreetTypes ?? []}
                              textField="Value"
                              dataItemKey="Key"
                              value={getDropdownValue(
                                valueGetter(nameOf("StreetNamePart_Type")),
                                addressLovs?.StreetTypes ?? [],
                                "Key"
                              )}
                              onChange={(event: ComboBoxChangeEvent) => {
                                onChange(nameOf("StreetNamePart_Type"), {
                                  value: event.target.value?.Key ?? null,
                                });
                              }}
                            />
                          </div>
                        )}

                        <div className="cc-field">
                          <label className="cc-label">Locality</label>
                          <Field
                            name={nameOf("Suburb")}
                            component={WastewaterSearchLocality}
                            placeholder={"Locality"}
                            onChangeLocality={handleOnLocalityChange}
                          />
                        </div>
                        <div className="cc-form-cols-2">
                          <div className="cc-field">
                            <label className="cc-label">State</label>
                            <Field
                              name={nameOf("State")}
                              component={CCDropDownList}
                              data={addressLovs?.States}
                            />
                          </div>
                          <div className="cc-field">
                            <label className="cc-label">Postcode</label>
                            <Field
                              name={nameOf("Postcode")}
                              placeholder={"Postcode"}
                              component={CCInput}
                            />
                          </div>
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Area</label>
                          <Field
                            name={nameOf("AreaCode")}
                            placeholder={"Area"}
                            component={CCInput}
                          />
                        </div>
                        <div className="cc-field">
                          <label className="cc-label">Assessment number</label>
                          <Field
                            name={nameOf("AssessmentNo")}
                            placeholder={"Assessment Number"}
                            component={CCInput}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {isShowDescriptive && (
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">Address</label>
                        <Field
                          name={nameOf("Location_Description")}
                          placeholder={"Address"}
                          component={CCInput}
                        />
                      </div>
                    </div>
                  )}
                </section>
                <br />
              </div>
            )
          }
          footerElement={
            <div className="cc-dialog-footer-actions-right">
              <Button className="cc-dialog-button" onClick={onClose}>
                Cancel
              </Button>
              <Button
                themeColor="primary"
                disabled={!modified || !valid || isLoading}
                onClick={onSubmit}
                className="cc-dialog-button"
              >
                OK
              </Button>
            </div>
          }
        />
      </FormElement>
    );
  }
);
