import { eventEmitter } from "@/App";
import { saveDocumentAsWebLink } from "@app/core/documents/api";
import {
  Attachment,
  eDocumentTypeCategory,
  IWebLink,
  Svc_Attachment,
} from "@app/products/property/components/action-bar/button/add-attachment/model";
import { AddAttachmentPropertyDialog } from "@app/products/property/components/action-bar/dialog/add-attachment/_index";
import { saveAttachmentProperty } from "@app/products/property/components/action-bar/dialog/add-attachment/api";
import {
  eUploadMode,
  IAddAttachmentDialog,
} from "@app/products/property/components/action-bar/dialog/add-attachment/model";
import { convertToFileStreamEntry } from "@app/products/property/components/action-bar/dialog/add-attachment/util";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import { EPrivilegesWorkflow } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import React, { useState } from "react";

interface IAddAttachmentButtonProps {
  id?: number;
  recordType: RECORDTYPE;
  documentCategoryTypeId: eDocumentTypeCategory;
  isVisible?: boolean;
  isDisabled?: boolean;
}
export const AddAttachmentPropertyButton = ({
  id,
  recordType,
  documentCategoryTypeId,
  isVisible = true,
  isDisabled = false,
}: IAddAttachmentButtonProps) => {
  const [isShowAttachmentDialog, setIsShowAttachmentDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();

  const handleOnSubmit = async (data: IAddAttachmentDialog) => {
    if (id) {
      setIsLoading(true);
      switch (data?.UploadMode) {
        case eUploadMode.UPLOAD_FILE:
          if (!isNil(data?.File)) {
            Promise.all(
              data?.File?.filter((item) => isNil(item.validationErrors))?.map(
                async (item) => {
                  return {
                    FileEntry: await convertToFileStreamEntry(item),
                    Title: data.Title ?? item?.name,
                    Attachment_ID: 0,
                  } as Attachment;
                }
              )
            ).then(async (attachments) => {
              const requestBody = {
                ComponentID: id,
                RecordType: recordType,
                AttachmentList: attachments,
                DocumentType: data?.Category?.Code
                  ? +data?.Category?.Code
                  : null,
              } as Svc_Attachment;
              const response = await saveAttachmentProperty(requestBody);
              setIsLoading(false);
              if (isSuccessResponse(response)) {
                clearErrorNotification();
                pushNotification({
                  title: "Attachment created successfully",
                  type: "success",
                });
                //Refresh document accordion after updated successfully
                eventEmitter.emit(PropertyDocumentEventType.RefreshData);
              } else {
                if (
                  response?.status === APIResponseStatus.EXCEEDED_SIZE_LIMIT
                ) {
                  pushNotification({
                    autoClose: false,
                    title: "Exceeded the specified size limit",
                    type: "error",
                  });
                } else {
                  pushNotification({
                    autoClose: false,
                    title:
                      response.data?.ErrorStatus ?? "Create attachment failed",
                    description: response.data?.Errors ?? response.statusText,
                    type: "error",
                  });
                }
              }
              setIsShowAttachmentDialog(false);
            });
          }
          break;
        case eUploadMode.UPLOAD_WEB_LINK:
          const requestBody = {
            WebLinkURI: data.WebLink,
            Tag: data?.Tag ?? "",
            WeblinkCategory_KWD: data?.WebLinkCategory?.Keyword_ID ?? null,
          } as IWebLink;
          const response = await saveDocumentAsWebLink(
            id,
            recordType,
            requestBody
          );
          setIsLoading(false);
          if (isSuccessResponse(response)) {
            if (
              response.data?.ErrorStatus === IdentityPacketErrorStatus.Success
            ) {
              clearErrorNotification();
              pushNotification({
                title: "Attachment created successfully",
                type: "success",
              });
              //Refresh document accordion after success
              eventEmitter.emit(PropertyDocumentEventType.RefreshData);
            } else {
              pushNotification({
                autoClose: false,
                title: "Attachment could not be saved",
                description: response.data?.Errors,
                type: "error",
              });
            }
          } else {
            pushNotification({
              autoClose: false,
              title: response.statusText,
              type: "error",
            });
          }
          setIsShowAttachmentDialog(false);
          break;
      }
    }
  };

  if (!isVisible) return null;
  return (
    <>
      <PrivilegeWrapper
        dialogType={ePermissionPrivilegeTypeCheck.Dialog}
        privilegesEnum={EPrivilegesWorkflow.Attachment_Add}
      >
        <CCNavButton
          title={"Attachment"}
          onClick={() => setIsShowAttachmentDialog(true)}
          disabled={isDisabled}
        />
      </PrivilegeWrapper>
      {isShowAttachmentDialog && (
        <AddAttachmentPropertyDialog
          onClose={() => setIsShowAttachmentDialog(false)}
          onSubmit={handleOnSubmit}
          isLoadingButton={isLoading}
          documentCategoryTypeId={documentCategoryTypeId}
        />
      )}
    </>
  );
};
