import { nameOfLov } from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";
import { getDropdownValue } from "@common/utils/common";
import { ICustomEditCell } from "@components/cc-grid/model";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import React from "react";

interface IRoleCellProps extends ICustomEditCell {
  dropdownList: DTO_LOV[];
}

export const RoleCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
  dropdownList,
}: IRoleCellProps) => {
  return (
    <DropDownList
      textField={nameOfLov("Name")}
      dataItemKey={nameOfLov("Code")}
      data={dropdownList}
      value={getDropdownValue(value, dropdownList, nameOfLov("Name"))}
      onChange={(event) => {
        if (onChange) {
          onChange({
            field: field,
            dataIndex: dataIndex || 0,
            dataItem,
            syntheticEvent: event.syntheticEvent,
            value: event.value?.Name,
          });
        }
      }}
    />
  );
};
