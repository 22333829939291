import { colSupplementary } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/config";
import {
  GET_EXISTING_SUPPLEMENTARY_RATES_LIST,
  GET_EXISTING_SUPPLEMENTARY_RATES_LIST_CREATED_BY_ME,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/constant";
import {
  SupplementaryMode,
  VO_Supplementary,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/which-supplementary/model";
import { dataWhichSupplementary } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/config";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import {
  RadioGroup,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { observer } from "mobx-react-lite";
import React from "react";

const nameOfVOSupplementary = nameOfFactory<VO_Supplementary>();

const formValidator = (values: any) => {
  if (
    checkSelectExistSupp(values?.SupplementaryMode) &&
    !values?._option?.SupplementarySelected?.length
  ) {
    return "Must select one supplementary.";
  }
  return;
};

const checkSelectExistSupp = (suppMode?: SupplementaryMode) => {
  if (!suppMode) return false;
  return (
    suppMode === SupplementaryMode.SelectFromList ||
    suppMode === SupplementaryMode.SelectFromMyList
  );
};

export const SupplementaryFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? formValidator : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    setStepsVisible,
    resetAllStepsAfter,
    formRenderProps,
    nameOf,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const isShowExistingSupp = checkSelectExistSupp(
      getFieldValue("SupplementaryMode")
    );

    const handleSupplementaryModeChange = (e: RadioGroupChangeEvent) => {
      let newSteps = resetAllStepsAfter();
      onChange(nameOf("SupplementaryMode"), {
        value: e.value,
      });
      onChange(nameOf("_option.SupplementarySelected"), {
        value: null,
      });
      onChange(nameOf("Supplementary_Id"), {
        value: null,
      });

      if (setStepsVisible) {
        setStepsVisible(
          [
            {
              key: options?.supplementaryStepKey,
              visible: e.value === SupplementaryMode.CreateNew,
              isClearData: true,
            },
          ],
          newSteps
        );
      }
    };

    return (
      <section className="cc-field-group">
        <div className="cc-form-cols-1">
          <>
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">Supplementary</label>
                <Field
                  name={nameOf("SupplementaryMode")}
                  data={dataWhichSupplementary}
                  component={RadioGroup}
                  onChange={handleSupplementaryModeChange}
                  disabled={options?.isReadOnly}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              {isShowExistingSupp ? (
                <div className="cc-field">
                  {errors?.[nameOf("")] ? (
                    <Error>{errors[nameOf("")]}</Error>
                  ) : null}
                  <CCGrid
                    dataUrl={
                      getFieldValue("SupplementaryMode") ===
                      SupplementaryMode.SelectFromList
                        ? GET_EXISTING_SUPPLEMENTARY_RATES_LIST
                        : GET_EXISTING_SUPPLEMENTARY_RATES_LIST_CREATED_BY_ME
                    }
                    selectedRows={getFieldValue(
                      "_option.SupplementarySelected"
                    )}
                    primaryField={nameOfVOSupplementary("Supplementary_Id")}
                    columnFields={colSupplementary}
                    selectableMode={"single"}
                    readOnly={options?.isReadOnly}
                    onSelectionChange={(dataItem: any[]) => {
                      onChange(nameOf("_option.SupplementarySelected"), {
                        value: dataItem,
                      });
                      onChange(nameOf("Supplementary_Id"), {
                        value: dataItem?.[0]?.Supplementary_Id ?? null,
                      });
                    }}
                    state={{
                      sort: [
                        {
                          field: nameOfVOSupplementary("Supplementary_Id"),
                          dir: "desc",
                        },
                      ],
                    }}
                  />
                </div>
              ) : null}
            </div>
          </>
        </div>
      </section>
    );
  }
);
