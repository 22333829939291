import { history } from "@/AppRoutes";
import { PickContactDialog } from "@app/core/contacts/components/dialogs/pick-contact/_index";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import {
  eventHandler,
  getAsseticVersion,
  setupPickEventAddress,
} from "@app/products/crms/[id]/api";
import { CloseEventDialog } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/close-event/_index";
import {
  CloseEventRequest,
  EventClose,
} from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/close-event/model";
import { RescheduleDialog } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/details-reschedule/_index";
import { RescheduleMapObj } from "@app/products/crms/[id]/components/forms/components/action-bar/dialogs/details-reschedule/model";
import { AddressPickerSearch } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/address-picker-search/_index";
import { AnimalRegistrationsLookup } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/animals-lookup/_index";
import { AssetPickerSearch } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/asset-picker-search/_index";
import { Details } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/details-accordion/_index";
import {
  renderOptionIssueLocation,
  renderOptionRequestor,
} from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/config";
import { DuplicationCheckerDialog } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/dialogs/duplication-checker-dialog/_index";
import {
  ISafetyReasonForm,
  SafetyReasonDialog,
} from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/dialogs/safety-reason-dialog/_index";
import {
  LocationRegisterView,
  PremisesView,
  Svc_ContactDetails,
} from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { SimilarEvents } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/recent-events-accordion/_index";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import {
  Asset,
  CS_Confidentiality,
  CS_CustomFlag,
  CS_EventStatus,
  CS_IssueLocationType,
  CS_OnBehalfOfType,
  CS_RequestorType,
  ElementDisplayStatus,
  Event,
  EventHandlerRequest,
  EventMapObj,
  EventUpdateTriggers,
  ServiceStandardActionTypeLink,
  Svc_EventFormAction,
} from "@app/products/crms/[id]/model";
import { useCRMSEventStore } from "@app/products/crms/[id]/store";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { CrmsSecurityDialog } from "@app/products/crms/components/dialogs/access-control/_index";
import { CategorySubCategoryDialog } from "@app/products/crms/components/dialogs/category-sub-category/_index";
import { CRMSCommentDialog } from "@app/products/crms/components/dialogs/comment/_index";
import { PickActionTypeDialog } from "@app/products/crms/components/dialogs/pick-action-type/_index";
import { covertToActionTypeSelected } from "@app/products/crms/components/dialogs/pick-action-type/util";
import {
  IInitDataResolutionResponse,
  ResolutionResponsesDialog,
} from "@app/products/crms/components/dialogs/resolution-responses/_index";
import { CrmsSpecialInterestDialog } from "@app/products/crms/components/dialogs/special-interest/_index";
import { getManageHubURL, isFieldVisible } from "@app/products/crms/util";
import { getMultipleSelectValue } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/util";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { ContactRelationshipType } from "@app/products/town-planning/ppr/[id]/model";
import { ContactManagerDialog } from "@app/products/town-planning/ppr/psa-referrals/_id/components/child-screens/general/components/form-element/components/dialog/contact-manager/_index";
import { WWSPISchemeView } from "@app/products/waste-water/model";
import { isSuccessResponse } from "@common/apis/util";
import { ACCESSRIGHTS } from "@common/constants/enumerations";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { Address_BuildAddress } from "@common/input-pickers/address/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { SiteUser } from "@common/pages/settings/security/site-users/_id/model";
import { AssetsSystem } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/assets/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import {
  convertStrIdsToList,
  getDropdownValue,
  nameOfFactory,
} from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { getFullAddressSingleLine } from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { isHTML } from "@components/cc-input-picker/util";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  DropDownListChangeEvent,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
  FormSubmitClickEvent,
} from "@progress/kendo-react-form";
import { SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { cloneDeep, compact, isFunction, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./_index.scss";

export interface IEventFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOfEventMapObj = nameOfFactory<EventMapObj>();
const nameOfEvent = nameOfFactory<Event>();
const nameOfEventAsset = nameOfFactory<Asset>();
const nameOfInitDataResolutionResponse =
  nameOfFactory<IInitDataResolutionResponse>();
const nameOfWorkflowActions = nameOfFactory<ServiceStandardActionTypeLink>();
const nameOfRespActionType = nameOfFactory<IRespActionType>();
const nameOfKeyValuePacket = nameOfFactory<IKeyValuePacket>();

const CSValidationData = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];
export const CRMSEventFormElement = observer(
  ({ formRenderProps }: IEventFormElementProps) => {
    const { siteBaseUrl } = useCommonCoreStore();
    const prevRequestorDisplayName = useRef("");
    const prevAddressLocationDisplayValue = useRef("");
    const prevNonPropertyLocationDisplayValue = useRef("");
    const prevStreetLocationDisplayValue = useRef("");
    const prevPremisesLocationDisplayValue = useRef("");
    const [initialAssetData, setInitialAssetData] = useState<Asset | null>();

    const prevOnBehalfOfDisplayName = useRef("");
    const { currentUserInfo } = useGlobalStore();
    const { settings } = useCommonCoreStore();
    const isNew = useIsNew();
    const { onChange, valueGetter, valid } = formRenderProps;
    const eventFormObj = valueGetter(nameOfEventMapObj("Event"));
    const { pushNotification, clearNotifications } =
      useCCAppNotificationStore();
    const {
      crmsEventLovs,
      setShowEventCategorisedAs,
      setIsShowResolutionResponsesDialog,
      setFormValid,
      isShowEventCategorisedAs,
      isShowResolutionResponsesDialog,
      isShowCloseEvent,
      setIsShowCloseEvent,
      crmsEventClose,
      closeEventHandler,
      crmsEvent,
      isFormModified,
      uiControl,
      setMeAsRequestor,
      setIsLoading,
      eventChangeHandler,
      setIsShowSpecialInterestDialog,
      isShowSpecialInterestDialog,
      setIsShowSecurityDialog,
      isShowSecurityDialog,
      isShowRescheduleDialog: isShowEventDetails,
      setIsShowRescheduleDialog: setIsShowEventDetails,
      eventReschedule,
      setEventReschedule,
      isShowContactVIPDialog,
      setIsShowContactVIPDialog,
      showSafetyReasonDialog,
      setShowSafetyReasonDialog,
      isExpandedAdditionalDetails,
      isLoadingCloseEvent,
      isLoading,
      accessRights,
      setIsHasVip,
      isShowContactManagerDialog,
      setIsShowContactManagerDialog,
      contactManagerDataURL,
      isShowReallocateOfficerCommentReDialog,
      isShowRecategorisationCommentDialog,
      isShowDuplicateCheckerDialog,
      setIsShowReallocateOfficerCommentReDialog,
      setIsShowRecategorisationCommentDialog,
      setIsShowDuplicateCheckerDialog,
      isGetDescriptionFromEvent,
      lastServiceStandardId,
      setIsShowPickActionTypeDialog,
      isShowPickActionTypeDialog,
      sendForActionHandler,
      isNewEventWithSameDetailOrIssue,
      changeSystemToNormalServiceStd,
      isAutoShowLocationDialog,
      setIsAutoShowLocationDialog,
      closeEventId,
      reLoadCRMS,
    } = useCRMSEventStore();

    //#region handle asset location ========/
    const issueLocationAddress = valueGetter(
      `${nameOfEventMapObj("Event")}.${nameOfEvent(
        "IssueLocation"
      )}.LocationAddress`
    );
    const assetLocationArrValue = [
      valueGetter(
        `${nameOfEventMapObj("Event")}.${nameOfEvent(
          "Asset"
        )}.${nameOfEventAsset("StreetName")}`
      ),
      valueGetter(
        `${nameOfEventMapObj("Event")}.${nameOfEvent(
          "Asset"
        )}.${nameOfEventAsset("SuburbArea")}`
      ),
    ];
    const assetLocation = issueLocationAddress
      ? compact(assetLocationArrValue).join(" ")
      : null;
    //#endregion handle asset location =====/

    const isOnBehalfOfMandatory = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_CouncillorRequests_MandatoryOBO
      ]
    );
    const requestorType = valueGetter(
      `${nameOfEventMapObj("Event")}.${nameOfEvent("RequestorType_ID")}`
    );
    const isRequestorTypeCouncillor = useMemo(() => {
      return (
        getDropdownValue(
          requestorType,
          uiControl?.DdlRequestorType?.Dataset ?? [],
          "CustomerService_LookupTable_Id"
        )?.LookupTable_EnumeratedValue_ENUM === CS_RequestorType.Councillor
      );
    }, [requestorType, uiControl]);

    const isOnbehalfOfRequire = useMemo(
      () => isOnBehalfOfMandatory && isRequestorTypeCouncillor,
      [isOnBehalfOfMandatory, isRequestorTypeCouncillor]
    );

    const [initialBuildAddress, setInitialBuildAddress] =
      useState<Address_BuildAddress>();

    const [requestorTypeEnum, setRequestorTypeEnum] =
      useState<CS_RequestorType>();

    const enableVIP = getBoolValueSetting(
      settings[ECorporateSettingsField.EnableVIP]
    );

    const customFlag1Workflow = getNumberValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_Event_CustomFlag1_Workflow
      ]
    );

    const customFlag2Workflow = getNumberValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_Event_CustomFlag2_Workflow
      ]
    );

    const customFlag3Workflow = getNumberValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_Event_CustomFlag3_Workflow
      ]
    );

    const isShowCommentDialogSettingOnReassign = getBoolValueSetting(
      settings[ECorporateSettingsField.CUSTOMERSERVICE_Event_CommentOnReassign]
    );

    const isShowCommentDialogSettingOnRecategorise = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_Event_CommentOnRecategorise
      ]
    );

    const isEnableComplaintHandling = getBoolValueSetting(
      settings[ECorporateSettingsField.CUSTOMERSERVICE_EnableComplaintHandling]
    );

    const assetsSystem = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AssetsSystem]
    );

    const asset = valueGetter(
      `${nameOfEventMapObj("Event")}.${nameOfEvent("Asset")}`
    );
    const isShowEnableResponses = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CUSTOMERSERVICE_ServiceStandard_EnableResponses
      ]
    );

    const isDisable =
      crmsEvent?.EventStatus_ENUM === CS_EventStatus.Closed ||
      accessRights === ACCESSRIGHTS.CanRead;
    const isDisableConfidentiality =
      uiControl?.DdlConfidentiality?.DisplayStatus ===
      ElementDisplayStatus.Disable;
    const locationType = valueGetter(
      `${nameOfEventMapObj("Event")}.${nameOfEvent(
        "IssueLocation"
      )}.LocationType_ENUM`
    );
    const isShowPropertyAddress = useMemo(() => {
      return (
        locationType === CS_IssueLocationType.PropertyAddress ||
        locationType === CS_IssueLocationType.SameAsReporter
      );
    }, [locationType]);

    const isShowLocationPremises = useMemo(() => {
      return (
        locationType === CS_IssueLocationType.HealthPremises ||
        locationType === CS_IssueLocationType.TobaccoPremises
      );
    }, [locationType]);

    const isShowLocationNonProperty = useMemo(() => {
      return locationType === CS_IssueLocationType.NonProperty;
    }, [locationType]);

    const isShowLocationRegister = useMemo(() => {
      return (
        locationType === CS_IssueLocationType.Reserve ||
        locationType === CS_IssueLocationType.CommunityProperty
      );
    }, [locationType]);

    const isShowLocationStreet = useMemo(() => {
      return locationType === CS_IssueLocationType.Street;
    }, [locationType]);

    const isShowLocationAsset = useMemo(() => {
      return locationType === CS_IssueLocationType.Assets;
    }, [locationType]);

    const isShowHealthPremises = useMemo(() => {
      return locationType === CS_IssueLocationType.HealthPremises;
    }, [locationType]);

    const isShowTobaccoPremises = useMemo(() => {
      return locationType === CS_IssueLocationType.TobaccoPremises;
    }, [locationType]);

    const isShowWasteWaterScheme = useMemo(() => {
      return locationType === CS_IssueLocationType.WWMSScheme;
    }, [locationType]);

    const premisesId = valueGetter(
      `${nameOfEventMapObj("Event")}.${nameOfEvent("Premises_ID")}`
    );
    const wasteWaterSchemeId = valueGetter(
      `${nameOfEventMapObj("Event")}.${nameOfEvent("WasteWater_SchemeID")}`
    );

    const locationAddress = valueGetter("Event.IssueLocation.LocationAddress");
    const binSizes =
      valueGetter(
        `${nameOfEventMapObj("Event")}.${nameOfEvent("BinSizeIDStr")}`
      ) ?? "";
    const binTypes =
      valueGetter(
        `${nameOfEventMapObj("Event")}.${nameOfEvent("BinTypeIDStr")}`
      ) ?? "";

    const isAnonymous = requestorTypeEnum === CS_RequestorType.Anonymous;
    const isExternalPerson =
      requestorTypeEnum === CS_RequestorType.ExternalPerson;

    const isInternalOnBehalfOf = useMemo(() => {
      return (
        crmsEvent?.ServiceStandard?.OnBehalfOfType_ENUM ===
          CS_OnBehalfOfType.AllowInternalOnly &&
        (requestorTypeEnum === CS_RequestorType.Councillor ||
          requestorTypeEnum === CS_RequestorType.InternalPerson ||
          requestorTypeEnum === CS_RequestorType.ExecutiveManagementTeam)
      );
    }, [crmsEvent, requestorTypeEnum]);

    const displaySiteAddress = useMemo(() => {
      if (locationAddress) {
        return getFullAddressSingleLine(locationAddress);
      } else return "";
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationAddress]);

    const customerContacted = valueGetter(
      `${nameOfEventMapObj("Event")}.${nameOfEvent("CustomerContacted")}`
    );

    const handleRequestorTypeChange = (event: DropDownListChangeEvent) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_RequestorTypeChange,
        Event: eventFormObj,
        EventArgs: {
          RequestorType_ID:
            event.target?.value?.CustomerService_LookupTable_Id!,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change requestor type fail");
      if (uiControl) uiControl.LitRequestedBy.Value = "";
    };

    const setAnonymousHandler = () => {
      const requestorTypeId = getDropdownValue(
        CS_RequestorType.Anonymous,
        uiControl?.DdlRequestorType?.Dataset ?? [],
        "LookupTable_EnumeratedValue_ENUM"
      )?.CustomerService_LookupTable_Id;

      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_RequestorTypeChange,
        Event: eventFormObj,
        EventArgs: {
          RequestorType_ID: requestorTypeId,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change requestor type fail");
      setRequestorTypeEnum(CS_RequestorType.Anonymous);
    };

    const setMeAsRequestorHandler = () => {
      const requestorTypeId = getDropdownValue(
        CS_RequestorType.InternalPerson,
        uiControl?.DdlRequestorType?.Dataset ?? [],
        "LookupTable_EnumeratedValue_ENUM"
      )?.CustomerService_LookupTable_Id;

      if (currentUserInfo?.UserPKID) {
        setMeAsRequestor(
          parseInt(currentUserInfo?.UserPKID),
          requestorTypeId,
          eventFormObj
        );
        setRequestorTypeEnum(CS_RequestorType.InternalPerson);
      }
    };

    const handleSelectRequestor = (value: Svc_ContactDetails | null) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_RequestorChange,
        Event: eventFormObj,
        EventArgs: {
          RequestedBy_SiteUser_ID: value?.Id,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change requestor fail");
    };

    const handleSelectRequestorExternal = (
      event: ComboBoxChangeEvent | null
    ) => {
      let requestedByContactRID = 0;
      let sysTimeStamp = "";
      if (
        eventFormObj?.RequestedBy_Contact_RID &&
        eventFormObj?.RequestedBy?.Contact?.Contact_ID ===
          event?.value?.Contact_ID &&
        eventFormObj?.RequestedBy?.Sys_TimeStamp
      ) {
        requestedByContactRID = eventFormObj.RequestedBy_Contact_RID;
        sysTimeStamp = eventFormObj.RequestedBy.Sys_TimeStamp;
      }

      let params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_RequestorChange,
        Event: eventFormObj,
        EventArgs: {},
        isFirstTimeLoad: false,
      };

      if (event?.value) {
        params = {
          ...params,
          EventArgs: {
            RequestedBy: {
              ContactRelationshipType_ENUM:
                ContactRelationshipType.CUSTOMERSERVICE_RequestedBy,
              Contact: event?.value,
              RID: requestedByContactRID,
              Sys_TimeStamp: sysTimeStamp,
            },
          },
        };
      }

      eventChangeHandler(params, "Change requestor fail");
    };

    const handleSelectOnBehalfOf = (value: Svc_ContactDetails | null) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickOnBehalfOfInternal,
        Event: eventFormObj,
        EventArgs: {
          OnBehalfOf_SiteUser_ID: value?.Id,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change on behalf of fail");
    };

    const handleSelectOnBehalfOfExternal = (
      event: ComboBoxChangeEvent | null
    ) => {
      let onBehalfOfContactRID = 0;
      let sysTimeStamp = "";
      if (
        eventFormObj?.OnBehalfOf_Contact_RID &&
        eventFormObj?.OnBehalfOf?.Contact?.Contact_ID ===
          event?.value?.Contact_ID &&
        eventFormObj?.OnBehalfOf?.Sys_TimeStamp
      ) {
        onBehalfOfContactRID = eventFormObj.OnBehalfOf_Contact_RID;
        sysTimeStamp = eventFormObj.OnBehalfOf.Sys_TimeStamp;
      }
      let params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickOnBehalfOfExternal,
        Event: eventFormObj,
        EventArgs: {},
        isFirstTimeLoad: false,
      };
      if (event?.value) {
        params = {
          ...params,
          EventArgs: {
            OnBehalfOf: {
              ContactRelationshipType_ENUM:
                ContactRelationshipType.CUSTOMERSERVICE_OnBehalfOf,
              Contact: event?.value,
              RID: onBehalfOfContactRID,
              Sys_TimeStamp: sysTimeStamp,
            },
          },
        };
      }
      eventChangeHandler(params, "Change on behalf of fail");
    };

    const handleLocationTypeChange = (event: DropDownListChangeEvent) => {
      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("Asset")}`, {
        value: null,
      });
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_LocationTypeChange,
        Event: eventFormObj,
        EventArgs: {
          LocationType_ID: event.target?.value?.CustomerService_LookupTable_Id
            ? event.target?.value?.CustomerService_LookupTable_Id
            : null,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change location type fail");
    };

    const handleAddressLocationChange = (
      buildAddress: Address_BuildAddress | null
    ) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickLocationAddress,
        Event: eventFormObj,
        EventArgs: buildAddress ?? {},
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change address location fail");
      setIsAutoShowLocationDialog(false);
    };

    const handleOpenDialog = async (
      setIsShowDialog?: (value: React.SetStateAction<boolean>) => void
    ) => {
      setIsLoading(true);
      if (!eventFormObj) return;
      clearNotifications();
      const response = await setupPickEventAddress(eventFormObj);
      setIsLoading(false);
      if (isSuccessResponse(response) && response.data) {
        setInitialBuildAddress(response.data.ReturnObj);
        if (setIsShowDialog && isFunction(setIsShowDialog))
          setIsShowDialog(true);
      } else {
        pushNotification({
          autoClose: false,
          title: "Setup property address fail.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };

    const handleTypeChange = (event: ComboBoxChangeEvent) => {
      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("Type_ID")}`, {
        value: event.target.value?.CustomerService_LookupTable_Id ?? null,
      });
      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("Type_Display")}`, {
        value: event.target.value?.Description ?? null,
      });
    };

    const handleSourceChange = (event: ComboBoxChangeEvent) => {
      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("Source_ID")}`, {
        value: event.target.value?.Key ?? null,
      });
      onChange(
        `${nameOfEventMapObj("Event")}.${nameOfEvent("Source_Display")}`,
        {
          value: event.target.value?.Value ?? null,
        }
      );

      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_SourceChange,
        Event: eventFormObj,
        EventArgs: {
          Source_ID: event.target.value?.Key,
        },
        isFirstTimeLoad: false,
      };

      eventChangeHandler(
        params,
        "Change source was failed.",
        undefined,
        undefined,
        undefined,
        true
      );
    };

    const handleConfidentialityChange = (event: ComboBoxChangeEvent) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_ConfidentialityChange,
        Event: eventFormObj,
        EventArgs: {
          Confidentiality_ENUM: CS_Confidentiality.NonConfidential,
        },
        isFirstTimeLoad: false,
      };
      if (event.target.value) {
        onChange(
          `${nameOfEventMapObj("Event")}.${nameOfEvent(
            "Confidentiality_ENUM"
          )}`,
          {
            value: event.target.value?.Key,
          }
        );
        eventChangeHandler(
          {
            ...params,
            EventArgs: {
              Confidentiality_ENUM: event.target.value?.Key,
            },
          },
          "Change Confidentiality fail"
        );
      } else {
        onChange(
          `${nameOfEventMapObj("Event")}.${nameOfEvent(
            "Confidentiality_ENUM"
          )}`,
          {
            value: CS_Confidentiality.NonConfidential,
          }
        );
        eventChangeHandler(params, "Change Confidentiality fail");
      }
    };

    const handleSelectLocationPremises = (location: PremisesView | null) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickPremises,
        Event: eventFormObj,
        EventArgs: {
          Premises_ID: location?.ID,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change location fail");
    };

    const handleSelectLocationWasteWaterScheme = (
      location: WWSPISchemeView | null
    ) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickWasteWaterScheme,
        Event: eventFormObj,
        EventArgs: {
          WasteWater_SchemeID: location?.ID,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change location fail");
    };

    const handleSelectLocationRegister = (
      location: LocationRegisterView | null
    ) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickLocationRegister,
        Event: eventFormObj,
        EventArgs: {
          Register_ID: location?.ID,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change location fail");
    };

    const handleUrgentChange = (event: SwitchChangeEvent) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_UrgentChange,
        Event: eventFormObj,
        EventArgs: {
          Urgency: event.target.value,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Change urgent flag fail");
    };

    const handleChangeCustomFlag1 = async (event: SwitchChangeEvent) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_ChangeCustomFlag1,
        Event: eventFormObj,
        EventArgs: {
          Flags_CustomFlag1: event.value,
        },
        isFirstTimeLoad: false,
      };
      const result = await eventChangeHandler(
        params,
        "Change custom flag 1 fail"
      );
      if (result) {
        if (customFlag1Workflow === (CS_CustomFlag.Safety as number)) {
          setShowSafetyReasonDialog({
            isShow: true,
            switchState: event.value,
            titleDialog: uiControl?.ChkCustomField1?.Label ?? "Safety",
          });
        } else if (
          customFlag1Workflow === (CS_CustomFlag.VIP as number) &&
          event.value
        ) {
          setIsShowContactVIPDialog(true);
        }
      }
    };

    const handleChangeCustomFlag2 = async (event: SwitchChangeEvent) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_ChangeCustomFlag2,
        Event: eventFormObj,
        EventArgs: {
          Flags_CustomFlag2: event.value,
        },
        isFirstTimeLoad: false,
      };
      const result = await eventChangeHandler(
        params,
        "Change custom flag 2 fail"
      );
      if (result) {
        if (customFlag2Workflow === (CS_CustomFlag.Safety as number)) {
          setShowSafetyReasonDialog({
            isShow: true,
            switchState: event.value,
            titleDialog: uiControl?.ChkCustomField2?.Label ?? "Safety",
          });
        } else if (
          customFlag2Workflow === (CS_CustomFlag.VIP as number) &&
          event.value
        ) {
          setIsShowContactVIPDialog(true);
        }
      }
    };

    const handleChangeCustomFlag3 = async (event: SwitchChangeEvent) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_ChangeCustomFlag3,
        Event: eventFormObj,
        EventArgs: {
          Flags_CustomFlag3: event.value,
        },
        isFirstTimeLoad: false,
      };
      const result = await eventChangeHandler(
        params,
        "Change custom flag 3 fail"
      );
      if (result) {
        if (customFlag3Workflow === (CS_CustomFlag.Safety as number)) {
          setShowSafetyReasonDialog({
            isShow: true,
            switchState: event.value,
            titleDialog: uiControl?.ChkCustomField3?.Label ?? "Safety",
          });
        } else if (
          customFlag3Workflow === (CS_CustomFlag.VIP as number) &&
          event.value
        ) {
          setIsShowContactVIPDialog(true);
        }
      }
    };

    const handlePickContactVIP = (e: Svc_ContactDetails[]) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickLookupValueVIP,
        Event: eventFormObj,
        EventArgs: {
          VIP_Contact_ID: e[0].Id,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Pick contact vip failed");
      setIsShowContactVIPDialog(false);
    };

    const handleChangeSafetyReason = (data: ISafetyReasonForm) => {
      const params: EventHandlerRequest = {
        EventFormAction: showSafetyReasonDialog?.switchState
          ? Svc_EventFormAction.Form_PickSafetyIssueReason
          : Svc_EventFormAction.Form_PickSafetyIssueRemovedReason,
        Event: eventFormObj,
        EventArgs: {
          SafetyReason: data?.SafetyReason,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Add safety reason fail.");
      setShowSafetyReasonDialog({
        isShow: false,
        titleDialog: "",
        switchState: false,
      });
    };

    const handleChangeActionOfficer = (data: any) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickActionOfficer,
        Event: eventFormObj,
        EventArgs: {
          ActionOfficer_SiteUser_ID: data?.[0]?.Id,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(
        params,
        "Change action officer fail.",
        isNew,
        isShowCommentDialogSettingOnReassign,
        true
      );
      setIsShowContactManagerDialog(false);
    };

    const handleSubmitResolutionAndResponses = (
      event: FormSubmitClickEvent
    ) => {
      onChange(
        `${nameOfEventMapObj("Event")}.${nameOfEvent("Event_Description")}`,
        {
          value:
            event?.values?.[
              `${nameOfInitDataResolutionResponse("EventDescription")}`
            ],
        }
      );
      setIsShowResolutionResponsesDialog(false);

      // Open recategorisation comment dialog
      if (
        !isNew &&
        isShowCommentDialogSettingOnRecategorise &&
        lastServiceStandardId !==
          eventFormObj?.ServiceStandard?.ServiceStandard_Id
      ) {
        setIsShowRecategorisationCommentDialog(true);
      } else if (
        eventFormObj?.ServiceStandard?.Flag_PropertySearch &&
        (!eventFormObj?.IssueLocation ||
          !eventFormObj?.IssueLocation?.LocationAddress)
      ) {
        // Open pick address dialog
        setIsAutoShowLocationDialog(true);
      }
    };

    const handleSubmitRecategorisationComment = (
      event: FormSubmitClickEvent
    ) => {
      onChange(
        `${nameOfEventMapObj("Event")}.${nameOfEvent(
          "RecategorisationComment"
        )}`,
        {
          value: event?.values?.[nameOfEvent("RecategorisationComment")],
        }
      );
      setIsShowRecategorisationCommentDialog(false);

      // Open pick address dialog
      if (
        eventFormObj?.ServiceStandard?.Flag_PropertySearch &&
        (!eventFormObj?.IssueLocation ||
          !eventFormObj?.IssueLocation?.LocationAddress)
      ) {
        setIsAutoShowLocationDialog(true);
      }
    };

    const handleSubmitReallocateOfficerComment = (
      event: FormSubmitClickEvent
    ) => {
      onChange(
        `${nameOfEventMapObj("Event")}.${nameOfEvent("ReassignComment")}`,
        {
          value: event?.values?.[nameOfEvent("ReassignComment")],
        }
      );
      setIsShowReallocateOfficerCommentReDialog(false);
    };

    const renderContact = (contact: SiteUser) => {
      if (!contact) return "";
      return (
        <>
          {contact._HomePhone && (
            <a href={`tel:${contact._HomePhone}`} className="cc-contact">
              {contact._HomePhone}(H)
            </a>
          )}
          {contact._WorkPhone && (
            <a href={`tel:${contact._WorkPhone}`} className="cc-contact">
              {contact._WorkPhone}(W)
            </a>
          )}
          {contact._Mobile && (
            <a href={`tel:${contact._Mobile}`} className="cc-contact">
              {contact._Mobile}(M)
            </a>
          )}
          {contact.Email && (
            <a href={`mailto:${contact.Email}`} className="cc-contact">
              {contact.Email}
            </a>
          )}
        </>
      );
    };

    const renderAddressDetails = useMemo(() => {
      let addressDetails;

      if (uiControl?.LblLocationAssessmentNo?.Value)
        addressDetails = <span>{uiControl.LblLocationAssessmentNo.Value}</span>;

      if (uiControl?.LblParcelID?.Value)
        addressDetails = addressDetails ? (
          <>
            {addressDetails} <br />
            <span>{uiControl.LblParcelID.Value}</span>
          </>
        ) : (
          <span>{uiControl.LblParcelID.Value}</span>
        );

      return addressDetails;
    }, [uiControl]);

    // Override the description value of the resolution responses dialog
    const resolutionResponsesInitData = useMemo(() => {
      let description = "";
      if (isGetDescriptionFromEvent) {
        description = crmsEvent?.Event_Description ?? "";
      }

      if (
        !isGetDescriptionFromEvent &&
        isNew &&
        crmsEvent?.ServiceStandard?.Description
      ) {
        description = crmsEvent?.ServiceStandard?.Description ?? "";
      } else if (
        crmsEvent?.ServiceStandard?.Description &&
        changeSystemToNormalServiceStd
      ) {
        description = crmsEvent?.ServiceStandard?.Description ?? "";
      }

      return {
        EventDescription: description,
      };
    }, [
      crmsEvent,
      isGetDescriptionFromEvent,
      changeSystemToNormalServiceStd,
      isNew,
    ]);

    const updateSaveTriggers = (triggers: EventUpdateTriggers) => {
      let saveTriggers: EventUpdateTriggers[] =
        valueGetter(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("SaveTriggers")}`
        ) ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("SaveTriggers")}`,
          {
            value: saveTriggers,
          }
        );
      }
    };

    const handleChangeCustomerContacted = () => {
      if (isDisable) return;
      const currentValue = valueGetter(
        `${nameOfEventMapObj("Event")}.${nameOfEvent("CustomerContacted")}`
      );

      onChange(
        `${nameOfEventMapObj("Event")}.${nameOfEvent("CustomerContacted")}`,
        { value: parseInt(currentValue ?? 0) + 1 }
      );
    };

    const handleChangeTier = (event: ComboBoxChangeEvent) => {
      const { value } = event;

      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("Tier_ID")}`, {
        value: value?.Key ?? null,
      });

      onChange(`${nameOfEventMapObj("Event")}.${nameOfEvent("Tier_Display")}`, {
        value: value?.Value ?? null,
      });

      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_TierChange,
        Event: eventFormObj,
        EventArgs: {},
        isFirstTimeLoad: false,
      };

      eventChangeHandler(params, "Change tier was failed.", isNew);
    };

    const handleChangeAsset = (data: Asset | null) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_AssetDetails,
        Event: eventFormObj,
        EventArgs: data ?? {},
        isFirstTimeLoad: false,
      };

      eventChangeHandler(params, "Change location failed.");
    };

    const requiredValidatorAsset = useCallback(
      (value: Asset) => {
        if (
          (!!value?.LocationDescription || !!value?.SiteCode) &&
          eventFormObj?.IssueLocation?.LocationAddress
        )
          return "";

        return (
          requiredValidator(value?.SiteCode) ||
          requiredValidator(eventFormObj?.IssueLocation?.LocationAddress)
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [asset]
    );

    const handleOpenAssetDialog = async () => {
      if (
        asset?.SiteCode &&
        (assetsSystem === AssetsSystem.AssetMaster ||
          assetsSystem === AssetsSystem.Custom)
      ) {
        const dialogData = cloneDeep(asset);
        setIsLoading(true);
        const response = await getAsseticVersion(asset.SiteCode);
        setIsLoading(false);
        if (isSuccessResponse(response) && response.data) {
          Object.assign(dialogData, response.data);
        }
        setInitialAssetData(dialogData);
      }
    };

    const handleRemovalAutoAction = async (
      workflowActions: IRespActionType[]
    ) => {
      const workflowActionIds = workflowActions.map(
        (workflowAction: IRespActionType) => workflowAction.ID
      );
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickAutoAction,
        Event: eventFormObj,
        EventArgs: workflowActionIds,
        isFirstTimeLoad: false,
      };
      setIsLoading(true);
      setIsShowPickActionTypeDialog(false);
      const response = await eventHandler(params);
      setIsLoading(false);
      if (
        isSuccessResponse(response) &&
        response.data?.ReturnObj &&
        currentUserInfo?.UserPKID
      ) {
        sendForActionHandler(
          response.data?.ReturnObj?.Event,
          currentUserInfo?.UserPKID,
          isNew
        );
      } else {
        pushNotification({
          autoClose: false,
          title: "Set workflow action failed.",
          type: "error",
          description: response.data?.Errors ?? response.error,
        });
      }
    };

    const handleDuplicateEventSameIssue = async (event: EventView) => {
      const params: EventHandlerRequest = {
        EventFormAction: Svc_EventFormAction.Form_PickRelatedEvent,
        Event: eventFormObj,
        EventArgs: {
          Key: event?.ID,
          Value: true,
        },
        isFirstTimeLoad: false,
      };
      eventChangeHandler(params, "Get event same issue fail.");
      setIsShowDuplicateCheckerDialog(false);
    };

    const handleGoToExistingEvent = async (event: EventView) => {
      window.open(`${CRMS_ROUTE}/${event.ID}`, "_blank");
    };

    useEffect(() => {
      const requestorTypeEnum = getDropdownValue(
        valueGetter(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("RequestorType_ID")}`
        ),
        uiControl?.DdlRequestorType?.Dataset ?? [],
        "CustomerService_LookupTable_Id"
      )?.LookupTable_EnumeratedValue_ENUM;

      setRequestorTypeEnum(requestorTypeEnum);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [crmsEvent, crmsEventLovs, isNew]);

    useEffect(() => {
      setFormValid(valid);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valid]);

    useEffect(() => {
      if (isFormModified) {
        onChange(
          `${nameOfEventMapObj("Event")}.${nameOfEvent("Event_ReferenceNo")}`,
          {
            value: valueGetter(
              `${nameOfEventMapObj("Event")}.${nameOfEvent(
                "Event_ReferenceNo"
              )}`
            ),
          }
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormModified]);

    useEffect(() => {
      if (
        (customFlag3Workflow === CS_CustomFlag.VIP ||
          customFlag2Workflow === CS_CustomFlag.VIP ||
          customFlag1Workflow === CS_CustomFlag.VIP) &&
        enableVIP
      ) {
        setIsHasVip(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      customFlag3Workflow,
      customFlag2Workflow,
      customFlag1Workflow,
      enableVIP,
    ]);

    const listPanelBar: ITabProps[] = isNew
      ? [
          {
            isExpanded: isExpandedAdditionalDetails,
            title: "Additional Details",
            component: <Details eventFormObject={eventFormObj} />,
          },
        ]
      : [
          {
            isExpanded: isExpandedAdditionalDetails,
            title: "Additional Details",
            component: <Details eventFormObject={eventFormObj} />,
          },
          {
            title: "Similar Events",
            component: <SimilarEvents />,
          },
        ];

    return (
      <>
        <FormElement>
          {/* Category dialog */}
          {isShowEventCategorisedAs && (
            <CategorySubCategoryDialog
              onClosePopup={() => setShowEventCategorisedAs(false)}
              formObject={eventFormObj}
            />
          )}
          {/* Close event dialog */}
          {isShowCloseEvent && crmsEventClose && !isLoading && (
            <CloseEventDialog
              isLoading={isLoadingCloseEvent}
              crmsEventClose={crmsEventClose}
              onClosePopup={() => {
                setIsShowCloseEvent(false);
                if (closeEventId) {
                  isNew
                    ? history.push(CRMS_ROUTE + "/" + closeEventId)
                    : reLoadCRMS(isNew);
                }
              }}
              onSubmit={(data: EventClose) => {
                closeEventHandler({
                  EventClose: data,
                  Event: eventFormObj,
                } as CloseEventRequest);
              }}
            />
          )}
          {/* Special interested dialog */}
          {isShowSpecialInterestDialog && (
            <CrmsSpecialInterestDialog
              onClosePopup={() => setIsShowSpecialInterestDialog(false)}
              eventForm={eventFormObj}
            />
          )}
          {/* Security dialog */}
          {isShowSecurityDialog && (
            <CrmsSecurityDialog
              onClosePopup={() => setIsShowSecurityDialog(false)}
              eventForm={eventFormObj}
            />
          )}
          {/* Reschedule dialog */}
          {isShowEventDetails && eventReschedule && crmsEvent && (
            <RescheduleDialog
              crmsEventReschedule={
                {
                  Event: crmsEvent,
                  EventReschedule: eventReschedule,
                } as RescheduleMapObj
              }
              onClosePopup={() => {
                setIsShowEventDetails(false);
                setEventReschedule(undefined);
              }}
            />
          )}
          {/* VIP dialog */}
          {isShowContactVIPDialog && (
            <PickContactDialog
              setShowDialog={(isShow: boolean) => {
                setIsShowContactVIPDialog(isShow);
              }}
              onCloseDialog={() => {
                setIsShowContactVIPDialog(false);
              }}
              oDataURLParams={"showOnlyVIP=true"}
              onSubmit={handlePickContactVIP}
            />
          )}
          {/* Reason dialog */}
          {showSafetyReasonDialog.isShow && (
            <SafetyReasonDialog
              onCloseDialog={() => {
                setShowSafetyReasonDialog({
                  isShow: false,
                  titleDialog: "",
                  switchState: false,
                });
              }}
              onSubmit={handleChangeSafetyReason}
              textAreaLabel={
                showSafetyReasonDialog.switchState
                  ? "Safety demo Issue Reason (to be inserted in the journal)"
                  : "Safety demo Issue Removed Reason (to be inserted in the journal)"
              }
              title={showSafetyReasonDialog.titleDialog}
            />
          )}
          {/* Contact manager dialog */}
          {isShowContactManagerDialog && (
            <ContactManagerDialog
              onClose={() => setIsShowContactManagerDialog(false)}
              onSubmit={handleChangeActionOfficer}
              dataURL={contactManagerDataURL}
              title="Contact Manager"
              isSubmitLoading={false}
            />
          )}
          {/* Resolution and Responses dialog */}
          {isShowResolutionResponsesDialog && isShowEnableResponses && (
            <ResolutionResponsesDialog
              onClosePopup={() => setIsShowResolutionResponsesDialog(false)}
              handleSubmit={handleSubmitResolutionAndResponses}
              initData={resolutionResponsesInitData}
            />
          )}
          {/* Reallocate officer comment dialog */}
          {isShowReallocateOfficerCommentReDialog && (
            <CRMSCommentDialog
              dialogTitle="Reassign Comment"
              fieldTitle="Reassign reason (to be inserted in the journal)"
              nameOfField={nameOfEvent("ReassignComment")}
              onClosePopup={() => {
                setIsShowReallocateOfficerCommentReDialog(false);
              }}
              handleSubmit={handleSubmitReallocateOfficerComment}
            />
          )}
          {/* Recategorisation comment dialog */}
          {isShowRecategorisationCommentDialog && (
            <CRMSCommentDialog
              dialogTitle="Recategorisation Comment"
              fieldTitle="Recategorisation reason (to be inserted in the journal)"
              nameOfField={nameOfEvent("RecategorisationComment")}
              onClosePopup={() => {
                setIsShowRecategorisationCommentDialog(false);
              }}
              handleSubmit={handleSubmitRecategorisationComment}
            />
          )}
          {/* pick action type dialog */}
          {isShowPickActionTypeDialog && (
            <PickActionTypeDialog
              onCloseDialog={() => setIsShowPickActionTypeDialog(false)}
              onSubmit={handleRemovalAutoAction}
              productType={PRODUCT_TYPE.CustomerService}
              serviceStandardId={
                eventFormObj?.ServiceStandard?.ServiceStandard_Id
              }
              selectedActionTypes={covertToActionTypeSelected(
                eventFormObj?.ServiceStandard?.WorkflowActions,
                nameOfWorkflowActions("ActionType_ID"),
                nameOfRespActionType("ID")
              )}
            />
          )}

          {/* Duplicate checker dialog */}
          {isShowDuplicateCheckerDialog &&
            isNew &&
            !isNewEventWithSameDetailOrIssue && (
              <DuplicationCheckerDialog
                duplicationEventValue={
                  uiControl?.DialogDuplicationEventCheck?.Value?.Args
                }
                onCloseDialog={() => setIsShowDuplicateCheckerDialog(false)}
                handleDuplicateEventSameIssue={handleDuplicateEventSameIssue}
                handleGoToExistingEvent={handleGoToExistingEvent}
              />
            )}

          {/* SECTION 1 */}
          <div>
            {/* ROW 1 */}
            <div className="cc-field-group">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <CCLabel title="Request description" isMandatory />
                  <Field
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "Event_Description"
                    )}`}
                    placeholder="Request description"
                    rows={4}
                    value={valueGetter(
                      `${nameOfEventMapObj("Event")}.${nameOfEvent(
                        "Event_Description"
                      )}`
                    )}
                    component={CCTextArea}
                    validator={requiredValidator}
                    disabled={isDisable}
                  />
                </div>
              </div>
              <div className="cc-form-cols-1">
                <div className="cc-group-switch">
                  {uiControl?.ChkUrgent?.DisplayStatus !==
                    ElementDisplayStatus.Hidden && (
                    <div className="cc-field">
                      <label className="cc-label cc-custom-mb">Urgent</label>
                      <Field
                        name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "Urgency"
                        )}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "Urgency"
                          )}`
                        )}
                        disabled={isDisable}
                        onChange={handleUrgentChange}
                      />
                    </div>
                  )}
                  {uiControl?.ChkCustomField1?.DisplayStatus !==
                    ElementDisplayStatus.Hidden && (
                    <div className="cc-field">
                      <label className="cc-label cc-custom-mb">
                        {uiControl?.ChkCustomField1?.Label}
                      </label>
                      <Field
                        name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "Flags_CustomFlag1"
                        )}`}
                        component={CCSwitch}
                        disabled={
                          uiControl?.ChkCustomField1?.DisplayStatus ===
                            ElementDisplayStatus.Disable || isDisable
                        }
                        checked={valueGetter(
                          `${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "Flags_CustomFlag1"
                          )}`
                        )}
                        onChange={handleChangeCustomFlag1}
                      />
                    </div>
                  )}
                  {uiControl?.ChkCustomField2?.DisplayStatus !==
                    ElementDisplayStatus.Hidden && (
                    <div className="cc-field">
                      <label className="cc-label cc-custom-mb">
                        {uiControl?.ChkCustomField2?.Label}
                      </label>
                      <Field
                        name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "Flags_CustomFlag2"
                        )}`}
                        component={CCSwitch}
                        disabled={
                          uiControl?.ChkCustomField2?.DisplayStatus ===
                            ElementDisplayStatus.Disable || isDisable
                        }
                        checked={valueGetter(
                          `${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "Flags_CustomFlag2"
                          )}`
                        )}
                        onChange={handleChangeCustomFlag2}
                      />
                    </div>
                  )}
                  {uiControl?.ChkCustomField3?.DisplayStatus !==
                    ElementDisplayStatus.Hidden && (
                    <div className="cc-field">
                      <label className="cc-label cc-custom-mb">
                        {uiControl?.ChkCustomField3?.Label}
                      </label>
                      <Field
                        name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "Flags_CustomFlag3"
                        )}`}
                        component={CCSwitch}
                        disabled={
                          uiControl?.ChkCustomField3?.DisplayStatus ===
                            ElementDisplayStatus.Disable || isDisable
                        }
                        checked={valueGetter(
                          `${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "Flags_CustomFlag3"
                          )}`
                        )}
                        onChange={handleChangeCustomFlag3}
                      />
                    </div>
                  )}
                </div>
              </div>

              {isEnableComplaintHandling &&
                crmsEvent?.ServiceStandard?.EnableCustomerContacted && (
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <label className="label">
                        <span
                          className={isDisable ? "" : "btn-link"}
                          onClick={handleChangeCustomerContacted}
                        >
                          Add customer interaction{" "}
                        </span>
                        {customerContacted > 0 && (
                          <span>({customerContacted})</span>
                        )}
                      </label>
                    </div>
                  </div>
                )}
            </div>
            <hr className="cc-divider" />

            {isFieldVisible(
              uiControl?.DivCustomerServiceValidation.DisplayStatus
            ) && (
              <>
                <div className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <label className="cc-label cc-text-red">
                        {uiControl?.DivCustomerServiceValidation.Label}
                        <CCTooltip type="validator" position="right" />
                      </label>

                      <Field
                        name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "CustomerServiceValidation"
                        )}`}
                        component={CCRadioGroup}
                        layout={"horizontal"}
                        data={CSValidationData}
                      />
                    </div>
                  </div>
                </div>
                <hr className="cc-divider" />
              </>
            )}

            {/* ROW 2 */}
            <div className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Type" isMandatory />
                  <Field
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "Type_ID"
                    )}`}
                    textField="Description"
                    dataItemKey="CustomerService_LookupTable_Id"
                    data={crmsEventLovs?.Type}
                    component={CCSearchComboBox}
                    validator={requiredValidator}
                    placeholder="Select type"
                    value={getDropdownValue(
                      valueGetter(
                        `${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "Type_ID"
                        )}`
                      ),
                      crmsEventLovs?.Type ?? [],
                      "CustomerService_LookupTable_Id"
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      handleTypeChange(event);
                    }}
                    disabled={isDisable}
                  />
                </div>

                <div className="cc-field">
                  <CCLabel title="Source" isMandatory />
                  <Field
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "Source_ID"
                    )}`}
                    textField="Value"
                    dataItemKey="Key"
                    data={crmsEventLovs?.Source}
                    component={CCSearchComboBox}
                    validator={requiredValidator}
                    placeholder="Select source"
                    value={getDropdownValue(
                      valueGetter(
                        `${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "Source_ID"
                        )}`
                      ),
                      crmsEventLovs?.Source ?? [],
                      "Key"
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      handleSourceChange(event);
                    }}
                    disabled={isDisable}
                  />
                </div>

                <div className="cc-field">
                  <CCLabel title="Confidentiality" />
                  <Field
                    placeholder="Select confidentiality"
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "Confidentiality_ENUM"
                    )}`}
                    textField="Value"
                    dataItemKey="Key"
                    data={crmsEventLovs?.Confidentiality}
                    component={CCSearchComboBox}
                    value={getDropdownValue(
                      valueGetter(
                        `${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "Confidentiality_ENUM"
                        )}`
                      ),
                      crmsEventLovs?.Confidentiality ?? [],
                      "Key"
                    )}
                    onChange={handleConfidentialityChange}
                    disabled={isDisable || isDisableConfidentiality}
                  />
                </div>
              </div>

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Requestor type" isMandatory />
                  <Field
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "RequestorType_ID"
                    )}`}
                    placeholder="Select requestor type"
                    textField="Description"
                    dataItemKey="CustomerService_LookupTable_Id"
                    data={uiControl?.DdlRequestorType?.Dataset ?? []}
                    component={CCDropDownList}
                    validator={requiredValidator}
                    value={getDropdownValue(
                      valueGetter(
                        `${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "RequestorType_ID"
                        )}`
                      ),
                      uiControl?.DdlRequestorType?.Dataset ?? [],
                      "CustomerService_LookupTable_Id"
                    )}
                    onChange={handleRequestorTypeChange}
                    disabled={isDisable}
                  />
                </div>

                <div className="cc-group-switch">
                  <div className="cc-field">
                    <label className="cc-label cc-custom-mb">
                      Return call required
                    </label>
                    <Field
                      name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                        "Flags_ReturnCall"
                      )}`}
                      component={CCSwitch}
                      checked={valueGetter(
                        `${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "Flags_ReturnCall"
                        )}`
                      )}
                      disabled={isDisable}
                    />
                  </div>
                  {valueGetter(
                    `${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "Flags_ReturnCall"
                    )}`
                  ) && (
                    <div className="cc-field">
                      <label className="cc-label cc-custom-mb">
                        Call returned
                      </label>
                      <Field
                        name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "CallReturned"
                        )}`}
                        component={CCSwitch}
                        checked={valueGetter(
                          `${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "CallReturned"
                          )}`
                        )}
                        disabled={isDisable}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <div className="cc-tool-tip-wrapper cc-group-label">
                    <label className="cc-label">Requestor</label>
                    {isFieldVisible(
                      uiControl?.BtnRequestedByAnonymous?.DisplayStatus
                    ) && (
                      <Button
                        fillMode="flat"
                        className="cc-icon-label-button cc-color-button"
                        onClick={(event) => {
                          event.preventDefault();
                          setAnonymousHandler();
                        }}
                        title={"Anonymous requestor"}
                        iconClass="fa fa-question-circle"
                        disabled={isDisable}
                      />
                    )}
                    {isFieldVisible(
                      uiControl?.BtnRequestedByMe?.DisplayStatus
                    ) && (
                      <Button
                        fillMode="flat"
                        className="cc-icon-label-button"
                        onClick={(event) => {
                          event.preventDefault();
                          setMeAsRequestorHandler();
                        }}
                        title={"Set me as requestor"}
                        iconClass="fas fa-user cc-label-field-danger"
                        disabled={isDisable}
                      />
                    )}
                  </div>
                  {!isExternalPerson && (
                    <Field
                      name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                        "RequestedBy_SiteUser"
                      )}`}
                      nameDisplay="DisplayName"
                      placeholder="Select requestor"
                      component={InputPickerSearch}
                      onChange={handleSelectRequestor}
                      validator={isAnonymous ? undefined : requiredValidator}
                      options={renderOptionRequestor(requestorTypeEnum!)}
                      value={
                        isAnonymous
                          ? "Anonymous"
                          : uiControl?.LitRequestedBy.Value ??
                            valueGetter(
                              `${nameOfEventMapObj("Event")}.${nameOfEvent(
                                "RequestedBy_SiteUser"
                              )}`
                            )
                      }
                      disabled={
                        isDisable ||
                        isAnonymous ||
                        !valueGetter(
                          `${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "RequestorType_ID"
                          )}`
                        )
                      }
                    />
                  )}
                  {isExternalPerson && (
                    <Field
                      uniqueKey="CRMSRequestedBy"
                      name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                        "RequestedBy"
                      )}.Contact`}
                      component={ContactPicker}
                      onChange={handleSelectRequestorExternal}
                      validator={requiredValidator}
                      onError={(error: any) => {
                        pushNotification({
                          type: "error",
                          title: "Pick contact errors",
                          description: error,
                          autoClose: false,
                        });
                      }}
                      placeholder={"Select requestor"}
                      disabled={isDisable}
                      disabledButton={isDisable}
                      displayValue={uiControl?.LitRequestedBy.Value}
                      removeDisplayValue={() => {
                        if (uiControl) {
                          prevRequestorDisplayName.current =
                            uiControl.LitRequestedBy.Value;
                          uiControl.LitRequestedBy.Value =
                            crmsEvent?.RequestedBy.Contact.DisplayName;
                        }
                      }}
                      restoreDisplayValue={() => {
                        if (
                          uiControl &&
                          isHTML(prevRequestorDisplayName.current) &&
                          valueGetter(
                            `${nameOfEventMapObj("Event")}.${nameOfEvent(
                              "RequestedBy"
                            )}.Contact`
                          )
                        ) {
                          uiControl.LitRequestedBy.Value =
                            prevRequestorDisplayName.current;
                        }
                      }}
                    />
                  )}
                </div>
                <div className="cc-form-cols-3 col-span-2">
                  <div className="cc-field">
                    <CCValueField
                      label={
                        crmsEvent?.RequestedBy ? "Organisation" : "Position"
                      }
                      value={
                        <span
                          title={uiControl?.LitRequestedByPosition.Value}
                          className="cc-text-ellipsis"
                        >
                          {uiControl?.LitRequestedByPosition.Value}
                        </span>
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCValueField
                      label="Address"
                      value={
                        isHTML(uiControl?.LblRequestedByAddress.Value)
                          ? sanitizeHtml(uiControl?.LblRequestedByAddress.Value)
                          : uiControl?.LblRequestedByAddress.Value
                      }
                    />
                  </div>
                  <div className="cc-field">
                    <CCValueField
                      label="Contact"
                      value={
                        isExternalPerson
                          ? renderContact(
                              valueGetter(
                                `${nameOfEventMapObj("Event")}.${nameOfEvent(
                                  "RequestedBy"
                                )}.Contact`
                              )
                            )
                          : renderContact(
                              valueGetter(
                                `${nameOfEventMapObj("Event")}.${nameOfEvent(
                                  "RequestedBy_SiteUser"
                                )}`
                              )
                            )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr className="cc-divider" />

            {/* ROW 5 */}
            <div className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel
                    title="On behalf of"
                    isMandatory={isOnbehalfOfRequire}
                  />
                  {isInternalOnBehalfOf ? (
                    // Internal
                    <Field
                      name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                        "OnBehalfOf_SiteUser"
                      )}`}
                      nameDisplay="DisplayName"
                      placeholder="Select on behalf of"
                      component={InputPickerSearch}
                      onChange={handleSelectOnBehalfOf}
                      options={renderOptionRequestor(
                        CS_RequestorType.InternalPerson
                      )}
                      value={valueGetter(
                        `${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "OnBehalfOf_SiteUser"
                        )}`
                      )}
                      disabled={isDisable}
                      disabledButton={isDisable}
                      validator={
                        isOnbehalfOfRequire ? requiredValidator : undefined
                      }
                    />
                  ) : (
                    // External
                    <Field
                      uniqueKey="CRMSOnBehalfOf"
                      name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                        "OnBehalfOf"
                      )}.Contact`}
                      component={ContactPicker}
                      onChange={handleSelectOnBehalfOfExternal}
                      onError={(error: any) => {
                        pushNotification({
                          type: "error",
                          title: "Pick contact errors",
                          description: error,
                          autoClose: false,
                        });
                      }}
                      placeholder="Select on behalf of"
                      disabled={isDisable}
                      disabledButton={isDisable}
                      displayValue={uiControl?.LitOnBehalfOf.Value}
                      removeDisplayValue={() => {
                        if (uiControl) {
                          prevOnBehalfOfDisplayName.current =
                            uiControl.LitOnBehalfOf.Value;
                          uiControl.LitOnBehalfOf.Value =
                            crmsEvent?.OnBehalfOf?.Contact?.DisplayName;
                        }
                      }}
                      restoreDisplayValue={() => {
                        if (
                          uiControl &&
                          isHTML(prevOnBehalfOfDisplayName.current) &&
                          valueGetter(
                            `${nameOfEventMapObj("Event")}.${nameOfEvent(
                              "OnBehalfOf"
                            )}.Contact`
                          )
                        ) {
                          uiControl.LitOnBehalfOf.Value =
                            prevOnBehalfOfDisplayName.current;
                        }
                      }}
                      validator={
                        isOnbehalfOfRequire ? requiredValidator : undefined
                      }
                    />
                  )}
                </div>
                <div className="cc-form-cols-3 col-span-2">
                  <div className="cc-field">
                    <CCValueField
                      label={
                        crmsEvent?.OnBehalfOf ? "Organisation" : "Position"
                      }
                      value={
                        <span
                          title={uiControl?.LitOnBehalfOfPosition.Value}
                          className="cc-text-ellipsis"
                        >
                          {uiControl?.LitOnBehalfOfPosition.Value}
                        </span>
                      }
                    />
                  </div>

                  <div className="cc-field">
                    <CCValueField
                      label="Address"
                      value={
                        isHTML(uiControl?.LitOnBehalfAddress.Value)
                          ? sanitizeHtml(uiControl?.LitOnBehalfAddress.Value)
                          : uiControl?.LitOnBehalfAddress.Value
                      }
                    />
                  </div>

                  <div className="cc-field">
                    <CCValueField
                      label="Contact"
                      value={
                        isInternalOnBehalfOf
                          ? renderContact(
                              valueGetter(
                                `${nameOfEventMapObj("Event")}.${nameOfEvent(
                                  "OnBehalfOf_SiteUser"
                                )}`
                              )
                            )
                          : renderContact(
                              valueGetter(
                                `${nameOfEventMapObj("Event")}.${nameOfEvent(
                                  "OnBehalfOf"
                                )}`
                              )?.Contact
                            )
                      }
                    />
                  </div>
                </div>

                {isEnableComplaintHandling &&
                  crmsEvent?.ServiceStandard?.EnableTierLookup && (
                    <div className="cc-field">
                      <CCLabel title="Tier" />
                      <Field
                        name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "Tier_ID"
                        )}`}
                        textField="Value"
                        dataItemKey="Key"
                        data={crmsEventLovs?.Tiers ?? []}
                        component={CCSearchComboBox}
                        onChange={handleChangeTier}
                        placeholder="Select tier"
                        value={getDropdownValue(
                          valueGetter(
                            `${nameOfEventMapObj("Event")}.${nameOfEvent(
                              "Tier_ID"
                            )}`
                          ),
                          crmsEventLovs?.Tiers ?? [],
                          "Key"
                        )}
                        disabled={isDisable}
                      />
                    </div>
                  )}
              </div>
            </div>
            <hr className="cc-divider" />

            <div className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Location type" isMandatory />

                  <Field
                    name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                      "LocationType_ID"
                    )}`}
                    placeholder="Select location type"
                    textField="Description"
                    dataItemKey="CustomerService_LookupTable_Id"
                    component={CCDropDownList}
                    validator={requiredValidator}
                    data={
                      uiControl?.RblLocationType.Dataset ??
                      crmsEventLovs?.LocationType
                    }
                    value={getDropdownValue(
                      valueGetter(
                        `${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "LocationType_ID"
                        )}`
                      ),
                      crmsEventLovs?.LocationType ?? [],
                      "CustomerService_LookupTable_Id"
                    )}
                    onChange={handleLocationTypeChange}
                    disabled={isDisable}
                  />
                </div>
                {isFieldVisible(uiControl?.DivLocation.DisplayStatus) && (
                  <>
                    {isShowPropertyAddress && (
                      <div className="cc-field">
                        <CCLabel title="Property address" isMandatory />
                        <Field
                          uniqueKey="CRMSAddressLocationPicker"
                          name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "IssueLocation"
                          )}.LocationAddress`}
                          component={PropertyDetail}
                          initialData={initialBuildAddress}
                          placeholder={"Select property address"}
                          formRenderProps={formRenderProps}
                          validator={requiredValidator}
                          onSubmit={handleAddressLocationChange}
                          isSearchPropertyAddresses={true}
                          onError={(error: any) => {
                            pushNotification({
                              type: "error",
                              title: "Select location fail",
                              description: error,
                              autoClose: false,
                            });
                          }}
                          disabled={
                            locationType ===
                              CS_IssueLocationType.SameAsReporter || isDisable
                          }
                          disabledButton={
                            locationType ===
                              CS_IssueLocationType.SameAsReporter || isDisable
                          }
                          value={uiControl?.LblLocation?.Value || ""}
                          onButtonClick={handleOpenDialog}
                          onChangeEventHandler={handleAddressLocationChange}
                          updateSaveTriggers={() => {
                            updateSaveTriggers(
                              EventUpdateTriggers.UpdateIssueLocation
                            );
                          }}
                          removeDisplayValue={() => {
                            if (uiControl) {
                              prevAddressLocationDisplayValue.current =
                                uiControl.LblLocation?.Value;
                              uiControl.LblLocation.Value = displaySiteAddress;
                            }
                          }}
                          restoreDisplayValue={() => {
                            if (
                              uiControl &&
                              isHTML(prevAddressLocationDisplayValue.current) &&
                              valueGetter("Event.IssueLocation")
                            ) {
                              uiControl.LblLocation.Value =
                                prevAddressLocationDisplayValue.current;
                            }
                          }}
                        />
                      </div>
                    )}
                    {isShowLocationPremises && (
                      <div className="cc-field">
                        <CCLabel title="Location" isMandatory />
                        <Field
                          uniqueKey="CRMSPremisesLocation"
                          name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "IssueLocation"
                          )}.LocationAddress`}
                          nameDisplay="TradingName"
                          placeholder="Select location"
                          component={InputPickerSearch}
                          onChange={handleSelectLocationPremises}
                          validator={requiredValidator}
                          options={renderOptionIssueLocation(locationType)}
                          disabled={isDisable}
                          value={uiControl?.LblLocation?.Value || ""}
                          removeDisplayValue={() => {
                            if (uiControl) {
                              prevPremisesLocationDisplayValue.current =
                                uiControl.LblLocation?.Value;
                              uiControl.LblLocation.Value = displaySiteAddress;
                            }
                          }}
                          restoreDisplayValue={() => {
                            if (
                              uiControl &&
                              isHTML(
                                prevPremisesLocationDisplayValue.current
                              ) &&
                              valueGetter(
                                `${nameOfEventMapObj("Event")}.${nameOfEvent(
                                  "IssueLocation"
                                )}`
                              )
                            ) {
                              uiControl.LblLocation.Value =
                                prevPremisesLocationDisplayValue.current;
                            }
                          }}
                        />
                      </div>
                    )}
                    {isShowWasteWaterScheme && (
                      <div className="cc-field">
                        <CCLabel title="Location" isMandatory />
                        <Field
                          name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "IssueLocation"
                          )}.LocationAddress`}
                          placeholder="Select location"
                          component={InputPickerSearch}
                          onChange={handleSelectLocationWasteWaterScheme}
                          validator={requiredValidator}
                          options={renderOptionIssueLocation(locationType)}
                          disabled={isDisable}
                          value={uiControl?.LblLocation?.Value || ""}
                        />
                      </div>
                    )}
                    {isShowLocationRegister && (
                      <div className="cc-field">
                        <CCLabel title="Location" isMandatory />
                        <Field
                          name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "IssueLocation"
                          )}.LocationAddress`}
                          nameDisplay="Name"
                          placeholder="Select location"
                          component={InputPickerSearch}
                          onChange={handleSelectLocationRegister}
                          validator={requiredValidator}
                          options={renderOptionIssueLocation(locationType)}
                          disabled={isDisable}
                          value={uiControl?.LblLocation?.Value || ""}
                        />
                      </div>
                    )}
                    {isShowLocationNonProperty && (
                      <div className="cc-field">
                        <CCLabel title="Location" isMandatory />
                        <Field
                          uniqueKey="CRMSLocationNonProperty"
                          name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "IssueLocation"
                          )}.LocationAddress`}
                          component={AddressPickerSearch}
                          isCRMS={true}
                          isHaveStreetLookup
                          formRenderProps={formRenderProps}
                          initialData={initialBuildAddress}
                          placeholder="Select location"
                          onButtonClick={handleOpenDialog}
                          onBeforeOpenDialog={handleOpenDialog}
                          onChangeEventHandler={handleAddressLocationChange}
                          onSubmit={handleAddressLocationChange}
                          onClose={() => {
                            setIsAutoShowLocationDialog(false);
                          }}
                          validator={requiredValidator}
                          isAutoShowDialog={isAutoShowLocationDialog}
                          value={uiControl?.LblLocation?.Value || ""}
                          disabled={
                            locationType ===
                              CS_IssueLocationType.SameAsReporter || isDisable
                          }
                          disabledButton={
                            locationType ===
                              CS_IssueLocationType.SameAsReporter || isDisable
                          }
                          onError={(error: any) => {
                            pushNotification({
                              type: "error",
                              title: "Select location fail",
                              description: error,
                              autoClose: false,
                            });
                          }}
                          removeDisplayValue={() => {
                            if (uiControl) {
                              prevNonPropertyLocationDisplayValue.current =
                                uiControl.LblLocation?.Value;
                              uiControl.LblLocation.Value = displaySiteAddress;
                            }
                          }}
                          restoreDisplayValue={() => {
                            if (
                              uiControl &&
                              isHTML(
                                prevNonPropertyLocationDisplayValue.current
                              ) &&
                              valueGetter("Event.IssueLocation")
                            ) {
                              uiControl.LblLocation.Value =
                                prevNonPropertyLocationDisplayValue.current;
                            }
                          }}
                        />
                      </div>
                    )}
                    {isShowLocationStreet && (
                      <div className="cc-field">
                        <CCLabel title="Location" isMandatory />
                        <Field
                          uniqueKey="CRMSLocationStreet"
                          name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "IssueLocation"
                          )}.LocationAddress`}
                          component={AddressPickerSearch}
                          isHaveStreetLookup
                          isCRMS={true}
                          formRenderProps={formRenderProps}
                          initialData={initialBuildAddress}
                          placeholder="Select location"
                          onButtonClick={handleOpenDialog}
                          onChangeEventHandler={handleAddressLocationChange}
                          onSubmit={handleAddressLocationChange}
                          validator={requiredValidator}
                          value={uiControl?.LblLocation?.Value || ""}
                          disabled={
                            locationType ===
                              CS_IssueLocationType.SameAsReporter || isDisable
                          }
                          disabledButton={
                            locationType ===
                              CS_IssueLocationType.SameAsReporter || isDisable
                          }
                          onError={(error: any) => {
                            pushNotification({
                              type: "error",
                              title: "Select location fail",
                              description: error,
                              autoClose: false,
                            });
                          }}
                          removeDisplayValue={() => {
                            if (uiControl) {
                              prevStreetLocationDisplayValue.current =
                                uiControl.LblLocation?.Value;
                              uiControl.LblLocation.Value = displaySiteAddress;
                            }
                          }}
                          restoreDisplayValue={() => {
                            if (
                              uiControl &&
                              isHTML(prevStreetLocationDisplayValue.current) &&
                              valueGetter("Event.IssueLocation")
                            ) {
                              uiControl.LblLocation.Value =
                                prevStreetLocationDisplayValue.current;
                            }
                          }}
                        />
                      </div>
                    )}
                    {isShowLocationAsset && (
                      <div className="cc-field">
                        <CCLabel title="Location" isMandatory />
                        <Field
                          name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                            "Asset"
                          )}`}
                          component={AssetPickerSearch}
                          formRenderProps={formRenderProps}
                          value={assetLocation || ""}
                          placeholder="Select location"
                          onChangeEventHandler={handleChangeAsset}
                          validator={requiredValidatorAsset}
                          onButtonClick={handleOpenAssetDialog}
                          initialData={initialAssetData}
                          initialDataSubmit={asset}
                          onError={(error: any) => {
                            if (error) {
                              pushNotification({
                                type: "error",
                                title: "Select location fail",
                                description: error,
                                autoClose: false,
                              });
                            }
                          }}
                          disabled={isDisable}
                          disabledButton={isDisable}
                        />
                      </div>
                    )}
                    {isFieldVisible(
                      uiControl?.DivLocationAssessment.DisplayStatus
                    ) && (
                      <div className="cc-field">
                        <CCValueField
                          label="Detail"
                          value={renderAddressDetails}
                        />
                      </div>
                    )}
                  </>
                )}

                {isShowLocationAsset && (
                  <div className="cc-field">
                    <CCValueField
                      label="Asset name"
                      value={
                        issueLocationAddress
                          ? valueGetter(
                              `${nameOfEventMapObj("Event")}.${nameOfEvent(
                                "Asset"
                              )}.${nameOfEventAsset("SiteName")}`
                            )
                          : ""
                      }
                    />
                  </div>
                )}

                {(isShowTobaccoPremises || isShowHealthPremises) &&
                  uiControl?.LblLocation?.Value && (
                    <div className="cc-field">
                      <label className="cc-label">
                        Health premises details
                      </label>
                      <div className="cc-label-field cc-link-field">
                        <a
                          href={`${siteBaseUrl}/${getManageHubURL(
                            RECORDTYPE.HealthManager_Premises,
                            premisesId
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cc-grid-link-text cc-label-field"
                        >
                          {uiControl?.LblPremisesRegistrationNumber?.Value}
                          <i className="fas fa-external-link-alt fa-xs cc-external-link-icon-sharepoint " />
                        </a>
                      </div>
                    </div>
                  )}

                {isShowWasteWaterScheme && uiControl?.LblLocation?.Value && (
                  <div className="cc-field">
                    <label className="cc-label">
                      Wastewater scheme details
                    </label>
                    <div className="cc-label-field cc-link-field">
                      <a
                        href={`${siteBaseUrl}/${getManageHubURL(
                          RECORDTYPE.WW_Scheme,
                          wasteWaterSchemeId
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cc-grid-link-text cc-label-field"
                      >
                        {wasteWaterSchemeId}
                        <i className="fas fa-external-link-alt fa-xs cc-external-link-icon-sharepoint " />
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {isFieldVisible(uiControl?.DivBinLookupDetails?.DisplayStatus) && (
              <>
                <hr className="cc-divider" />
                <div className="cc-field-group">
                  <div className="cc-form-cols-1">
                    <div className="cc-field">
                      <CCLabel title="Bin size" isMandatory />
                      <Field
                        name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "BinSizeIDStr"
                        )}`}
                        textField={nameOfKeyValuePacket("Value")}
                        dataItemKey={nameOfKeyValuePacket("Key")}
                        data={uiControl?.DdlBinSizeLookup?.Dataset ?? []}
                        component={CCMultiSelectDropdown}
                        disabled={isDisable}
                        onChange={(event: MultiSelectChangeEvent) => {
                          let value = event?.value;
                          if (value?.length) {
                            value = value.map(
                              (item: IKeyValuePacket) => item.Key
                            );
                            value = value.join(",");
                          }
                          onChange(
                            `${nameOfEventMapObj("Event")}.${nameOfEvent(
                              "BinSizeIDStr"
                            )}`,
                            {
                              value: value,
                            }
                          );
                        }}
                        value={getMultipleSelectValue(
                          convertStrIdsToList(binSizes, ","),
                          uiControl?.DdlBinSizeLookup?.Dataset ?? [],
                          nameOfKeyValuePacket("Key")
                        )}
                        validator={requiredValidator}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Bin type" isMandatory />
                      <Field
                        name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "BinTypeIDStr"
                        )}`}
                        textField={nameOfKeyValuePacket("Value")}
                        dataItemKey={nameOfKeyValuePacket("Key")}
                        data={uiControl?.DdlBinTypeLookup?.Dataset ?? []}
                        component={CCMultiSelectDropdown}
                        disabled={isDisable}
                        onChange={(event: MultiSelectChangeEvent) => {
                          let value = event?.value;
                          if (value?.length) {
                            value = value.map(
                              (item: IKeyValuePacket) => item.Key
                            );
                            value = value.join(",");
                          }
                          onChange(
                            `${nameOfEventMapObj("Event")}.${nameOfEvent(
                              "BinTypeIDStr"
                            )}`,
                            {
                              value: value,
                            }
                          );
                        }}
                        value={getMultipleSelectValue(
                          convertStrIdsToList(binTypes, ","),
                          uiControl?.DdlBinTypeLookup?.Dataset ?? [],
                          nameOfKeyValuePacket("Key")
                        )}
                        validator={requiredValidator}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {eventFormObj?.ServiceStandard?.Flag_ReferenceNumberAdditional && (
              <>
                <hr className="cc-divider" />
                <div className="cc-field-group">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel
                        title={`${
                          !isNil(
                            eventFormObj?.ServiceStandard
                              ?.ReferenceNumberAdditionalLabel
                          ) &&
                          eventFormObj?.ServiceStandard
                            ?.ReferenceNumberAdditionalLabel !== ""
                            ? eventFormObj?.ServiceStandard
                                ?.ReferenceNumberAdditionalLabel
                            : "Reference number"
                        }`}
                      />
                      <Field
                        name={`${nameOfEventMapObj("Event")}.${nameOfEvent(
                          "Event_ReferenceNoAdditional"
                        )}`}
                        placeholder="Reference number additional"
                        component={CCInput}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {eventFormObj?.ServiceStandard?.IsAnimalsLookUpEnabled &&
              uiControl?.LitAnimalNoView && (
                <AnimalRegistrationsLookup
                  formRenderProps={formRenderProps}
                  isDisabled={isDisable}
                />
              )}

            {/* Additional Details */}
            <br />
            <div className="cc-custom-sub-panel-bar">
              <TabTable
                recordType={RECORDTYPE.CUSTOMERSERVICE_Event}
                initialPanels={listPanelBar}
              />
            </div>
          </div>
        </FormElement>
      </>
    );
  }
);
