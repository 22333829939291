import { getContact } from "@app/core/contacts/_id/api";
import { Svc_Contact } from "@app/core/contacts/_id/model";
import { getDirectDebitAuthorisationById } from "@app/products/direct-debit/authorisations/[id]/api";
import { TitleDDAuthorisationManagePage } from "@app/products/direct-debit/authorisations/[id]/constant";
import { ExistManageDirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/[id]/existed/_index";
import { DirectDebitAuthorisation } from "@app/products/direct-debit/authorisations/model";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { EWatchStatusLoading } from "@common/stores/route/model";
import { routeDataStoreInstance } from "@common/stores/route/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { merge } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

const ManageDirectDebitAuthorisation = () => {
  //#region STORE ========/
  const history = useHistory();
  const { setInitialDataForms, isLoadingForm } = useFlexibleFormStore();
  const { pushNotificationPortal } = useNotificationPortalStore();
  //#endregion STORE =====/

  //#region HOOK ========/
  const { cancelToken } = useCancelRequest();
  //#endregion HOOK =====/

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  //#endregion Variable =====/

  const manageDirectDebitAuthorisationSlider = useFlexibleFetchData({
    alias: "manageDirectDebitAuthorisationSlider",
    slides: [
      {
        fetch: () => getDirectDebitAuthorisationById(+id, cancelToken()),
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<DirectDebitAuthorisation> = dataFromApi;
          const ddAuthorisationFormData = response?.data;
          //#region save data to store ========/
          setInitialDataForms({
            GeneralForm: ddAuthorisationFormData,
          });
          //#endregion save data to store =====/
        },
        extractParamForNextAPI: ({ dataFromApi }) => {
          return dataFromApi;
        },
      },
      {
        fetch: ({ dataFromExtractParam }) => {
          const response: APIResponse<DirectDebitAuthorisation> =
            dataFromExtractParam;

          return getContact(response?.data?.Contact_ID!);
        },
        handleSuccess: ({ dataFromApi, dataFromExtractParam }) => {
          const response: APIResponse<DirectDebitAuthorisation> =
            dataFromExtractParam;

          const contact: Svc_Contact = dataFromApi?.data;
          const ddAccountFormData = response.data;
          const ddAccountFormDataAddOptions = merge({}, ddAccountFormData, {
            CCFormOptions: {
              contact: contact,
              initialContact: contact,
            },
          });
          setInitialDataForms({
            GeneralForm: ddAccountFormDataAddOptions,
          });
        },
        handleError: () => {
          pushNotificationPortal(
            {
              title: `Loading name failed.`,
              type: "error",
              autoClose: false,
            },
            true
          );
        },
      },
    ],
  });

  useEffect(() => {
    manageDirectDebitAuthorisationSlider.fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <>
      <Loading
        isLoading={
          manageDirectDebitAuthorisationSlider.isFetching ||
          isLoadingForm ||
          routeDataStoreInstance.isLoadingFor(
            EWatchStatusLoading.IsLoadingPermission
          )
        }
        isFullScreen
      />
      <FormNavigation title={TitleDDAuthorisationManagePage} />
      {manageDirectDebitAuthorisationSlider.errorComponent ? (
        manageDirectDebitAuthorisationSlider.errorComponent
      ) : (
        <ExistManageDirectDebitAuthorisation />
      )}
    </>
  );
};

export default observer(ManageDirectDebitAuthorisation);
