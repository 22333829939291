import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { useAnimalPoundRegisterStore } from "@app/products/animals/pound-register/[id]/store";
import { CRMS_ROUTE } from "@app/products/crms/[id]/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { useIsNew } from "@common/hooks/useIsNew";
import { formatDisplayValue } from "@common/utils/formatting";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

export const PoundRegisterDetailsTab = observer(() => {
  const isNew = useIsNew();
  const { lastSelectedId } = useCCProductListViewStore();
  const {
    poundRegisterInfo,
    loadDetails,
    isLoadingDetails,
    responseDetailLoadError,
  } = useAnimalPoundRegisterStore();

  const { id } = useParams<{ id: string }>();
  const poundRegisterId: number = id ? parseInt(id) : lastSelectedId;

  useEffect(() => {
    if (!isNew && !id) loadDetails(poundRegisterId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poundRegisterId]);

  if (isLoadingDetails) return <Loading isLoading={isLoadingDetails} />;

  if (responseDetailLoadError) {
    return (
      <CCLoadFailed
        responseError={responseDetailLoadError}
        onReload={() => {
          poundRegisterId && loadDetails(poundRegisterId);
        }}
      />
    );
  }

  if (!poundRegisterInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Reference number:"}
        value={poundRegisterInfo.RegisterNo}
        style={() => {
          let style: React.CSSProperties = {};
          style.maxWidth = "65%";
          return style;
        }}
        primary
      />
      <ProductReferenceBlock>
        <ProductReferenceRow
          title={"Date entered:"}
          value={formatDisplayValue(
            poundRegisterInfo?.DateEntered,
            DATE_FORMAT.DATE
          )}
        />
        {poundRegisterInfo?.CRMSEvent_ID && poundRegisterInfo?.CRMSEventNo && (
          <ProductReferenceRow
            title={"Event number:"}
            value={getCRMSEventPath(
              poundRegisterInfo.CRMSEvent_ID,
              poundRegisterInfo.CRMSEventNo
            )}
          />
        )}
        <ProductReferenceRow
          title="Impounded by:"
          value={poundRegisterInfo?.ImpoundedByCouncilOfficer?.DisplayName}
        />
        <ProductReferenceRow
          title="Pick up location:"
          value={poundRegisterInfo?.PickUpLocation?.Formatted_SingleLine}
        />
        {poundRegisterInfo?.AnimalsRegistration_ID &&
          poundRegisterInfo?.AnimalsRegistration_No && (
            <ProductReferenceRow
              title={"Animal number:"}
              value={getAnimalPath(
                poundRegisterInfo.AnimalsRegistration_ID,
                poundRegisterInfo.AnimalsRegistration_No
              )}
            />
          )}
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});

const getAnimalPath = (animalsRegistrationID: number, animalRef: string) => {
  return (
    <Link
      className="cc-detail-tab-link"
      to={{
        pathname: `${ANIMALS_ROUTE}/${animalsRegistrationID}`,
      }}
    >
      {animalRef}
    </Link>
  );
};

const getCRMSEventPath = (crmsEventID: number, crmsEventRef: string) => {
  return (
    <Link
      className="cc-detail-tab-link"
      to={{
        pathname: `${CRMS_ROUTE}/${crmsEventID}`,
      }}
    >
      {crmsEventRef}
    </Link>
  );
};
