import { postDirectDebitAccount } from "@app/products/direct-debit/accounts/[id]/api";
import { ChangeStatusSlider } from "@app/products/direct-debit/accounts/[id]/constant";
import { checkIsInactiveDirectDebitAccount } from "@app/products/direct-debit/accounts/[id]/util";
import { DirectDebitAccount } from "@app/products/direct-debit/accounts/model";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

interface ISaveButtonDirectDebitAccountProps {
  isDisabled?: boolean;
}
export const SaveButtonDirectDebitAccount = observer(
  ({ isDisabled = false }: ISaveButtonDirectDebitAccountProps) => {
    //#region STORE ========/
    const { submitFormGetData, setIsLoadingForm } = useFlexibleFormStore();

    //#endregion STORE =====/

    //#region HOOK ========/
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { cancelToken } = useCancelRequest();
    //#endregion HOOK =====/

    const saveButtonDirectDebitAccountSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const directDebitAccount: DirectDebitAccount = initialData;
            return postDirectDebitAccount(directDebitAccount, cancelToken());
          },
          handleSuccess: async () => {
            await fetchApiByAlias(ChangeStatusSlider);
            pushNotificationPortal({
              title: "Direct debit account saved successfully.",
              type: "success",
            });
          },
          handleError: (error) => {
            const { errorFromApi, initialData } = error;
            pushNotificationPortal({
              title: initialData?.errorMsg,
              type: "error",
              description: errorFromApi.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    useEffect(() => {
      setIsLoadingForm(saveButtonDirectDebitAccountSlider.isFetching);
    }, [saveButtonDirectDebitAccountSlider.isFetching, setIsLoadingForm]);

    const handleClickButton = async () => {
      const directDebitAccount: DirectDebitAccount = await submitFormGetData(
        "GeneralForm"
      );

      if (directDebitAccount) {
        await saveButtonDirectDebitAccountSlider.fetchApi({
          initialData: directDebitAccount,
        });
      }
    };

    return (
      <CCNavButton
        title="Save"
        onClick={handleClickButton}
        disabled={
          checkIsInactiveDirectDebitAccount() ||
          saveButtonDirectDebitAccountSlider.isFetching ||
          isDisabled
        }
      />
    );
  }
);
