import { searchNameItemRender } from "@app/products/property/certificates/[id]/components/form-steps/components/config";
import { OfficerAndOfficerRegion } from "@app/products/property/components/fields/officer-and-officer-region/_index";
import { SearchNameCombobox } from "@app/products/property/components/fields/search-name/_index";
import { loadNameDetail } from "@app/products/property/components/fields/search-name/api";
import { DTO_Entity_Name_Address } from "@app/products/property/components/fields/search-name/model";
import {
  handleSearchNameProcess,
  ISearchNameProcessParams,
} from "@app/products/property/components/fields/search-name/util";
import { SearchOptions } from "@app/products/property/components/fields/search-options/_index";
import { DTO_Workflow_CreateContact } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/model";
import { ResponseMessage } from "@app/products/property/model";
import { isSuccessResponse } from "@common/apis/util";
import { ContactLookahead_JSON } from "@common/models/contact";
import { requiredValidator } from "@common/utils/field-validators";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
import { processDefaultValue } from "@app/products/property/certificates/[id]/components/form-steps/new-certificate/components/form-elements/details/util";

export const NewCertificateDetailFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    localNotificationRef,
    options = {
      isReadOnly: false,
      isDisabled: false,
      isLLS: false,
      setIsLoadingInStep: () => {},
      isLoadingInStep: false,
      isToBeApprovalMode: false,
    },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const {
      isReadOnly,
      isDisabled,
      isLLS,
      setIsLoadingInStep,
      isLoadingInStep,
      isToBeApprovalMode,
    } = options;

    const handleLoadNameDetail = async (value: any, entityId: number) => {
      const response = await loadNameDetail(entityId);
      if (isSuccessResponse(response)) {
        const contactDetail = response?.data?.Entity_Name_Address;
        onChange(nameOf("ApplicantName"), {
          value: { Name: contactDetail?.Name },
        });
        onChange(nameOf("ApplicantId"), {
          value: contactDetail?.Entity_Name_Address_Id,
        });
        onChange(nameOf("Address"), { value: contactDetail?.Address });
        onChange(nameOf("Locality"), { value: contactDetail?.Locality });
        onChange(nameOf("State"), { value: contactDetail?.State });
        onChange(nameOf("Postcode"), { value: contactDetail?.Postcode });
        onChange(nameOf("Email"), {
          value: value?.Email,
        });
        onChange(nameOf("Country"), { value: contactDetail?.Country });
      } else {
        localNotificationRef?.current?.pushNotification({
          title: response?.error ?? "Name could not be added",
          type: "error",
          autoClose: false,
        });
      }
    };

    const handleAddName = async (value: any) => {
      const dataProcess: ISearchNameProcessParams = {
        data: value,
        handleLoadNameDetail: async (data: DTO_Entity_Name_Address) => {
          return await handleLoadNameDetail(value, data?.Entity_Id);
        },
        notificationAction: {
          canNotAddName: (response) => {
            localNotificationRef?.current?.pushNotification({
              title: response?.error ?? "Name could not be added",
              type: "error",
              autoClose: false,
            });
          },
        },
        setLoading: (isLoading: boolean) => {
          return setIsLoadingInStep(isLoading);
        },
      };

      await handleSearchNameProcess(dataProcess);
    };

    const handleSubmitNewContactDialog = (
      _payload: DTO_Workflow_CreateContact,
      responseMessage: ResponseMessage
    ) => {
      if (responseMessage?.IsSuccess && responseMessage?.Component_ID) {
        handleAddName({
          Entity_Id: responseMessage.Component_ID + "",
        } as ContactLookahead_JSON);
      } else {
        localNotificationRef?.current?.pushNotification({
          title: responseMessage?.ErrorMessage ?? "Name could not be added",
          type: "error",
          autoClose: false,
        });
      }
    };

    return (
      <>
        <section className="cc-field-group">
          {isToBeApprovalMode && (
            <>
              <CCLabel title="Workflow instructions" />
              <Field
                name={nameOf("Instructions")}
                rows={3}
                component={CCTextArea}
                placeholder="Workflow instructions"
                readOnly
              />
            </>
          )}
        </section>
        <OfficerAndOfficerRegion
          formRenderProps={formRenderProps}
          nameOf={nameOf}
          isDisabled={isDisabled}
        />
        <section className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">
                Reference
                {isLLS && <CCTooltip type="validator" position="right" />}
              </label>
              <Field
                name={nameOf("Reference")}
                readOnly={isReadOnly}
                component={CCInput}
                validator={isLLS && !isReadOnly ? requiredValidator : undefined}
                placeholder="Reference"
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <SearchNameCombobox
                name={nameOf("ApplicantName")}
                title="Applicant name"
                placeholder="Applicant name"
                onSelectionChange={(values: ContactLookahead_JSON) => {
                  handleAddName(values);
                }}
                disabled={isReadOnly}
                selectedSearchOptions={getFieldValue("SearchOptions")}
                onError={(error: any) => {
                  localNotificationRef?.current?.pushNotification({
                    type: "error",
                    title: error ?? "Search name error",
                    autoClose: false,
                  });
                }}
                isClearSearchValue={false}
                defaultValue={processDefaultValue(
                  getFieldValue("ApplicantName")
                )}
                onSubmitNewContactDialog={handleSubmitNewContactDialog}
                itemRender={searchNameItemRender}
              />
            </div>
            <SearchOptions
              formRenderProps={formRenderProps}
              nameOf={nameOf}
              isDisable={isReadOnly}
            />
            <div className="cc-field">
              <CCLabel title={"Address"} isLoading={isLoadingInStep} />
              <Field
                name={nameOf("Address")}
                readOnly
                component={CCInput}
                placeholder="Address"
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Locality"} isLoading={isLoadingInStep} />
              <Field
                name={nameOf("Locality")}
                readOnly
                component={CCInput}
                placeholder="Locality"
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"State"} isLoading={isLoadingInStep} />
              <Field
                name={nameOf("State")}
                readOnly
                component={CCInput}
                placeholder="State"
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Postcode"} isLoading={isLoadingInStep} />
              <Field
                name={nameOf("Postcode")}
                readOnly
                component={CCInput}
                placeholder="Postcode"
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Email address"} isLoading={isLoadingInStep} />
              <Field
                name={nameOf("Email")}
                readOnly
                component={CCInput}
                placeholder="Email address"
              />
            </div>
            <div className="cc-field">
              <CCLabel title={"Country"} isLoading={isLoadingInStep} />
              <Field
                name={nameOf("Country")}
                readOnly
                component={CCInput}
                placeholder="Country"
              />
            </div>
          </div>
        </section>
      </>
    );
  }
);
