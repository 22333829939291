import { ConditionsDialog } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/components/form-element/components/conditions-section/components/button/add-condition/conditions-dialog/_index";
import { Svc_Condition } from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { Button } from "@progress/kendo-react-buttons";
import React, { useState } from "react";

interface IAddConditionButtonProps {
  onSubmit: (conditions: Svc_Condition[]) => void;
}

export const AddConditionButton = ({ onSubmit }: IAddConditionButtonProps) => {
  const [isShowConditions, setIsShowConditions] = useState(false);

  return (
    <>
      {isShowConditions && (
        <ConditionsDialog
          productType={PRODUCT_TYPE_NUMBER.LLPermits}
          onClosePopup={() => {
            setIsShowConditions(false);
          }}
          onSubmit={onSubmit}
        />
      )}
      <Button
        type="button"
        iconClass="fas fa-plus"
        title="Add conditions"
        onClick={() => {
          setIsShowConditions(true);
        }}
      />
    </>
  );
};
