import {
  formatNames,
  getFormatMask,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/util";
import { DTO_Entity_Details } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { DTO_TitleLrsOwner } from "@app/products/property/changes-of-ownership/notice-of-sales/components/form-steps/update-notice-of-sale/model";
import { nameOfFactory } from "@common/utils/common";

export const nameOfTitleLrsOwner = nameOfFactory<DTO_TitleLrsOwner>();

/**
 * Formats owner names and addresses based on the format setting.
 *
 * Processes a list of owners, separates them into individuals and organizations,
 * formats their names, combines them, and appends the address of the first owner.
 * The combined name is limited to 100 characters.
 *
 * @param {DTO_TitleLrsOwner[]} lrsOwners - List of owners to format.
 * @param {string} [ownerNameFormat] - Format for the owners' names (got from setting).
 * @returns {string} - Combined formatted names and address.
 */
export const formatNameAndAddress = (
  lrsOwners: DTO_TitleLrsOwner[],
  ownerNameFormat?: string
) => {
  const organisationList: DTO_TitleLrsOwner[] = [];
  const personList: DTO_Entity_Details[] = [];
  lrsOwners.forEach((owner: DTO_TitleLrsOwner) => {
    // Get list of owners which are individuals
    if (owner.Family_Name || owner.Given_Name) {
      // Convert to object of DTO_Entity_Details to pass to 'formatNames' function of COO workflow
      const entityDetail = {
        Surname: owner.Family_Name,
        GivenName: owner.Given_Name,
      } as DTO_Entity_Details;
      personList.push(entityDetail);
    }
    // Get list of owners which are organisations
    else {
      organisationList.push(owner);
    }
  });

  // Get format mask from the setting
  const ownerNameFormatMask = getFormatMask(ownerNameFormat ?? "");

  // Format organisation names
  const formattedOrganisationName = organisationList.reduce(
    (accumulator, currentItem, index) => {
      const corporateMiscellaneousName =
        currentItem?.Corporate_Miscellaneous_Name ?? "";
      // Join formatted names with '&'
      if (index === 0) {
        return corporateMiscellaneousName; // For the first item, no need for '&'
      } else {
        return accumulator + " & " + corporateMiscellaneousName; // Join with '&'
      }
    },
    ""
  );

  // Format individual names
  const formattedPersonName = formatNames(personList, ownerNameFormatMask);

  // Combine organisation and individual names and
  const formattedName = combineNames(
    formattedOrganisationName,
    formattedPersonName
  );

  // Concat the address of the first owner to the formatted names
  // Name is limited to 100 characters
  const formattedNamesAndAddress =
    formattedName.substring(0, 100) + `\r\n` + (lrsOwners?.[0]?.Address || "");

  return formattedNamesAndAddress;
};

/**
 * Combines the formatted names of organizations and individuals.
 *
 * If both are given, joins them with '&'.
 * If only one is given, returns it.
 * If neither is given, returns an empty string.
 *
 * @param {string} formattedOrganisationName - The formatted name of the organizations.
 * @param {string} formattedPersonName - The formatted name of the individuals.
 * @returns {string} - The combined name.
 */
function combineNames(
  formattedOrganisationName: string,
  formattedPersonName: string
) {
  // Check if both names exist, if so, concatenate with '&'
  if (formattedPersonName && formattedOrganisationName) {
    return `${formattedOrganisationName} & ${formattedPersonName} `;
  }
  // If only formattedOrganisationName exists, return it
  else if (formattedOrganisationName) {
    return formattedOrganisationName;
  }
  // If only formattedPersonName exists, return it
  else if (formattedPersonName) {
    return formattedPersonName;
  }
  // If neither exists, return an empty string
  else {
    return "";
  }
}
