import { useHelperServicesStore } from "@common/helper/store";
import { ServiceName } from "@common/models/settings";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { CCLogo } from "@components/cc-logo/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./_index.scss";
interface IErrorDialog {
  error: any;
  title?: string;
}

const ErrorDialog = ({
  error,
  title = "Something went wrong...",
}: IErrorDialog) => {
  const [loading, setLoading] = useState(false);
  const { headerBarLogo } = useCommonCoreStore();
  const { isShowHelper, setIsShowHelper } = useHelperServicesStore();
  const {
    allSetting: { helperService },
  } = useGlobalStore();
  return (
    <div className="cc-error-dialog">
      {headerBarLogo && (
        <CCLogo
          className="cc-logo-error-dialog"
          src={headerBarLogo}
          alt="OpenOffice Logo"
          onLoad={() => setLoading(true)}
        />
      )}
      {loading && (
        <>
          <h2>{title}</h2>
          <p>
            {`Please report this error to your system administrator (to take a screen
        shot, press the 'Print Scrn' button on your keyboard and paste into an
        email)`}
          </p>

          <span
            onClick={() => {
              if (helperService?.serviceName === ServiceName.Freshdesk) {
                isShowHelper
                  ? window.HideFreshWidget()
                  : window.OpenFreshWidget();
              }
              setIsShowHelper(!isShowHelper);
            }}
          >
            <i className="fal fa-question-circle"></i> More Help
          </span>
          <a href="/">Click here to return to the home page</a>
          <div className="cc-error-notification">
            <i className="fas fa-exclamation"></i>
            <div>{error.toString()}</div>
          </div>
        </>
      )}
    </div>
  );
};
export default observer(ErrorDialog);
