import { odataCoreGetOrgUnit } from "@app/core/components/common/utils";
import { Svc_ContactDetails } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { ServiceStandardView } from "@app/products/crms/[id]/components/forms/components/reference-sidebar/details/model";
import { CS_ServiceStandardType } from "@app/products/crms/[id]/model";
import { IManager } from "@app/products/crms/service-standards/[id]/components/forms/components/child-screens/general/components/form-element/model";
import { ServiceStandardPickListPacket } from "@app/products/crms/service-standards/[id]/model";
import { defaultGroupBy } from "@app/products/crms/util";
import { IOptionInputPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { OrgUnit } from "@components/cc-pick-org-units/model";

const nameOfOrgUnit = nameOfFactory<OrgUnit>();
const nameOfManager = nameOfFactory<IManager>();

export const colOrgUnit: IColumnFields[] = [
  { title: "Hierarchy", field: nameOfOrgUnit("Hierarchy") },
  { title: "Org Level", field: nameOfOrgUnit("OrgLevel") },
  { title: "Org Unit Name", field: nameOfOrgUnit("OrgUnit_Name") },
  { title: "Manager", field: nameOfOrgUnit("Manager") },
];

export const colManager: IColumnFields[] = [
  { title: "Name", field: nameOfManager("Name") },
  { title: "Position", field: nameOfManager("Position") },
  { title: "Org Structure", field: nameOfManager("OrgStructure") },
  { title: "Email", field: nameOfManager("Email") },
  { title: "Work Phone", field: nameOfManager("Phone") },
  { title: "Mobile", field: nameOfManager("Mobile") },
];

export const colContactManagerColumns: IColumnFields[] = [
  { title: "Name", field: nameOfManager("Name") },
  { title: "Position", field: nameOfManager("Position") },
  { title: "Org Structure", field: nameOfManager("Name") },
  { title: "Email", field: nameOfManager("Email") },
  { title: "Work Phone", field: nameOfManager("Phone") },
  { title: "Home Phone", field: nameOfManager("Phone") },
  { title: "Mobile", field: nameOfManager("Mobile") },
];

export const dataServiceStandardType = [
  {
    label: "Request",
    value: CS_ServiceStandardType.Request,
  },
  {
    label: "Incident",
    value: CS_ServiceStandardType.Incident,
  },
];

export const dataActionPersonAllocation = [
  {
    label: "Staff",
    value: true,
  },
  {
    label: "Roster",
    value: false,
  },
];

export const dataCalculateCompletionTargetInDaysOrHours = [
  {
    label: "Days",
    value: true,
  },
  {
    label: "Hours",
    value: false,
  },
];

export const optionOrgUnits: IOptionInputPicker = {
  dialog: {
    titleHeader: "Pick Org Units",
    maxWidth: "60%",
  },
  boxSearch: {
    colSearch: nameOfOrgUnit("Hierarchy"),
    colShowComboboxSearch: [
      {
        title: "Hierarchy",
        field: nameOfOrgUnit("Hierarchy"),
      },
    ],
  },
  grid: {
    columnFields: colOrgUnit,
    primaryField: nameOfOrgUnit("ID"),
    dataUrl: odataCoreGetOrgUnit(false, null),
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOfOrgUnit("Hierarchy"),
          dir: "asc",
        },
      ],
    },
  },
};

const nameOfActionOfficer = nameOfFactory<Svc_ContactDetails>();
export const renderOptionActionOfficer = (dataUrl?: string) => {
  const optionActionOfficer: IOptionInputPicker = {
    dialog: {
      titleHeader: "Contact Manager",
      maxWidth: "60%",
    },
    boxSearch: {
      colSearch: nameOfActionOfficer("DisplayName"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOfActionOfficer("DisplayName"),
        },
      ],
    },
    grid: {
      columnFields: [
        { title: "Name", field: nameOfActionOfficer("DisplayName") },
        { title: "Position", field: nameOfActionOfficer("Position") },
        { title: "Org Structure", field: nameOfActionOfficer("OrgStructure") },
        { title: "Email", field: nameOfActionOfficer("Email") },
        { title: "Work Phone", field: nameOfActionOfficer("WorkPhone") },
        { title: "Home Phone", field: nameOfActionOfficer("HomePhone") },
        { title: "Mobile", field: nameOfActionOfficer("Mobile") },
      ],
      primaryField: nameOfActionOfficer("Id"),
      dataUrl: dataUrl,
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [
          {
            field: nameOfActionOfficer("DisplayName"),
            dir: "asc",
          },
        ],
      },
    },
  };

  return optionActionOfficer;
};

const nameOfPickServiceStandard = nameOfFactory<ServiceStandardView>();
export const renderOptionPickServiceStandard = (
  columnFieldsConfig: IColumnFields[],
  serviceStandardCategoryLevels: number,
  dataFilter?: ServiceStandardPickListPacket
) => {
  const optionPickServiceStandard: IOptionInputPicker = {
    dialog: {
      titleHeader: "Please Select The Category - Subcategory",
      maxWidth: "60%",
    },
    boxSearch: {
      colSearch: nameOfPickServiceStandard("Name"),
      colShowComboboxSearch: [
        {
          title: "Name",
          field: nameOfPickServiceStandard("Name"),
        },
      ],
    },
    grid: {
      columnFields: columnFieldsConfig,
      primaryField: nameOfPickServiceStandard("ID"),
      dataUrl: "odata/crms/internal/servicestandard/GetPickServiceStandardList",
      selectableMode: "single",
      isAutoHiddenPager: false,
      itemPerPage: 10,
      state: {
        sort: [
          {
            field: nameOfPickServiceStandard("Name"),
            dir: "asc",
          },
        ],
        filter: dataFilter
          ? {
              filters: [
                {
                  field: nameOfPickServiceStandard("DisplayAsInternetChoice"),
                  operator: "eq",
                  value: dataFilter?.Flag_InternetChoice ? "Yes" : "No",
                },
              ],
              logic: "and",
            }
          : undefined,
        group: defaultGroupBy(serviceStandardCategoryLevels),
      },
    },
  };

  return optionPickServiceStandard;
};
