import { ListItemProps } from "@progress/kendo-react-dropdowns";
import React from "react";

export const searchNameItemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
): React.ReactNode => {
  const itemChildren = <span>{itemProps?.dataItem?.EntityId_Name}</span>;
  return React.cloneElement(li, li.props, itemChildren);
};
