import { hmRoute } from "@app/products/hm/route";
import { CCActionBarNavDropdown } from "@components/cc-list-view-action-bar/components/nav-dropdown/_index";
import React from "react";

interface IHMActionBarNavDropdownProps {
  category: string;
}

export const HMActionBarNavDropdown = ({
  category,
}: IHMActionBarNavDropdownProps) => {
  return (
    <CCActionBarNavDropdown
      category={category}
      product={hmRoute.path}
      route={hmRoute}
    />
  );
};
