import { getDisplayTextWithDashes } from "@common/utils/common";

export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `HM - Premises ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow.ID, selectedRow.PremisesType]);
};
