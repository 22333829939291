import { getAssetList } from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/asset-picker-search/api";
import {
  colAssetPickerSearch,
  searchAssetConfig,
} from "@app/products/crms/[id]/components/forms/components/child-screens/general/components/asset-picker-search/config";
import { Asset } from "@app/products/crms/[id]/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { IColumnFields } from "@components/cc-grid/model";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ComboBoxProps,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { FormRenderProps } from "@progress/kendo-react-form";
import axios, { CancelTokenSource } from "axios";
import { isArray, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useMemo, useRef, useState } from "react";

interface IAssetPickerSearchInputProps extends ComboBoxProps {
  textField?: string;
  onError?: (value?: any) => void;
  formRenderProps: FormRenderProps;
  onChange: (data: any) => void;
  inputValueDisplayName?: string;
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
}

export const AssetPickerSearchInput = observer(
  (props: IAssetPickerSearchInputProps) => {
    const {
      isLoading = false,
      setIsLoading,
      className,
      textField,
      value,
      onError,
      onChange,
      inputValueDisplayName,
      formRenderProps,
      ...others
    } = props;

    const [data, setData] = useState<Asset[]>();
    const refTimeOut = useRef<NodeJS.Timeout | null>(null);
    const cancelRequest = useRef<CancelTokenSource>();

    const inputData = useMemo(() => {
      return data;
    }, [data]);

    const inputDisplayName = useMemo(() => {
      if (isNil(value)) return "";
      if (textField && !isArray(value) && textField in value)
        return value[textField];
      return value ?? "";
    }, [value, textField]);

    const handleSearch = (event: ComboBoxFilterChangeEvent) => {
      const searchText = event.filter.value;
      if (searchText.length < searchAssetConfig.minCharacters) return;

      if (refTimeOut.current) clearTimeout(refTimeOut.current);
      refTimeOut.current = setTimeout(() => {
        cancelRequest.current?.cancel("CancelSearching");
        cancelRequest.current = axios.CancelToken.source();
        setIsLoading(true);

        getAssetList(searchText, cancelRequest.current).then((response) => {
          if (response.status === 406) {
            if (onError)
              onError(
                "Cannot open server requested by the login. Client's IP address is not allowed to access the server."
              );
          } else if (isSuccessResponse(response) && response?.data) {
            setData(response?.data?.value ?? []);
          } else {
            if (onError && response.status !== APIResponseStatus.CANCELLED) {
              onError(response.error);
            }
          }
          setIsLoading(false);
        });
      }, searchAssetConfig.typeSpeed);
    };

    const handleOnChange = async (event: ComboBoxChangeEvent) => {
      const newValue = event.value;
      onChange(newValue ?? null);
    };

    return (
      <ComboBox
        {...others}
        filterable
        suggest
        data={inputData}
        loading={isLoading}
        onFilterChange={handleSearch}
        itemRender={(
          li: ReactElement<HTMLLIElement>,
          itemProps: ListItemProps
        ) => ItemRender(li, itemProps)}
        value={inputValueDisplayName ?? inputDisplayName}
        header={
          <div className="cc-search-header">
            {colAssetPickerSearch.map((col: IColumnFields) => (
              <div key={col.field} style={{ width: col.width }}>
                {col.title}
              </div>
            ))}
          </div>
        }
        onChange={handleOnChange}
        popupSettings={{ className: "cc-site-address-search" }}
      />
    );
  }
);

const ItemRender = (
  li: ReactElement<HTMLLIElement>,
  itemProps: ListItemProps
) => {
  const { dataItem } = itemProps;
  const itemChildren = (
    <div className="cc-search-item">
      {colAssetPickerSearch.map((col: IColumnFields) => (
        <div key={col.field} style={{ width: col.width }}>
          {dataItem[col.field] ?? ""}
        </div>
      ))}
    </div>
  );
  return React.cloneElement(li, li.props, itemChildren);
};
