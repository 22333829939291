import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyCollections } from "@app/products/property/components/child-screen/collections/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { AddLeakageButton } from "@app/products/property/meters/[id]/components/action-bar/buttons/add-leakage/_index";
import { AddMasterSubMeterButton } from "@app/products/property/meters/[id]/components/action-bar/buttons/add-master-sub-meter/_index";
import { AddMemoButton } from "@app/products/property/meters/[id]/components/action-bar/buttons/add-memo/_index";
import { AssociateButton } from "@app/products/property/meters/[id]/components/action-bar/buttons/associate/_index";
import { DisassociateMeterButton } from "@app/products/property/meters/[id]/components/action-bar/buttons/disassociate-meter/_index";
import { EditLeakageButton } from "@app/products/property/meters/[id]/components/action-bar/buttons/edit-leakage/_index";
import { EditMeterAssociationButton } from "@app/products/property/meters/[id]/components/action-bar/buttons/edit-meter-association/_index";
import { InstallationButton } from "@app/products/property/meters/[id]/components/action-bar/buttons/installation/_index";
import { RemoveLeakageButton } from "@app/products/property/meters/[id]/components/action-bar/buttons/remove-leakage/_index";
import { RemoveMasterSubMeterButton } from "@app/products/property/meters/[id]/components/action-bar/buttons/remove-master-sub-meter/_index";
import { MeterForm } from "@app/products/property/meters/[id]/components/child-screens/general/components/_index";
import { MeterInstallation } from "@app/products/property/meters/[id]/components/child-screens/installation/_index";
import { MeterLeakage } from "@app/products/property/meters/[id]/components/child-screens/leakage/_index";
import { MeterMemos } from "@app/products/property/meters/[id]/components/child-screens/memos/_index";
import { MetersReadings } from "@app/products/property/meters/[id]/components/child-screens/reading/_index";
import { MeterTransactions } from "@app/products/property/meters/[id]/components/child-screens/transactions/_index";
import { MetersUsage } from "@app/products/property/meters/[id]/components/child-screens/usage/_index";
import { MetersUserFields } from "@app/products/property/meters/[id]/components/child-screens/user-field/_index";
import { ManualReadingButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/manual-reading/_index";
import { ModifyInstallationButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/modify-installation/_index";
import { ModifyMeterButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/modify-meter/_index";
import { RemoveMeterButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/remove-meter/_index";
import { ReplaceMeterButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/replace-meter/_index";
import { ReverseVoidButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/reverse-void-readings/_index";
import { UnVerifyReadingButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/un-verify-readings/components/dialogs/confirm-un-verify-readings/_index";
import { VerifyReadingButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/verify-readings/_index";
import { VoidReadingsButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/void-readings/_index";
import { MeterContactsTab } from "@app/products/property/meters/[id]/components/reference-sidebar/contact/_index";
import { MeterDetailTab } from "@app/products/property/meters/[id]/components/reference-sidebar/detail/_index";
import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";
import { useMeterStore } from "@app/products/property/meters/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedMeter = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { pushNotification } = useCCAppNotificationStore();
  const { meter, loadMeter, isLoading, responseLoadError } = useMeterStore();
  const { id } = useParams<{ id: string }>();
  // Can use after for bookmark feature
  // const managePageUrl = window.location.pathname;
  // const getBookmarkDetail = useMemo(() => {
  //   const dynamicDetail =
  //     (meter?.MeterNumber ? meter?.MeterNumber + " - " : "") +
  //     (meter?.PropertyAddress ?? "");
  //   return `${dynamicDetail}`;
  // }, [meter]);
  // const bookmarkList: IBookmarkItem[] = [
  //   {
  //     detail: getBookmarkDetail,
  //     url: managePageUrl,
  //     displayName: `Property - Meters - ${id}`,
  //   },
  // ];

  const getFormTitle = () => {
    return `${meter?.MeterNumber} - ${meter?.PropertyAddress}`;
  };

  const listPanelBar: IPropertyPanelBar[] = [
    {
      title: "Memo",
      component: <MeterMemos />,
    },
    {
      title: "Leakage",
      component: <MeterLeakage />,
    },
    {
      title: "Transactions",
      component: <MeterTransactions />,
    },
    {
      title: "Collections",
      component: (
        <PropertyCollections
          componentId={parseInt(id)}
          componentNumber={eComponent.Meter}
        />
      ),
    },
    {
      title: "Readings",
      component: <MetersReadings />,
    },
    {
      title: "User Fields",
      component: <MetersUserFields />,
    },
    {
      title: "Installation",
      component: <MeterInstallation />,
    },
    { title: "Usage", component: <MetersUsage /> },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Meter" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadMeter(+id)}
        />
      ) : (
        <>
          <FormTitle title={getFormTitle()} badge={meter?.Status} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Leakage"} type="dropdown">
                  <AddLeakageButton />
                  <EditLeakageButton />
                  <RemoveLeakageButton />
                </CCNavButton>
                <CCNavButton title={"Maintenance"} type="dropdown">
                  <AssociateButton />
                  <DisassociateMeterButton />
                  <EditMeterAssociationButton />
                  <InstallationButton />
                  <ModifyInstallationButton
                    onModify={() => {
                      pushNotification({
                        title: "Modify meter installation successfully",
                        type: "success",
                      });
                    }}
                  />
                  <RemoveMeterButton />
                  <ReplaceMeterButton
                    meterInfo={meter}
                    onReplace={(data: IMeterFormGeneral) => {
                      pushNotification({
                        title: `Meter has been successfully replaced by meter - ${data.MeterNumber}`,
                        type: "success",
                      });
                    }}
                  />
                </CCNavButton>
                <CCNavButton title={"Master/sub meter"} type="dropdown">
                  <AddMasterSubMeterButton />
                  <RemoveMasterSubMeterButton />
                </CCNavButton>
                <ModifyMeterButton />
                <CCNavButton title={"Readings"} type="dropdown">
                  <ManualReadingButton />
                  <ReverseVoidButton />
                  <UnVerifyReadingButton />
                  <VerifyReadingButton />
                  <VoidReadingsButton />
                </CCNavButton>
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
              <CCNavButton title={"Add"} type="sub">
                <AddMemoButton />
              </CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,

              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"ePlanning - development"} />
                <CCNavButton title={"Portal links"} />
                <CCNavButton title={"Certification"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              //  Holding now but can use later
              // <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
              <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />

              <div className="cc-manage-form-body">
                {meter && (
                  <CCGeneralPanel component={<MeterForm isNew={false} />} />
                )}
                <PropertyPanelBar initialPanels={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <MeterDetailTab />,
                    },
                    { title: "Map", component: <MapSTripTab /> },
                    {
                      title: "Related",
                    },
                    {
                      title: "Contacts",
                      component: <MeterContactsTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Meter}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
