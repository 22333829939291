import { fieldName } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/names/config";
import { DTO_ChangeOfOwnership_RetainNames } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/property/model";
import {
  COONotRetainOwnersTypes,
  eChangeOfOwnershipType,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/form-elements/type/model";
import {
  DTO_COO_Type,
  DTO_ChangeOfOwnership_LOVs,
  DTO_Entity_Details,
  DTO_Role,
  EKeysOfSteps,
  RoleId,
  RoleTypes,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { loadNameDetail } from "@app/products/property/components/fields/search-name/api";
import { DTO_Entity_Name_Address } from "@app/products/property/components/fields/search-name/model";
import { isSuccessResponse } from "@common/apis/util";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { trimSpace } from "@common/utils/formatting";
import { cloneDeep, includes, isArray, isNil, map, uniqBy } from "lodash";

const nameOfNames = nameOfFactory<DTO_Entity_Details>();

export const validatorFullPercentages = (values: any) => {
  if (!values || getTotalPercentages(values) !== 100)
    return "Please complete form.";
  return undefined;
};

export const validatorArrayIsNotEmpty = (values: any) => {
  if (values?.length) return undefined;
  return "Please complete form.";
};

export const validatorRoleIsRequired = (values: any) => {
  return includes(map(values, nameOfNames("Role_Name")), undefined)
    ? "A contact must be assigned a role."
    : "";
};

const getTotalPercentages = (values: any) => {
  if (!isArray(values)) return;
  return parseFloat(
    values
      .reduce(
        (total: number, name: any) =>
          name[fieldName.Percentage]
            ? total + name[fieldName.Percentage]
            : total,

        0
      )
      .toFixed(4)
  );
};

enum EPartialName {
  Title = "<Title>",
  Initials = "<Initials>",
  Initial = "<Initial>",
  Surname = "<SURNAME>",
  FirstName = "<First Name>",
  GivenNames = "<Given Names>",
}

const getInitialsName = (name?: string) => {
  if (!name) return "";
  const words = name.trim().split(/\s+/);
  const initials = words.map((word) => word.charAt(0).toUpperCase()).join(" ");
  return initials;
};

/**
 * Retrieves the format mask from the given setting option.
 *
 * @param settingOption - The setting option string.
 * `<Title> <Initials> <SURNAME> eg. Mr J D & Mrs S SMITH`
 * @returns The format mask extracted from the setting option.
 * `<Title> <Initials> <SURNAME>`
 */
export const getFormatMask = (settingOption: string) => {
  const lastIndexOfFormatString = settingOption.indexOf(" eg");
  return settingOption.substring(0, lastIndexOfFormatString);
};
/**
 * Retrieves the surname from a DTO
 *
 * If the surname and given name are not provided, the contact is assumed to be an organisation
 * and the Name is returned as the name of the organisation.
 * In the contrary, the contact is assumed to be an individual and the Surname is returned.
 *
 * @param name
 * @returns
 */
const getSurname = (name: DTO_Entity_Details) => {
  return !name?.Surname && !name?.GivenName ? name?.Name : name?.Surname;
};

/**
 * Formats a name based on the provided format mask and entity details.
 * @param name - The entity details containing the name information.
 * @param formatMask - The format mask to be applied to the name.
 * @returns The formatted name.
 */
const formatOneName = (name: DTO_Entity_Details, formatMask: string) => {
  const connectionTwoFormatIndex = formatMask.indexOf("> & <");
  const newFormatMask = formatMask.substring(0, connectionTwoFormatIndex + 1);
  let formattedName = connectionTwoFormatIndex > 0 ? newFormatMask : formatMask;
  formattedName = formattedName.replace(EPartialName.Title, name?.Title ?? "");
  const givenNameWords = name.GivenName?.split(" ") ?? "";
  const firstName = givenNameWords?.[0] ?? "";
  const lastName = name.GivenName?.replace(firstName, "") ?? "";
  formattedName = formattedName.replace(EPartialName.FirstName, firstName);
  formattedName = formattedName.replace(
    EPartialName.Initial,
    getInitialsName(lastName)
  );
  formattedName = formattedName.replace(
    EPartialName.Initials,
    getInitialsName(name?.GivenName ?? "")
  );
  formattedName = formattedName.replace(
    EPartialName.GivenNames,
    name?.GivenName ?? ""
  );
  formattedName = formattedName.replace(
    EPartialName.Surname,
    getSurname(name)?.toUpperCase() ?? ""
  );
  return trimSpace(formattedName.trim());
};

/**
 * Formats an array of names according to the specified format mask.
 *
 * @param names - The array of names to be formatted.
 * @param formatMask - The format mask to be applied to the names.
 * @returns The formatted names as a string.
 */
export const formatNames = (
  names: DTO_Entity_Details[],
  formatMask: string
) => {
  let formattedNames = "";
  const initNames = cloneDeep(names);
  for (let i = 0; i < initNames.length; i++) {
    formattedNames += formatOneName(initNames[i], formatMask);
    const surnameInLastRegex = new RegExp(`${getSurname(initNames[i])}$`, "gi");
    const surnameInFirstRegex = new RegExp(
      `^${getSurname(initNames[i])},{0,1}`,
      "gi"
    );
    const lastIndexOfSurname = formatMask.lastIndexOf(EPartialName.Surname);
    for (let j = i + 1; j < initNames.length; j++) {
      if (
        getSurname(initNames[i])?.toLowerCase() ===
        getSurname(initNames[j])?.toLowerCase()
      ) {
        switch (lastIndexOfSurname) {
          case 0:
            formattedNames = formatOneName(initNames[j], formatMask).replace(
              surnameInFirstRegex,
              `${formattedNames} &`
            );
            break;
          case formatMask.length - EPartialName.Surname.length:
            formattedNames = formattedNames.replace(
              surnameInLastRegex,
              `& ${formatOneName(initNames[j], formatMask)}`
            );
            break;
          default:
            formattedNames += " & " + formatOneName(initNames[j], formatMask);
        }
        initNames.splice(j, 1);
        j--;
      }
    }
    if (i !== initNames.length - 1) {
      formattedNames += " & ";
    }
  }
  return formattedNames;
};

export const addUniqueKey = (names: DTO_Entity_Details[]) => {
  return names.map((name) => {
    return {
      ...name,
      [fieldName.UniqueKey]: getUUID(),
    };
  });
};

export const getSelectedType = (valueGetter: (field: string) => any) =>
  valueGetter(`${EKeysOfSteps.Type}._option.Type`) as DTO_COO_Type;

const getGroupedRoles = (lovsData: DTO_ChangeOfOwnership_LOVs | undefined) => {
  const ownerRoles =
    lovsData?.Roles?.filter((roleItem: DTO_Role) => roleItem?.IsOwner)?.map(
      (roleItem: DTO_Role) => roleItem?.Role_Name
    ) ?? [];
  const picRoles =
    lovsData?.Roles?.filter((roleItem: DTO_Role) => roleItem?.IsPICRole)?.map(
      (roleItem: DTO_Role) => roleItem?.Role_Name
    ) ?? [];
  const ratepayerRoles =
    lovsData?.Roles?.filter((roleItem: DTO_Role) => roleItem?.IsRatepayer)?.map(
      (roleItem: DTO_Role) => roleItem?.Role_Name
    ) ?? [];
  const duplicateRoles =
    lovsData?.Roles?.filter(
      (roleItem: DTO_Role) =>
        roleItem?.Role_Type_Id === RoleTypes.Duplicate_Owner
    )?.map((roleItem: DTO_Role) => roleItem?.Role_Name) ?? [];
  const associatedRoles =
    lovsData?.Roles?.filter(
      (roleItem: DTO_Role) => roleItem?.IsAssociatedName
    )?.map((roleItem: DTO_Role) => roleItem?.Role_Name) ?? [];
  return {
    OwnerAndPIC: [...ownerRoles, ...picRoles],
    Owner: ownerRoles,
    Ratepayer: ratepayerRoles,
    Duplicate: duplicateRoles,
    Associated: associatedRoles,
  };
};

export const getRolesBySelectedType = (
  selectedType: DTO_COO_Type,
  lovsData?: DTO_ChangeOfOwnership_LOVs
) => {
  return (
    lovsData?.Roles?.filter((roleItem: DTO_Role) => {
      return (
        (selectedType?.COOT_Display_Owner_Roles && roleItem?.IsOwner) ||
        (selectedType?.COOT_Display_Associated_Names &&
          roleItem?.IsAssociatedName) ||
        (selectedType?.COOT_Display_RatePayer_Roles && roleItem?.IsRatepayer) ||
        (selectedType?.COOT_Display_PIC_Roles && roleItem?.IsPICRole) ||
        (selectedType?.COOT_Display_Register_Roles &&
          roleItem?.IsRegisterAccount)
      );
    }) ?? []
  );
};

export const setFormatNameAddressForStep = (
  step: EKeysOfSteps,
  contactDetail: DTO_Entity_Name_Address,
  onChange: (field: string, value: any) => void
) => {
  const changedFields = [
    fieldName.Name,
    fieldName.AttentionOf,
    fieldName.CareOf,
    fieldName.Address,
    fieldName.Locality,
    fieldName.State,
    fieldName.Country,
    fieldName.Postcode,
    fieldName.DPID,
    fieldName.FormattedNameAddress,
    fieldName.NoticeId,
  ];
  changedFields.forEach((field: string) => {
    onChange(`${step}.${field}`, {
      value: contactDetail?.[field as keyof DTO_Entity_Name_Address],
    });
  });
};

export const handleChangeFormatEachType = async (
  names: DTO_Entity_Details[],
  formatMask: string,
  step: EKeysOfSteps,
  valueGetter: (field: string) => any,
  onChange: (field: string, value: any) => void
) => {
  const namesFormatted = formatNames(names, formatMask);
  const firstEntityId: number = names?.[0]?.Entity_Id ?? 0;
  const formattedNamesAddressValue =
    namesFormatted + `\r\n` + (names?.[0]?.Address || "");
  //Set value for formatted text area
  onChange(`${step}.${fieldName.FormattedNameAddress}`, {
    value: formattedNamesAddressValue,
  });
  //Use first entityId to load contact detail formatted dialog
  const isNewFirstEntity =
    valueGetter(`${step}.${fieldName.FirstEntity}`) !== firstEntityId;
  if (!firstEntityId) {
    setFormatNameAddressForStep(
      step,
      {
        Formatted_Name_Address: formattedNamesAddressValue,
      } as DTO_Entity_Name_Address,
      onChange
    );
  } else if (isNewFirstEntity) {
    const response = await loadNameDetail(firstEntityId);
    if (isSuccessResponse(response)) {
      const contactDetail = response?.data?.Entity_Name_Address;
      setFormatNameAddressForStep(
        step,
        {
          ...contactDetail,
          Formatted_Name_Address: formattedNamesAddressValue,
        },
        onChange
      );
    }
  }
  //Set first entityId
  onChange(`${step}.${fieldName.FirstEntity}`, {
    value: firstEntityId,
  });
  //Set formatted
  onChange(`${step}.${fieldName.Name}`, { value: namesFormatted });
};

export const handleChangeNamesGrid = async (
  newNames: DTO_Entity_Details[],
  lovsData: DTO_ChangeOfOwnership_LOVs | undefined,
  valueGetter: (field: string) => any,
  onChange: (field: string, value: any) => void,
  setIsLoadingStep: (value: boolean) => void,
  isNeedToProcessFormat: boolean = true
) => {
  const groupedRoles = getGroupedRoles(lovsData);
  const ownerNames =
    newNames?.filter((nameItem: DTO_Entity_Details) =>
      groupedRoles.OwnerAndPIC.includes(nameItem?.Role_Name ?? "")
    ) ?? [];
  const ratePayerNames =
    newNames?.filter((nameItem: DTO_Entity_Details) =>
      groupedRoles.Ratepayer.includes(nameItem?.Role_Name ?? "")
    ) ?? [];
  const duplicateNames =
    newNames?.filter((nameItem: DTO_Entity_Details) =>
      groupedRoles.Duplicate.includes(nameItem?.Role_Name ?? "")
    ) ?? [];
  const associatedNames =
    newNames?.filter((nameItem: DTO_Entity_Details) =>
      groupedRoles.Associated.includes(nameItem?.Role_Name ?? "")
    ) ?? [];

  //Set value for each DTO names step
  onChange(EKeysOfSteps.NamesGridData, { value: newNames });
  onChange(`${EKeysOfSteps.Owners}.${fieldName.Names}`, {
    value: ownerNames,
  });
  onChange(`${EKeysOfSteps.RatePayers}.${fieldName.Names}`, {
    value: ratePayerNames,
  });
  onChange(`${EKeysOfSteps.DuplicateRatePayers}.${fieldName.Names}`, {
    value: duplicateNames,
  });
  onChange(EKeysOfSteps.AssociatedNames, {
    value: associatedNames,
  });

  if (!isNeedToProcessFormat) return;

  const ownerNameFormatMask = getFormatMask(
    lovsData?.OwnerNameFormat?.Name ?? ""
  );
  const nameFormatMask = getFormatMask(lovsData?.NameFormat?.Name ?? "");

  setIsLoadingStep(true);
  await Promise.all([
    handleChangeFormatEachType(
      ownerNames,
      ownerNameFormatMask,
      EKeysOfSteps.Owners,
      valueGetter,
      onChange
    ),
    handleChangeFormatEachType(
      ratePayerNames,
      nameFormatMask,
      EKeysOfSteps.RatePayers,
      valueGetter,
      onChange
    ),
    handleChangeFormatEachType(
      duplicateNames,
      nameFormatMask,
      EKeysOfSteps.DuplicateRatePayers,
      valueGetter,
      onChange
    ),
  ]);
  setIsLoadingStep(false);
};

export const checkPercentageRoles = (
  role: string,
  lovsData: DTO_ChangeOfOwnership_LOVs | undefined
) => {
  const groupedRoles = getGroupedRoles(lovsData);
  return groupedRoles.Owner.includes(role);
};

export const calculatePercentage = (
  names: any[],
  lovsData: DTO_ChangeOfOwnership_LOVs | undefined
) => {
  let countPercentageRole = 0;
  let lastIndex = 0;

  names.forEach((item, index) => {
    if (checkPercentageRoles(item[fieldName.Role], lovsData)) {
      countPercentageRole += 1;
      lastIndex = index;
    }
  });
  let normalPercentage: number = parseFloat(
    (100 / countPercentageRole).toFixed(2)
  );
  let lastPercentage: number = normalPercentage;
  if (countPercentageRole > 0) {
    lastPercentage = parseFloat(
      (100 - (countPercentageRole - 1) * normalPercentage).toFixed(2)
    );
  }

  const newNames = names.map((item, index) => ({
    ...item,
    [fieldName.Percentage]: checkPercentageRoles(item[fieldName.Role], lovsData)
      ? index !== lastIndex
        ? normalPercentage
        : lastPercentage
      : null,
  }));

  return newNames;
};

export const handleSwitchRoleToOwner = (
  lovsData: DTO_ChangeOfOwnership_LOVs | undefined,
  names: DTO_Entity_Details[]
) => {
  const ownerRatepayerItems =
    lovsData?.Roles?.filter(
      (role: DTO_Role) => role.Role_Type_Id === RoleTypes.Owner_RatePayer
    ) || [];
  const ownerRatepayerRoles = map(
    ownerRatepayerItems,
    nameOfNames("Role_Name")
  );
  const ownerRole = lovsData?.Roles?.find(
    (role: DTO_Role) => role.Role_Id === RoleId.Owner
  );
  const newNames = names.map((name: DTO_Entity_Details) => {
    if (ownerRatepayerRoles.includes(name.Role_Name)) {
      return {
        ...name,
        Role_Name: ownerRole?.Role_Name ?? "Owner",
      };
    }
    return name;
  });
  return newNames;
};

export const handleRetainNames = (
  selectedType: DTO_COO_Type,
  lovsData: DTO_ChangeOfOwnership_LOVs | undefined,
  names: DTO_ChangeOfOwnership_RetainNames,
  valueGetter: (field: string) => any,
  onChange: (field: string, value: any) => void,
  setIsLoadingStep: (value: boolean) => void,
  isRetainRatePayers?: boolean,
  isRetainAssociatedNames?: boolean
) => {
  const selectedTypeID = selectedType?.Change_of_Ownership_Type ?? 0;
  let retainedOwners = COONotRetainOwnersTypes.includes(selectedTypeID)
    ? []
    : names?.OwnerDetails?.Contacts || [];
  if (selectedTypeID === eChangeOfOwnershipType.Full_Transfer_of_Occupier) {
    retainedOwners = handleSwitchRoleToOwner(lovsData, retainedOwners);
  }
  const retainedRatePayers = (
    !isNil(isRetainRatePayers)
      ? isRetainRatePayers
      : valueGetter(`${EKeysOfSteps.Details}.RetainRatePayerDetails`)
  )
    ? names?.RatePayerDetails?.Contacts || []
    : [];
  const retainedAssociatedNames = (
    !isNil(isRetainAssociatedNames)
      ? isRetainAssociatedNames
      : valueGetter(`${EKeysOfSteps.Details}.RetainAssociatedNames`)
  )
    ? names?.AssociatedNamesDetails || []
    : [];
  const allNames: DTO_Entity_Details[] = valueGetter(
    EKeysOfSteps.NamesGridData
  );
  let newNames = uniqBy(
    [
      ...allNames,
      ...retainedOwners,
      ...retainedRatePayers,
      ...retainedAssociatedNames,
    ],
    (name: DTO_Entity_Details) => `${name.Entity_Id}-${name.Role_Name}`
  );
  const ownerRatepayerItems =
    lovsData?.Roles?.filter(
      (role: DTO_Role) => role.Role_Type_Id === RoleTypes.Owner_RatePayer
    ) || [];
  const ownerRatepayerRoles = map(
    ownerRatepayerItems,
    nameOfNames("Role_Name")
  );
  const haveOwnerRatepayerRole = retainedRatePayers.some(
    (ratePayer: DTO_Entity_Details) =>
      ownerRatepayerRoles.includes(ratePayer.Role_Name)
  );

  if (retainedOwners.length > 0 || haveOwnerRatepayerRole) {
    newNames = calculatePercentage(newNames, lovsData);
  }
  newNames = addUniqueKey(newNames);
  handleChangeNamesGrid(
    newNames,
    lovsData,
    valueGetter,
    onChange,
    setIsLoadingStep
  );
};

export const handleRemoveRetainedAsociatedNames = (
  lovsData: DTO_ChangeOfOwnership_LOVs | undefined,
  valueGetter: (field: string) => any,
  onChange: (field: string, value: any) => void,
  setIsLoadingStep: (value: boolean) => void
) => {
  const allNames: DTO_Entity_Details[] = valueGetter(
    EKeysOfSteps.NamesGridData
  );
  const groupedRoles = getGroupedRoles(lovsData);
  const newNames = allNames.filter((name: DTO_Entity_Details) => {
    return (
      !name.isFromRetainNames ||
      !groupedRoles.Associated.includes(name.Role_Name ?? "")
    );
  });
  handleChangeNamesGrid(
    newNames,
    lovsData,
    valueGetter,
    onChange,
    setIsLoadingStep
  );
};

export const handleRemoveRetainedRatepayers = (
  lovsData: DTO_ChangeOfOwnership_LOVs | undefined,
  valueGetter: (field: string) => any,
  onChange: (field: string, value: any) => void,
  setIsLoadingStep: (value: boolean) => void
) => {
  const allNames: DTO_Entity_Details[] = valueGetter(
    EKeysOfSteps.NamesGridData
  );
  const groupedRoles = getGroupedRoles(lovsData);
  const newNames = allNames.filter((name: DTO_Entity_Details) => {
    return (
      !name.isFromRetainNames ||
      !groupedRoles.Ratepayer.includes(name.Role_Name ?? "")
    );
  });
  handleChangeNamesGrid(
    newNames,
    lovsData,
    valueGetter,
    onChange,
    setIsLoadingStep
  );
};

export const handleUpdateRetainedNames = (
  newRetainedNames: DTO_Entity_Details[],
  lovsData: DTO_ChangeOfOwnership_LOVs | undefined,
  valueGetter: (field: string) => any,
  onChange: (field: string, value: any) => void,
  setIsLoadingStep: (value: boolean) => void
) => {
  const allNames: DTO_Entity_Details[] = valueGetter(
    EKeysOfSteps.NamesGridData
  );
  let newNames = allNames.filter((name: DTO_Entity_Details) => {
    return (
      !name.isFromRetainNames ||
      newRetainedNames.some(
        (newName: DTO_Entity_Details) => newName.Entity_Id === name.Entity_Id
      )
    );
  });
  newNames = calculatePercentage(newNames, lovsData);
  newNames = addUniqueKey(newNames);
  handleChangeNamesGrid(
    newNames,
    lovsData,
    valueGetter,
    onChange,
    setIsLoadingStep
  );
};
