import { VO_Charge_Run_Assessment_Levy_Summary } from "@app/products/property/charge-and-notice-runs/charge-runs/[id]/components/child-screens/assessments/model";
import {
  CURRENCY_FORMAT,
  DECIMAL_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOfLevySummaryGrid =
  nameOfFactory<VO_Charge_Run_Assessment_Levy_Summary>();
export const colAssessmentLevySummary: IColumnFields[] = [
  {
    field: nameOfLevySummaryGrid("LevyName"),
    title: "Levy Name",
  },
  {
    field: nameOfLevySummaryGrid("Assessment_Group"),
    title: "Assessment Group",
  },
  {
    field: nameOfLevySummaryGrid("Differential_Rating_Category"),
    title: "Differential Rating Category",
  },
  {
    field: nameOfLevySummaryGrid("FA_Name"),
    title: "Financial Area",
  },
  {
    field: nameOfLevySummaryGrid("Rate"),
    title: "Rate",
    format: DECIMAL_FORMAT.DECIMAL1,
  },
  {
    field: nameOfLevySummaryGrid("LevyAmount"),
    title: "Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("Quantity"),
    title: "Quantity",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfLevySummaryGrid("Valuation"),
    title: "Valuation",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("BaseAmount"),
    title: "Base Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("RateByValuationAmount"),
    title: "Rate by Valuation Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("IncrementToMinimum"),
    title: "Increment to Minimum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("ReductionToMaximum"),
    title: "Reduction to Maximum",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("CappingAdjustment"),
    title: "Adjustment for Capping",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("PercentageOfRatingPeriod"),
    title: "Percentage of Rating Period",
    format: PERCENTAGE_FORMAT.PERCENTAGE,
  },
  {
    field: nameOfLevySummaryGrid("GrossLevyAmount"),
    title: "Gross Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("NettLevyAmount"),
    title: "Nett Levy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("RebateAmount"),
    title: "Rebates",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("UnitAmount"),
    title: "Unit Amount",
  },
  {
    field: nameOfLevySummaryGrid("AttributableValue"),
    title: "Attribute Value",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfLevySummaryGrid("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: nameOfLevySummaryGrid("Levy_Id"),
    title: "Levy ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
