import { PoundRegister_Status } from "@app/products/animals/pound-register/[id]/model";
import { useAnimalPoundRegisterStore } from "@app/products/animals/pound-register/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const PoundCloseRegistrationButton = observer(() => {
  const { poundRegisterInfo, isLoadingWorkflow, setIsShowWorkflowClosure } =
    useAnimalPoundRegisterStore();

  const isVisible = useMemo(() => {
    return poundRegisterInfo?.Status_ENUM === PoundRegister_Status.Impounded;
  }, [poundRegisterInfo]);

  return isVisible ? (
    <CCNavButton
      title={"Close registration"}
      onClick={() => setIsShowWorkflowClosure(true)}
      isLoading={isLoadingWorkflow}
      disabled={isLoadingWorkflow}
    />
  ) : null;
});
