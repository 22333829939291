import { DynamicQuestionPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/dynamic-question-picker/_index";
import { KeyWordsPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/keywords-picker/_index";
import { OfficerPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/_index";
import { getSiteUser } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/api";
import { IOfficer } from "@app/products/town-planning/ppr/[id]/components/input-picker/officer-picker/model";
import { isSuccessResponse } from "@common/apis/util";
import { NUMBER_FORMAT } from "@common/constants/common-format";
import { ChecklistType } from "@common/constants/enumerations";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ActionType } from "@common/models/actionType";
import { CoreKeyword } from "@common/models/coreKeyword";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { DynamicQuestionList_Light } from "@common/models/dynamicQuestion";
import { ActionTypePicker } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/_index";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { useActionTypesStore } from "@common/pages/settings/lookups/action-types/_id/store";
import { SystemFilter } from "@common/pages/settings/lookups/components/system-filter/_index";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  FieldProps,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";

interface ILookupActionTypeFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<ActionType>();
const nameOfKeyword = nameOfFactory<CoreKeyword>();
const nameOfDynamicQuestion = nameOfFactory<DynamicQuestionList_Light>();

export const LookupActionTypeFormElement = observer(
  ({ formRenderProps }: ILookupActionTypeFormElementProps) => {
    const { settings } = useCommonCoreStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { isInactive, defaultLocation, isShowWorkerLink } =
      useActionTypesStore();
    const { valueGetter, onChange } = formRenderProps;
    const isEnableTag = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_ActionType_Tags]
    );

    const handleOnChangeDefaultOfficer = (value: IOfficer, name: string) => {
      if (value) {
        getSiteUser(value.ID).then((response) => {
          if (isSuccessResponse(response)) {
            if (response.data) {
              onChange(name, {
                value: response.data,
              });
              onChange(`${name}_ID`, {
                value: response.data.Contact_ID,
              });
            }
          } else {
            pushNotification({
              title: "Officer is not found",
              type: "error",
              autoClose: false,
            });
          }
        });
      } else {
        onChange(name, {
          value: null,
        });
        onChange(`${name}_ID`, {
          value: null,
        });
      }
    };

    const handleOnPickNextAction = (nextAction: IRespActionType[]) => {
      if (nextAction) {
        onChange(nameOf("NextAction_IDs"), {
          value: nextAction.map((value) => value.ID),
        });
        onChange(nameOf("NextAction_CSV"), {
          value: nextAction
            .reduce((pre, cur) => pre + ", " + cur.Name, "")
            .slice(1)
            .trim(),
        });
      } else {
        onChange(nameOf("NextAction_IDs"), {
          value: null,
        });
        onChange(nameOf("NextAction_CSV"), {
          value: null,
        });
      }
    };

    const handleOnPickChecklist = (checklist: DynamicQuestionList_Light) => {
      onChange(nameOf("ActionTypeChecklist_ID"), {
        value: checklist.DynamicQuestionList_ID,
      });
      onChange(nameOf("ActionTypeChecklist_Name"), {
        value: checklist.DynamicQuestionList_Name,
      });
      onChange(nameOf("ActionTypeChecklist"), {
        value: checklist,
      });
    };

    const ActionTypeField = useCallback(
      (props: FieldProps) => {
        const { disabled, ...others } = props;
        return (
          <Field {...others} disabled={isInactive ? isInactive : disabled} />
        );
      },
      [isInactive]
    );

    return (
      <FormElement className="cc-action-type-form-elements">
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Name" isMandatory={true} />
              <ActionTypeField
                name={nameOf("Name")}
                component={CCInput}
                placeholder={"Name"}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Description</label>
              <ActionTypeField
                name={nameOf("Description")}
                component={CCTextArea}
                placeholder={"Description"}
                rows={3}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Details prefill</label>
              <ActionTypeField
                name={nameOf("Details_Prefill")}
                component={CCTextArea}
                placeholder={"Details prefill"}
                rows={3}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Closure prefill</label>
              <ActionTypeField
                name={nameOf("Closure_Prefill")}
                component={CCTextArea}
                placeholder={"Closure prefill"}
                rows={3}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Action required (default)</label>
              <ActionTypeField
                name={nameOf("ActionRequired")}
                component={CCSwitch}
                checked={valueGetter(nameOf("ActionRequired"))}
              />
            </div>
            {isEnableTag && (
              <div className="cc-field">
                <label className="cc-label">Tags</label>
                <ActionTypeField
                  name={nameOf("ActionTypeTags_CSV")}
                  placeholder="Tags"
                  component={KeyWordsPicker}
                  keyWordType={KEYWORD_TYPE.Core_ActionTypeTag}
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  showClearButton
                  onPickKeyWords={(keyword: CoreKeyword[]) => {
                    onChange(nameOf("ActionTypeTags_IDs"), {
                      value: keyword?.map(
                        (item: CoreKeyword) => item?.Keyword_ID
                      ),
                    });
                    onChange(nameOf("ActionTypeTags_CSV"), {
                      value: keyword
                        ?.reduce((pre, cur) => pre + "," + cur.Name, "")
                        .slice(1)
                        .trim(),
                    });
                  }}
                  isGetDetail={false}
                  onError={(error: any) => {
                    pushNotification({
                      title: error,
                      type: "warning",
                    });
                  }}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">Is referral</label>
              <ActionTypeField
                name={nameOf("Flag_IsReferral")}
                component={CCSwitch}
                checked={valueGetter(nameOf("Flag_IsReferral"))}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Is meeting</label>
              <ActionTypeField
                name={nameOf("Flag_IsMeeting")}
                component={CCSwitch}
                checked={valueGetter(nameOf("Flag_IsMeeting"))}
              />
            </div>
            {!valueGetter(nameOf("Flag_IsMeeting")) && (
              <>
                <div className="cc-field">
                  <label className="cc-label">Directions hearing</label>
                  <ActionTypeField
                    name={nameOf("isDirectionsHearing")}
                    component={CCSwitch}
                    checked={valueGetter(nameOf("isDirectionsHearing"))}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Panel meeting</label>
                  <ActionTypeField
                    name={nameOf("isPanelMeeting")}
                    component={CCSwitch}
                    checked={valueGetter(nameOf("isPanelMeeting"))}
                  />
                </div>
              </>
            )}
            <div className="cc-field">
              <label className="cc-label">External officer</label>
              <ActionTypeField
                name={nameOf("Flag_ExternalOfficer")}
                component={CCSwitch}
                checked={valueGetter(nameOf("Flag_ExternalOfficer"))}
              />
            </div>
            {!valueGetter(nameOf("Flag_IsReferral")) &&
              !valueGetter(nameOf("Flag_IsMeeting")) && (
                <>
                  <div className="cc-field">
                    <label className="cc-label">Has outcome</label>
                    <ActionTypeField
                      name={nameOf("Flag_HasOutcome")}
                      component={CCSwitch}
                      checked={valueGetter(nameOf("Flag_HasOutcome"))}
                    />
                  </div>
                  {valueGetter(nameOf("Flag_HasOutcome")) && (
                    <div className="cc-field">
                      <label className="cc-label">Outcome notes</label>
                      <ActionTypeField
                        name={nameOf("isOutcomeNotes")}
                        component={CCSwitch}
                        checked={valueGetter(nameOf("isOutcomeNotes"))}
                      />
                    </div>
                  )}
                </>
              )}
            {!valueGetter(nameOf("Flag_IsReferral")) &&
              valueGetter(nameOf("Flag_IsMeeting")) &&
              valueGetter(nameOf("Flag_HasOutcome")) && (
                <div className="cc-field">
                  <label className="cc-label">Default location</label>
                  <ActionTypeField
                    name={nameOf("DefaultLocation_KWD")}
                    dataItemKey={nameOfKeyword("Keyword_ID")}
                    textField={nameOfKeyword("Name")}
                    component={CCSearchComboBox}
                    data={defaultLocation ?? []}
                    isUseDefaultOnchange
                  />
                </div>
              )}
            <div className="cc-field">
              <label className="cc-label">User selection</label>
              <ActionTypeField
                name={nameOf("Flag_UserSelection")}
                component={CCSwitch}
                checked={valueGetter(nameOf("Flag_UserSelection")) ?? true}
              />
            </div>
            {!valueGetter(nameOf("Flag_IsReferral")) &&
              !valueGetter(nameOf("Flag_IsMeeting")) && (
                <div className="cc-field">
                  <label className="cc-label">Is approval</label>
                  <ActionTypeField
                    name={nameOf("IsApproval")}
                    component={CCSwitch}
                    checked={valueGetter(nameOf("IsApproval"))}
                  />
                </div>
              )}
            <div className="cc-field">
              <label className="cc-label">Include weekend days</label>
              <ActionTypeField
                name={nameOf("Include_Weekend_Days")}
                component={CCSwitch}
                checked={valueGetter(nameOf("Include_Weekend_Days"))}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Target days</label>
              <ActionTypeField
                name={nameOf("TargetDays")}
                component={CCNumericTextBox}
                placeholder={"Target Days"}
                format={NUMBER_FORMAT.NUMBER2}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Is regulatory action</label>
              <ActionTypeField
                name={nameOf("IsRegulatoryAction")}
                component={CCSwitch}
                checked={valueGetter(nameOf("IsRegulatoryAction"))}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Is information sharing</label>
              <ActionTypeField
                name={nameOf("IsInformationSharing")}
                component={CCSwitch}
                checked={valueGetter(nameOf("IsInformationSharing"))}
              />
            </div>
            {valueGetter(nameOf("IsInformationSharing")) && (
              <div className="cc-field">
                <label className="cc-label">Custom label</label>
                <ActionTypeField
                  name={nameOf("InformationSharing_CustomLabel")}
                  component={CCInput}
                  placeholder={"Custom Label"}
                />
              </div>
            )}
            <div className="cc-field">
              <label className="cc-label">Is assessment</label>
              <ActionTypeField
                name={nameOf("IsAssessment")}
                component={CCSwitch}
                checked={valueGetter(nameOf("IsAssessment"))}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Is conciliation</label>
              <ActionTypeField
                name={nameOf("IsConciliation")}
                component={CCSwitch}
                checked={valueGetter(nameOf("IsConciliation"))}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Is counsel worker</label>
              <ActionTypeField
                name={nameOf("IsCounselWorker")}
                component={CCSwitch}
                checked={valueGetter(nameOf("IsCounselWorker"))}
              />
            </div>
            {isShowWorkerLink && (
              <div className="cc-field">
                <label className="cc-label">Show worker link</label>
                <ActionTypeField
                  name={nameOf("Has_WorkerLink")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("Has_WorkerLink"))}
                />
              </div>
            )}
            {!valueGetter(nameOf("Flag_ExternalOfficer")) && (
              <>
                <div className="cc-field">
                  <label className="cc-label">Restrict officer selection</label>
                  <ActionTypeField
                    name={nameOf("RestrictActionOfficerSelection")}
                    component={CCSwitch}
                    checked={valueGetter(
                      nameOf("RestrictActionOfficerSelection")
                    )}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Default officer</label>
                  <ActionTypeField
                    name={nameOf("DefaultActionOfficer")}
                    component={OfficerPicker}
                    textField={"DisplayName"}
                    showClearButton
                    placeholder={"Default officer"}
                    onPickOfficer={handleOnChangeDefaultOfficer}
                  />
                </div>
              </>
            )}
          </div>
        </section>
        {valueGetter(nameOf("ActionRequired")) && (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <label className="cc-label">Next action</label>
                  <ActionTypeField
                    name={nameOf("NextAction_IDs")}
                    placeholder={"Next action"}
                    value={valueGetter("NextAction_CSV")}
                    component={ActionTypePicker}
                    selectableMode={"multiple"}
                    recordType={RECORDTYPE.CORE_ActionType}
                    showClearButton
                    onPickActionType={handleOnPickNextAction}
                    userSelection={true}
                    selectedItemIDs={valueGetter(nameOf("NextAction_IDs"))}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Default officer</label>
                  <ActionTypeField
                    name={nameOf("DefaultNextActionOfficer")}
                    component={OfficerPicker}
                    textField={"DisplayName"}
                    showClearButton
                    placeholder={"Default officer"}
                    onPickOfficer={handleOnChangeDefaultOfficer}
                  />
                </div>
              </div>
            </section>
          </>
        )}
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Check list</label>
              <ActionTypeField
                name={nameOf("ActionTypeChecklist")}
                placeholder={"Action type check list"}
                component={DynamicQuestionPicker}
                checklistType={ChecklistType.ActionTypeChecklist}
                selectableMode="single"
                onPickDynamicQuestion={handleOnPickChecklist}
                textField={nameOfDynamicQuestion("DynamicQuestionList_Name")}
              />
            </div>
            {valueGetter(nameOf("ActionTypeChecklist_ID")) && (
              <>
                <div className="cc-field">
                  <label className="cc-label">Check list label</label>
                  <ActionTypeField
                    name={nameOf("ActionTypeChecklist_Label")}
                    component={CCInput}
                    placeholder={"Checklist Label"}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">Check list mandatory</label>
                  <ActionTypeField
                    name={nameOf("IsActionTypeChecklistMandatory")}
                    component={CCSwitch}
                    checked={valueGetter(
                      nameOf("IsActionTypeChecklistMandatory")
                    )}
                  />
                </div>
              </>
            )}
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <SystemFilter
              formRenderProps={formRenderProps}
              readOnly={isInactive}
            ></SystemFilter>
          </div>
        </section>
      </FormElement>
    );
  }
);
