import { odataCoreGetConditionReferences } from "@app/core/components/common/utils";
import { getConditionsColumnConfig } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/components/form-element/components/conditions-section/components/button/add-condition/conditions-dialog/config";
import { Svc_Condition } from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { GridSelectionDialog } from "@components/dialog/dialog-grid-selection/_index";
import { Form } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";

const nameOf = nameOfFactory<Svc_Condition>();

export interface IConditionsDialogProps {
  onSubmit: (conditions: Svc_Condition[]) => void;
  onClosePopup: () => void;
  productType: PRODUCT_TYPE_NUMBER;
}

export const ConditionsDialog = observer(
  ({ onSubmit, onClosePopup, productType }: IConditionsDialogProps) => {
    const { settings } = useCommonCoreStore();

    // Check setting to group the conditions data
    let isHasGroup = useMemo(() => {
      let isAllowGroup = false;
      switch (productType) {
        case PRODUCT_TYPE_NUMBER.HealthManager:
          isAllowGroup = getBoolValueSetting(
            settings[
              ECorporateSettingsField
                .HealthManager_Views_Conditions_DisplayConditionsInGroup
            ]
          );
          break;
        case PRODUCT_TYPE_NUMBER.WasteWater:
          isAllowGroup = getBoolValueSetting(
            settings[
              ECorporateSettingsField
                .WW_Views_Conditions_DisplayConditionsInGroup
            ]
          );
          break;
        case PRODUCT_TYPE_NUMBER.LLPermits:
          isAllowGroup = getBoolValueSetting(
            settings[
              ECorporateSettingsField
                .LLP_Views_Conditions_DisplayConditionsInGroup
            ]
          );
          break;
      }
      return isAllowGroup;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productType]);

    const [stateGrid, setStateGrid] = useState<any>({
      group: isHasGroup
        ? [
            {
              field: nameOf("Group"),
              dir: "asc",
            },
          ]
        : undefined,
    });

    return (
      <Form
        render={() => (
          <GridSelectionDialog
            selectableMode="multiple"
            maxWidth="60%"
            height={720}
            titleHeader="Conditions"
            onCloseDialog={() => onClosePopup()}
            dataUrl={odataCoreGetConditionReferences(
              PRODUCT_TYPE_NUMBER.LLPermits
            )}
            columnFields={getConditionsColumnConfig(isHasGroup)}
            setShowDialog={() => onClosePopup()}
            hasSearchField
            groupDragMode="auto"
            state={stateGrid}
            onDataStateChange={(state) => setStateGrid(state)}
            onSubmit={(conditions: Svc_Condition[]) => {
              if (onSubmit) {
                onSubmit(conditions);
              }
              onClosePopup();
            }}
            filterCol={nameOf("Title")}
          />
        )}
      />
    );
  }
);
