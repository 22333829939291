import { HMFieldMapping } from "@app/products/hm/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { PREMISES_MANAGE_ROUTE } from "../[id]/constant";

export const colHMPremisesRegister = [
  {
    field: HMFieldMapping.TradingName,
    title: "Trading Name",
    locked: true,
    linkTo: (dataItem: { ID: string }) => {
      return `${PREMISES_MANAGE_ROUTE}/` + dataItem.ID;
    },
    width: 150,
  },
  {
    field: HMFieldMapping.RegNo,
    title: "Registration Number",
    width: 150,
  },
  // {
  //   field: HMFieldMapping.LocationDesc,
  //   title: "Location Description",
  //   width: 150
  // },
  {
    field: HMFieldMapping.StreetNo,
    title: "Number",
    width: 150,
  },
  {
    field: HMFieldMapping.AddressLine1,
    title: "Address",
    width: 150,
  },
  {
    field: HMFieldMapping.Street,
    title: "Street",
    width: 150,
  },
  {
    field: HMFieldMapping.Suburb,
    title: "Suburb",
    width: 150,
  },
  // {
  //   field: HMFieldMapping.Area,
  //   title: "Area",
  //   width: 150
  // }, {
  //   field: HMFieldMapping.Premise_Home_Jurisdiction,
  //   title: "Jurisdiction",
  //   width: 150
  // },
  {
    field: HMFieldMapping.PremisesClassification,
    title: "Classification",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesType,
    title: "Type",
    width: 150,
  },
  {
    field: HMFieldMapping.Risk,
    title: "Risk",
    width: 150,
  },
  {
    field: HMFieldMapping.Status,
    title: "Status",
    width: 150,
  },
  {
    field: HMFieldMapping.Officer,
    title: "Officer",
    width: 150,
  },
  {
    field: HMFieldMapping.AmountOS,
    title: "Outstanding",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: HMFieldMapping.RefNo,
    title: "Reference Number",
    width: 150,
  },
  {
    field: HMFieldMapping.FileNumber,
    title: "File Number",
    width: 150,
  },
  {
    field: HMFieldMapping.DebtorNo,
    title: "Debtor Number",
    width: 150,
  },
  {
    field: HMFieldMapping.AssessmentNo,
    title: "Assessment Number",
    width: 150,
  },
  // {
  //   field: HMFieldMapping.Flag_MultiTenancy,
  //   title: "Multi",
  //   width: 150
  // }, {
  //   field: HMFieldMapping.Flag_DeptOfEducation,
  //   title: "DoE",
  //   width: 150
  // },
  {
    field: HMFieldMapping.HomeBusiness,
    title: "Home Bus",
    width: 150,
  },
  {
    field: HMFieldMapping.LastInspection,
    title: "Last Inspection",
    width: 150,
  },
  {
    field: HMFieldMapping.LastCompliance,
    title: "Last Compliance",
    width: 150,
  },
  {
    field: HMFieldMapping.Email,
    title: "Email",
    width: 150,
  },
  //  {
  //   field: HMFieldMapping.PreviousCategory,
  //   title: "Previous Category",
  //   width: 150
  // },
  {
    field: HMFieldMapping.PostalAddress,
    title: "Postal Address",
    width: 150,
  },
  {
    field: HMFieldMapping.PostalAddress2,
    title: "Postal Address 2",
    width: 150,
  },
  {
    field: HMFieldMapping.Proprietor,
    title: "Proprietor",
    width: 150,
  },
  // {
  //   field: HMFieldMapping.Mobile,
  //   title: "Mobile",
  //   width: 150
  // },
  {
    field: HMFieldMapping.Phone,
    title: "Phone",
    width: 150,
  },
  {
    field: HMFieldMapping.ProprietorABN,
    title: "ABN",
    width: 150,
  },
  {
    field: HMFieldMapping.ProprietorACN,
    title: "ACN",
    width: 150,
  },
  {
    field: HMFieldMapping.NoEmployees,
    title: "Emp",
    width: 150,
  },
  {
    field: HMFieldMapping.PrimaryContact,
    title: "Contact",
    width: 150,
  },
  {
    field: HMFieldMapping.FSPContact,
    title: "FSS Contact",
    width: 150,
  },
  {
    field: HMFieldMapping.FSP,
    title: "FSP Template",
    width: 150,
  },
  {
    field: HMFieldMapping.DateLodged,
    title: "Lodged",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.DateRegistrationExpires,
    title: "Due Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.Fax,
    title: "Fax",
    width: 150,
  },
];
