import { Svc_Condition } from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Svc_Condition>();
export const colConditions: IColumnFields[] = [
  { field: nameOf("Title"), title: "Title" },
  { field: nameOf("Condition"), title: "Condition" },
  { field: nameOf("Group"), title: "Group" },
];

export const getConditionsColumnConfig = (isHasGroup: boolean) => {
  if (isHasGroup) {
    return colConditions;
  } else {
    return colConditions.filter(
      (item: IColumnFields) => item.field !== nameOf("Group")
    );
  }
};
