import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { SaveButtonSystemAdminPermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/components/buttons/save/_index";
import { PermitTypeForm } from "@app/products/local-laws/system-admin/permit-types/[id]/components/general/_index";
import { IManagePermitType } from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { IPermissionsWrapperProps } from "@common/hocs/permissions/model";
import { renderWithPermissionsWrapper } from "@common/hocs/permissions/render-with-permission-wrapper";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { YieldNotificationPortal } from "@components/cc-notification-portal/_index";
import { TypePlaceId } from "@components/cc-notification-portal/constant";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { observer } from "mobx-react-lite";
import React from "react";
import { useEffectOnce } from "react-use";

const NewManagePermitTypeComponent = observer(
  ({ errorComponent, titleManagePage, permitType }: IManagePermitType) => {
    const { listenNotificationPortal } = useNotificationPortalStore();

    useEffectOnce(() => {
      listenNotificationPortal();
    });

    return (
      <>
        <FormNavigation title={titleManagePage} />
        <FormTitle title={`New ${titleManagePage}`} />
        {errorComponent ? (
          errorComponent
        ) : (
          <>
            <CCManagePageActionBar
              leftActions={[<SaveButtonSystemAdminPermitType />]}
            />
            <div className="cc-manage-container">
              <div className="cc-manage-left">
                <CCAppNotification />
                <YieldNotificationPortal placeId={TypePlaceId.Main} />
                <div className="cc-manage-form-body">
                  {permitType && <PermitTypeForm />}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
);

const renderProps: IPermissionsWrapperProps = {
  component: NewManagePermitTypeComponent,
  permissions: { productType: PRODUCT_TYPE_NUMBER.LLPermits },
  useNewPermissionDataCreator: (props: IPermissionsWrapperProps) => {
    return {
      formIdentifier: FormIdentifier.LLPermits_Form_PermitType,
      productType: props.permissions.productType,
      formAction: FormAction.CORE_ALLOW_NEW,
    };
  },
};

export const NewManagePermitType = renderWithPermissionsWrapper(renderProps);
