import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ReplaceMeterButton } from "@app/products/property/meters/[id]/components/forms/existed/components/action-bar/buttons/replace-meter/_index";
import { IMeterFormGeneral } from "@app/products/property/meters/[id]/model";
import { AssociateButton } from "@app/products/property/registers/[id]/components/buttons/workflow/associate/_index";
import { AssociateMeterButton } from "@app/products/property/registers/[id]/components/buttons/workflow/meter/associate-meter/_index";
import { DisassociateMeterButton } from "@app/products/property/registers/[id]/components/buttons/workflow/meter/disassociate-meter/_index";
import { RaiseChargeButton } from "@app/products/property/registers/[id]/components/buttons/workflow/raise-charge/_index";
import { ReverseTransactionButton } from "@app/products/property/registers/[id]/components/buttons/workflow/reverse-transaction/_index";
import { ScheduleSpecialReadingsButton } from "@app/products/property/registers/[id]/components/buttons/workflow/schedule-special-readings/_index";
import { TransferToAnotherAccountButton } from "@app/products/property/registers/[id]/components/buttons/workflow/transfer-to-another-account/_index";
import { UnAssociateButton } from "@app/products/property/registers/[id]/components/buttons/workflow/un-associate/_index";
import { PropertyRegisterForm } from "@app/products/property/registers/[id]/components/child-screens/general/_index";
import { RegisterMemos } from "@app/products/property/registers/[id]/components/child-screens/memos/_index";
import { RegisterMeters } from "@app/products/property/registers/[id]/components/child-screens/meters/_index";
import { useRegisterMetersStore } from "@app/products/property/registers/[id]/components/child-screens/meters/store";
import { RegisterNames } from "@app/products/property/registers/[id]/components/child-screens/names/_index";
import { RegisterTagHistory } from "@app/products/property/registers/[id]/components/child-screens/tag-history/_index";
import { RegisterTransactions } from "@app/products/property/registers/[id]/components/child-screens/transactions/_index";
import { AddGISReferenceButton } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/add-gis-reference/_index";
import { AdjustAccountBalanceButton } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/adjust-account-balance/_index";
import { ChangeOfNameAddrButton } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/change-of-name-addr/_index";
import { DeleteGISReferenceButton } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/delete-gis-reference/_index";
import { EditGISReferenceButton } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/edit-gis-reference/_index";
import { EnterSpecialReadingButton } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/enter-special-reading/_index";
import { ManualReadingsButton } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/manual-readings/_index";
import { ModifyRegisterButton } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/modify-register/_index";
import { NewTransactionButton } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/new-transaction/_index";
import { TransferButton } from "@app/products/property/registers/[id]/components/forms/existed/components/action-bar/buttons/transfer/_index";
import { PropertyRegisterAssociationTab } from "@app/products/property/registers/[id]/components/reference-sidebar/associations/_index";
import { PropertyRegisterContactsTab } from "@app/products/property/registers/[id]/components/reference-sidebar/contact/_index";
import { PropertyRegisterDetailTab } from "@app/products/property/registers/[id]/components/reference-sidebar/detail/_index";
import { useRegisterStore } from "@app/products/property/registers/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { MapSTripTab } from "@components/cc-reference-sidebar/components/MapView/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

export const ExistedPropertyRegister = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { loadRegister, register, isLoading, resetStore, responseLoadError } =
    useRegisterStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { metersSelected } = useRegisterMetersStore();
  // Can use after for bookmark feature
  // const managePageUrl = window.location.pathname;
  // const getBookmarkDetail = useMemo(() => {
  //   const dynamicDetail = (register?.Name ? register.Name + " - " : "") + id;
  //   return `${dynamicDetail}`;
  // }, [id, register]);
  // const bookmarkList: IBookmarkItem[] = [
  //   {
  //     detail: getBookmarkDetail,
  //     url: managePageUrl,
  //     displayName: `Property - Register - ${id}`,
  //   },
  // ];

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  const listPanelBar: IPropertyPanelBar[] = [
    {
      title: "Memos",
      component: <RegisterMemos />,
    },
    {
      title: "Tag History",
      component: <RegisterTagHistory />,
    },
    {
      title: "Meters",
      component: <RegisterMeters />,
    },
    {
      title: "Names",
      component: <RegisterNames />,
    },
    {
      title: "Transactions",
      component: <RegisterTransactions />,
    },
  ];

  const formTitle = useMemo(() => {
    let title = "";
    if (register?.Id && register?.AccountReference)
      title = `${register?.Id?.toString()} - ${register?.AccountReference}`;
    else if (register?.Id && !register?.AccountReference)
      title = `${register?.Id?.toString()}`;
    else if (!register?.Id && register?.AccountReference)
      title = `${register?.AccountReference}`;
    return title;
  }, [register]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title="Register" />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadRegister(+id)}
        />
      ) : (
        <>
          <FormTitle title={formTitle} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"GIS reference"} type="dropdown">
                  <AddGISReferenceButton />
                  <DeleteGISReferenceButton />
                  <EditGISReferenceButton />
                </CCNavButton>
                <ChangeOfNameAddrButton />
                <ModifyRegisterButton />
                <AssociateButton />
                <UnAssociateButton />
                <RaiseChargeButton />

                <CCNavButton title={"Journal"} type="dropdown">
                  <AdjustAccountBalanceButton />
                  <NewTransactionButton />
                  <ReverseTransactionButton />
                  <TransferToAnotherAccountButton />
                </CCNavButton>
                <CCNavButton title={"Meter"} type="dropdown">
                  <AssociateMeterButton />
                  <DisassociateMeterButton />
                  <EnterSpecialReadingButton />
                  <ManualReadingsButton />
                  <ReplaceMeterButton
                    meterInfo={metersSelected[0]}
                    disabled={metersSelected.length !== 1}
                    onReplace={(data: IMeterFormGeneral) => {
                      pushNotification({
                        title: `Meter has been successfully replaced by meter - ${data.MeterNumber}`,
                        type: "success",
                      });
                    }}
                  />
                  <ScheduleSpecialReadingsButton />
                  <TransferButton />
                </CCNavButton>
              </CCNavButton>,
              <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,

              <CCNavButton title={"Add"} type="sub"></CCNavButton>,
              <CCNavButton title={"View"} />,
              <CCNavButton title={"Print"} />,

              <CCNavButton title={"More Options"} type="more">
                <CCNavButton title={"Portal Links"} />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              //  Holding now but can use later
              // <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
              <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {register && (
                  <CCGeneralPanel component={<PropertyRegisterForm />} />
                )}
                <PropertyPanelBar initialPanels={listPanelBar} />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <PropertyRegisterDetailTab />,
                    },
                    { title: "Map", component: <MapSTripTab /> },
                    {
                      title: "Related",
                      component: <PropertyRegisterAssociationTab />,
                    },
                    {
                      title: "Contacts",
                      component: <PropertyRegisterContactsTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_Register}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
