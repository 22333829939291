import { KeyValuePair } from "@app/core/documents/model";
import { isSuccessResponse } from "@common/apis/util";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { getSystemFilters } from "@common/pages/settings/lookups/components/system-filter/api";
import { useKeywordsStore } from "@common/pages/settings/lookups/keywords/_id/store";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { FormRenderProps } from "@progress/kendo-react-form";
import { isNaN, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

interface ISystemFilterFormElementProps {
  formRenderProps: FormRenderProps;
  readOnly?: boolean;
}

const nameOfKeyValue = nameOfFactory<IKeyValuePacket>();

export const SystemFilter = observer(
  ({ formRenderProps, readOnly }: ISystemFilterFormElementProps) => {
    const { pushNotification } = useCCAppNotificationStore();
    const { isInactive } = useKeywordsStore();
    const { valueGetter, onChange } = formRenderProps;
    const [systemFilterData, setSystemFilterData] =
      useState<KeyValuePair<number, string>[]>();

    useEffectOnce(() => {
      getSystemFilters().then((response) => {
        if (isSuccessResponse(response)) {
          if (response?.data) {
            setSystemFilterData(response?.data);
          } else {
            pushNotification({
              title: `System filter load failed`,
              type: "warning",
            });
          }
        }
      });
    });

    const systemFilterENUMS = valueGetter("SystemFilter_ENUMS");
    const selectedModules = useMemo(() => {
      if (!systemFilterENUMS) return [];
      return systemFilterENUMS
        .split("|")
        .reduce((result: IKeyValuePacket[], sysEnum: string) => {
          const keyInt = parseInt(sysEnum);
          if (!isNil(keyInt))
            return [
              ...result,
              {
                Key: keyInt,
                Value:
                  systemFilterData?.find((item) => item.Key === keyInt)
                    ?.Value ?? "",
              },
            ];
          return result;
        }, []);
    }, [systemFilterENUMS, systemFilterData]);

    const handleChangeSelectedModules = useCallback(
      (dataItems: KeyValuePair<number, string>[]) => {
        const newValue = dataItems.reduce(
          (pre, cur) => {
            if (!isNaN(cur.Key) && cur.Value !== "") {
              return {
                Key: pre.Key + "|" + cur.Key,
                Value: pre.Value + "," + cur.Value,
              };
            } else {
              return {
                Key: pre.Key,
                Value: pre.Value,
              };
            }
          },
          { Key: "", Value: "" }
        );
        newValue.Key += newValue.Key.length > 0 ? "|" : "";
        newValue.Value =
          newValue.Value.indexOf(",") === 0
            ? newValue.Value.slice(1)
            : newValue.Value;
        onChange("SystemFilter_ENUMS", { value: newValue.Key });
        onChange("SystemFilter_Names", { value: newValue.Value });
      },
      [onChange]
    );

    return (
      <div className="cc-field">
        <label className="cc-label">Modules</label>
        <CCGrid
          data={systemFilterData}
          selectableMode="multiple"
          itemPerPage={systemFilterData?.length ?? 10}
          selectedRows={selectedModules}
          onSelectionChange={handleChangeSelectedModules}
          primaryField={nameOfKeyValue("Key")}
          readOnly={readOnly}
          columnFields={[
            {
              field: nameOfKeyValue("Value"),
              title: "Module Name",
              isGrow: true,
            },
          ]}
          disableRowField={isInactive ? nameOfKeyValue("Value") : undefined}
        />
      </div>
    );
  }
);
