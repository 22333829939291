import { WorkflowApprovalStatus } from "@app/products/property/components/action-bar/property-workflow/model";
import { DTO_Title } from "@app/products/property/components/dialogs/add-title-lookup/model";
import { isNil } from "lodash";

export const validatorAssociation = (values: any): string | undefined => {
  if (
    isNil(values?.Folios) ||
    isNil(values?.Holdings) ||
    (!isNil(values?.Folios) && values?.Folios?.length < 1) ||
    (!isNil(values?.Holdings) && values?.Holdings?.length < 1)
  )
    return "ValidateBothGrid";
  if (values?.PrimaryHolding === 0 || isNil(values?.PrimaryHolding))
    return "PrimaryHolding";
  return undefined;
};

// Task 15285
export const validatorAssociationNew = (
  values: any,
  options: any
): string | undefined => {
  if (
    options?.workflowHeader?.WorkflowDraft?.WD_Workflow_Approval_Status ===
    WorkflowApprovalStatus.AwaitingGisApproval
  ) {
    if (
      isNil(values?.Folios) ||
      isNil(values?.Holdings) ||
      (!isNil(values?.Folios) && values?.Folios?.length < 1) ||
      (!isNil(values?.Holdings) && values?.Holdings?.length < 1)
    )
      return "ValidateBothGrid";
    if (values?.PrimaryHolding === 0 || isNil(values?.PrimaryHolding))
      return "PrimaryHolding";
  } else {
    if (values?.PrimaryHolding === 0 || isNil(values?.PrimaryHolding))
      return "OnlyPrimaryHolding";
  }
  return undefined;
};

// export const PrimaryHolding
interface TObject {
  [key: string]: any;
}

/**
 * Process data grid
 * Input: previous data, current data, field name
 * @param preData
 * @param currData
 * @param primaryKey
 * @returns
 */
export const processCombineData = <T extends TObject>(
  preData: T[],
  currData: T[],
  primaryKey: string
) => {
  if (preData?.length === 0) return currData;
  if (currData?.length === 1) {
    const existedItem = preData?.find(
      (e: T) => e[primaryKey] === currData[0]?.[primaryKey]
    );
    return isNil(existedItem) ? [currData[0], ...preData] : preData;
  } else {
    const listIds = preData?.map((item: any) => item[primaryKey]);
    const newData =
      currData.filter((item: any) => !listIds.includes(item[primaryKey])) ?? [];
    return [...newData, ...preData];
  }
};

export const combineValueWithDash = (
  values: string[],
  characterJoin: string = "-"
) => {
  return values?.filter((item) => item.length).join(characterJoin);
};

export const calculationMaxPercentage = (dataRow: DTO_Title) => {
  //calculate Area Percentage maximum can be allocated =>
  // In Create PIC the Maximum = 100% - AssociatedPICAreaTotal
  // In Modify/Reactivate PIC the Maximum = AssociatedPICAreaRemaining + AssociatedPICArea
  // The AssociatedPICArea will return in the API & it is the stable variable
  return dataRow?.isNewPIC
    ? 100 - (dataRow?.AssociatedPICAreaTotal ?? 0)
    : (dataRow?.AssociatedPICAreaRemaining ?? 0) +
        (dataRow?.AssociatedPICArea ?? 0);
};
