import { DeleteButtonCommon } from "@app/core/delete/buttons/common/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React from "react";

interface IDeleteButtonProps {
  recordType?: RECORDTYPE;
  disabled?: boolean;
  visible?: boolean;
  refreshGridIds?: string[]; 
}

export const DeleteButton = observer(
  ({
    recordType,
    disabled,
    visible = true,
    refreshGridIds = [],
  }: IDeleteButtonProps) => {

    const {
      gridSelectedIds,
    } = useCCProductListViewStore();

    return (
      <DeleteButtonCommon recordType={recordType} disabled={disabled} ids={gridSelectedIds} visible={visible} refreshGridIds={refreshGridIds}/>
    );
  }
);
