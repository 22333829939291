import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { MailMergeButtonManagePage } from "@app/core/new-mail-merge/buttons/mail-merge-manage-page/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ChangeOfOwnerShipButton } from "@app/products/property/assessments/components/action-bar/buttons/change-of-ownership/_index";
import { eDocumentTypeCategory } from "@app/products/property/components/action-bar/button/add-attachment/model";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyCollections } from "@app/products/property/components/child-screen/collections/_index";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import { SAPTransaction } from "@app/products/property/components/child-screen/sap-transactions/_index";
import { GISAccordion } from "@app/products/property/components/gis-accordion/_index";
import { LineageAccordion } from "@app/products/property/components/lineage-accordion/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { ModifyPICButton } from "@app/products/property/pic/list/components/action-bar/buttons/modify-pic/_index";
import { CreatePICButton } from "@app/products/property/pic/list/components/action-bar/buttons/new-pic/_index";
import { NewTransactionPICButton } from "@app/products/property/pic/list/components/action-bar/buttons/new-transaction/_index";
import { ReactivePICButton } from "@app/products/property/pic/list/components/action-bar/buttons/reactive-pic/_index";
import { AmalgamatePICManagePagePICButton } from "@app/products/property/pic/[id]/components/buttons/amalgamate-pic/_index";
import { PicAttributes } from "@app/products/property/pic/[id]/components/child-screens/attributes/_index";
import { PicCertificates } from "@app/products/property/pic/[id]/components/child-screens/certificates/_index";
import { PicFolios } from "@app/products/property/pic/[id]/components/child-screens/folios/_index";
import { PropertyPicForm } from "@app/products/property/pic/[id]/components/child-screens/general/_index";
import { PicTransaction } from "@app/products/property/pic/[id]/components/child-screens/transactions/_index";
import { PICTransfer } from "@app/products/property/pic/[id]/components/child-screens/transfer/_index";
import { PropertyPicContactsTab } from "@app/products/property/pic/[id]/components/reference-sidebar/contact/_index";
import { PropertyPicDetailTab } from "@app/products/property/pic/[id]/components/reference-sidebar/detail/_index";
import { PICMapTab } from "@app/products/property/pic/[id]/components/reference-sidebar/map/_index";
import { PROPERTY_PIC_ROUTE } from "@app/products/property/pic/[id]/constant";
import { usePicStore } from "@app/products/property/pic/[id]/store";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedPropertyPIC = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const { pic, loadPic, isLoading, responseLoadError, picId } = usePicStore();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const { isLLS } = CommunityProperty.getFlagOfStates();

  //Get label
  const titleLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Title
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        pic?.PICNumber,
        pic?.PropertyAddress,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `Property - PIC - ${picId}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_PIC,
      Record_ID: +id,
    },
  ];

  const listPanelBar: IPropertyPanelBar[] = [
    {
      title: "Lineage",
      component: (
        <LineageAccordion
          apiUrl={`/api/property/internal/pic/${parseInt(id)}/lineage`}
          redirectUrl={PROPERTY_PIC_ROUTE}
        />
      ),
    },
    {
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_PIC}
          componentNumber={eComponent.PIC}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    {
      title: "Related",
      component: (
        <PropertyAssociations
          id={parseInt(id)}
          componentNumber={eComponent.PIC}
        />
      ),
    },
    {
      title: "Attributes",
      component: <PicAttributes />,
    },
    // Requirement: https://dev.azure.com/openofficeau/Radium/_workitems/edit/12802/
    // {
    //   title: "Audit",
    //   component: (
    //     <PropertyAudit
    //       id={parseInt(id)}
    //       //TODO: Change enum later
    //       componentNumber={eComponent.CommunityCentral_Other}
    //     />
    //   ),
    // },
    {
      title: "Certificates",
      component: <PicCertificates />,
    },
    {
      title: "Collections",
      component: (
        <PropertyCollections
          componentId={parseInt(id)}
          componentNumber={eComponent.PIC}
        />
      ),
    },
    {
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_PIC}
        />
      ),
    },
    {
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.PIC}
        />
      ),
    },
    {
      title: titleLabel ?? "Folios",
      component: <PicFolios />,
    },
    {
      title: "Transfers",
      component: <PICTransfer />,
    },
    {
      title: "Transactions",
      component: <PicTransaction />,
    },
    {
      title: "SAP Transactions",
      component: <SAPTransaction sapId={pic?.Occupier_SAP_Number} />,
      isVisible: isLLS,
    },

    {
      title: "GIS References",
      component: (
        <GISAccordion
          apiUrl={`/api/property/int/gisreference/${eComponent.PIC}/${parseInt(
            id
          )}`}
        />
      ),
    },
  ];

  const formTitle = useMemo(() => {
    let title = "";
    if (pic?.PICNumber && pic?.PropertyAddress)
      title = `${pic?.PICNumber} - ${pic?.PropertyAddress}`;
    else if (pic?.PICNumber && !pic?.PropertyAddress)
      title = `${pic?.PICNumber}`;
    else if (!pic?.PICNumber && pic?.PropertyAddress)
      title = `${pic?.PropertyAddress}`;
    return title;
  }, [pic]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation
        title="PIC"
        badge={[pic?.BannerStatus, pic?.PICQuarantineStatus]}
      />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadPic(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={formTitle} badge={pic?.PICStatus} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"PIC"} type="dropdown">
                  <AmalgamatePICManagePagePICButton />
                  <CreatePICButton />
                  <ModifyPICButton />
                  <ReactivePICButton />
                </CCNavButton>
                <ChangeOfOwnerShipButton componentNumber={eComponent.PIC} />
                <CCNavButton title={"Journal"} type="dropdown">
                  <NewTransactionPICButton listPICId={[parseInt(id)]} />
                </CCNavButton>
              </CCNavButton>,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_PIC}
                />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_PIC}
                  documentCategoryTypeId={eDocumentTypeCategory.PIC_Document}
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_PIC}
                />
                <MailMergeButtonManagePage
                  productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
                  dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_PIC}
                  recordType={RECORDTYPE.CommunityProperty_PIC}
                  isEmptyGridSelectedRow={!parseInt(id)}
                  selectedRecordIds={[parseInt(id)]}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {pic && <CCGeneralPanel component={<PropertyPicForm />} />}
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.PIC}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <PropertyPicDetailTab /> },
                    {
                      title: "Map",
                      component: <PICMapTab address={pic?.PropertyAddress} />,
                    },
                    {
                      title: "Contacts",
                      component: <PropertyPicContactsTab id={parseInt(id)} />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={RECORDTYPE.CommunityProperty_PIC}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
