import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";
import { Collection } from "@app/products/property/assessments/components/form-steps/new-assessment/model";
import { VO_Assessment_w_Detail } from "@app/products/property/assessments/list/model";
import { WorkflowGroupButtonsSpatial } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial";
import { IButtonSpatial } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/model";
import { useWorkflowGroupButtonsSpatialStore } from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/store";
import {
  getNotificationSpatialWorkflow,
  isDisabledApproveButton,
  isHiddenRejectButton,
  isSpatialGISMode,
} from "@app/products/property/components/action-bar/property-workflow/component/buttons/workflow-group-buttons-spatial/util";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { useConfirmReallocateDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reallocate/store";
import { useConfirmRejectDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-reject/store";
import { useConfirmSendBackDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-send-back/store";
import { CollectionFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/_index";
import { collectionUtilProcessing } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/collection/collection/util";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { WorkflowStepFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/_index";
import {
  getWorkflowInstructions,
  secondaryWorkflowUtilProcessing,
} from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/util";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import {
  INewProcessWorkflow,
  WorkflowApprovalStatus,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import { DTO_GIS } from "@app/products/property/components/dialogs/gis-reference/model";
import { GISAccordionEventType } from "@app/products/property/components/gis-accordion/constant";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import {
  getInitialDataAmalgamatePIC,
  postAmalgamatePIC,
} from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/api";
import { AssociationAmalgamatePICFormStep } from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/components/form-element/associations/_index";
import { DetailAmalgamatePICFormStep } from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/components/form-element/details/_index";
import { PicDetailsAmalgamateFormStep } from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/components/form-element/pic-details/_index";
import {
  DTO_Workflow_PIC_Amalgamate,
  EKeysOfStepsAmalgamatePIC,
  ELabelAmalgamatePIC,
  keysOfStepsAmalgamatePIC,
} from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/model";
import {
  getActualValueOption,
  processDataLovInAmalgamate,
} from "@app/products/property/pic/list/components/action-bar/form-steps/amalgamate-pic/util";
import { useNewSwineBrandDialogStoreContext } from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/components/form-element/pic-details/components/dialogs/swine-brands/store";
import {
  DTO_PIC_Swine,
  ELoadingModeWorkflow,
} from "@app/products/property/pic/list/components/action-bar/form-steps/new-pic/model";
import { PROPERTY_PICS_ROUTE } from "@app/products/property/pic/list/constant";
import { VO_PIC } from "@app/products/property/pic/list/model";
import {
  convertValueLOVToNumber,
  isShowParkButton,
} from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacket, ResponsePacket } from "@common/models/identityPacket";
import { CommunityProperty } from "@common/stores/products/config";
import { useCommonProductStore } from "@common/stores/products/store";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import { EBadgeDialogType, IBadgeDialog } from "@components/cc-dialog/model";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep, head, isEmpty, isNil, pickBy } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
interface IAmalgamatePICDialog {
  onClose: () => void;
  prefixTitle?: string;
  suffixTitle?: string;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  isShowCancelWorkflowButton?: boolean;
  picIDs: number[];
}
export const AmalgamatePICDialog = (props: IAmalgamatePICDialog) => {
  const {
    onClose,
    isSaveOnNextStep = false,
    prefixTitle,
    suffixTitle,
    dataFromActionList,
    picIDs,
  } = props;
  //#region <Store>
  const { setLov } = useNewSwineBrandDialogStoreContext();
  const { pushNotification } = useCCAppNotificationStore();
  const { setDataForCancelDialog } = useConfirmCancelDialogStore();
  const { setDataForFinishDialog } = useConfirmFinishDialogStore();
  const { setDataForCloseDialog, setIsLoadingClose } =
    useConfirmCloseDialogStore();
  const { buttonMapping, buttonFetch, setButtonMapping, setButtonFetch } =
    useWorkflowGroupButtonsSpatialStore();
  const { setDataForSendBackDialog } = useConfirmSendBackDialogStore();
  const { setDataForReallocateDialog } = useConfirmReallocateDialogStore();
  const { setDataForRejectDialog } = useConfirmRejectDialogStore();
  const history = useHistory();
  const { currentFormTitle } = useCommonProductStore();
  const { isLLS } = CommunityProperty.getFlagOfStates();
  //#endregion

  //#region <Local state>
  const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
  const localNotificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [workflowInitData, setWorkflowInitData] =
    useState<DTO_Workflow_PIC_Amalgamate>();
  const [isDifferentType, setIsDifferentType] = useState<boolean>(false);
  const [listIDs, setListIDs] = useState<number[]>(picIDs);
  const [isLoadingProcess, setIsLoadingProcess] = useState<
    WorkflowProcessMode | undefined | ELoadingModeWorkflow
  >();
  //#endregion

  //Hooks
  const {
    isReadOnly,
    isIncompleteMode,
    isShowCancelWorkflowButton,
    statusBadge,
    isFromActionList,
    isToBeApprovalMode,
  } = usePropertyWorkflow(dataFromActionList);

  const isFirstSave = useMemo(() => {
    const draftId =
      workflowInitData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id;
    return isNil(draftId) || draftId === 0;
  }, [workflowInitData]);

  const workflowDraftId = useMemo(() => {
    return (
      workflowInitData?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id ?? 0
    );
  }, [workflowInitData]);

  const workflowName = useMemo(() => {
    const formId = workflowInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id;
    return currentFormTitle(formId ?? 0) || "Amalgamate PIC";
  }, [
    currentFormTitle,
    workflowInitData?.WorkflowHeader?.WorkflowDraft?.WD_Form_Id,
  ]);

  //#region <Title header>
  const titleHeader = useMemo(() => {
    return getTitleWorkflow(workflowName, prefixTitle, suffixTitle);
  }, [prefixTitle, suffixTitle, workflowName]);
  //#region

  //#region <Initial value>
  const initialValue = useMemo((): any => {
    const workflowDetail = workflowInitData?.WorkflowDetail;

    //---step Details---
    let lovReason: any = workflowDetail?.LOVs?.ReasonForCreation;
    lovReason = convertValueLOVToNumber(lovReason ?? [], "Code") ?? [];
    let assessmentGroupId;
    if (workflowInitData?.WorkflowHeader?.OfficerAssessmentGroups?.[0]?.Code) {
      assessmentGroupId = parseInt(
        workflowInitData?.WorkflowHeader?.OfficerAssessmentGroups?.[0]?.Code
      );
    }
    let initDetail = {
      ...workflowDetail?.Details,
      _option: {
        Instructions: getWorkflowInstructions(
          workflowInitData?.WorkflowHeader?.WorkflowApprovals ?? []
        ),
        ReasonForCreation: lovReason,
        Officer: workflowInitData?.WorkflowHeader?.Officer,
        WD_Assessment_Group_Id:
          workflowInitData?.WorkflowHeader?.WorkflowDraft
            ?.WD_Assessment_Group_Id ?? assessmentGroupId,
        OfficerAssessmentGroups: convertValueLOVToNumber(
          workflowInitData?.WorkflowHeader?.OfficerAssessmentGroups ?? [],
          "Code"
        ),
      },
    };
    let newPicList: VO_PIC[] = [];
    const picList = workflowDetail?.Associations?.PICs ?? [];
    if (picList?.length) {
      newPicList = picList.map((item: VO_PIC) => {
        return {
          ...item,
          Is_Primary:
            item.PIC_Id ===
            workflowDetail?.Associations?.Selected_Primary_PIC_Id,
        };
      });
    }
    let newHolding: VO_Assessment_w_Detail[] = [];
    const holdingList = workflowDetail?.Associations?.Primary_Assessments ?? [];
    if (holdingList?.length) {
      newHolding = holdingList?.map((item: VO_Assessment_w_Detail) => {
        return {
          ...item,
          Is_Primary:
            item.Assessment_Id ===
              workflowDetail?.Associations?.Selected_Primary_Assessment_Id ??
            workflowDetail?.Associations?.Default_Primary_Assessment_Id,
        };
      });
    }
    //---step Association---
    let initAssociation = {
      ...workflowDetail?.Associations,
      Selected_Primary_Assessment_Id:
        workflowDetail?.Associations?.Selected_Primary_Assessment_Id ??
        workflowDetail?.Associations?.Default_Primary_Assessment_Id,
      _option: {
        PICs: newPicList,
        Primary_Assessments: newHolding,
      },
    };

    let lovPICDetail: any = {};
    const district = workflowDetail?.PICDetails?.Districts;
    const rlpBoard = workflowDetail?.PICDetails?.RLP_Boards;
    const picLandUse = workflowDetail?.PICDetails?.PIC_Land_Uses;
    const picManager = workflowDetail?.PICDetails?.PIC_Manager_Ids;
    if (!isEmpty(picManager)) {
      lovPICDetail.Managers = processDataLovInAmalgamate(picManager);
    }
    if (!isEmpty(district)) {
      lovPICDetail.Districts = processDataLovInAmalgamate(district);
    }
    if (!isEmpty(rlpBoard)) {
      lovPICDetail.RLP_Boards = processDataLovInAmalgamate(rlpBoard);
    }
    if (!isEmpty(picLandUse)) {
      lovPICDetail.PIC_Land_Uses = processDataLovInAmalgamate(picLandUse);
    }
    let picPrimary = undefined;
    if (picList?.length) {
      picPrimary = picList?.find(
        (item: VO_PIC) =>
          item?.PIC_Id === workflowDetail?.Associations?.Selected_Primary_PIC_Id
      );
    }
    let holdingPrimary: VO_Assessment_w_Detail | undefined = undefined;
    let rlpBoardName: string = "";
    let rlpBoardId: number | undefined;
    if (holdingList?.length) {
      holdingPrimary = holdingList?.find(
        (item: VO_Assessment_w_Detail) =>
          item?.Assessment_Id ===
            workflowDetail?.Associations?.Selected_Primary_Assessment_Id ??
          workflowDetail?.Associations?.Default_Primary_Assessment_Id
      );
      rlpBoardName = holdingPrimary?.Differential_Category_Name ?? "";
      rlpBoardId = holdingPrimary?.Differential_Category_Id;
    }
    //---step PIC detail---
    //process data GIS
    const gisData =
      workflowInitData?.WorkflowDetail?.PICDetails?.GisReferences?.map(
        (item: DTO_GIS, index: number) => {
          return {
            ...item,
            Id: `exist_${index + 1}`,
          };
        }
      ) ?? [];
    //process data Swine brand
    const swineData =
      workflowInitData?.WorkflowDetail?.PICDetails?.Swines?.map(
        (item: DTO_PIC_Swine, index: number) => {
          return {
            ...item,
            Id: `exist_${index + 1}`,
          };
        }
      ) ?? [];

    let typeName = "";
    if (workflowDetail?.PICDetails?.Default_PIC_Type_Id) {
      typeName =
        workflowDetail?.PICDetails?.PIC_Types?.[
          workflowDetail?.PICDetails?.Default_PIC_Type_Id
        ];
    }
    const lovAgentLicense =
      workflowDetail?.PICDetails?.Agent_License_Numbers ?? [];
    const picManagerId =
      workflowDetail?.PICDetails?.Selected_PIC_Manager_Id ??
      workflowDetail?.PICDetails?.Default_PIC_Manager_Id ??
      0;
    const picLandUseId =
      workflowDetail?.PICDetails?.Selected_PIC_Land_Use_Id ??
      workflowDetail?.PICDetails?.Default_PIC_Land_Use_Id ??
      0;
    const districtId =
      workflowDetail?.PICDetails?.Selected_District_Id ??
      workflowDetail?.PICDetails?.Default_District_Id ??
      0;
    let initPICDetail = {
      ...workflowDetail?.PICDetails,
      Selected_PIC_Number:
        workflowDetail?.PICDetails?.Selected_PIC_Number ??
        workflowDetail?.PICDetails?.Default_PIC_Number,
      Selected_PIC_Manager_Id: getActualValueOption(
        lovPICDetail?.Managers ?? [],
        picManagerId
      ),
      Selected_PIC_Type_Name: typeName,
      Selected_PIC_Land_Use_Id: getActualValueOption(
        lovPICDetail?.PIC_Land_Uses ?? [],
        picLandUseId
      ),
      Selected_Agent_License_Number:
        workflowDetail?.PICDetails?.Selected_Agent_License_Number ??
        workflowDetail?.PICDetails?.Default_Agent_License_Number,
      Selected_District_Id: getActualValueOption(
        lovPICDetail?.Districts ?? [],
        districtId
      ),
      Selected_RLP_Board_Id: rlpBoardId,
      Default_RLP_Board_Id: rlpBoardId,
      _option: {
        isMandatoryPicManager: lovPICDetail?.Managers?.length ? true : false,
        isMandatoryLandUse: lovPICDetail?.PIC_Land_Uses?.length ? true : false,
        isMandatoryAgentLicense: lovAgentLicense?.length ? true : false,
        isMandatoryDistrict: lovPICDetail?.Districts?.length ? true : false,
        isMandatoryRLPBoard: lovPICDetail?.RLP_Boards?.length ? true : false,
        Districts: lovPICDetail?.Districts ?? [],
        RLP_Boards: rlpBoardName,
        Managers: lovPICDetail?.Managers ?? [],
        PIC_Land_Uses: lovPICDetail?.PIC_Land_Uses ?? [],
        Primary_Holding:
          workflowDetail?.Associations?.Selected_Primary_Assessment_Id ??
          workflowDetail?.Associations?.Default_Primary_Assessment_Id,
        Occupier: holdingPrimary?.Owners_Name,
        PIC_Address: picPrimary?.PropertyAddress,
        GISType: workflowDetail?.LOVs?.GISType,
      },
      GisReferences: gisData ?? [],
      Swines: swineData ?? [],
    };

    //---step Secondary Workflow---
    const initialWorkflow =
      secondaryWorkflowUtilProcessing.processDataInit(workflowInitData);

    return {
      [EKeysOfStepsAmalgamatePIC.Detail]: initDetail,
      [EKeysOfStepsAmalgamatePIC.Association]: initAssociation,
      [EKeysOfStepsAmalgamatePIC.PICDetail]: initPICDetail,
      [EKeysOfStepsAmalgamatePIC.Collection]:
        collectionUtilProcessing.processDataInit<Collection[]>(
          workflowInitData
        ),
      [EKeysOfStepsAmalgamatePIC.Document]: {},
      [EKeysOfStepsAmalgamatePIC.Comment]: {},
      [EKeysOfStepsAmalgamatePIC.Workflow]: initialWorkflow,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowInitData]);
  //#region

  //#region <Handle submit>
  const handleSubmit = async (data: any, buttonId?: string, actions?: any) => {
    //reset notification before go next calling
    formStepRef?.current
      ?.getNotificationFormStep()
      ?.current?.resetNotifications();
    switch (buttonId) {
      case EListSubmitButton.Approve:
        handleApproveProcess(processData(data));
        break;
      case EListSubmitButton.SendBack:
        handleConfirmSendBackProcess(processData(data));
        break;
      case EListSubmitButton.Reallocate:
        handleConfirmReallocateProcess(processData(data));
        break;
      case EListSubmitButton.Reject:
        handleRejectButton(processData(data));
        break;
      case EListSubmitButton.Save:
        await handleSaveAndNext(processData(data));
        break;
      case EListSubmitButton.SaveWorkflow:
        await handleSaveAndNext(processData(data));
        break;
      case EListSubmitButton.Finish:
        handleConfirmFinish(data);
        break;
      case EListSubmitButton.Cancel:
      case EListSubmitButton.ConfirmCloseNo:
        handleCancelButton(processData(data));
        break;
      case EListSubmitButton.ConfirmCloseYes:
        await handleParkProcess(
          processData(data),
          EListSubmitButton.ConfirmCloseYes
        );
        break;
      case EListSubmitButton.Park:
      case EListSubmitButton.Close:
        await handleParkProcess(processData(data));
        break;
      case EListSubmitButton.MappingSpatialWorkflow:
        await handleMappingSpatialProcess(processData(data));
        break;
      case EListSubmitButton.FetchSpatialWorkflow:
        await handleFetchSpatialProcess(processData(data));
        break;
    }
  };
  //#endregion

  /**
   * Handle reject process
   */
  const handleRejectButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForRejectDialog({
        rejectCallback: postAmalgamatePIC,
        dataReject: data,
        defaultSuccessMessage: "Amalgamate PIC was rejected successfully.",
        defaultErrorMessage: "Amalgamate PIC could not be rejected.",
      });
    } else {
      onClose();
    }
  };

  /**
   * handle confirm send back workflow process
   * @param payload
   */
  const handleConfirmSendBackProcess = (
    payload: DTO_Workflow_PIC_Amalgamate
  ) => {
    setDataForSendBackDialog({
      sendBackCallback: postAmalgamatePIC,
      dataSendBack: payload,
      defaultSuccessMessage: "Amalgamate PIC was sent back successfully.",
      defaultErrorMessage: "Amalgamate PIC could not be sent back.",
    });
  };

  /**
   * handle confirm reallocate workflow process
   * @param payload
   */
  const handleConfirmReallocateProcess = (
    payload: DTO_Workflow_PIC_Amalgamate
  ) => {
    setDataForReallocateDialog({
      reallocateCallback: postAmalgamatePIC,
      dataReallocate: payload,
      defaultSuccessMessage: "Amalgamate PIC was reallocated successfully.",
      defaultErrorMessage: "Amalgamate PIC could not be reallocated.",
    });
  };

  //#region <Process data>
  const processData = (data: any): any => {
    let workflowDetail: any = { ...workflowInitData?.WorkflowDetail };
    const workflowHeader =
      workflowInitData?.WorkflowHeader as DTO_WorkflowHeader;
    const newData = cloneDeep(data);
    const sendSteps = pickBy(newData, (value, key) => {
      if (keysOfStepsAmalgamatePIC.includes(key as EKeysOfStepsAmalgamatePIC)) {
        return { [key]: value };
      }
    });
    for (const [key, value] of Object.entries(sendSteps)) {
      let dataStep;
      if (key === EKeysOfStepsAmalgamatePIC.Collection) {
        dataStep = [...(value ?? [])];
      } else {
        dataStep = { ...value };
      }
      if (dataStep && dataStep?._option) {
        delete dataStep._option;
      }
      workflowDetail[key] = dataStep;
      if (key === EKeysOfStepsAmalgamatePIC.Workflow) {
        secondaryWorkflowUtilProcessing.processData(
          value,
          workflowHeader,
          workflowDetail
        );
      }
    }
    if (workflowDraftId === 0 && isShowRegionDropDown && workflowHeader) {
      workflowHeader.WorkflowDraft.WD_Assessment_Group_Id =
        data?.[
          EKeysOfStepsAmalgamatePIC.Detail
        ]?._option?.WD_Assessment_Group_Id;
    }
    return {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    };
  };
  //#endregion

  //#region <Handle process workflow>
  /**
   * common function
   * handle calling api with multiple process
   * @param props
   */
  const handleProcessWorkflow = async (
    props: INewProcessWorkflow<DTO_Workflow_PIC_Amalgamate>
  ) => {
    const {
      actionSuccess,
      defaultFailedMessage,
      setLoading,
      modeProcess,
      payload,
    } = props;
    const response = await postAmalgamatePIC(modeProcess, payload);
    setLoading();
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        if (props?.actionFail) props?.actionFail(response);
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    } else {
      if (props?.actionFail) props?.actionFail(response);
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
    if (props?.actionClose) props.actionClose();
  };
  //#endregion

  //#region <Handle next button>
  /**
   * @param data
   * @param step
   * @param keyStep
   * @returns
   */
  const handleNextButton = async (data: any) => {
    const newData = { ...data };
    const processPayload = processData(newData);
    //send data to call api save
    return handleSaveAndNext(processPayload);
  };
  //#endregion

  //#region <Handle save and next>
  const handleSaveAndNext = async (
    payload: DTO_Workflow_PIC_Amalgamate
  ): Promise<boolean> => {
    //check condition use for Save button
    setIsLoadingProcess(WorkflowProcessMode.Save);
    //Calling process Save at next button
    const response = await postAmalgamatePIC(WorkflowProcessMode.Save, payload);
    setIsLoadingProcess(undefined);
    //set default notification
    const defaultFailedMessage = "Amalgamate PIC could not be saved.";
    formStepRef?.current
      ?.getNotificationFormStep()
      ?.current?.resetNotifications();
    if (
      response.data?.ErrorMessage ===
      "PICs from different PIC Types were detected. PIC Amalgamate can only be done on PICs with the same PIC Type."
    ) {
      setIsDifferentType(true);
    }
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      setWorkflowInitData(response?.data?.ReturnData);
      setListIDs(
        response?.data?.ReturnData?.WorkflowDetail?.Associations?.PIC_Ids ?? []
      );
      return true;
    } else {
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
    return false;
  };
  //#endregion

  //#region <Handle finish process>
  /**
   * @param payload
   */
  const handleFinishProcess = async (payload: DTO_Workflow_PIC_Amalgamate) => {
    //set loading button
    setIsLoadingProcess(WorkflowProcessMode.Finish);
    //props send to process workflow
    const finishProps: INewProcessWorkflow<DTO_Workflow_PIC_Amalgamate> = {
      payload: payload,
      actionSuccess: (e) => {
        const notificationContent: IAppNotificationItemAddProps = {
          title:
            e?.SuccessMessage ??
            e?.Notification ??
            "PIC was amalgamated successfully.",
          type: "success",
        };
        onClose();
        if (
          workflowInitData?.WorkflowHeader?.WorkflowApprovals?.length === 0 &&
          !isNil(e?.Component_ID)
        ) {
          history.push(`${PROPERTY_PICS_ROUTE}/${e?.Component_ID}`, {
            notification: notificationContent,
          });
        } else {
          pushNotification(notificationContent);
        }
        if (!isFromActionList) {
          //Refresh GIS Reference accordion if the workflow is auto approved
          if (e?.WorkflowApprovalStatus === WorkflowApprovalStatus.Approved) {
            eventEmitter.emit(GISAccordionEventType.reloadGISReferenceGrid);
          }
        }
      },
      setLoading: () => {
        setIsLoadingProcess(undefined);
      },
      defaultFailedMessage: "PIC could not be amalgamated.",
      modeProcess: WorkflowProcessMode.Finish,
    };
    //calling api process workflow
    await handleProcessWorkflow(finishProps);
  };
  //#endregion

  //#region <Handle confirm finish>
  const handleConfirmFinish = (payload: DTO_Workflow_PIC_Amalgamate) => {
    const dataProcessed = processData(payload);
    const finishCallback = function async() {
      return handleFinishProcess(dataProcessed);
    };
    setDataForFinishDialog({
      finishCallback,
      confirmMessage:
        "The PIC will be amalgamated based on the information provided. Are you sure you want to submit?",
    });
  };
  //#endregion

  //#region <Handle park process>
  /**
   * @param payload
   * @param mode
   */
  const handleParkProcess = async (
    payload: DTO_Workflow_PIC_Amalgamate,
    mode?: EListSubmitButton
  ) => {
    //set loading button and dialog
    setIsLoadingProcess(WorkflowProcessMode.Park);
    mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
    const actionCloseRetainDialog = () => {
      setIsLoadingClose(false);
      setDataForCloseDialog();
    };
    //props send to process workflow
    const parkProps: INewProcessWorkflow<DTO_Workflow_PIC_Amalgamate> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title:
            e?.Notification ??
            e?.SuccessMessage ??
            "Amalgamate PIC was parked successfully.",
          type: "success",
        });
        isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
      },
      setLoading: () => {
        setIsLoadingProcess(undefined);
      },
      actionClose: () => {
        mode === EListSubmitButton.ConfirmCloseYes && actionCloseRetainDialog();
      },
      defaultFailedMessage: "Amalgamate PIC could not be parked.",
      modeProcess: WorkflowProcessMode.Park,
    };
    //calling api process workflow
    await handleProcessWorkflow(parkProps);
  };
  //#endregion

  //#region <Handle Approve process>
  /**
   * @param payload
   * @param mode
   */
  const handleApproveProcess = async (payload: DTO_Workflow_PIC_Amalgamate) => {
    //set loading button and dialog
    setIsLoadingProcess(WorkflowProcessMode.Approve);

    //props send to process workflow
    const approveProps: INewProcessWorkflow<DTO_Workflow_PIC_Amalgamate> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title:
            e?.Notification ??
            e?.SuccessMessage ??
            "Amalgamate PIC was approved successfully.",
          type: "success",
        });
        isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
      },
      setLoading: () => {
        setIsLoadingProcess(undefined);
      },
      defaultFailedMessage: "Amalgamate PIC could not be approved.",
      modeProcess: WorkflowProcessMode.Approve,
    };
    //calling api process workflow
    await handleProcessWorkflow(approveProps);
  };
  //#endregion

  //#region <handle cancel button>
  const handleCancelButton = (data: DTO_Workflow_PIC_Amalgamate) => {
    if (isFromActionList || !isFirstSave) {
      setDataForCancelDialog({
        cancelAPI: postAmalgamatePIC,
        dataCancel: data,
        defaultSuccessMessage: "Amalgamate PIC was cancelled successfully",
        defaultErrorMessage: "Amalgamate PIC could not be cancelled.",
      });
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Handle close dialog>
  /**
   * @param renderProps
   */
  const handleCloseDialog = (renderProps: ICCFormStepRender) => {
    if (!isFromActionList && !isFirstSave) {
      //Store submit event
      setDataForCloseDialog({
        closeCallback: renderProps.submitButton.onClick,
      });
    } else if (
      isIncompleteMode &&
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
    ) {
      onClose();
    } else if (
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Incomplete &&
      !isFirstSave
    ) {
      const newEvent = {
        currentTarget: { id: EListSubmitButton.Close },
      };
      renderProps.submitButton.onClick(newEvent);
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Get workflow data>
  /**
   * load initValue for FormStep
   * call once time
   */
  const getWorkflowData = async () => {
    const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
    formStepRef?.current?.setLoadingFormStep(true);
    const response = await getInitialDataAmalgamatePIC(picIDs, workflowDraftId);
    if (
      isSuccessResponse(response) &&
      response?.data &&
      !response?.data?.hasOwnProperty("MessageType")
    ) {
      setWorkflowInitData(response?.data);

      // Set Swine Type
      setLov({
        SwineTypes:
          convertValueLOVToNumber(
            response?.data?.WorkflowDetail?.LOVs?.SwineType ?? [],
            "Code"
          ) ?? [],
      });

      setListIDs(response?.data?.WorkflowDetail?.Associations?.PIC_Ids ?? []);
      if (response.data?.WorkflowHeader) {
        //Get notification for Spatial
        const notificationSpatial = getNotificationSpatialWorkflow(
          response?.data?.WorkflowHeader
        );
        if (!isNil(notificationSpatial)) {
          if (notificationSpatial?.mapping) {
            setButtonMapping((preState: IButtonSpatial) => ({
              ...preState,
              ...notificationSpatial?.mapping,
            }));
          }
          if (notificationSpatial?.fetch) {
            setButtonFetch((preState: IButtonSpatial) => ({
              ...preState,
              ...notificationSpatial?.fetch,
            }));
          }
        }
      }
      formStepRef?.current?.setLoadingFormStep(false);
    } else {
      const responseError = response as APIResponse<
        DTO_Workflow_PIC_Amalgamate | ResponsePacket
      >;
      formStepRef?.current?.setLoadingFormStep(false);
      formStepRef?.current?.setLoadFailedFormStep({
        onReload: () => getWorkflowData(),
        responseError: {
          status: responseError.status,
          error:
            (responseError.data as ResponsePacket)?.Errors ??
            "Load workflow failed",
        },
      });
    }
  };
  //#endregion

  /**
   * set condition to show Region of Officer
   */
  const isShowRegionDropDown = useMemo(() => {
    return (
      workflowInitData?.WorkflowHeader?.OfficerAssessmentGroups &&
      workflowInitData?.WorkflowHeader?.OfficerAssessmentGroups?.length > 1 &&
      workflowDraftId === 0
    );
  }, [
    workflowDraftId,
    workflowInitData?.WorkflowHeader?.OfficerAssessmentGroups,
  ]);

  //#region <Step>
  const steps: IStep[] = [
    {
      label: ELabelAmalgamatePIC.Detail,
      component: DetailAmalgamatePICFormStep,
      visible: true,
      key: EKeysOfStepsAmalgamatePIC.Detail,
      initialValues: initialValue[EKeysOfStepsAmalgamatePIC.Detail],
      options: {
        isReadOnly,
        isLLS,
        isShowRegionDropDown,
        isToBeApprovalMode,
      },
    },
    {
      label: ELabelAmalgamatePIC.Association,
      component: AssociationAmalgamatePICFormStep,
      visible: true,
      key: EKeysOfStepsAmalgamatePIC.Association,
      initialValues: initialValue[EKeysOfStepsAmalgamatePIC.Association],
      options: {
        isReadOnly,
        processNewPicId: { isDifferentType, setIsDifferentType, listIDs },
      },
    },
    {
      label: ELabelAmalgamatePIC.PICDetail,
      component: PicDetailsAmalgamateFormStep,
      visible: true,
      key: EKeysOfStepsAmalgamatePIC.PICDetail,
      initialValues: initialValue[EKeysOfStepsAmalgamatePIC.PICDetail],
      options: {
        isReadOnly,
      },
    },
    {
      label: ELabelAmalgamatePIC.Collection,
      component: CollectionFormStep,
      visible: true,
      key: EKeysOfStepsAmalgamatePIC.Collection,
      initialValues: initialValue[EKeysOfStepsAmalgamatePIC.Collection],
      options: {
        isReadOnly,
      },
    },
    {
      label: ELabelAmalgamatePIC.Comment,
      initialValues: initialValue[EKeysOfStepsAmalgamatePIC.Comment],
      component: CommentsFormStep,
      visible: true,
      key: EKeysOfStepsAmalgamatePIC.Comment,
      customClassName: "cc-comment-step-fixed-height-grid",
      options: {
        isReadOnly,
        workflowDraftId,
        recordType: RECORDTYPE.CommunityProperty_PIC,
      },
    },
    {
      label: ELabelAmalgamatePIC.Document,
      initialValues: initialValue[EKeysOfStepsAmalgamatePIC.Document],
      component: DocumentsFormStep,
      visible: true,
      key: EKeysOfStepsAmalgamatePIC.Document,
      options: {
        isReadOnly,
        workflowDraftId,
        workflowType: WorkflowTypes.PIC_Amalgamate,
      },
    },
    {
      label: ELabelAmalgamatePIC.Workflow,
      initialValues: initialValue[EKeysOfStepsAmalgamatePIC.Workflow],
      component: WorkflowStepFormStep,
      visible: true,
      key: EKeysOfStepsAmalgamatePIC.Workflow,
      options: {
        isReadOnly,
        isFromActionList,
        dataFromActionList,
      },
    },
  ];
  //#endregion
  //#region <Use Effect calling initial data>
  useEffectOnce(() => {
    getWorkflowData();
  });
  //#region

  /**
   * handle mapping button at Spatial
   * Call 2 apis (Process, Get)
   * @param payload
   */
  const handleMappingSpatialProcess = async (
    payload: DTO_Workflow_PIC_Amalgamate
  ) => {
    //set loading dialog
    setIsLoadingProcess(ELoadingModeWorkflow.ActionLoading);
    //set info mapping
    setButtonMapping((preState: IButtonSpatial) => ({
      ...preState,
      isLoading: true,
    }));
    let mappingInfo: any = {};
    //Calling api process
    const responsePost = await postAmalgamatePIC(
      WorkflowProcessMode.Mapping,
      payload
    );
    if (isSuccessResponse(responsePost)) {
      const resSuccessPost =
        responsePost?.data as ResponseMessageWorkflow<DTO_Workflow_PIC_Amalgamate>;
      if (resSuccessPost?.IsSuccess) {
        //open new tab spatial
        window.open(responsePost?.data?.Gis_Mapping_Launch_Url, "_blank");
        mappingInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (resSuccessPost?.Notification?.length) {
          mappingInfo.loadSuccess = {
            message: {
              messageTitle: resSuccessPost?.Notification,
            },
            type: EBadgeDialogType.SUCCESS,
          };
        }
        setWorkflowInitData(resSuccessPost?.ReturnData);
      } else {
        mappingInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (resSuccessPost?.Notification?.length) {
          mappingInfo.loadFail = {
            message: {
              messageTitle: resSuccessPost?.Notification,
            },
            type: EBadgeDialogType.ERROR,
          };
        }
        if (resSuccessPost?.ErrorMessage?.length) {
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: resSuccessPost?.Notification?.length
                ? resSuccessPost?.Notification
                : "Mapping data failed.",
              description: resSuccessPost?.ErrorMessage,
              type: "error",
              autoClose: false,
            });
        }
      }
    } else {
      //TODO: fix type for temporary
      const responseFail = responsePost?.data as any as IdentityPacket;
      mappingInfo = {
        isLoading: false,
        loadSuccess: undefined,
        loadFail: undefined,
      };
      if (responseFail?.Errors?.length) {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: "Mapping data failed.",
            description: head(responseFail?.Errors),
            type: "error",
            autoClose: false,
          });
      }
    }
    setButtonMapping((preState: IButtonSpatial) => ({
      ...preState,
      ...mappingInfo,
    }));
    setIsLoadingProcess(undefined);
  };

  const handleFetchSpatialProcess = async (
    payload: DTO_Workflow_PIC_Amalgamate
  ) => {
    //set loading dialog
    setIsLoadingProcess(ELoadingModeWorkflow.ActionLoading);
    //set info fetch
    setButtonFetch((preState: IButtonSpatial) => ({
      ...preState,
      isLoading: true,
      loadFail: undefined,
      loadSuccess: undefined,
    }));
    let fetchInfo: any = {};
    const responsePost = await postAmalgamatePIC(
      WorkflowProcessMode.Fetch,
      payload
    );
    if (isSuccessResponse(responsePost)) {
      const resSuccessPost =
        responsePost?.data as ResponseMessageWorkflow<DTO_Workflow_PIC_Amalgamate>;
      if (resSuccessPost?.IsSuccess) {
        fetchInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (resSuccessPost?.Notification?.length) {
          fetchInfo.loadSuccess = {
            message: {
              messageTitle: resSuccessPost?.Notification,
            },
            type: EBadgeDialogType.SUCCESS,
          };
        }
        setWorkflowInitData(resSuccessPost?.ReturnData);
      } else {
        fetchInfo = {
          isLoading: false,
          loadSuccess: undefined,
          loadFail: undefined,
        };
        if (resSuccessPost?.Notification?.length) {
          fetchInfo.loadFail = {
            message: {
              messageTitle: resSuccessPost?.Notification,
            },
            type: EBadgeDialogType.ERROR,
          };
        }
        if (resSuccessPost?.ErrorMessage?.length) {
          formStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: resSuccessPost?.Notification?.length
                ? resSuccessPost?.Notification
                : "Fetch data failed.",
              description: resSuccessPost?.ErrorMessage,
              type: "error",
              autoClose: false,
            });
        }
      }
    } else {
      //TODO: fix type for temporary
      const resFailPost = responsePost?.data as any as IdentityPacket;
      fetchInfo = {
        isLoading: false,
        loadSuccess: undefined,
        loadFail: undefined,
      };
      if (resFailPost?.Errors?.length) {
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: "Fetch data failed.",
            description: head(resFailPost?.Errors),
            type: "error",
            autoClose: false,
          });
      }
    }
    setButtonFetch((preState: IButtonSpatial) => ({
      ...preState,
      ...fetchInfo,
    }));
    setIsLoadingProcess(undefined);
  };

  const newBadge = useMemo(() => {
    if (!isNil(statusBadge)) return statusBadge;
    let newStatus: undefined | IBadgeDialog[];
    if (isSpatialGISMode(workflowInitData?.WorkflowHeader)) {
      newStatus = [];
      if (
        buttonMapping?.loadSuccess &&
        buttonMapping?.loadSuccess?.message?.messageTitle
      ) {
        newStatus.push({
          content:
            buttonMapping?.loadSuccess?.message?.messageTitle ??
            "Mapping in progress",
        });
      } else if (
        buttonMapping?.loadFail &&
        buttonMapping?.loadFail?.message?.messageTitle
      ) {
        newStatus.push({
          content:
            buttonMapping?.loadFail?.message?.messageTitle ??
            "Mapping in failed",
          type: EBadgeDialogType.ERROR,
        });
      }
      if (
        buttonFetch?.loadSuccess &&
        buttonFetch?.loadSuccess?.message?.messageTitle
      )
        newStatus.push({
          content:
            buttonFetch?.loadSuccess?.message?.messageTitle ??
            "Fetch data successful",
          type: EBadgeDialogType.SUCCESS,
        });
      else if (
        buttonFetch?.loadFail &&
        buttonFetch?.loadFail?.message?.messageTitle
      )
        newStatus.push({
          content:
            buttonFetch?.loadFail?.message?.messageTitle ?? "Fetch data failed",
          type: EBadgeDialogType.ERROR,
        });
    }
    return newStatus;
  }, [
    statusBadge,
    workflowInitData?.WorkflowHeader,
    buttonMapping?.loadSuccess,
    buttonMapping?.loadFail,
    buttonFetch?.loadSuccess,
    buttonFetch?.loadFail,
  ]);

  return (
    <CCFormStep
      ref={formStepRef}
      onSubmit={handleSubmit}
      localNotificationRef={localNotificationRef?.current ?? null}
      listButtonId={listSubmitButton}
      initialSteps={steps}
      initialValues={initialValue}
      saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          titleHeader={titleHeader}
          onClose={() => handleCloseDialog(renderProps)}
          bodyElement={renderProps.children}
          badge={newBadge}
          disabled={isLoadingProcess !== undefined}
          footerElement={
            <>
              <div className={"cc-dialog-footer-actions-right"}>
                {/* Park button */}
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button
                    iconClass={
                      isLoadingProcess === WorkflowProcessMode.Park
                        ? "fas fa-spinner fa-spin"
                        : ""
                    }
                    className={"cc-dialog-button"}
                    id={EListSubmitButton.Park}
                    onClick={renderProps.submitButton.onClick}
                    disabled={
                      renderProps.nextButton.disabled ||
                      isLoadingProcess === WorkflowProcessMode.Park
                    }
                  >
                    Park
                  </Button>
                )}
                {/* Cancel button */}
                {isShowCancelWorkflowButton && (
                  <Button
                    className={"cc-dialog-button"}
                    disabled={!isNil(isLoadingProcess)}
                    id={EListSubmitButton.Cancel}
                    onClick={renderProps.submitButton.onClick}
                  >
                    Cancel
                  </Button>
                )}
                {isToBeApprovalMode &&
                  workflowInitData?.WorkflowHeader?.OfficerCanApprove && (
                    <>
                      {/* Send Back button */}
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.SendBack}
                        disabled={
                          isLoadingProcess === WorkflowProcessMode.SendBack ||
                          renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.SendBack
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                        onClick={renderProps.submitButton.onClick}
                      >
                        Send Back
                      </Button>
                      {/* Reallocate button */}
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Reallocate}
                        disabled={
                          isLoadingProcess === WorkflowProcessMode.Reallocate ||
                          renderProps.nextButton.disabled
                        }
                        className={"cc-dialog-button"}
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.Reallocate
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                        onClick={renderProps.submitButton.onClick}
                      >
                        Reallocate
                      </Button>
                      {/* Approve button */}
                      <Button
                        themeColor="primary"
                        id={EListSubmitButton.Approve}
                        disabled={
                          isLoadingProcess === WorkflowProcessMode.Approve ||
                          renderProps.nextButton.disabled ||
                          isDisabledApproveButton(
                            workflowInitData?.WorkflowHeader
                          )
                        }
                        className={"cc-dialog-button"}
                        onClick={renderProps.submitButton.onClick}
                        iconClass={
                          isLoadingProcess === WorkflowProcessMode.Approve
                            ? "fas fa-spinner fa-spin"
                            : ""
                        }
                      >
                        Approve
                      </Button>
                      {/* Reject button */}
                      {isHiddenRejectButton(
                        workflowInitData?.WorkflowHeader
                      ) ? null : (
                        <Button
                          themeColor="primary"
                          id={EListSubmitButton.Reject}
                          disabled={renderProps.nextButton.disabled}
                          className={"cc-dialog-button"}
                          onClick={renderProps.submitButton.onClick}
                        >
                          Reject
                        </Button>
                      )}
                    </>
                  )}
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                {(isToBeApprovalMode || isReadOnly) &&
                renderProps.isLastStep ? null : (
                  <Button
                    themeColor="primary"
                    id={
                      !renderProps.isLastStep &&
                      (isToBeApprovalMode || isReadOnly)
                        ? "cc-next-step-button"
                        : renderProps.nextButton.idButton
                    }
                    disabled={
                      isLoadingProcess === WorkflowProcessMode.Save ||
                      renderProps.nextButton.disabled
                    }
                    iconClass={
                      isLoadingProcess === WorkflowProcessMode.Save
                        ? "fas fa-spinner fa-spin"
                        : ""
                    }
                    className={"cc-dialog-button"}
                    onClick={renderProps.nextButton.onClick}
                  >
                    {isLoadingProcess === WorkflowProcessMode.Save
                      ? "Saving"
                      : renderProps.nextButton.label}
                  </Button>
                )}
              </div>

              <div className="cc-dialog-footer-actions-left">
                <WorkflowGroupButtonsSpatial
                  workflowHeader={workflowInitData?.WorkflowHeader}
                  actionSubmit={renderProps.nextButton.onClick}
                />
              </div>
            </>
          }
        />
      )}
    />
  );
};
