import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { loadViewConfiguresColumns } from "@app/products/property/api";
import { AssessmentDetailTab } from "@app/products/property/assessments/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_ASSESSMENT_ROUTE } from "@app/products/property/assessments/[id]/constant";
import { ChangeInstalmentButton } from "@app/products/property/assessments/components/action-bar/buttons/change-instalment/_index";
import { HousekeepingButton } from "@app/products/property/assessments/components/action-bar/buttons/housekeeping/_index";
import { ReAllocationOfCreditsButton } from "@app/products/property/assessments/components/action-bar/buttons/re-allocation-of-credits/_index";
import { colMailBoxListAll } from "@app/products/property/assessments/mailbox-list/all/config";
import { ASSESSMENTS_MAILBOX_LIST_VIEW_URL } from "@app/products/property/assessments/mailbox-list/all/constant";
import { VO_Assessment_Mailbox } from "@app/products/property/assessments/mailbox-list/all/model";
import {
  getBookmarkListViewDetail,
  getBookmarkListViewDisplayName,
} from "@app/products/property/assessments/mailbox-list/all/util";
import {
  getBookmarkDetail,
  getBookmarkDisplayName,
} from "@app/products/property/assessments/mailbox-list/util";
import { assessmentsRoute } from "@app/products/property/assessments/route";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyContactTab } from "@app/products/property/components/reference-sidebar/contact/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_Assessment_Mailbox>();
export default () => {
  const [colsConfigured, setColsConfigured] = useState<IColumnFields[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={assessmentsRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Assessment}
        recordType={RECORDTYPE.CommunityProperty_Mail_Box}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Mail_Box}
        keyField={"assessmentId"}
      />,
      <CCNavButton title={"Tools"} type="more">
        <HousekeepingButton />
        <ReAllocationOfCreditsButton />
        <ChangeInstalmentButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_ASSESSMENT_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Unknown}
        detail={getBookmarkDetail}
        displayName={getBookmarkDisplayName}
        listViewDisplayName={getBookmarkListViewDisplayName}
        listViewDetail={getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AssessmentDetailTab /> },
      { title: "Map", component: <MapTab addressField="propertyAddress" /> },
      {
        title: "Contacts",
        component: (
          <PropertyContactTab
            //Hyperlink open Assessment -> Use Assessment
            componentNumber={eComponent.Assessment}
          />
        ),
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            //Hyperlink open Assessment -> Use Assessment
            recordType={RECORDTYPE.CommunityProperty_Assessment}
          />
        ),
      },
    ],
  });

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.Assessment_MailBoxList,
      colMailBoxListAll
    );
    if (Array.isArray(response)) {
      setColsConfigured(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCProductListView
      dataUrl={ASSESSMENTS_MAILBOX_LIST_VIEW_URL}
      columnFields={colsConfigured}
      primaryField={nameOf("Mailbox_Id")}
    />
  );
};
