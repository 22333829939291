import { FINANCE_FEE_MANAGE_ROUTE } from "@app/core/fees/[id]/constant";
import { Fees } from "@app/core/invoice/invoice-item-accordion/model";
import {
  BOOLEAN_FORMAT,
  CURRENCY_FORMAT,
  DATE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<Fees>();
export const colInvoiceItemConfig: IColumnFields[] = [
  {
    field: nameOf("Type"),
    title: "Type",
    linkTo: (dataItem: Fees) => {
      return `${FINANCE_FEE_MANAGE_ROUTE}/${dataItem?.ID}`;
    },
  },
  {
    field: nameOf("ID"),
    title: "ID",
  },
  {
    field: nameOf("RecordType_Id"),
    title: "Record Type ID",
  },
  {
    field: nameOf("Invoice"),
    title: "Invoice #",
  },
  {
    field: nameOf("InvoiceDate"),
    title: "Invoice Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ServiceDate"),
    title: "Service Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("ItemType"),
    title: "Item Type",
  },
  {
    field: nameOf("CodeCost"),
    title: "Cost Code",
  },
  {
    field: nameOf("FeeTypeProductId"),
    title: "Fee Product Id",
  },
  {
    field: nameOf("Amount"),
    title: "Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("SubsidyTypeName"),
    title: "Subsidy",
  },
  {
    field: nameOf("SubsidyProductId"),
    title: "Subsidy Product Id",
  },
  {
    field: nameOf("SubsidyAmount"),
    title: "Subsidy Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("FinalAmount"),
    title: "Final Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Owing"),
    title: "Owing",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("ExportedToFinance"),
    title: "Exported to Finance",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: nameOf("U2GFeeOS"),
    title: "Fee OS",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("GSTFee"),
    title: "GST Fee",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("PaidInFull"),
    title: "Paid In Full",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];
