import { history } from "@/AppRoutes";
import { postCheckIsSuperAdmin } from "@app/core/notices/[id]/api";
import {
  getAnimalsKennelById,
  getTotalRelatedAnimalKennelsRecords,
  kennelHandler,
  postKennelDeregister,
  postKennelIssueRegistration,
  postKennelReactivate,
  postKennelRenew,
  saveAnimalKennel,
} from "@app/products/animals/kennels/[id]/api";
import { ANIMALS_KENNEL_MANAGE_PAGE } from "@app/products/animals/kennels/[id]/constant";
import {
  AnimalsKennel,
  InitGetKennelData,
  KennelFormHandlerRequest,
  KennelUIControl,
  Svc_FormAction_Kennel,
  Svc_Kennel_Workflow_DateFromDateTo,
} from "@app/products/animals/kennels/[id]/model";
import { ViewActionButton } from "@app/products/crms/model";
import { ProvideReason } from "@app/products/town-planning/ppr/components/action-bar/dialogs/reason/model";
import { APIResponse, APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { TypeSubmitActions } from "@common/constants/enumerations";
import { APIResponseStatus } from "@common/constants/response-status";
import { DateFromDateTo } from "@common/dialog/date-to-from/model";
import { DBRowState } from "@common/models/baseClassStandard";
import { ICCLocalNotificationHandle } from "@components/cc-app-notification/_index";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class AnimalKennelStore {
  private _isLoading: boolean = false;
  private _isLoadingDetails: boolean = false;
  private _animalsKennel?: AnimalsKennel = undefined;
  private _isFormModified: boolean = false;
  private _isSuperAdmin: boolean = false;
  private _initData?: InitGetKennelData = undefined;
  private _totalRelatedAnimalRecords: number = 0;
  private _osFee?: number = undefined;
  private _isInactive?: boolean = false;
  private _isLoadingSuperAdmin?: boolean = false;
  private _UIControl?: KennelUIControl = undefined;
  private _responseDetailLoadError?: APIResponseError = undefined;
  private _securityViewActionButton?: ViewActionButton[] = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _isWorkflowLoading: boolean = false;
  private _isShowKennelIssueRegistrationDialog: boolean = false;
  private _isShowKennelRenewDialog: boolean = false;
  private _isShowKennelDeregisterDialog: boolean = false;
  private _isShowKennelReactivateDialog: boolean = false;
  private _alerts?: IAppNotificationItemAddProps[] = undefined;
  private _isReIssue: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isInactive() {
    return this._isInactive;
  }
  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get isReIssue() {
    return this._isReIssue;
  }
  setIsReIssue = (isReIssue: boolean) => {
    runInAction(() => {
      this._isReIssue = isReIssue;
    });
  };

  get initData() {
    return this._initData;
  }
  setInitData = (initData: InitGetKennelData) => {
    runInAction(() => {
      this._initData = initData;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingDetails() {
    return this._isLoadingDetails;
  }
  setIsLoadingDetails = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoadingDetails = isLoading;
    });
  };

  get responseDetailLoadError() {
    return toJS(this._responseDetailLoadError);
  }
  setResponseDetailLoadError = (responseDetailLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseDetailLoadError = responseDetailLoadError;
    });
  };

  get animalsKennel() {
    return this._animalsKennel;
  }
  setAnimalsKennel = (animalsKennel?: AnimalsKennel) => {
    runInAction(() => {
      this._animalsKennel = animalsKennel;
    });
  };
  get osFee() {
    return this._osFee;
  }
  setOSFee = (osFee?: number) => {
    runInAction(() => {
      this._osFee = osFee;
    });
  };

  get isFormModified() {
    return this._isFormModified;
  }
  setIsFormModified = (isFormModified: boolean) => {
    runInAction(() => {
      this._isFormModified = isFormModified;
    });
  };

  get securityViewActionButton() {
    return this._securityViewActionButton;
  }
  setSecurityViewActionButton = (
    securityViewActionButton?: ViewActionButton[]
  ) => {
    runInAction(() => {
      this._securityViewActionButton = securityViewActionButton;
    });
  };

  get totalRelatedAnimalRecords() {
    return this._totalRelatedAnimalRecords;
  }
  setTotalRelatedAnimalRecords = (totalRelatedAnimalRecords: number) => {
    runInAction(() => {
      this._totalRelatedAnimalRecords = totalRelatedAnimalRecords;
    });
  };

  get isSuperAdmin() {
    return toJS(this._isSuperAdmin);
  }
  setIsSuperAdmin = (isSuperAdmin: boolean) => {
    runInAction(() => {
      this._isSuperAdmin = isSuperAdmin;
    });
  };

  get isLoadingSuperAdmin() {
    return this._isLoadingSuperAdmin;
  }
  setIsLoadingSuperAdmin = (isLoadingSuperAdmin: boolean) => {
    runInAction(() => {
      this._isLoadingSuperAdmin = isLoadingSuperAdmin;
    });
  };

  get animalKennelId() {
    return toJS(this._animalsKennel?.Kennel_ID);
  }

  get UIControl() {
    return toJS(this._UIControl);
  }
  setUIControl = (UIControl: KennelUIControl) => {
    runInAction(() => {
      this._UIControl = UIControl;
    });
  };

  get isWorkflowLoading() {
    return this._isWorkflowLoading;
  }
  setIsWorkflowLoading = (isWorkflowLoading: boolean) => {
    runInAction(() => {
      this._isWorkflowLoading = isWorkflowLoading;
    });
  };

  get isShowKennelIssueRegistrationDialog() {
    return this._isShowKennelIssueRegistrationDialog;
  }
  setIsShowKennelIssueRegistrationDialog = (
    isShowKennelIssueRegistrationDialog: boolean
  ) => {
    runInAction(() => {
      this._isShowKennelIssueRegistrationDialog =
        isShowKennelIssueRegistrationDialog;
    });
  };

  get isShowKennelRenewDialog() {
    return this._isShowKennelRenewDialog;
  }
  setIsShowKennelRenewDialog = (isShowKennelRenewDialog: boolean) => {
    runInAction(() => {
      this._isShowKennelRenewDialog = isShowKennelRenewDialog;
    });
  };

  get isShowKennelDeregisterDialog() {
    return this._isShowKennelDeregisterDialog;
  }
  setIsShowKennelDeregisterDialog = (isShowKennelDeregisterDialog: boolean) => {
    runInAction(() => {
      this._isShowKennelDeregisterDialog = isShowKennelDeregisterDialog;
    });
  };

  get isShowKennelReactivateDialog() {
    return this._isShowKennelReactivateDialog;
  }
  setIsShowKennelReactivateDialog = (isShowKennelReactivateDialog: boolean) => {
    runInAction(() => {
      this._isShowKennelReactivateDialog = isShowKennelReactivateDialog;
    });
  };

  get alerts() {
    return this._alerts;
  }
  setAlerts = (alerts: IAppNotificationItemAddProps[] | undefined) => {
    runInAction(() => {
      this._alerts = alerts;
    });
  };

  //action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._animalsKennel = undefined;
      this._responseLoadError = undefined;
      this._isFormModified = false;
      this._totalRelatedAnimalRecords = 0;
      this._osFee = undefined;
      this._UIControl = undefined;
      this._isInactive = false;
      this._isSuperAdmin = false;
      this._isLoadingSuperAdmin = false;
      this._securityViewActionButton = undefined;
      this._isWorkflowLoading = false;
      this._isShowKennelIssueRegistrationDialog = false;
      this._isShowKennelRenewDialog = false;
      this._isShowKennelDeregisterDialog = false;
      this._isShowKennelReactivateDialog = false;
      this._alerts = undefined;
    });
  };

  checkIsSuperAdmin = async (userID: number) => {
    this.setIsLoadingSuperAdmin(true);
    this.setResponseLoadError(undefined);
    const response = await postCheckIsSuperAdmin(userID);
    if (isSuccessResponse(response)) {
      this.setIsSuperAdmin(response.data ?? false);
    } else {
      this.setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    this.setIsLoadingSuperAdmin(false);
  };

  loadTotalRelatedAnimalKennelRecords = async (id: number, pid: string) => {
    const response = await getTotalRelatedAnimalKennelsRecords(id, pid);
    if (isSuccessResponse(response) && response?.data) {
      this.setTotalRelatedAnimalRecords(response?.data);
    }
  };

  loadAnimalsKennelById = async (id: number, isNew: boolean = false) => {
    let errorResponse = undefined;
    this.setIsLoading(true);

    const response = await getAnimalsKennelById(isNew ? 0 : id);

    if (isSuccessResponse(response) && response?.data) {
      this.setInitData({
        ...this.initData,
        Address: response.data.ReturnObj.Kennel.Address,
      });
      this.setAnimalsKennel(response.data.ReturnObj.Kennel);
      this.setOSFee(response.data.ReturnObj.OSFee);
      let params: KennelFormHandlerRequest = {
        KennelFormAction: Svc_FormAction_Kennel.SystemInitialise,
        Kennel: response?.data?.ReturnObj.Kennel,
        KennelArgs: {},
        IsFirstTimeLoad: true,
      };
      const responseHandler = await kennelHandler(params);
      if (
        isSuccessResponse(responseHandler) &&
        responseHandler.data?.ReturnObj
      ) {
        const returnObj = responseHandler.data.ReturnObj;
        if (!isNew && this.animalsKennel?.Kennel_ID) {
          await this.loadTotalRelatedAnimalKennelRecords(
            this.animalsKennel?.Kennel_ID,
            this.animalsKennel?.Address.PropertyAssessment.PID_No
          );
        }
        this.setInitData({
          ...this.initData,
          LitAddressUnFormat: returnObj.UIControl?.LitAddressUnFormat,
          LitContactUnFormat: returnObj.UIControl?.LitContactUnFormat,
        });
        this.setUIControl(responseHandler.data.ReturnObj.UIControl);

        // Customer Alert
        let alerts: IAppNotificationItemAddProps[] = [];
        if (returnObj.Kennel?.Owner?.Contact?.ContactAlert) {
          alerts.push({
            title: `Contact alert: ${returnObj.Kennel?.Owner?.Contact?.ContactAlert}`,
            type: "warning",
            autoClose: false,
          });
        }
        if (returnObj.Kennel?.AdditonalHolder?.Contact?.ContactAlert) {
          alerts.push({
            title: `Contact alert: ${returnObj.Kennel?.AdditonalHolder?.Contact?.ContactAlert}`,
            type: "warning",
            autoClose: false,
          });
        }
        // Property Alert
        if (returnObj.Kennel?.Address?.PropertyAlert) {
          alerts.push({
            title: `Property alert: ${returnObj.Kennel?.Address?.PropertyAlert}`,
            type: "warning",
            autoClose: false,
          });
        }
        // Set alerts
        if (alerts?.length > 0) {
          this.setAlerts(alerts);
        }
      } else {
        errorResponse = {
          status: responseHandler.status,
          error: responseHandler.data?.Errors ?? responseHandler.error,
        };
      }
      if (!isNew && response?.data?.ReturnObj?.Kennel) {
        if (
          response?.data?.ReturnObj?.Kennel.Sys_DBRowState ===
          DBRowState.Inactive
        ) {
          this.setIsInactive(true);
          appNotificationStore.clearNotifications();
          appNotificationStore.pushNotification({
            autoClose: false,
            title: "Important Note",
            type: "info",
            description:
              "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
          });
        } else {
          this.setIsInactive(false);
        }
      }

      // Push alerts
      if (this.alerts && this.alerts.length > 0) {
        this.alerts.forEach((alert) => {
          appNotificationStore.pushNotification(alert);
        });
        this.setAlerts(undefined);
      }
    } else {
      const responseError = response as APIResponse;
      errorResponse = {
        status: responseError.status,
        error: responseError.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  loadKennelHandler = async (
    params: KennelFormHandlerRequest,
    errorMsg: string
  ) => {
    this.setIsLoading(true);
    const response = await kennelHandler(params);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setIsFormModified(true);
      const kennel = response.data.ReturnObj.Kennel;
      if (kennel?.Address) {
        this.setInitData({ ...this.initData, Address: kennel.Address });
      }
      this.setAnimalsKennel(kennel);
      this.setUIControl(response.data.ReturnObj.UIControl);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: errorMsg,
        type: "error",
        description: response.data?.Errors || response.error,
      });
    }
    this.setIsLoading(false);
  };

  loadDetails = async (id: number) => {
    this.setIsLoadingDetails(true);
    this.setResponseDetailLoadError(undefined);
    const response = await getAnimalsKennelById(id, true);
    this.setIsLoadingDetails(false);
    if (isSuccessResponse(response) && response.data?.ReturnObj) {
      this.setAnimalsKennel(response.data.ReturnObj.Kennel);
      this.setOSFee(response?.data?.ReturnObj?.OSFee ?? 0);
      this.setUIControl(response.data.ReturnObj.UIControl);
    } else {
      this.setResponseDetailLoadError({
        status: response.status,
        error: response.data?.Errors ?? response.error,
      });
    }
  };

  saveAnimalsKennel = async (
    data: AnimalsKennel,
    action: TypeSubmitActions
  ) => {
    this.setIsLoading(true);
    const response = await saveAnimalKennel(data);
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      if (this.animalKennelId)
        await this.loadAnimalsKennelById(this.animalKennelId);
      this.runActions(action, response);
    } else {
      const responseError = response as APIResponse;
      appNotificationStore.pushNotification({
        autoClose: false,
        title: "Save animal kennel failed.",
        type: "error",
        description: responseError.data?.Errors ?? response?.statusText,
      });
    }
  };

  runActions = (action: TypeSubmitActions, response?: APIResponse) => {
    switch (action) {
      case TypeSubmitActions.Save:
        appNotificationStore.clearErrorNotification();
        appNotificationStore.pushNotification({
          title: "Animal kennel saved successfully.",
          type: "success",
        });
        break;
      case TypeSubmitActions.New:
        appNotificationStore.clearErrorNotification();
        history.replace(`${ANIMALS_KENNEL_MANAGE_PAGE}/${response?.data?.ID}`, {
          notification: [
            {
              title: "Animal kennel created successfully.",
              type: "success",
            },
          ],
        });
        break;
      case TypeSubmitActions.IssueRegistration:
        this.setIsShowKennelIssueRegistrationDialog(true);
        break;
      case TypeSubmitActions.Renew:
        this.setIsShowKennelRenewDialog(true);
        break;
      case TypeSubmitActions.Deregister:
        this.setIsShowKennelDeregisterDialog(true);
        break;
      case TypeSubmitActions.Reactivate:
        this.setIsShowKennelReactivateDialog(true);
        break;
      default:
        break;
    }
  };

  handleWorkFlowIssueRegistration = async (
    kennelFormObj: AnimalsKennel,
    dateFromDateTo: DateFromDateTo,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    appNotificationStore.clearErrorNotification();
    this.setIsWorkflowLoading(true);
    let kennelWorkflow = {
      Kennel: kennelFormObj,
      DateFromDateTo: dateFromDateTo,
      isReIssue: false,
    };
    if (this.isReIssue) kennelWorkflow.isReIssue = this.isReIssue;
    const response = await postKennelIssueRegistration(
      kennelWorkflow as Svc_Kennel_Workflow_DateFromDateTo
    );
    this.setIsWorkflowLoading(false);
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setAnimalsKennel(response.data.ReturnObj);
      this.setIsShowKennelIssueRegistrationDialog(false);
      if (this.isReIssue) {
        appNotificationStore.pushNotification({
          type: "success",
          title: "Reactivate successfully.",
        });
      } else {
        appNotificationStore.pushNotification({
          type: "success",
          title: "Issue registration successfully.",
        });
      }
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Issue registration failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
    this.setIsReIssue(false);
  };

  handleWorkFlowRenew = async (
    kennelFormObj: AnimalsKennel,
    dateFromDateTo: DateFromDateTo,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    appNotificationStore.clearErrorNotification();
    this.setIsWorkflowLoading(true);
    const response = await postKennelRenew({
      Kennel: kennelFormObj,
      DateFromDateTo: dateFromDateTo,
    } as Svc_Kennel_Workflow_DateFromDateTo);
    this.setIsWorkflowLoading(false);
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setAnimalsKennel(response.data.ReturnObj);
      this.setIsShowKennelRenewDialog(false);
      appNotificationStore.pushNotification({
        type: "success",
        title: "Renew successfully.",
      });
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Renew failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
  };

  handleWorkFlowDeregister = async (
    kennelFormObj: AnimalsKennel,
    provideReason: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    appNotificationStore.clearErrorNotification();
    this.setIsWorkflowLoading(true);
    const response = await postKennelDeregister({
      Kennel: kennelFormObj,
      ProvideReason: provideReason,
    });
    this.setIsWorkflowLoading(false);
    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setAnimalsKennel(response.data.ReturnObj);
      this.setIsShowKennelDeregisterDialog(false);
      appNotificationStore.pushNotification({
        type: "success",
        title: "Deregister successfully.",
      });
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Deregister failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
  };

  handleWorkFlowReactivate = async (
    kennelFormObj: AnimalsKennel,
    provideReason: ProvideReason,
    notificationRef: React.MutableRefObject<ICCLocalNotificationHandle | null>
  ) => {
    appNotificationStore.clearErrorNotification();
    this.setIsWorkflowLoading(true);
    const response = await postKennelReactivate({
      Kennel: kennelFormObj,
      ProvideReason: provideReason,
    });
    this.setIsWorkflowLoading(false);

    if (isSuccessIdentityPacket(response) && response?.data?.ReturnObj) {
      this.setAnimalsKennel(response.data.ReturnObj);
      this.setIsReIssue(true);
      this.setIsShowKennelIssueRegistrationDialog(true);
      this.setIsShowKennelReactivateDialog(false);
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: "Reactivate failed.",
        type: "error",
        description: response.data?.Errors ?? response.statusText,
      });
    }
  };
}

const kennelStoreContext = createContext(new AnimalKennelStore());
export const useAnimalsKennelStore = () => {
  return useContext(kennelStoreContext);
};
