import {
  PERMIT_TYPE_BY_ID_API,
  PERMIT_TYPE_LOVS_API,
} from "@app/products/local-laws/system-admin/permit-types/[id]/constant";
import {
  ILoadPermitTypeById,
  PermitType,
  TApiPermitType,
  TApiPermitTypeResponse,
} from "@app/products/local-laws/system-admin/permit-types/[id]/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { IdentityPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const getPermitTypeById = async (
  permitTypeId: number,
  cancelToken?: CancelToken
): Promise<TApiPermitTypeResponse["Value"]> => {
  try {
    return await CoreAPIService.getClient().get<TApiPermitType["Value"]>(
      PERMIT_TYPE_BY_ID_API.replace("[id]", `${permitTypeId}`),
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const getPermitTypeFormById = async (
  permitTypeId: number,
  cancelToken: CancelToken
): Promise<ILoadPermitTypeById> => {
  try {
    return await Promise.all([
      CoreAPIService.getClient().get<TApiPermitType["Lovs"]>(
        PERMIT_TYPE_LOVS_API,
        {
          cancelToken,
        }
      ),
      CoreAPIService.getClient().get<TApiPermitType["Value"]>(
        PERMIT_TYPE_BY_ID_API.replace("[id]", `${permitTypeId}`),
        { cancelToken }
      ),
    ]);
  } catch (error) {
    return handleCatchResponse(error);
  }
};

export const postSavePermitType = async (
  permitTypeObj: PermitType
): Promise<APIResponse<IdentityPacket | undefined>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      "api/locallaw/internal/permit-type",
      permitTypeObj
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
