import { CORE_OFFICERS_ODATA } from "@app/core/components/common/constant";
import { CS_CustomFlag } from "@app/products/crms/[id]/model";
import { EventView } from "@app/products/crms/all-events/by-refno/model";
import { calculatorDynamicWidthIconColumn } from "@app/products/crms/util";
import { SettingsMap } from "@app/products/property/system-admin/settings/model";

import { RegisterType } from "@app/products/town-planning/ppr/enquiries/_id/model";
import {
  BOOLEAN_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { RECORDSSYSTEM } from "@common/constants/enumerations";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { AssetsSystem } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/assets/model";
import { globalStoreInstance } from "@common/stores/global/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { TextCell } from "@components/cc-grid/components/grid-cells/text/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { State } from "@progress/kendo-data-query";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import {
  GRID_COL_INDEX_ATTRIBUTE,
  GridCellProps,
} from "@progress/kendo-react-grid";
import { cloneDeep } from "lodash";
import React, { ReactElement } from "react";

export const CRMS_PRODUCT_NAME = "CX";

const MOCK_URL = "";
export const CrmsUrlApi = {
  // TODO
  // Get Lov of CRMS Form Event
  GET_CRMS_FORM_EVENT_LOVS: "",
  // Crms CRUD API
  CREATE_CRMS: "",
  GET_CRMS: "",
  UPDATE_CRMS: "",
  DELETE_CRMS: "",

  // Crms Form OData
  GET_FORM_CRMS: "",

  // Crms View OData
  GET_VIEW_CRMS_ISSUE_LOCATION_RESERVE: `/odata/crms/internal/events/GetCurrentLocationRegister(registerType=${RegisterType.Reserve})`,
  GET_VIEW_CRMS_ISSUE_LOCATION_COMMUNITY_PROPERTY: `/odata/crms/internal/events/GetCurrentLocationRegister(registerType=${RegisterType.CommunityProperty})`,

  // Actions InputPicker ODATA
  // @TODO: Need to correct right Url
  GET_CRMS_EVENT_OFFICER: CORE_OFFICERS_ODATA,

  // Mock Crms View OData
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_MYEVENTS: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_MYCOORDINATORPERSONEVENTS: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYACTIONPERSON: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYCOORDINATORPERSON: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYREFNO: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYCATEGORY: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYSTREET: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYSUBURB: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYDUESTATUS: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYSTATUS: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYORGSTRUCTURE: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYASSIGNEDTOORG: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYRECORDEDBY: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYWORKDATE: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYSPECIALINTEREST: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYRECIPIENT: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_ACKNOWLEDGEMENTREQ: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_RESENT: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_DRAFTS: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_HAZZARDCOMPLEX: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_VIP: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_HELLO: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_CSADMIN: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_BYASSET: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_REFLECT: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_HARDRUBBISH: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_AFTERHOURSSERVICE: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_QUALITYCHECK: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_INCIDENTS: MOCK_URL,
  GET_VIEW_CRMS_UNRESOLVEDEVENTS_NEWEVENTS: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_BYACTIONPERSON: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_BYCOORDINATORPERSON: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_BYREFNO: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_BYCATEGORY: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_BYSTREET: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_BYSUBURB: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_BYSTATUS: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_BYORGSTRUCTURE: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_BYRECORDEDBY: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_MYCOORDINATORPERSONEVENTS: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_BYASSET: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_REFLECT: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_HARDRUBBISH: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_AFTERHOURSSERVICE: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_HAZZARDCOMPLEX: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_HELLO: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_VIP: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_QUALITYCHECK: MOCK_URL,
  GET_VIEW_CRMS_ALLEVENTS_INCIDENTS: MOCK_URL,
  GET_VIEW_CRMS_COUNCILLOREVENTS_MYEVENTS: MOCK_URL,
  GET_VIEW_CRMS_COUNCILLOREVENTS_UNRESOLVEDBYCOUNCILLOR: MOCK_URL,
  GET_VIEW_CRMS_COUNCILLOREVENTS_ALLBYCOUNCILLOR: MOCK_URL,
  GET_VIEW_CRMS_COUNCILLOREVENTS_ALLBYORG1: MOCK_URL,
  GET_VIEW_CRMS_COUNCILLOREVENTS_ALLBYWARD: MOCK_URL,
  GET_VIEW_CRMS_EXTERNALUSEREVENTS_UNRESOLVEDBYREFNO: MOCK_URL,
  GET_VIEW_CRMS_EXTERNALUSEREVENTS_UNRESOLVEDBYACTIONPERSON: MOCK_URL,
  GET_VIEW_CRMS_EXTERNALUSEREVENTS_ALLBYREFNO: MOCK_URL,
  GET_VIEW_CRMS_EXTERNALUSEREVENTS_ALLBYACTIONPERSON: MOCK_URL,
  GET_VIEW_CRMS_HARDRUBBISH_NOTCOLLECTED: MOCK_URL,
  GET_VIEW_CRMS_HARDRUBBISH_ALLCOLLECTIONS: MOCK_URL,
  GET_VIEW_CRMS_HARDRUBBISH_ALLEVENTS: MOCK_URL,
  GET_VIEW_CRMS_HARDRUBBISH_COLLECTIONLOOKUP: MOCK_URL,
  GET_VIEW_CRMS_PHONEMESSAGES_OPENMESSAGES: MOCK_URL,
  GET_VIEW_CRMS_PHONEMESSAGES_ALLMESSAGES: MOCK_URL,
  GET_VIEW_CRMS_INSPECTIONS_BYDATE: MOCK_URL,
  GET_VIEW_CRMS_INSPECTIONS_BYTYPE: MOCK_URL,
  GET_VIEW_CRMS_INSPECTIONS_BYOFFICER: MOCK_URL,
  GET_VIEW_CRMS_INSPECTIONS_BYMONTH: MOCK_URL,
  GET_VIEW_CRMS_NOTICES_BYDATE: MOCK_URL,
  GET_VIEW_CRMS_NOTICES_BYDUESTATUS: MOCK_URL,
  GET_VIEW_CRMS_ITSUPPORT_UNALLOCATEDEVENTS: MOCK_URL,
  GET_VIEW_CRMS_ITSUPPORT_ALLEVENTS: MOCK_URL,
  GET_VIEW_CRMS_ITSUPPORT_UNRESOLVEDEVENTS: MOCK_URL,
  GET_VIEW_CRMS_PAYROLL_MYEVENTS: MOCK_URL,
  GET_VIEW_CRMS_PAYROLL_MYDRAFTS: MOCK_URL,
  GET_VIEW_CRMS_PAYROLL_ALLMYEVENTS: MOCK_URL,
  GET_VIEW_CRMS_PAYROLL_UNRESOLVEDEVENTS: MOCK_URL,
  GET_VIEW_CRMS_PAYROLL_ALLEVENTS: MOCK_URL,
  GET_VIEW_CRMS_PAYROLL_DRAFTS: MOCK_URL,
  GET_VIEW_CRMS_KNOWLEDGEBASE_PUBLISHED: MOCK_URL,
  GET_VIEW_CRMS_KNOWLEDGEBASE_PUBLISHEDBYORGSTRUCTURE: MOCK_URL,
  GET_VIEW_CRMS_KNOWLEDGEBASE_UNRELEASED: MOCK_URL,
  GET_VIEW_CRMS_KNOWLEDGEBASE_DUEFORREVIEW: MOCK_URL,
  GET_VIEW_CRMS_KNOWLEDGEBASE_UNDERREVIEW: MOCK_URL,
  GET_VIEW_CRMS_KNOWLEDGEBASE_EXPIRED: MOCK_URL,
  GET_VIEW_CRMS_KNOWLEDGEBASE_DRAFTS: MOCK_URL,
  GET_VIEW_CRMS_KNOWLEDGEBASE_NOSERVICESTANDARD: MOCK_URL,
  GET_VIEW_CRMS_KNOWLEDGEBASE_ALERTS: MOCK_URL,
  GET_VIEW_CRMS_KNOWLEDGEBASE_TOPICS: MOCK_URL,
  GET_VIEW_CRMS_SERVICESTANDARDS_BYNAME: MOCK_URL,
  GET_VIEW_CRMS_SERVICESTANDARDS_BYACTIONPERSON: MOCK_URL,
  GET_VIEW_CRMS_SERVICESTANDARDS_BYCATEGORY: MOCK_URL,
  GET_VIEW_CRMS_SERVICESTANDARDS_BYCOORDINATORPERSON: MOCK_URL,
  GET_VIEW_CRMS_SERVICESTANDARDS_BYORGSTRUCTURE: MOCK_URL,
  GET_VIEW_CRMS_SERVICESTANDARDS_DEACTIVATEDSVCSTDS: MOCK_URL,
  GET_VIEW_CRMS_SERVICESTANDARDS_DEACTIVATEDORGS: MOCK_URL,
  GET_VIEW_CRMS_SYSTEMADMIN_CATEGORIES: MOCK_URL,
  GET_VIEW_CRMS_SYSTEMADMIN_LOCATIONREGISTER: MOCK_URL,
  GET_VIEW_CRMS_SYSTEMADMIN_LOOKUPTABLES: MOCK_URL,
  GET_VIEW_CRMS_SYSTEMADMIN_ROSTERS: MOCK_URL,
};

enum CallReturnEnumDisplay {
  "None" = 0,
  "ReturnCallRequired" = 1,
  "CallReturned" = 2,
}

enum CallHazardEnumDisplay {
  "None" = 0,
  "Hazard" = 1,
}

enum CallReturnEnumFilter {
  "None" = 0,
  "Return call required" = 1,
  "Call returned" = 2,
}

enum HazardEnumFilter {
  "Is not hazard" = 0,
  "Is hazard" = 1,
}

const CallReturnIcon = ({ dataItem }: { dataItem: EventView }) => {
  switch (dataItem.CallReturn) {
    case CallReturnEnumDisplay.ReturnCallRequired:
      return (
        <i
          title="Return Call Required"
          className="far fa-phone-alt"
          style={{
            color: "var(--error)",
          }}
        />
      );
    case CallReturnEnumDisplay.CallReturned:
      return (
        <i
          title="Call Returned"
          className="far fa-phone-alt"
          style={{
            color: "var(--success)",
          }}
        />
      );
    default:
      return null;
  }
};

const HazardIcon = ({ dataItem }: { dataItem: EventView }) => {
  switch (dataItem?.Hazard) {
    case CallHazardEnumDisplay.Hazard:
      return <i title="Hazard" className="far fa-bolt" />;
    case CallHazardEnumDisplay.None:
      return null;
    default:
      return null;
  }
};

export const AlertIcon = ({ dataItem }: { dataItem: EventView }) => {
  return dataItem?.HasAlert ? (
    <i
      title="Alert"
      className="fas fa-bell-exclamation"
      style={{
        color: "var(--warning)",
      }}
    />
  ) : null;
};

export const IconCell = ({
  props,
  children,
}: {
  props: GridCellProps;
  children: ReactElement;
}) => {
  const {
    ariaColumnIndex,
    columnIndex,
    colSpan,
    isSelected,
    className,
    id,
    style,
  } = props;
  const navigationAttributes = useTableKeyboardNavigation(id + "");
  return (
    <td
      style={style}
      className={`${className}`}
      colSpan={colSpan}
      role={"gridcell"}
      aria-colindex={ariaColumnIndex}
      aria-selected={isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: columnIndex,
      }}
      {...navigationAttributes}
    >
      {children}
    </td>
  );
};

// Icon cloumns for list view
const nameOf = nameOfFactory<EventView>();
export const iconColumnViewConfigUrgent: IColumnFields[] = [
  {
    field: nameOf("Urgent"),
    title: "Urgent",
    format: ICON_FORMAT.ICON_URGENT,
    iconHeaderCell: (
      <i title="Urgent" className="far fa-exclamation-triangle" />
    ),
    dynamicStyle: (state: State) => {
      // Get dynamic width column
      return {
        width: calculatorDynamicWidthIconColumn(55, state, "Urgent"),
      };
    },
  },
];

export const iconColumnViewConfigCallReturn: IColumnFields[] = [
  {
    field: nameOf("CallReturn"),
    title: "Call Return",
    format: NUMBER_FORMAT.NUMBER2,
    filterByEnum: CallReturnEnumFilter,
    cell: (props: GridCellProps) => {
      return (
        <IconCell props={props}>
          <CallReturnIcon dataItem={props.dataItem} />
        </IconCell>
      );
    },
    iconHeaderCell: <i title="Call Return" className="far fa-phone-alt" />,
    dynamicStyle: (state: State) => {
      return {
        // Get dynamic width column
        width: calculatorDynamicWidthIconColumn(55, state, "CallReturn"),
      };
    },
  },
];

export const columnViewConfigLocation: IColumnFields = {
  field: nameOf("Location"),
  title: "Location",
  cell: (props: GridCellProps) => {
    const { dataItem } = props;
    let newDataItem = dataItem?.Location
      ? dataItem.Location?.split("|")?.[1] || dataItem.Location || ""
      : "";
    newDataItem = newDataItem.trim().replace(/^-\s|\s-$/gm, "");
    return <TextCell value={newDataItem} />;
  },
};

export const iconColumnViewConfigHazard: IColumnFields[] = [
  {
    field: nameOf("Hazard"),
    title: "Hazard",
    format: NUMBER_FORMAT.NUMBER2,
    filterByEnum: HazardEnumFilter,
    cell: (props: GridCellProps) => {
      return (
        <IconCell props={props}>
          <HazardIcon dataItem={props.dataItem} />
        </IconCell>
      );
    },
    iconHeaderCell: <i title="Hazard" className="far fa-bolt" />,
    dynamicStyle: (state: State) => {
      return {
        // Get dynamic width column
        width: calculatorDynamicWidthIconColumn(55, state, "Hazard"),
      };
    },
  },
];

export const iconColumnViewConfigAlert: IColumnFields[] = [
  {
    field: nameOf("HasAlert"),
    title: "Alert",
    format: BOOLEAN_FORMAT.BOOLEAN,
    cell: (props: GridCellProps) => {
      return (
        <IconCell props={props}>
          <AlertIcon dataItem={props.dataItem} />
        </IconCell>
      );
    },
    iconHeaderCell: <i title="Alert" className="fas fa-bell-exclamation" />,
    dynamicStyle: (state: State) => {
      return {
        // Get dynamic width column
        width: calculatorDynamicWidthIconColumn(55, state, "HasAlert"),
      };
    },
  },
];

export interface IGenerateEventsColumnsBySettingProps {
  defaultConfig: IColumnFields[];
  settings?: SettingsMap;
}

// Use for show/hide columns event - control from settings
export const generateEventsColumnsBySetting = ({
  settings,
  defaultConfig = [],
}: IGenerateEventsColumnsBySettingProps) => {
  if (!settings) return defaultConfig;
  const isVisibleITSupport =
    globalStoreInstance.isVisibleAndIsCommunityCentral(
      PRODUCT_TYPE.ITSupport
    ) === "visible";

  const recordsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_RecordsSystem]
  );
  const customFlag1Workflow = getNumberValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Event_CustomFlag1_Workflow]
  );
  const customFlag2Workflow = getNumberValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Event_CustomFlag2_Workflow]
  );
  const customFlag3Workflow = getNumberValueSetting(
    settings[ECorporateSettingsField.CUSTOMERSERVICE_Event_CustomFlag3_Workflow]
  );

  const assetsSystem = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_AssetsSystem]
  );

  const checkCustomFlagWorkflow = (flag: CS_CustomFlag) => {
    if (
      customFlag1Workflow === flag ||
      customFlag2Workflow === flag ||
      customFlag3Workflow === flag
    ) {
      return true;
    }
    return false;
  };

  let newConfig: IColumnFields[] = cloneDeep(defaultConfig);
  return newConfig.filter((col) => {
    switch (col.field) {
      case nameOf("FileNumber"):
        if (recordsSystem === RECORDSSYSTEM.None) {
          return false;
        }
        return true;
      case nameOf("Flag_Safety"):
        if (checkCustomFlagWorkflow(CS_CustomFlag.Safety)) {
          return true;
        }
        return false;
      case nameOf("Flag_Risk"):
        if (checkCustomFlagWorkflow(CS_CustomFlag.Risk)) {
          return true;
        }
        return false;
      case nameOf("Flag_VIP"):
        if (checkCustomFlagWorkflow(CS_CustomFlag.VIP)) {
          return true;
        }
        return false;
      case nameOf("EnquiryRefNo"):
        if (assetsSystem !== AssetsSystem.None) {
          return true;
        }
        return false;
      case nameOf("Flag_ITSupport"):
        if (isVisibleITSupport) {
          return true;
        }
        return false;
      default:
        return true;
    }
  });
};

// Regex to check valid email
export const emailManualEntriesRegex = new RegExp(
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/
);
