import { eventEmitter } from "@/App";
import { VO_Workflow_Draft } from "@app/products/property/actions/model";
import { listSubmitButton } from "@app/products/property/assessments/components/form-steps/new-assessment/config";

import {
  getInitialEditChangeOfOwnership,
  postProcessEditChangeOfOwnership,
} from "@app/products/property/changes-of-ownership/components/form-steps/edit-change-of-ownership/api";
import { ChangeOfOwnershipFormStep } from "@app/products/property/changes-of-ownership/components/form-steps/edit-change-of-ownership/components/element-steps/change-of-ownership/_index";
import { DTO_Workflow_EditChangeOfOwnership } from "@app/products/property/changes-of-ownership/components/form-steps/edit-change-of-ownership/model";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { useConfirmFinishDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-finish/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { DocumentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/_index";
import { INewProcessWorkflow } from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  ResponseMessageWorkflow,
  WorkflowProcessMode,
  WorkflowTypes,
} from "@app/products/property/model";
import { isShowParkButton } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { useCommonProductStore } from "@common/stores/products/store";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { isNil } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import { EditChangeOfOwnershipOfSteps } from "./config";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";

interface IEditChangeOfOwnershipDialog {
  onClose: () => void;
  dataFromActionList?: VO_Workflow_Draft;
  isSaveOnNextStep?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  changeOfOwnershipId?: number;
  actionAfterFinish?: (
    data: DTO_Workflow_EditChangeOfOwnership,
    res: ResponseMessageWorkflow<DTO_Workflow_EditChangeOfOwnership>
  ) => void;
}
export const EditChangeOfOwnershipDialog = ({
  onClose,
  isSaveOnNextStep = false,
  dataFromActionList,
  changeOfOwnershipId,
  prefixTitle,
  suffixTitle,
  actionAfterFinish,
}: IEditChangeOfOwnershipDialog) => {
  //#region <Store>
  const { currentFormTitle } = useCommonProductStore();
  const { pushNotification } = useCCAppNotificationStore();
  //set data for modes
  const { setDataForCancelDialog } = useConfirmCancelDialogStore();
  const { setDataForFinishDialog } = useConfirmFinishDialogStore();
  const { setDataForCloseDialog, setIsLoadingClose } =
    useConfirmCloseDialogStore();
  const {
    isIncompleteMode,
    isReadOnly,
    statusBadge,
    isShowCancelWorkflowButton,
    isToBeApprovalMode,
    isFromActionList,
  } = usePropertyWorkflow(dataFromActionList);
  //#endregion

  //#region <Local state>
  const formStepRef = useRef<ICCFormStepNotificationHandle | null>(null);
  const [workflowInitData, setWorkflowInitData] =
    useState<DTO_Workflow_EditChangeOfOwnership>();
  const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
    WorkflowDraft: { Workflow_Draft_Id: 0 },
    AvailableSecondaryWorkflows: [],
    WorkflowApprovals: [],
  });
  const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
  const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
  const [isLoadingProcess, setIsLoadingProcess] = useState<
    WorkflowProcessMode | undefined
  >();
  //#endregion

  //#region <Title header>
  const titleHeader = useMemo(() => {
    const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
    const title = currentFormTitle(formId ?? 0) || "Edit Change of Ownership";
    return getTitleWorkflow(title, prefixTitle, suffixTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixTitle, suffixTitle, workflowHeader]);
  //#endregion

  //region <Initial value>
  const initialValue = useMemo((): any => {
    let initChangeOfOwnership: any = {
      ...workflowInitData?.WorkflowDetail?.COODetails,
    };
    return {
      [EditChangeOfOwnershipOfSteps.ChangeOfOwnership]: initChangeOfOwnership,
      [EditChangeOfOwnershipOfSteps.Documents]: {},
      [EditChangeOfOwnershipOfSteps.Comments]: {},
    };
  }, [workflowInitData]);
  //#endregion

  const steps: IStep[] = [
    {
      label: "Change of ownership",
      initialValues:
        initialValue[EditChangeOfOwnershipOfSteps.ChangeOfOwnership],
      component: ChangeOfOwnershipFormStep,
      visible: true,
      key: EditChangeOfOwnershipOfSteps.ChangeOfOwnership,
      options: {
        isReadOnly,
      },
    },
    {
      label: "Comments",
      component: CommentsFormStep,
      visible: true,
      key: EditChangeOfOwnershipOfSteps.Comments,
      customClassName: "cc-comment-step-fixed-height-grid",
      options: {
        isReadOnly,
        workflowDraftId,
        recordType: RECORDTYPE.CommunityProperty_Change_of_Ownership,
      },
      initialValues: initialValue[EditChangeOfOwnershipOfSteps.Comments],
    },
    {
      label: "Documents",
      component: DocumentsFormStep,
      visible: true,
      key: EditChangeOfOwnershipOfSteps.Documents,
      options: {
        isReadOnly,
        workflowDraftId,
        workflowType: WorkflowTypes.Edit_Change_Of_Ownership,
      },
      initialValues: initialValue[EditChangeOfOwnershipOfSteps.Documents],
    },
  ];

  //#region handle Cancel Button>
  const handleCancelButton = (data: any) => {
    if (isFromActionList || !isFirstSave) {
      setDataForCancelDialog({
        cancelAPI: postProcessEditChangeOfOwnership,
        dataCancel: data,
        defaultSuccessMessage:
          "Edit change of ownership was cancelled successfully.",
        defaultErrorMessage: "Edit change of ownership could not be cancelled.",
      });
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Handle close dialog>
  /**
   * @param renderProps
   */
  const handleCloseDialog = (renderProps: ICCFormStepRender) => {
    if (!isFromActionList && !isFirstSave) {
      //Store submit event
      setDataForCloseDialog({
        closeCallback: renderProps.submitButton.onClick,
      });
    } else if (
      isIncompleteMode &&
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
    ) {
      onClose();
    } else if (
      dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Incomplete &&
      !isFirstSave
    ) {
      const newEvent = {
        currentTarget: { id: EListSubmitButton.Close },
      };
      renderProps.submitButton.onClick(newEvent);
    } else {
      onClose();
    }
  };
  //#endregion

  //#region <Handle save and next>
  const handleSaveAndNext = async (
    payload: DTO_Workflow_EditChangeOfOwnership
  ): Promise<boolean> => {
    //check condition use for Save button
    setIsLoadingProcess(WorkflowProcessMode.Save);
    //Calling process Save at next button
    const response = await postProcessEditChangeOfOwnership(
      WorkflowProcessMode.Save,
      payload
    );
    setIsLoadingProcess(undefined);
    //set default notification
    const defaultSuccessMessage =
      "Edit change of ownership was saved successfully.";
    const defaultFailedMessage = "Edit change of ownership could not be saved.";
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      if (!isSaveOnNextStep) {
        onClose();
        pushNotification({
          title: response?.data?.Notification ?? defaultSuccessMessage,
          type: "success",
        });
      } else {
        // check is the first saving
        if (isFirstSave) {
          setIsFirstSave(false);
          //set current workflowDraft Id
          setWorkflowDraftId(response?.data?.ID ?? 0);
          // set payload to send
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID,
            },
          });
        }
      }
      return true;
    } else {
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
    return false;
  };
  //#endregion

  //#region <Handle next button>
  /**
   * @param data
   * @param step
   * @param keyStep
   * @returns
   */
  const handleNextButton = async (data: DTO_Workflow_EditChangeOfOwnership) => {
    const newData = { ...data };
    const processPayload = processData(newData);
    //send data to call api save
    return handleSaveAndNext(processPayload);
  };
  //#endregion

  //#region <Handle park process>
  /**
   * @param payload
   * @param mode
   */
  const handleParkProcess = async (
    payload: DTO_Workflow_EditChangeOfOwnership,
    mode?: EListSubmitButton
  ) => {
    //set loading button and dialog
    setIsLoadingProcess(WorkflowProcessMode.Park);
    mode === EListSubmitButton.ConfirmCloseYes && setIsLoadingClose(true);
    const actionCloseRetainDialog = () => {
      setIsLoadingClose(false);
      setDataForCloseDialog();
    };
    //props send to process workflow
    const parkProps: INewProcessWorkflow<DTO_Workflow_EditChangeOfOwnership> = {
      payload: payload,
      actionSuccess: (e) => {
        onClose();
        pushNotification({
          title:
            e?.Notification ??
            "Edit change of ownership was parked successfully.",
          type: "success",
        });
        isFromActionList && eventEmitter.emit(CCGridEventType.RefreshOData);
      },
      setLoading: () => {
        setIsLoadingProcess(undefined);
      },
      actionClose: () => {
        mode === EListSubmitButton.ConfirmCloseYes && actionCloseRetainDialog();
      },
      defaultFailedMessage: "Edit change of ownership could not be parked.",
      modeProcess: WorkflowProcessMode.Park,
    };

    //calling api process workflow
    await handleProcessWorkflow(parkProps);
  };
  //#endregion

  //#region
  const handleSubmit = async (data: any, buttonId?: string) => {
    switch (buttonId) {
      case EListSubmitButton.Finish:
        handleConfirmFinish(data);
        break;
      case EListSubmitButton.Cancel:
      case EListSubmitButton.ConfirmCloseNo:
        handleCancelButton(processData(data));
        break;
      case EListSubmitButton.ConfirmCloseYes:
        await handleParkProcess(
          processData(data),
          EListSubmitButton.ConfirmCloseYes
        );
        break;
      case EListSubmitButton.Park:
      case EListSubmitButton.Close:
        await handleParkProcess(processData(data));
        break;
    }
  };
  //#endregion

  const processData = (data: any) => {
    let workflowDetail: any = {};
    const newData = { ...data };
    const certificateDetail =
      newData[`${EditChangeOfOwnershipOfSteps.ChangeOfOwnership}`];
    workflowDetail.COODetails = certificateDetail;
    return {
      WorkflowHeader: workflowHeader,
      WorkflowDetail: workflowDetail,
    };
  };

  //#region <Handle process workflow>
  /**
   * common function
   * handle calling api with multiple process
   * @param props
   */
  const handleProcessWorkflow = async (
    props: INewProcessWorkflow<DTO_Workflow_EditChangeOfOwnership>
  ) => {
    const {
      actionSuccess,
      defaultFailedMessage,
      setLoading,
      modeProcess,
      payload,
    } = props;
    const response = await postProcessEditChangeOfOwnership(
      modeProcess,
      payload
    );
    setLoading();
    if (isSuccessResponse(response)) {
      if (response?.data?.IsSuccess) {
        actionSuccess(response?.data);
      } else {
        if (props?.actionFail) props?.actionFail(response);
        formStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    } else {
      if (props?.actionFail) props?.actionFail(response);
      formStepRef?.current
        ?.getNotificationFormStep()
        ?.current?.pushNotification({
          title: response?.data?.ErrorMessage ?? defaultFailedMessage,
          type: "error",
          autoClose: false,
        });
    }
    if (props?.actionClose) props.actionClose();
  };
  //#endregion

  //#region <Handle finish process>
  /**
   * @param payload
   */
  const handleFinishProcess = async (
    payload: DTO_Workflow_EditChangeOfOwnership
  ) => {
    //set loading button
    setIsLoadingProcess(WorkflowProcessMode.Finish);
    //props send to process workflow
    const finishProps: INewProcessWorkflow<DTO_Workflow_EditChangeOfOwnership> =
      {
        payload: payload,
        actionSuccess: (e) => {
          const notificationContent: IAppNotificationItemAddProps = {
            title:
              e?.Notification ??
              "Change of ownership was updated successfully.",
            type: "success",
          };
          onClose();
          pushNotification(notificationContent);
          if (actionAfterFinish) {
            actionAfterFinish(payload, e);
          }
        },
        setLoading: () => {
          setIsLoadingProcess(undefined);
        },
        defaultFailedMessage: "Change of ownership could not be updated.",
        modeProcess: WorkflowProcessMode.Finish,
      };
    //calling api process workflow
    await handleProcessWorkflow(finishProps);
  };
  //#endregion

  //#region <Handle confirm finish>
  const handleConfirmFinish = (payload: DTO_Workflow_EditChangeOfOwnership) => {
    const dataProcessed = processData(payload);
    const finishCallback = function async() {
      return handleFinishProcess(dataProcessed);
    };
    setDataForFinishDialog({
      finishCallback,
      confirmMessage:
        "The change of ownership will be updated based on the information provided. Are you sure you want to submit?",
    });
  };
  //#endregion

  //#region <Get workflow data>
  /**
   * load initValue for FormStep
   * call once time
   */
  const getWorkflowData = async () => {
    const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;
    formStepRef?.current?.setLoadingFormStep(true);
    const response = await getInitialEditChangeOfOwnership(
      workflowDraftId,
      isFromActionList,
      changeOfOwnershipId
    );
    formStepRef?.current?.setLoadingFormStep(false);
    if (isSuccessResponse(response) && response?.data) {
      setWorkflowInitData(response?.data);
      if (response.data?.WorkflowHeader) {
        setWorkflowHeader(response.data?.WorkflowHeader);
        setWorkflowDraftId(
          response.data?.WorkflowHeader?.WorkflowDraft?.Workflow_Draft_Id ?? 0
        );
      }
    } else {
      let responseError: APIResponse<
        DTO_Workflow_EditChangeOfOwnership | ResponsePacket
      > = response;
      formStepRef?.current?.setLoadFailedFormStep({
        onReload: () => getWorkflowData(),
        responseError: {
          status: responseError.status,
          error:
            (responseError.data as ResponsePacket)?.Errors ??
            "Load workflow failed",
        },
      });
    }
  };
  //#endregion

  //#region <Use Effect calling initial data>
  useEffectOnce(() => {
    getWorkflowData();
  });
  //#endregion

  return (
    <CCFormStep
      ref={formStepRef}
      onSubmit={handleSubmit}
      initialSteps={steps}
      initialValues={initialValue}
      listButtonId={listSubmitButton}
      saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
      renderForm={(renderProps: ICCFormStepRender) => (
        <CCDialog
          maxWidth="60%"
          titleHeader={titleHeader}
          onClose={() => handleCloseDialog(renderProps)}
          bodyElement={renderProps.children}
          badge={statusBadge}
          disabled={isLoadingProcess !== undefined}
          footerElement={
            <>
              <div className={"cc-dialog-footer-actions-right"}>
                {isShowParkButton(isFromActionList, isIncompleteMode) && (
                  <Button
                    iconClass={
                      isLoadingProcess === WorkflowProcessMode.Park
                        ? "fas fa-spinner fa-spin"
                        : ""
                    }
                    className={"cc-dialog-button"}
                    id={EListSubmitButton.Park}
                    onClick={renderProps.submitButton.onClick}
                    disabled={
                      renderProps.nextButton.disabled ||
                      isLoadingProcess === WorkflowProcessMode.Park
                    }
                  >
                    Park
                  </Button>
                )}
                {isShowCancelWorkflowButton && (
                  <Button
                    className={"cc-dialog-button"}
                    disabled={!isNil(isLoadingProcess)}
                    id={EListSubmitButton.Cancel}
                    onClick={renderProps.submitButton.onClick}
                  >
                    Cancel
                  </Button>
                )}
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  themeColor="primary"
                  id={
                    !renderProps.isLastStep &&
                    (isToBeApprovalMode || isReadOnly)
                      ? "cc-next-step-button"
                      : renderProps.nextButton.idButton
                  }
                  disabled={
                    isLoadingProcess === WorkflowProcessMode.Save ||
                    renderProps.nextButton.disabled
                  }
                  iconClass={
                    isLoadingProcess === WorkflowProcessMode.Save
                      ? "fas fa-spinner fa-spin"
                      : ""
                  }
                  className={"cc-dialog-button"}
                  onClick={renderProps.nextButton.onClick}
                >
                  {isLoadingProcess === WorkflowProcessMode.Save
                    ? "Saving"
                    : renderProps.nextButton.label}
                </Button>
              </div>
            </>
          }
        />
      )}
    />
  );
};
