import { DTO_Documents } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/documents/model";
import {
  DTO_Request_Base,
  DTO_WorkflowDetail_Base,
  DTO_Workflow_Base,
} from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfStepsCOONoS {
  Type = "Type",
  Details = "COODetails",
  Property = "PropertyDetails",
  Names = "Names", // @TODO - 14306: Hidden for LLS, will be handled in other task
  Comments = "Comments",
  Documents = "Documents",
  Workflow = "Workflow",
}

export const keysOfSendStepsCOONoS = [
  EKeysOfStepsCOONoS.Details,
  EKeysOfStepsCOONoS.Property,
  EKeysOfStepsCOONoS.Documents,
];

export interface DTO_ChangeOfOwnership_NoticeOfSale_LOVs {
  ChangeOfOwnershipTypes: DTO_COO_Type[];
  NoticeGroups: DTO_LOV[];
  Roles: DTO_Role[];
  NameFormat: DTO_LOV;
  OwnerNameFormat: DTO_LOV;
}

export interface DTO_Role {
  Role_Id: number;
  Role_Name: string;
  Role_Type_Id: number;
  Role_Type_Name: string;
  IsOwner: boolean | null;
  IsRatepayer: boolean | null;
  IsAssociatedName: boolean | null;
  IsPICRole: boolean | null;
  IsRegisterAccount: boolean | null;
}

export interface DTO_COO_Type {
  Change_of_Ownership_Type: number;
  COOT_Name: string;
  COOT_Clear_Rebates: boolean;
  COOT_Display_Rebates: boolean;
  COOT_Display_Percentage: boolean;
  COOT_Display_Owner_Roles: boolean;
  COOT_Display_Associated_Names: boolean;
  COOT_Display_RatePayer_Roles: boolean;
  COOT_Display_PIC_Roles: boolean;
  COOT_Display_Register_Roles: boolean;
  COOT_Display_Retain_AssociatedNames: boolean;
  COOT_Display_Retain_Occupier: boolean;
  COOT_Retain_Occupier: boolean;
  COOT_Retain_AssociatedNames: boolean;
}

export interface RequestChangeOfOwnershipObj extends DTO_Request_Base {
  NoticeOfSale_Id: number | null;
  Assessment_Group_Id: number | null;
}

export interface DTO_Workflow_ChangeOfOwnership_NoticeOfSale
  extends DTO_Workflow_Base {
  WorkflowDetail: DTO_WorkflowDetail_ChangeOfOwnership_NoticeOfSale;
}

export interface DTO_WorkflowDetail_ChangeOfOwnership_NoticeOfSale
  extends DTO_WorkflowDetail_Base {
  COODetails: DTO_ChangeOfOwnerShip_Detail;
  PropertyDetails: DTO_PropertyDetails;
  Documents: DTO_Documents;
}

export interface DTO_ChangeOfOwnerShip_Detail {
  Change_Of_Ownership_Id: number;
  Notice_Of_Sale_Id: number;
  Type: number;
  Reference: string;
  EffectiveDate: Date;
  SalePrice: number | null;
  Chattels: number | null;
  Comment: string;
  Contract_Date: Date | null;
}

export interface DTO_PropertyDetails {
  Assessments: DTO_Assessment[];
  Titles: DTO_Title[];
  Pics: DTO_PIC[];
  RetainAssociatedNames: boolean;
}

export interface DTO_Assessment {
  Assessment_Id: number;
  Assessment_Number: string;
  Property_Address: string;
  Occupier_Name_Address: string;
}

export interface DTO_Title {
  Legal_Description: string;
  Reference_Number: number | null;
  Current_Lot_Owner: string;
  Title_Id: number;
  Assessment_Id: number | null;
  LRS_Title_Id: number;
  Formatted_Name_Address: string;
  LRS_Title_Owners: DTO_TitleLrsOwner[];
}

export interface DTO_PIC {
  PIC_Id: number;
  Property_Address: string;
  PIC_Number: string;
  Pic_Manager: string;
  Trading_Name: string;
}

export interface DTO_TitleLrsOwner {
  Id: string;
  LRS_NoS_Owners_Name_Id: number | null;
  LRS_NoS_Previous_Owner_Id: number | null;
  Title_Id: number;
  Family_Name: string;
  Given_Name: string;
  Corporate_Miscellaneous_Name: string;
  Address: string;
  IsPreviousOwner: boolean;
}
