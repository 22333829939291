import { eventEmitter } from "@/App";
import { useConfirmCancelDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-cancel/store";
import { useConfirmCloseDialogStore } from "@app/products/property/components/action-bar/property-workflow/component/dialogs/confirm-close/store";
import { CommentsFormStep } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/comments/_index";
import { usePropertyWorkflow } from "@app/products/property/components/action-bar/property-workflow/component/hooks/useProprtyWorkflow/usePropertyWorkflow";
import {
  IProcessWorkflow,
  TypesActionsWorkflow,
} from "@app/products/property/components/action-bar/property-workflow/model";
import { getTitleWorkflow } from "@app/products/property/components/action-bar/property-workflow/util";
import {
  getInitialDataAuthoriseJournal,
  postProcessAuthoriseParcel,
} from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/api";
import { AuthoriseFormStep } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/components/form-elements/authorise/_index";
import { listSubmitButton } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/config";
import { mockInitDataAuthoriseStep } from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/mock";
import {
  AuthoriseType,
  DTO_Journal_LOVs,
  DTO_WorkflowDetail_JournalAuthorise,
  DTO_Workflow_JournalAuthorise,
  EAuthoriseJournalSteps,
} from "@app/products/property/journals/[id]/components/forms/existed/components/dialogs/authorise/model";
import {
  DTO_WorkflowHeader,
  EListSubmitButton,
  EWorkflowStatus,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { convertValueLOVToNumber } from "@app/products/property/util";
import { APIResponse } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ResponsePacket } from "@common/models/identityPacket";
import { DTO_LOV } from "@common/models/odataResponse";
import { useCommonProductStore } from "@common/stores/products/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDialog } from "@components/cc-dialog/_index";
import {
  CCFormStep,
  ICCFormStepNotificationHandle,
  ICCFormStepRender,
} from "@components/cc-form-step/_index";
import { IStep } from "@components/cc-form-step/model";
import { CCGridEventType } from "@components/cc-grid/constant";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import { cloneDeep, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";

interface IAuthoriseJournalDialogProps {
  onClose: () => void;
  onSubmit?: (data: any) => void;
  dataFromActionList?: any;
  isSaveOnNextStep?: boolean;
  prefixTitle?: string;
  suffixTitle?: string;
  reloadJournal?: () => void;
  typeList?: TypesActionsWorkflow;
  journalNumber: number;
}

export const AuthoriseJournalDialog = observer(
  (props: IAuthoriseJournalDialogProps) => {
    //Props
    const {
      dataFromActionList,
      onClose = () => {},
      isSaveOnNextStep,
      prefixTitle,
      suffixTitle,
      reloadJournal = () => {},
      typeList,
      journalNumber,
    } = props;
    //State
    const [isLoadingOnNext, setIsLoadingOnNext] = useState<boolean>(false);
    const notificationFormStepRef =
      useRef<ICCFormStepNotificationHandle | null>(null);
    const [initialData, setInitialData] =
      useState<DTO_Workflow_JournalAuthorise>();
    const [lovOfficer, setLovOfficer] = useState<DTO_LOV[]>();
    const [workflowHeader, setWorkflowHeader] = useState<DTO_WorkflowHeader>({
      WorkflowDraft: { Workflow_Draft_Id: 0 },
      AvailableSecondaryWorkflows: [],
      WorkflowApprovals: [],
    });
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true);
    const [workflowDraftId, setWorkflowDraftId] = useState<number>(0);
    const [finishConfirmDialogData, setFinishConfirmDialogData] =
      useState<any>();
    const [isLoadingFinish, setIsLoadingFinish] = useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

    //Store
    const { currentFormTitle } = useCommonProductStore();
    const { pushNotification } = useCCAppNotificationStore();
    const { setDataForCloseDialog, setIsLoadingClose } =
      useConfirmCloseDialogStore();
    const { setDataForCancelDialog } = useConfirmCancelDialogStore();
    const {
      isIncompleteMode,
      isFromActionList,
      isReadOnly,
      statusBadge,
      isShowCancelWorkflowButton,
      isToBeApprovalMode,
      isBeingApprovedMode,
    } = usePropertyWorkflow(dataFromActionList);

    const initialValues = useMemo(() => {
      let initAuthorisationStep: any = {};
      if (initialData) {
        //Store all workflowDetail into Authorisation step
        initAuthorisationStep = { ...initialData?.WorkflowDetail };

        //set 'Authorising_Officer_Id = 0' as default
        initAuthorisationStep.Journal.Authorising_Officer_Id = 0;

        //Init options values
        const { Assigned_to_Authorise_Officer_Id, Jnl_Can_Be_Authorised } =
          initialData?.WorkflowDetail?.Journal;
        initAuthorisationStep = {
          ...initAuthorisationStep,
          _options: {
            //Options:
            // + Assign authorisation to
            // + Authorise journal
            Authorise: Jnl_Can_Be_Authorised
              ? AuthoriseType.AuthoriseJournals
              : !isNil(Assigned_to_Authorise_Officer_Id)
              ? AuthoriseType.AssignAuthorisationTo
              : null,
            //Officer Data
            AssignAuthorisationToData:
              convertValueLOVToNumber<DTO_LOV>(lovOfficer ?? [], "Code") ?? [],
          },
        };
      }
      return {
        [EAuthoriseJournalSteps.Authorise]: { ...initAuthorisationStep },
        [EAuthoriseJournalSteps.Comments]: {},
      };
    }, [initialData, lovOfficer]);

    const getWorkflowData = async () => {
      notificationFormStepRef?.current?.setLoadingFormStep(true);
      const workflowDraftId = dataFromActionList?.Workflow_Draft_Id;

      const responseDataAuthoriseJournal = await getInitialDataAuthoriseJournal(
        workflowDraftId,
        journalNumber
      );
      let errorResponse = undefined;

      if (Array.isArray(responseDataAuthoriseJournal)) {
        const [lovsJournal, workflowData] = responseDataAuthoriseJournal;
        if (
          isSuccessResponse(responseDataAuthoriseJournal[0]) &&
          isSuccessResponse(responseDataAuthoriseJournal[1]) &&
          lovsJournal?.data &&
          workflowData?.data
        ) {
          //set LOV Data
          setLovOfficer(lovsJournal?.data?.Officer ?? []);
          //Set Init Data
          setInitialData(workflowData.data);
          //set Workflow header
          setWorkflowHeader(workflowData?.data?.WorkflowHeader);
        } else {
          let responseError: APIResponse<
            DTO_Journal_LOVs | DTO_Workflow_JournalAuthorise | ResponsePacket
          > = responseDataAuthoriseJournal[0];
          if (!isSuccessResponse(responseDataAuthoriseJournal[1])) {
            responseError = responseDataAuthoriseJournal[1];
          }
          errorResponse = {
            status: responseError.status,
            error:
              (responseError.data as ResponsePacket)?.Errors ??
              "Initial data load failed",
          };
        }
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        if (errorResponse) {
          notificationFormStepRef?.current?.setLoadFailedFormStep({
            onReload: () => getWorkflowData(),
            responseError: errorResponse,
          });
        }
      } else {
        const responseError = responseDataAuthoriseJournal as APIResponse;
        notificationFormStepRef?.current?.setLoadingFormStep(false);
        notificationFormStepRef?.current?.setLoadFailedFormStep({
          onReload: () => getWorkflowData(),
          responseError: {
            status: responseError.status,
            error: "Load workflow failed",
          },
        });
      }
    };

    const titleHeader = useMemo(() => {
      const formId = workflowHeader?.WorkflowDraft?.WD_Form_Id;
      const title = currentFormTitle(formId ?? 0) || "Authorise Journal";

      return getTitleWorkflow(title, prefixTitle, suffixTitle);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prefixTitle, suffixTitle, workflowHeader]);

    useEffectOnce(() => {
      getWorkflowData();
    });

    const steps: IStep[] = [
      {
        label: "Authorise",
        component: AuthoriseFormStep,
        initialValues: mockInitDataAuthoriseStep,
        visible: true,
        key: EAuthoriseJournalSteps.Authorise,
        options: {
          isReadOnly,
          workflowDraftId,
          isLoadingOnNext,
        },
      },
      {
        label: "Comments",
        component: CommentsFormStep,
        key: EAuthoriseJournalSteps.Comments,
        visible: true,
        options: {
          isReadOnly,
          workflowDraftId,
          recordType: RECORDTYPE.CommunityProperty_Journal,
        },
        customClassName: "cc-comment-step-fixed-height-grid",
      },
    ];

    const handleNextButton = async (data: any) => {
      setIsLoadingOnNext(true);
      //clone Authorise data step
      let workflowDetail: DTO_WorkflowDetail_JournalAuthorise =
        cloneDeep(data)?.[EAuthoriseJournalSteps.Authorise];

      if (workflowDetail) {
        //Remove options data
        if (workflowDetail._options) {
          delete workflowDetail._options;
        }
      }

      return handleSaveAndNext({
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      });
    };

    /**
     * handle save & next workflow
     * @param payload
     * @param isCloseDialog
     * @returns Promise<boolean>
     */
    const handleSaveAndNext = async (
      payload: DTO_Workflow_JournalAuthorise,
      isCloseDialog: boolean = false
    ): Promise<boolean> => {
      //check condition use for Save button
      isCloseDialog && setIsLoadingSave(true);
      //Calling process Save at next button
      const response = await postProcessAuthoriseParcel(
        WorkflowProcessMode.Save,
        payload
      );

      setIsLoadingOnNext(false);
      //set default notification
      const defaultSuccessMessage = "Authorise journal was saved successfully.";
      const defaultFailedMessage = "Authorise journal could not be saved.";

      if (isSuccessResponse(response) && response?.data?.IsSuccess) {
        if (isCloseDialog) {
          setIsLoadingSave(false);
          onClose();
          pushNotification({
            title: response?.data?.Notification ?? defaultSuccessMessage,
            type: "success",
          });
        }
        // check is the first saving
        if (isFirstSave) {
          setIsFirstSave(false);
          //set current workflowDraft Id
          setWorkflowDraftId(response?.data?.ID ?? 0);
          // set payload to send
          setWorkflowHeader({
            ...workflowHeader,
            WorkflowDraft: {
              ...workflowHeader.WorkflowDraft,
              Workflow_Draft_Id: response?.data?.ID,
            },
          });
        }
        return true;
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
          });
        return false;
      }
    };

    const handleSubmit = async (data: any, buttonId?: string) => {
      switch (buttonId) {
        case EListSubmitButton.Save:
          await handleSaveAndNext(processData(data), true);
          break;
        case EListSubmitButton.Finish:
          setFinishConfirmDialogData(data);
          break;
        case EListSubmitButton.Cancel:
        case EListSubmitButton.ConfirmCloseNo:
          await handleCancelButton(processData(data));
          break;
        case EListSubmitButton.ConfirmCloseYes:
          await handleConfirmRetainProcess(processData(data));
          break;
        case EListSubmitButton.Park:
        case EListSubmitButton.Close:
          await handleParkProcess(processData(data));
          break;
      }
    };

    /**
     * handle park process
     * @param payload
     */
    const handleParkProcess = async (
      payload: DTO_Workflow_JournalAuthorise
    ) => {
      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_JournalAuthorise> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.SuccessMessage ?? "Authorise journal was parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Authorise journal could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, () => {});
    };

    const processData: DTO_Workflow_JournalAuthorise | any = (data: any) => {
      //clone Authorise data step
      let workflowDetail: DTO_WorkflowDetail_JournalAuthorise =
        cloneDeep(data)?.[EAuthoriseJournalSteps.Authorise];

      if (workflowDetail) {
        //Remove options data
        if (workflowDetail._options) {
          delete workflowDetail._options;
        }
      }

      return {
        WorkflowHeader: workflowHeader,
        WorkflowDetail: workflowDetail,
      };
    };

    const handleConfirmFinish = async () => {
      await handleFinishProcess(
        processData(finishConfirmDialogData, WorkflowProcessMode.Finish)
      );
    };

    /**
     * handle confirm retain workflow process
     * @param payload
     */
    const handleConfirmRetainProcess = async (
      payload: DTO_Workflow_JournalAuthorise
    ) => {
      //set loading button and dialog
      setIsLoadingClose(true);

      //props send to process workflow
      const parkProps: IProcessWorkflow<DTO_Workflow_JournalAuthorise> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title:
              e?.SuccessMessage ?? "Authorise journal parked successfully.",
            type: "success",
          });
        },
        defaultFailedMessage: "Authorise journal could not be parked.",
        modeProcess: WorkflowProcessMode.Park,
      };

      const setLoading = () => {
        setIsLoadingClose(false);
        setDataForCloseDialog(undefined);
      };

      //calling api process workflow
      await handleProcessWorkflow(parkProps, setLoading);
    };

    /**
     * handle finish process
     * @param payload
     */
    const handleFinishProcess = async (
      payload: DTO_Workflow_JournalAuthorise
    ) => {
      //set loading button
      setIsLoadingFinish(true);

      //props send to process workflow
      const finishProps: IProcessWorkflow<DTO_Workflow_JournalAuthorise> = {
        payload: payload,
        actionSuccess: (e) => {
          onClose();
          pushNotification({
            title: e?.SuccessMessage ?? "Journal authorised successfully.",
            type: "success",
          });
          if (!typeList) {
            reloadJournal();
          } else {
            eventEmitter.emit(CCGridEventType.RefreshOData);
          }
        },
        defaultFailedMessage: "Journal could not be authorised.",
        modeProcess: WorkflowProcessMode.Finish,
      };

      const setLoading = () => {
        setIsLoadingFinish(false);
      };
      //calling api process workflow
      await handleProcessWorkflow(finishProps, setLoading);
      //set close dialog confirm before finish
      setFinishConfirmDialogData(undefined);
    };

    /**
     * Handle cancel process
     */
    const handleCancelButton = async (data: any) => {
      if (typeList || !isFirstSave) {
        setDataForCancelDialog({
          cancelAPI: postProcessAuthoriseParcel,
          dataCancel: data,
          defaultSuccessMessage:
            "Authorise journal was cancelled successfully.",
          defaultErrorMessage: "Authorise journal could not be cancelled.",
        });
      } else {
        onClose();
      }
    };

    /**
     * common function
     * handle calling api with multiple process
     * @param props
     */
    const handleProcessWorkflow = async (
      props: IProcessWorkflow<DTO_Workflow_JournalAuthorise>,
      setLoading: () => void
    ) => {
      const { payload, actionSuccess, defaultFailedMessage, modeProcess } =
        props;

      const response = await postProcessAuthoriseParcel(modeProcess, payload);

      if (isSuccessResponse(response)) {
        setLoading();
        if (response?.data?.IsSuccess) {
          actionSuccess(response?.data);
        } else {
          notificationFormStepRef?.current
            ?.getNotificationFormStep()
            ?.current?.pushNotification({
              title: response.data?.ErrorMessage ?? defaultFailedMessage,
              type: "error",
              autoClose: false,
            });
        }
      } else {
        notificationFormStepRef?.current
          ?.getNotificationFormStep()
          ?.current?.pushNotification({
            title: response?.data?.ErrorMessage ?? defaultFailedMessage,
            type: "error",
            autoClose: false,
          });
      }
    };

    const handleCloseDialog = (renderProps: ICCFormStepRender) => {
      if (!isFromActionList && !isFirstSave) {
        //Store submit event
        setDataForCloseDialog({
          closeCallback: renderProps.submitButton.onClick,
        });
      } else if (
        isIncompleteMode &&
        dataFromActionList?.Workflow_Status_Name === EWorkflowStatus.Park
      ) {
        onClose();
      } else if (
        dataFromActionList?.Workflow_Status_Name ===
          EWorkflowStatus.Incomplete &&
        !isFirstSave
      ) {
        const newEvent = {
          currentTarget: { id: EListSubmitButton.Close },
        };
        renderProps.submitButton.onClick(newEvent);
      } else {
        onClose();
      }
    };

    return (
      <CCFormStep
        ref={notificationFormStepRef}
        listButtonId={listSubmitButton}
        onSubmit={handleSubmit}
        initialSteps={steps}
        initialValues={initialValues}
        saveOnNextStep={isSaveOnNextStep ? handleNextButton : undefined}
        renderForm={(renderProps: ICCFormStepRender) => (
          <CCDialog
            maxWidth="50%"
            height="60%"
            badge={statusBadge}
            titleHeader={titleHeader}
            onClose={() => handleCloseDialog(renderProps)}
            bodyElement={renderProps.children}
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                {!isReadOnly && isToBeApprovalMode && !isBeingApprovedMode && (
                  <Button
                    iconClass={isLoadingSave ? "fas fa-spinner fa-spin" : ""}
                    disabled={renderProps.nextButton.disabled || isLoadingSave}
                    className={"cc-dialog-button"}
                    id={EListSubmitButton.Save}
                    onClick={renderProps.submitButton.onClick}
                  >
                    Save
                  </Button>
                )}
                {isShowCancelWorkflowButton && (
                  <Button
                    className={"cc-dialog-button"}
                    disabled={
                      isLoadingFinish || isLoadingOnNext || isLoadingSave
                    }
                    id={EListSubmitButton.Cancel}
                    onClick={renderProps.submitButton.onClick}
                  >
                    Cancel
                  </Button>
                )}
                {!renderProps.prevButton.disabled && (
                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={renderProps.prevButton.onClick}
                  >
                    Previous
                  </Button>
                )}
                {isToBeApprovalMode || isReadOnly ? (
                  !renderProps.isLastStep && (
                    <Button
                      themeColor="primary"
                      id="cc-next-step-button"
                      disabled={
                        isLoadingOnNext || renderProps.nextButton.disabled
                      }
                      className={"cc-dialog-button"}
                      iconClass={
                        isLoadingOnNext ? "fas fa-spinner fa-spin" : ""
                      }
                      onClick={renderProps.nextButton.onClick}
                    >
                      {isLoadingOnNext
                        ? "Saving"
                        : renderProps.nextButton.label}
                    </Button>
                  )
                ) : (
                  <Button
                    themeColor="primary"
                    id={renderProps.nextButton.idButton}
                    disabled={
                      isLoadingOnNext || renderProps.nextButton.disabled
                    }
                    iconClass={isLoadingOnNext ? "fas fa-spinner fa-spin" : ""}
                    className={"cc-dialog-button"}
                    onClick={renderProps.nextButton.onClick}
                  >
                    {isLoadingOnNext ? "Saving" : renderProps.nextButton.label}
                  </Button>
                )}
                {finishConfirmDialogData && (
                  <ConfirmDialog
                    title="Confirmation"
                    subMessage="The journal will be authorised based on the information provided. Are you sure you want to submit?"
                    isLoadingYes={isLoadingFinish}
                    onClosePopup={() => setFinishConfirmDialogData(undefined)}
                    onAsyncConfirm={handleConfirmFinish}
                  />
                )}
              </div>
            }
          />
        )}
      />
    );
  }
);
